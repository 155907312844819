"use strict"

import {connectDiscriminated} from "../../utils/aj";
import {EntitiesStore} from "../../../stores/entities";
import AbstractDossierGrid from "./abstractDossierGrid";
import {updateQuery} from "../../../actions/entities";

export default class DossierGrid extends AbstractDossierGrid {
    constructor(props) {
        super(props)
        this.type = props.type
        this.discriminator = "entity_grid_" + this.getEntity()
        let _query = this.getInitialQuery();

        this.state = {grid: null, result: null, query: _query}
        this.state.query.on("change", () => {
            if (!this.state.query.hasFilter("type")) {
                this.state.query.die()
                this.state.query.eq("type", this.type)
                this.state.query.hideFilter("type")
                this.state.query.live()
            }
            updateQuery({discriminator: this.discriminator, query: this.state.query});
            this.onQueryChanged()
        })



        connectDiscriminated(this.discriminator, this, [EntitiesStore])
    }
}