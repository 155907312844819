"use strict";

import * as config from "../framework/config";
import {get, postJson} from "./utils";
import {format} from "../utils/lang";

export function generateQuotationDossier_2022(dossierType, dto) {
    return postJson(config.get("dossiers2022.url") + format("/{0}/generateQuotation", dossierType), dto)
}

export function editDossier_2022(dossierType, dto) {
    return postJson(config.get("dossiers2022.url") + format("/{0}/edit", dossierType), dto)
}

export function createDossier_2022(dossierType, dto) {
    return postJson(config.get("dossiers2022.url") + format("/{0}/create", dossierType), dto)
}

export function generateQuotationAmounts_2022(dossierType, data) {
    if (data["continuationWork"] == null) {
        delete data["continuationWork"];
    }
    return get(config.get("dossiers2022.url") + format("/{0}/quotation/amounts", dossierType), data)
}

export function confirmQuotation_2022(dossierType, dto) {
    return postJson(config.get("dossiers2022.url") + format("/{0}/confirmQuotation", dossierType), dto)
}
