import M from "../strings";
import {TextCell, TextCellWithSubText} from "./components/grids";
import {
    AreaNoCard,
    Button,
    Column,
    DateTime,
    FORM_FOOTER,
    MultiLineText,
    Number,
    OnlyTextInfo,
    ReadOnlyText,
    ReadOnlyTextArea,
    Select,
    Spacer,
    Switch,
    Text,
    TextArea,
    YesNo
} from "./components/forms";
import {ValuesLookupContainer, ValuesSelectContainer} from "./components/containers";
import {
    AssignationType,
    BlindsPresentsValues, dateNewLabel,
    DocumentStatus,
    DOSSIER_TYPE,
    DossierStatus,
    DossierStatusDatasource,
    getBlindsPresentsValueDescription,
    getCustomerTypeDescription,
    getDossierEntityNameFromType,
    getDossierStatusPosition,
    getKPMGStatusDescription,
    KPMGStatusDatasource,
    QUOTATION_TYPES_ONLY_BLIND,
    WORK_COMPLETION_DOCUMENTS_CODE
} from "../model/vars";
import * as datasource from "../utils/datasource";
import {StatusCell} from "./components/customDossierGrids";
import {DossierStore} from "../stores/dossier";
import {updateFormData} from "../actions/entities";
import {getUrlParameter} from "./utils/ui";
import _, {isUndefined} from "underscore";
import {isDifferent, optional} from "../utils/lang";
import {isSuperuser} from "../api/session";
import {DossierAddDocumentDialog} from "./components/dossiers/dossierAddDocumentDialog";
import {DossierRefuseDocumentDialog} from "./components/dossiers/dossierRefuseDocumentDialog";
import {currencyFormat} from "../utils/customUtils";
import {confirmQuotation, generateQuotationAmounts, KPMGSendForReview, restoreCanDownload} from "../actions/dossier";
import {PriceCalculatorControl} from "./components/dossiers/priceCalculatorControl";
import {DossierDocumentContainer} from "./components/dossiers/dossierDocumentContainer";
import moment from "moment";
import * as config from "../framework/config";
import {
    formatEuroNumber,
    formatNumber,
    isQuotationValueVisible,
    isQuotationValueVisibleWithoutBlind
} from "./components/dossiers/utils";
import {valuesSelectContainerField} from "./commonFields";
import {DossierFormFooter} from "./components/dossiers/dossierForm";

const FABRICATORS_WITHOUT_TAKEN_MEASURES_CONTROL = [
    "5dbb32cb3f6d8b28dc9add6d"
    ]
;

let takenMeasureValidator = (value, model) => {
    if (model.get("_fabricator") == null || FABRICATORS_WITHOUT_TAKEN_MEASURES_CONTROL.includes(model.get("_fabricator").id))
        return;
    let takenMeasures = optional(model.get("takenMeasures"), 0);
    if (takenMeasures === "" || takenMeasures === "-")
        takenMeasures = 0;
    let takenMeasuresWithoutBlind = optional(model.get("takenMeasuresWithoutBlind"), 0);
    if (takenMeasuresWithoutBlind === "" || takenMeasuresWithoutBlind === "-")
        takenMeasuresWithoutBlind = 0;

    let invalid = (model.get("type") === DOSSIER_TYPE.ECOBONUS_2020_50 || model.get("type") === DOSSIER_TYPE.ECOBONUS_2021_50 || model.get("type") === DOSSIER_TYPE.ECOBONUS_2022_1) && (model.get("blindsPresentsType") === BlindsPresentsValues.BOTH.value || model.get("blindsPresentsType") === BlindsPresentsValues.YES.value) && (parseFloat(takenMeasures) + parseFloat(takenMeasuresWithoutBlind) < 200);

    let timeData = new Date(dateNewLabel).getTime();
    let updateData = (model.get("type") === DOSSIER_TYPE.ECOBONUS_2021_50 || model.get("type") === DOSSIER_TYPE.ECOBONUS_2022_1)  && (model.get("creationDate") && model.get("creationDate") > timeData) || (model.get("creationDate") == null && new Date().getTime() > timeData)

    if (!updateData && invalid) {
        throw new Error("La somma dei valori deve essere maggiore di 200");
    }
}

export const dossierEntity = {
    grid: {
        canDelete: () => {
            return false;
        },
        getTitle: (type) => {
            return M(type)
        },
        descriptor: {
            columns: [
                {
                    property: "dossierCode",
                    header: M("#"),
                    cell: TextCellWithSubText,
                    sortable: true,
                    searchable: true,
                    searchForm: {
                        searchTitle: M("filterByDate"),
                        showInCard: false,
                        fields: [
                            {
                                property: "_dateFrom",
                                label: M("from"),
                                control: Text,
                                filterType: "eq",
                            },
                            {
                                property: "_dateTo",
                                label: M("to"),
                                control: Text,
                                filterType: "eq",
                            }
                        ]
                    },
                    props: {
                        formatterTitle: (v) => {
                            return v != null ? v : "";
                        },
                        formatterSubtitle: (v, data) => {
                            return v != null ? moment(data.creationDate).format("DD/MM/YYYY") : "";
                        }
                    }
                },
                {
                    property: "customer",
                    header: M("customer"),
                    cell: TextCellWithSubText,
                    sortable: true,
                    searchable: true,
                    searchForm: {
                        showInCard: false,
                        fields: [
                            {
                                property: "customerId",
                                label: M("customer"),
                                control: ValuesSelectContainer,
                                filterType: "eq",
                                props: {
                                    id: "dossier_customer",
                                    mode: "single",
                                    allowNull: true,
                                    searchEnabled: true,
                                    collection: "customers",
                                    getSingleItemLabel: (value) => {
                                        return value.name
                                    },
                                    getSingleItemValue: (value) => {
                                        return value.id
                                    },
                                    formatter: v => {
                                        return v != null ? v.name : "";
                                    }
                                }
                            },
                        ]
                    },
                    props: {
                        formatterTitle: (v) => {
                            return v != null ? v.name : "";
                        },
                        formatterSubtitle: (v) => {
                            return v != null ? getCustomerTypeDescription(v.subjectType) : "";
                        }
                    }
                },
                {
                    property: "fabricator",
                    header: M("fabricator"),
                    cell: TextCellWithSubText,
                    sortable: true,
                    searchable: true,
                    searchForm: {
                        showInCard: false,
                        fields: [
                            {
                                property: "fabricatorId",
                                label: M("fabricator"),
                                control: ValuesSelectContainer,
                                filterType: "eq",
                                props: {
                                    id: "dossier_fabricator",
                                    mode: "single",
                                    allowNull: true,
                                    searchEnabled: true,
                                    collection: "fabricators",
                                    getSingleItemLabel: (value) => {
                                        return value.businessName
                                    },
                                    getSingleItemValue: (value) => {
                                        return value.id
                                    },
                                    formatter: v => {
                                        return v != null ? v.businessName : "";
                                    }
                                }
                            },
                            {
                                property: "fabricatorCategoryId",
                                label: M("referenceToUserCategory"),
                                control: ValuesSelectContainer,
                                filterType: "eq",
                                props: {
                                    id: "fabricator_category",
                                    mode: "single",
                                    allowNull: true,
                                    searchEnabled: true,
                                    collection: "fabricatorCategories",
                                    getSingleItemLabel: (value) => {
                                        return value.description
                                    },
                                    getSingleItemValue: (value) => {
                                        return value.id
                                    },
                                    formatter: v => {
                                        return v != null ? v.description : "";
                                    }
                                }
                            },
                        ]
                    },
                    props: {
                        formatterTitle: (v) => {
                            return v != null ? v.businessName : "";
                        },
                        formatterSubtitle: (v) => {
                            return v != null && v.category? v.category.description : "";
                        }
                    }
                },
                {
                    property: "recommendedPrice",
                    header: M("amount"),
                    cell: TextCell,
                    sortable: true,
                    searchable: false,

                    props: {
                        formatter: v => {

                            let value = v;
                            if (v && v.recommendedRetailPrice) {
                                value = v.recommendedRetailPrice
                            }
                            return formatEuroNumber(value)
                        }
                    }
                },
                {
                    property: "canBeCandidate",
                    header: M("canBeCandidate"),
                    cell: TextCell,
                    sortable: true,
                    searchable: true,
                    isVisible(data) {
                        return isSuperuser()
                    },
                    searchForm: {
                        showInCard: false,
                        fields: [
                            {
                                property: "canBeCandidate",
                                label: M("canBeCandidate"),
                                control: Select,
                                filterType: "eq",
                                props: {
                                    allowNull: true,
                                    datasource: datasource.fixed([
                                        {label: M("yes"), value: true},
                                        {label: M("no"), value: false},
                                    ]),
                                },
                            }
                        ]
                    },
                    props: {
                        formatter: v => {
                            return v != null && v ? M("yes") : M("no");
                        }
                    }
                },
                {
                    property: "candidateAE",
                    header: M("candidateAE"),
                    cell: TextCell,
                    sortable: true,
                    searchable: true,
                    isVisible(data) {
                        return isSuperuser()
                    },
                    searchForm: {
                        showInCard: false,
                        fields: [
                            {
                                property: "candidateAE",
                                label: M("candidateAE"),
                                control: Select,
                                filterType: "eq",
                                props: {
                                    allowNull: true,
                                    datasource: datasource.fixed([
                                        {label: M("yes"), value: true},
                                        {label: M("no"), value: false},
                                    ]),
                                },
                            }
                        ]
                    },
                    props: {
                        formatter: v => {
                            return v != null && v ? M("yes") : M("no");
                        }
                    }
                },
                {
                    property: "candidateENEA",
                    header: M("candidateENEA"),
                    cell: TextCell,
                    sortable: true,
                    isVisible(data) {
                        return isSuperuser()
                    },
                    searchable: true,
                    searchForm: {
                        showInCard: false,
                        fields: [
                            {
                                property: "candidateENEA",
                                label: M("candidateENEA"),
                                control: Select,
                                filterType: "eq",
                                props: {
                                    allowNull: true,
                                    datasource: datasource.fixed([
                                        {label: M("yes"), value: true},
                                        {label: M("no"), value: false},
                                    ]),
                                },
                            }
                        ]
                    },
                    props: {
                        formatter: v => {
                            return v != null && v ? M("yes") : M("no");
                        }
                    }
                },
                {
                    property: "dl157",
                    header: M("dl157"),
                    cell: TextCell,
                    isVisible: (data) => {
                        return data === getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2021_50)

                    },
                    sortable: true,
                    searchable: true,
                    searchForm: {
                        showInCard: false,
                        fields: [
                            {
                                property: "dl157",
                                label: M("dl157"),
                                control: Select,
                                filterType: "eq",
                                props: {
                                    allowNull: true,
                                    datasource: datasource.fixed([
                                        {label: M("yes"), value: true},
                                        {label: M("no"), value: false},
                                    ]),
                                },
                            }
                        ]
                    },
                    props: {
                        formatter: v => {
                            return v != null && v ? M("yes") : M("no");
                        }
                    }
                },
                {
                    property: "freeConstruction",
                    header: M("freeConstruction"),
                    cell: TextCell,
                    isVisible: (data) => {
                        return data === getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2022_1)

                    },
                    sortable: true,
                    searchable: true,
                    searchForm: {
                        showInCard: false,
                        fields: [
                            {
                                property: "freeConstruction",
                                label: M("freeConstruction"),
                                control: Select,
                                filterType: "eq",
                                props: {
                                    allowNull: true,
                                    datasource: datasource.fixed([
                                        {label: M("yes"), value: true},
                                        {label: M("no"), value: false},
                                    ]),
                                },
                            }
                        ]
                    },
                    props: {
                        formatter: v => {
                            return v != null && v ? M("yes") : M("no");
                        }
                    }
                },
                {
                    property: "serviceFeeInvoiced",
                    header: M("serviceFeeInvoiced"),
                    cell: TextCell,
                    sortable: true,
                    isVisible(data) {
                        return isSuperuser()
                    },
                    searchable: true,
                    searchForm: {
                        showInCard: false,
                        fields: [
                            {
                                property: "serviceFeeInvoiced",
                                label: M("serviceFeeInvoiced"),
                                control: Select,
                                filterType: "eq",
                                props: {
                                    allowNull: true,
                                    datasource: datasource.fixed([
                                        {label: M("yes"), value: true},
                                        {label: M("no"), value: false},
                                    ]),
                                },
                            }
                        ]
                    },
                    props: {
                        formatter: v => {
                            return v != null && v ? M("yes") : M("no");
                        }
                    }
                },
                {
                    property: "_province",
                    header: M("province"),
                    cell: TextCell,
                    sortable: true,
                    searchable: true,
                },
                {
                    property: "_region",
                    header: M("region"),
                    cell: TextCell,
                    sortable: true,
                    searchable: true,
                },
                {
                    property: "_preparatoryDocumentations",
                    header: M("preparatoryDocumentsShort"),
                    cell: TextCell,
                    sortable: true,
                    searchable: true,
                    searchForm: {
                        showInCard: false,
                        fields: [
                            {
                                property: "_filterType",
                                label: "Doc. propedeutica - Tipo filtro",
                                control: Select,
                                hide: true,
                                props: {
                                    allowNull: false,
                                    datasource: datasource.fixed([
                                        {label: M("equalsTo"), value: "eq"},
                                        {label: M("lowerThan"), value: "lt"},
                                        {label: M("greaterThan"), value: "gt"}
                                    ])
                                }
                            },
                            {
                                property: "_preparatoryDocumentations",
                                label: M("_preparatoryDocumentations"),
                                control: Number,
                            },


                            {
                                property: "_approvedDocuments_filterType",
                                label: M("_approvedDocuments_filterType"),
                                control: Select,
                                hide: true,
                                props: {
                                    allowNull: false,
                                    datasource: datasource.fixed([
                                        {label: M("equalsTo"), value: "eq"},
                                        {label: M("lowerThan"), value: "lt"},
                                        {label: M("greaterThan"), value: "gt"}
                                    ])
                                }
                            },

                            {
                                property: "_approvedDocumentationsNumber",
                                label: M("_approvedDocumentationsNumber"),
                                control: Number,
                            },

                            {
                                property: "_documentTypeSet",
                                label: M("documentType"),
                                control: ValuesSelectContainer,
                                filterType: "eq",
                                props: {
                                    id: "dossier_documentType",
                                    mode: "single",
                                    allowNull: true,
                                    searchEnabled: true,
                                    getCollection: (model) => {
                                        return "documentTypes?types=" + AssignationType.ENEA_PREPARATORY_DOCUMENTATION.value + ";" + AssignationType.PREPARATORY_DOCUMENTATION.value;
                                    },
                                    getSingleItemLabel: (value) => {
                                        return value.fullDescription
                                    },
                                    getSingleItemValue: (value) => {
                                        return value.id
                                    },
                                    formatter: v => {
                                        return v != null ? v.fullDescription : "";
                                    }
                                }
                            },

                        ]
                    },
                },
                {
                    property: "_closingDocumentations",
                    header: M("closingDocumentsShort"),
                    cell: TextCell,
                    sortable: false,
                    searchable: true,
                    searchForm: {
                        showInCard: false,
                        fields: [
                            {
                                property: "_approvedClosingDocuments_filterType",
                                label: M("_uploadClosingDocuments_filterType"),
                                control: Select,
                                hide: true,
                                props: {
                                    allowNull: false,
                                    datasource: datasource.fixed([
                                        {label: M("equalsTo"), value: "eq"},
                                        {label: M("lowerThan"), value: "lt"},
                                        {label: M("greaterThan"), value: "gt"}
                                    ])
                                }
                            },
                            {
                                property: "_approvedDocumentationsClosingNumber",
                                label: M("_uploadDocumentationsClosingNumber"),
                                control: Number,
                            },
                            {
                                property: "_documentTypeClosingSet",
                                label: M("documentType"),
                                control: ValuesSelectContainer,
                                filterType: "eq",
                                props: {
                                    id: "dossier_closingDocumentType",
                                    mode: "single",
                                    allowNull: true,
                                    searchEnabled: true,
                                    getCollection: (model) =>{
                                        return "documentTypes?types=" + AssignationType.PUBLIC_CLOSING_DOCUMENTATION.value + ";" + AssignationType.PUBLIC_CLOSING_DOCUMENTATION.value;
                                    },
                                    getSingleItemLabel: (value) => {
                                        return value.fullDescription
                                    },
                                    getSingleItemValue: (value) => {
                                        return value.id
                                    },
                                    formatter: v => {
                                        return v != null ? v.fullDescription : "";
                                    }
                                }
                            },

                        ]
                    },
                },
                {
                    property: "workCompletionDate",
                    header: M("workCompletionDate"),
                    cell: TextCell,
                    sortable: true,
                    searchable: false,
                    props: {
                        formatter: v => {
                            return v != null ? moment(v).format("MM/DD/YYYY") : "-"
                        }
                    }
                },
                {
                    property: "kpmgstatus",
                    header: M("KPMGStatus"),
                    cell: TextCell,
                    sortable: false,
                    isVisible(entity) {
                        return isSuperuser() && (entity === getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2020_50) || entity === getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2021_50) || entity === getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2022_1));
                    },
                    searchable: true,
                    props: {
                        formatter: v => {
                            return v != null ? getKPMGStatusDescription(v) : "-"
                        }
                    },
                    searchForm: {
                        showInCard: false,
                        fields: [
                            {
                                property: "KPMGStatus",
                                label: M("KPMGStatus"),
                                control: Select,
                                filterType: "eq",
                                props: {
                                    allowNull: true,
                                    datasource: KPMGStatusDatasource,
                                },
                            }
                        ]
                    },
                },
                {
                    property: "priority",
                    header: M("priority"),
                    cell: TextCell,
                    sortable: true,
                    isVisible(data) {
                        return isSuperuser()
                    },
                    searchable: true,
                    searchForm: {
                        showInCard: false,
                        fields: [
                            {
                                property: "priority",
                                label: M("priority"),
                                control: Select,
                                filterType: "eq",
                                props: {
                                    allowNull: true,
                                    datasource: datasource.fixed([
                                        {label: M("yes"), value: true},
                                        {label: M("no"), value: false},
                                    ]),
                                },
                            }
                        ]
                    },
                    props: {
                        formatter: v => {
                            return v != null && v ? M("yes") : M("no");
                        }
                    }
                },
                {
                    property: "status",
                    header: M("status"),
                    cell: StatusCell,
                    sortable: false,
                    searchable: true,
                    searchForm: {
                        showInCard: false,
                        fields: [
                            {
                                property: "status",
                                label: M("status"),
                                control: Select,
                                filterType: "eq",
                                props: {
                                    allowNull: true,
                                    datasource: DossierStatusDatasource,
                                },
                            }
                        ]
                    },
                },
            ]
        }
    },
    form: {
        title: M("dossierEcoBonus"),
        getActions(data) {
            return ["back", "save", "save-go-back", "revisions"];
        },
        stores: [
            {
                store: DossierStore,
                callback(model, state) {
                    if (state.dossier) {
                        updateFormData({
                            data: state.dossier,
                            discriminator: "entity_form_dossier"
                        })
                    }
                }
            }
        ],
        descriptor: {
            stores: [DossierStore],
            showFormFooter: (model) => {
                return getUrlParameter('type') === DOSSIER_TYPE.ECOBONUS_2019
            },
            formUpdateFunction: (newState, oldState, model) => {
                if (newState.canDownload && newState.downloadUrl != null) {
                    restoreCanDownload();
                    window.open(config.get("service.url") + newState.downloadUrl, "_blank");
                }

                if (newState && newState.completed) {
                    if (!_.isEmpty(newState.documents) && isDifferent(newState.documents, oldState.documents)) {
                        model.set("documents", newState.documents);
                        model.invalidateForm();
                    }
                }

                if (isDifferent(newState.priceCalculatorSheet, oldState.priceCalculatorSheet) && !isUndefined(oldState.priceCalculatorSheet)) {
                    //Set: setto i nuovi valori
                    model.set("priceCalculatorSheet", newState.priceCalculatorSheet);

                    if (newState.priceCalculatorSheet.message != null && newState.priceCalculatorSheet.message.length > 0 && (oldState.priceCalculatorSheet == null || newState.priceCalculatorSheet.message !== oldState.priceCalculatorSheet.message)) {
                        Swal.fire({
                            title: M("warning"),
                            icon: 'error',
                            html: newState.priceCalculatorSheet.message,
                            showCloseButton: true,
                            focusConfirm: false,
                        })
                    }
                    model.invalidateForm();
                }
            },
            onModelLoadFirstTime: (model) => {
                model.on("property:change", (property, value) => {
                    switch (property) {
                        case "freeConstruction":
                            model.set("totalCostHigherTenThousand", false)
                            model.invalidateForm()
                            break;
                        case "_fabricator":
                            model.set("_fabricatorCollaborator", null);
                            model.invalidateForm()
                            break;

                        case "quotationType":
                        case "blindsPresentsType":
                        case "climaticZone":
                        case "surfaceSize":
                        case "window":
                        case "blind":
                        case "installation":
                        case "otherWorks":
                        case "takenMeasures":
                        case "surfaceSizeWithoutBlind":
                        case "windowWithoutBlind":
                        case "blindWithoutBlind":
                        case "installationWithoutBlind":
                        case "otherWorksWithoutBlind":
                        case "takenMeasuresWithoutBlind":

                            if(property === "quotationType" && QUOTATION_TYPES_ONLY_BLIND.indexOf(model.get("quotationType")) !== -1) {
                                model.set("blindsPresentsType", BlindsPresentsValues.YES.value)
                            }

                            let quotationType = model.get("quotationType");
                            let blindsPresentsType = model.get("blindsPresentsType")
                            let climaticZone = model.get("climaticZone");
                            let surfaceSize = optional(model.get("surfaceSize"), 0);
                            if (surfaceSize === "" || surfaceSize === "-")
                                surfaceSize = 0;
                            let window = optional(model.get("window"), 0);
                            if (window === "" || window === "-")
                                window = 0;
                            let blind = optional(model.get("blind"), 0);
                            if (blind === "" || blind === "-")
                                blind = 0;
                            let installation =optional( model.get("installation"), 0);
                            if (installation === "" || installation === "-")
                                installation = 0;
                            let otherWorks = optional(model.get("otherWorks"), 0);
                            if (otherWorks === "" || otherWorks === "-")
                                otherWorks = 0;
                            let takenMeasures = optional(model.get("takenMeasures"), 0);
                            if (takenMeasures === "" || takenMeasures === "-")
                                takenMeasures = 0;


                            let surfaceSizeWithoutBlind = optional(model.get("surfaceSizeWithoutBlind"), 0);
                            if (surfaceSizeWithoutBlind === "" || surfaceSizeWithoutBlind === "-")
                                surfaceSizeWithoutBlind = 0;
                            let windowWithoutBlind = optional(model.get("windowWithoutBlind"), 0);
                            if (windowWithoutBlind === "" || windowWithoutBlind === "-")
                                windowWithoutBlind = 0;
                            let blindWithoutBlind = optional(model.get("blindWithoutBlind"), 0);
                            if (blindWithoutBlind === "" || blindWithoutBlind === "-")
                                blindWithoutBlind = 0;
                            let installationWithoutBlind =optional( model.get("installationWithoutBlind"), 0);
                            if (installationWithoutBlind === "" || installationWithoutBlind === "-")
                                installationWithoutBlind = 0;
                            let otherWorksWithoutBlind = optional(model.get("otherWorksWithoutBlind"), 0);
                            if (otherWorksWithoutBlind === "" || otherWorksWithoutBlind === "-")
                                otherWorksWithoutBlind = 0;
                            let takenMeasuresWithoutBlind = optional(model.get("takenMeasuresWithoutBlind"), 0);
                            if (takenMeasuresWithoutBlind === "" || takenMeasuresWithoutBlind === "-")
                                takenMeasuresWithoutBlind = 0;

                            let skipErrorsIfIncomplete = true;
                            let fabricatorId = model.get("_fabricator") != null ? model.get("_fabricator").id : null;

                            model.propagateValidationException = false;
                            model.resetValidation()
                            model.validate()

                            let dossierType = model.get("type");
                            let dossierCreationDate = model.get("creationDate") != null ? model.get("creationDate") : new Date().getTime();
                            generateQuotationAmounts({quotationType, blindsPresentsType, surfaceSize, climaticZone, window, blind, installation, otherWorks, takenMeasures, surfaceSizeWithoutBlind, windowWithoutBlind, blindWithoutBlind, installationWithoutBlind, otherWorksWithoutBlind, takenMeasuresWithoutBlind, skipErrorsIfIncomplete, fabricatorId, dossierType, dossierCreationDate});
                            model.propagateValidationException = true;
                            model.invalidateForm()
                            break;
                    }


                });
            },
            canSave: (model) => {
                return isSuperuser() || (model && ([DossierStatus.STATUS_DRAFT, DossierStatus.STATUS_QUOTATION].indexOf(model.get("status") !== -1)));
            },
            areas: [
                {
                    component: AreaNoCard,
                    className: "zero-padding",
                    fields: [
                        {
                            property: "addDocumentDialog",
                            control: DossierAddDocumentDialog,
                            emptyRow: true
                        },
                        {
                            property: "refuseDocumentDialog",
                            control: DossierRefuseDocumentDialog,
                            emptyRow: true
                        },
                        {
                            property: "c",
                            control: Column,
                            size: "col-md-8",
                            className: "m-b-10",
                            noLateralPadding: false,
                            fields: [
                                {
                                    property: "c_1",
                                    control: Column,
                                    size: "col-sm-12",
                                    className: "card",
                                    noLateralPadding: false,
                                    fields: [
                                        {
                                            property: "c_1",
                                            control: Column,
                                            size: "col-md-4 col-sm-6",
                                            className: "zero-padding",
                                            noLateralPadding: false,
                                            fields: [
                                                {
                                                    property: "code",
                                                    control: Spacer,
                                                    label: M("dossierEcoBonus"),
                                                    size: "col-sm-12",
                                                    emptyRow: true,
                                                    className: "m-b-10",
                                                    props: {
                                                        className: "ecobonus-code",
                                                        defaultTheme: false,
                                                        formatter: v => {
                                                            return v != null ? v.get("dossierCode") : ""
                                                        }
                                                    },
                                                },
                                            ]
                                        },
                                        {
                                            property: "c_2",
                                            control: Column,
                                            size: "col-md-4 col-sm-6",
                                            className: "zero-padding",
                                            noLateralPadding: false,
                                            fields: [
                                                {
                                                    property: "type",
                                                    control: ReadOnlyText,
                                                    label: M("type"),
                                                    placeholder: M("type"),
                                                    size: "col-sm-12",
                                                    props: {
                                                        formatter: v => {
                                                            return M(v)
                                                        }
                                                    }
                                                },
                                            ]
                                        },
                                        {
                                            property: "c_3",
                                            control: Column,
                                            size: "col-md-4 col-sm-6",
                                            className: "zero-padding",
                                            noLateralPadding: false,
                                            fields: [
                                                {
                                                    property: "creationDate",
                                                    control: ReadOnlyText,
                                                    label: M("creationDate"),
                                                    placeholder: M("creationDate"),
                                                    size: "col-sm-12",
                                                    props: {
                                                        formatter: v => {
                                                            return v != null ? moment(v).format("DD/MM/YYYY HH:mm") : moment(new Date()).format("DD/MM/YYYY HH:mm")
                                                        }
                                                    }
                                                },
                                            ]
                                        },
                                        {
                                            property: "_customer",
                                            label: M("customer"),
                                            getControl: (model) => {
                                                //Il cliente non è modificabile in fase di modifica della pratica oppure in caso di creazione delle pratiche 2020_50 nel quale viene pre-selezionato a monte
                                                let readonly =  model.get("id") !== null;
                                                if (!readonly && (model.get("type") === DOSSIER_TYPE.ECOBONUS_2020_50 || model.get("type") === DOSSIER_TYPE.ECOBONUS_2021_50 || model.get("type") === DOSSIER_TYPE.ECOBONUS_2022_1) && model.get("_customer")) {
                                                    readonly = true;
                                                }
                                                return readonly? ReadOnlyText: ValuesLookupContainer
                                            },
                                            size: "col-md-4 col-sm-6",
                                            isRequired: true,
                                            props: {
                                                id: "dossier_customer",
                                                mode: "single",
                                                collection: "customers",
                                                selectionGrid: {
                                                    columns: [
                                                        {property: "code", header: M("code"), cell: TextCell},
                                                        {property: "name", header: M("name"), cell: TextCell}
                                                    ]
                                                },
                                                popupGrid: {
                                                    columns: [
                                                        {property: "code", header: M("code"), cell: TextCell},
                                                        {property: "name", header: M("name"), cell: TextCell}
                                                    ]
                                                },
                                                formatter: v => {
                                                    return v != null ? v.name : "";
                                                }
                                            }
                                        },
                                        {
                                            property: "_fabricator",
                                            label: M("fabricator"),
                                            isRequired: true,
                                            getControl: (model) => {
                                                return model.get("id") == null && isSuperuser() ? ValuesLookupContainer : ReadOnlyText
                                            },
                                            size: "col-md-4 col-sm-6",
                                            props: {
                                                id: "dossier_fabricator",
                                                mode: "single",
                                                collection: "fabricators",
                                                selectionGrid: {
                                                    columns: [
                                                        {property: "code", header: M("code"), cell: TextCell},
                                                        {
                                                            property: "businessName",
                                                            header: M("businessName"),
                                                            cell: TextCell
                                                        },
                                                        {
                                                            property: "completedProfile",
                                                            header: M("completedProfile"),
                                                            cell: TextCell,
                                                            props: {
                                                                formatter: v => {
                                                                    return v ? M("yes") : M("no");
                                                                }
                                                            }
                                                        }
                                                    ]
                                                },
                                                popupGrid: {
                                                    columns: [
                                                        {property: "code", header: M("code"), cell: TextCell},
                                                        {
                                                            property: "businessName",
                                                            header: M("businessName"),
                                                            cell: TextCell
                                                        },
                                                        {
                                                            property: "completedProfile",
                                                            header: M("completedProfile"),
                                                            cell: TextCell,
                                                            props: {
                                                                formatter: v => {
                                                                    return v ? M("yes") : M("no");
                                                                }
                                                            }
                                                        }
                                                    ]
                                                },
                                                formatter: v => {
                                                    return v != null ? v.businessName : "";
                                                }
                                            }
                                        },
                                        {
                                            property: "_fabricatorCollaborator",
                                            label: M("collaborator"),
                                            isRequired: true,
                                            getControl: (model) => {
                                                return model.get("id") == null && model.get("_fabricator") != null && isSuperuser() ? ValuesLookupContainer : ReadOnlyText
                                            },
                                            size: "col-md-4 col-sm-6",
                                            props: {
                                                id: "dossier_fabricator_collaborator",
                                                mode: "single",
                                                getCollection: (model) => {
                                                    return "collaborators/" + model.get("_fabricator").id;
                                                },
                                                selectionGrid: {
                                                    columns: [
                                                        {property: "code", header: M("code"), cell: TextCell},
                                                        {property: "name", header: M("name"), cell: TextCell},
                                                        {
                                                            property: "lastname",
                                                            header: M("lastname"),
                                                            cell: TextCell
                                                        },
                                                    ]
                                                },
                                                popupGrid: {
                                                    columns: [
                                                        {property: "code", header: M("code"), cell: TextCell},
                                                        {property: "name", header: M("name"), cell: TextCell},
                                                        {
                                                            property: "lastname",
                                                            header: M("lastname"),
                                                            cell: TextCell
                                                        },
                                                    ]
                                                },
                                                formatter: v => {
                                                    return v != null ? v.lastname + " " + v.name : "";
                                                }
                                            }
                                        },

                                        {
                                            property: "_significantValue",
                                            getControl: (model) => {
                                                return model.get("id") == null || model.get("status") === DossierStatus.STATUS_QUOTATION.value ? Number : ReadOnlyText
                                            },
                                            visibility: (model) => {
                                                return model.get("type") === DOSSIER_TYPE.ECOBONUS_2019 && isSuperuser()
                                            },
                                            label: M("significantValue"),
                                            placeholder: M("significantValue"),
                                            size: "col-md-4 col-sm-6",
                                            isRequired: true,
                                            props: {
                                                formatter: v => {
                                                    return v != null ? currencyFormat(v) : null;
                                                }
                                            }
                                        },
                                        {
                                            property: "_nonSignificantValue",
                                            getControl: (model) => {
                                                return model.get("id") == null || model.get("status") === DossierStatus.STATUS_QUOTATION.value ? Number : ReadOnlyText
                                            },
                                            visibility: (model) => {
                                                return model.get("type") === DOSSIER_TYPE.ECOBONUS_2019 && isSuperuser()
                                            },
                                            label: M("nonSignificantValue"),
                                            placeholder: M("nonSignificantValue"),
                                            size: "col-md-4 col-sm-6",
                                            isRequired: true,
                                            props: {
                                                formatter: v => {
                                                    return v != null ? currencyFormat(v) : null;
                                                }
                                            }
                                        },
                                        {
                                            property: "_serviceValue",
                                            getControl: (model) => {
                                                return model.get("id") == null || model.get("status") === DossierStatus.STATUS_QUOTATION.value ? Number : ReadOnlyText
                                            },
                                            visibility: (model) => {
                                                return model.get("type") === DOSSIER_TYPE.ECOBONUS_2019 && isSuperuser()
                                            },
                                            label: M("serviceValue"),
                                            placeholder: M("serviceValue"),
                                            size: "col-sm-6",
                                            isRequired: true,
                                            props: {
                                                formatter: v => {
                                                    return v != null ? currencyFormat(v) : null;
                                                }
                                            }
                                        },


                                        valuesSelectContainerField({
                                            property: "quotationType",
                                            isRequired: true,
                                            getCollection(model) {
                                                let customerType = "";
                                                if (model)
                                                    customerType = model.get("_customer").subjectType
                                                return "quotationTypes/" + customerType
                                            },
                                            itemValue: "type",
                                            itemLabel: "fullDescription",
                                            size: "col-sm-4",
                                            visibility: (model) => {
                                                return model.get("type") === DOSSIER_TYPE.ECOBONUS_2020_50 || model.get("type") === DOSSIER_TYPE.ECOBONUS_2021_50 || model.get("type") === DOSSIER_TYPE.ECOBONUS_2022_1;
                                            },
                                            getControl: (model) => {
                                                return model && model.get("id") == null || model.get("status") === DossierStatus.STATUS_QUOTATION.value? ValuesSelectContainer : ReadOnlyText
                                            },
                                            formatter(v, model) {
                                                if (model && model.get("id") != null)
                                                    return model.get("priceCalculatorSheet").quotationTypeDescription
                                                return  v != null ? v["fullDescription"] : ""
                                            }
                                        }),

                                        // {
                                        //     property: "blindsPresent",
                                        //     getControl: (model) => {
                                        //         let readonlyComponent = ReadOnlyText;
                                        //         if (!model)
                                        //             return readonlyComponent
                                        //         return model.get("id") == null? Select : readonlyComponent
                                        //     },
                                        //     visibility: (model) => {
                                        //         return model.get("type") === DOSSIER_TYPE.ECOBONUS_2020_50
                                        //     },
                                        //     label: M("blindsPresent"),
                                        //     size: "col-sm-4",
                                        //     isRequired: true,
                                        //     props: {
                                        //         datasource: datasource.fromEnum(BooleanStatus),
                                        //         allowNull: true,
                                        //         nullText: M("insertValue"),
                                        //         formatter(v, model) {
                                        //             return v ? M("YES") : M("NO")
                                        //         }
                                        //     },
                                        //
                                        // },

                                        valuesSelectContainerField({
                                            property: "blindsPresentsType",
                                            isRequired: true,
                                            itemLabel: "label",
                                            itemValue: "value",
                                            collection: "blindsPresentsType",
                                            size: "col-sm-4",
                                            visibility: (model) => {
                                                return model.get("type") === DOSSIER_TYPE.ECOBONUS_2020_50 || model.get("type") === DOSSIER_TYPE.ECOBONUS_2021_50 || model.get("type") === DOSSIER_TYPE.ECOBONUS_2022_1;
                                            },
                                            getControl: (model) => {
                                                let readonlyComponent = ReadOnlyText;
                                                if (!model || QUOTATION_TYPES_ONLY_BLIND.indexOf(model.get("quotationType")) !== -1)
                                                    return readonlyComponent
                                                return model.get("id") == null || model.get("status") === DossierStatus.STATUS_QUOTATION.value ? ValuesSelectContainer : ReadOnlyText
                                            },
                                            formatter(v, model) {
                                                if (model && model.get("id") != null)
                                                    return getBlindsPresentsValueDescription(model.get("priceCalculatorSheet").blindsPresentsType)
                                                return  v != null ? getBlindsPresentsValueDescription(v) : ""
                                            }
                                        }),

                                        valuesSelectContainerField({
                                            property: "climaticZone",
                                            isRequired: true,
                                            itemLabel: "label",
                                            itemValue: "value",
                                            collection: "climaticZones",
                                            size: "col-sm-4",
                                            visibility: (model) => {
                                                return model.get("type") === DOSSIER_TYPE.ECOBONUS_2020_50 || model.get("type") === DOSSIER_TYPE.ECOBONUS_2021_50 || model.get("type") === DOSSIER_TYPE.ECOBONUS_2022_1;
                                            },
                                            getControl: (model) => {
                                                let readonlyComponent = ReadOnlyText;
                                                if (!model)
                                                    return readonlyComponent
                                                return model.get("id") == null || model.get("status") === DossierStatus.STATUS_QUOTATION.value ? ValuesSelectContainer : ReadOnlyText
                                            },
                                            formatter(v, model) {
                                                if (model && model.get("id") != null)
                                                    return model.get("priceCalculatorSheet").climaticZone
                                                return  v != null ? v["label"] : ""
                                            }
                                        }),

                                        {
                                            property: "spacer_blind",
                                            control: Spacer,
                                            size: "col-sm-12",
                                            emptyRow: true,
                                            className: "m-b-10 m-t-10",
                                            props: {
                                                defaultTheme: false,
                                            },
                                        },

                                        {
                                            property: "c_blind",
                                            control: Column,
                                            emptyRow: true,
                                            size: "col-sm-6",
                                            visibility: (model) => {
                                                return model != null && (model.get("type") === DOSSIER_TYPE.ECOBONUS_2020_50 || model.get("type") === DOSSIER_TYPE.ECOBONUS_2021_50 || model.get("type") === DOSSIER_TYPE.ECOBONUS_2022_1) && !model.get("avoidNotPermittedSurfaceSize") && (model.get("blindsPresentsType") === BlindsPresentsValues.BOTH.value || model.get("blindsPresentsType") === BlindsPresentsValues.YES.value)
                                                // return isSuperuser() && model != null && model.get("id") != null && model.get("status") !== DossierStatus.STATUS_QUOTATION.value;
                                            },
                                            noLateralPadding: true,
                                            props: {
                                                setStyle: false
                                            },
                                            fields: [
                                                {
                                                    property: "spacer_blind",
                                                    control: Spacer,
                                                    label: null,
                                                    size: "col-sm-12",
                                                    emptyRow: true,
                                                    className: "m-b-10",
                                                    props: {
                                                        className: "blind-title",
                                                        defaultTheme: false,
                                                        formatter: model => {
                                                            return QUOTATION_TYPES_ONLY_BLIND.indexOf(model.get("quotationType")) === -1 ? "Fornitura infissi con oscuranti" : "Fornitura persiane";
                                                        }
                                                    },
                                                },

                                                {
                                                    property: "notPermittedAmount",
                                                    required: false,
                                                    control: OnlyTextInfo,
                                                    visibility: (model) => {
                                                        return  isQuotationValueVisible(model);
                                                    },
                                                    getLabel(model) {
                                                        if (model) {
                                                            let priceCalculatorSheet = model.get("priceCalculatorSheet")
                                                            return priceCalculatorSheet && priceCalculatorSheet.maxSizeValueDescription ? priceCalculatorSheet.maxSizeValueDescription : "Limite spesa massima agevolabile : - "
                                                        }

                                                        return "";
                                                    },
                                                    size: "col-sm-12",
                                                    props: {
                                                        showIcon: false,
                                                        getClassName(model) {
                                                            if (model) {
                                                                let priceCalculatorSheet = model.get("priceCalculatorSheet")

                                                                let totalNotPermitted = priceCalculatorSheet.blindsPresentsType === BlindsPresentsValues.BOTH.value ? priceCalculatorSheet.totalNotPermittedWithBlind : priceCalculatorSheet.totalNotPermitted;

                                                                if (totalNotPermitted > 0)
                                                                    return "notPermittedAmount-label";
                                                                else
                                                                    return "allPermittedAmount-label";
                                                            }

                                                            return null;
                                                        },
                                                        formatter(v, model) {
                                                            if (model) {
                                                                let priceCalculatorSheet = model.get("priceCalculatorSheet")
                                                                return priceCalculatorSheet?  priceCalculatorSheet.notPermittedAmountDescription: "-"
                                                            }

                                                            return "";

                                                        }
                                                    }
                                                },

                                            ]
                                        },

                                        {
                                            property: "surfaceSize",
                                            getControl: (model) => {
                                                let readonlyComponent = ReadOnlyText;
                                                if (!model)
                                                    return readonlyComponent
                                                return model.get("id") == null || model.get("status") === DossierStatus.STATUS_QUOTATION.value ? Number : readonlyComponent
                                            },
                                            visibility: (model) => {
                                                return (model.get("type") === DOSSIER_TYPE.ECOBONUS_2020_50 || model.get("type") === DOSSIER_TYPE.ECOBONUS_2021_50 || model.get("type") === DOSSIER_TYPE.ECOBONUS_2022_1) && (model.get("blindsPresentsType") === BlindsPresentsValues.BOTH.value || model.get("blindsPresentsType") === BlindsPresentsValues.YES.value)
                                            },
                                            label: M("surfaceSizeMq"),
                                            placeholder: M("surfaceSizeMq"),
                                            size: "col-sm-6",
                                            isRequired: true,
                                            props: {
                                                formatter(v, model) {
                                                    let value = v
                                                    if (model && model.get("id") != null)
                                                        value = model.get("priceCalculatorSheet").surfaceSize
                                                    return formatNumber(value);
                                                }
                                            }
                                        },

                                        {
                                            property: "window",
                                            getControl: (model) => {
                                                let readonlyComponent = ReadOnlyText;
                                                if (!model)
                                                    return readonlyComponent
                                                return model.get("id") == null || model.get("status") === DossierStatus.STATUS_QUOTATION.value ? Number : readonlyComponent
                                            },
                                            visibility: (model) => {
                                                return isQuotationValueVisible(model) && (isSuperuser() || !model.get("id") || model.get("status") === DossierStatus.STATUS_QUOTATION.value) && (model.get("blindsPresentsType") === BlindsPresentsValues.BOTH.value || model.get("blindsPresentsType") === BlindsPresentsValues.YES.value)

                                            },
                                            getLabel: (model) => {
                                                return QUOTATION_TYPES_ONLY_BLIND.indexOf(model.get("quotationType")) === -1 ? "Infissi - Bene Significativo" : "Persiane";
                                            },
                                            getPlaceholder: (model) => {
                                                return QUOTATION_TYPES_ONLY_BLIND.indexOf(model.get("quotationType")) === -1 ? "Infissi - Bene Significativo" : "Persiane";
                                            },
                                            size: "col-sm-6",
                                            isRequired: true,
                                            props: {
                                                formatter: v => {
                                                    return formatNumber(v)
                                                }
                                            }
                                        },

                                        {
                                            property: "blind",
                                            getControl: (model) => {
                                                let readonlyComponent = ReadOnlyText;
                                                if (!model)
                                                    return readonlyComponent
                                                return model.get("id") == null || model.get("status") === DossierStatus.STATUS_QUOTATION.value ? Number : readonlyComponent
                                            },
                                            visibility: (model) => {
                                                return isQuotationValueVisible(model) && (isSuperuser() || !model.get("id") || model.get("status") === DossierStatus.STATUS_QUOTATION.value) && (model.get("blindsPresentsType") === BlindsPresentsValues.BOTH.value || model.get("blindsPresentsType") === BlindsPresentsValues.YES.value)
                                            },
                                            getLabel: (model) => {
                                                return QUOTATION_TYPES_ONLY_BLIND.indexOf(model.get("quotationType")) === -1 ? "Oscuranti, Beni NS, Mark-Up" : "Beni NS, Mark-Up";
                                            },
                                            getPlaceholder: (model) => {
                                                return QUOTATION_TYPES_ONLY_BLIND.indexOf(model.get("quotationType")) === -1 ? "Oscuranti, Beni NS, Mark-Up" : "Beni NS, Mark-Up";
                                            },
                                            size: "col-sm-6",
                                            props: {
                                                formatter: v => {
                                                    return formatNumber(v)
                                                }
                                            }
                                        },
                                        {
                                            property: "installation",
                                            getControl: (model) => {
                                                let readonlyComponent = ReadOnlyText;
                                                if (!model)
                                                    return readonlyComponent
                                                return model.get("id") == null || model.get("status") === DossierStatus.STATUS_QUOTATION.value ? Number : readonlyComponent
                                            },
                                            visibility: (model) => {
                                                return false;
                                                // return isQuotationValueVisible(model) && (isSuperuser() || !model.get("id") || model.get("status") === DossierStatus.STATUS_QUOTATION.value) && (model.get("blindsPresentsType") === BlindsPresentsValues.BOTH.value || model.get("blindsPresentsType") === BlindsPresentsValues.YES.value)
                                            },
                                            label: "Posa in Opera e Installazione",
                                            placeholder: "Posa in Opera e Installazione",
                                            size: "col-sm-4",
                                            isRequired: true,
                                            props: {
                                                formatter: v => {
                                                    return formatNumber(v)
                                                }
                                            }
                                        },
                                        {
                                            property: "otherWorks",
                                            getControl: (model) => {
                                                let readonlyComponent = ReadOnlyText;
                                                if (!model)
                                                    return readonlyComponent
                                                return model.get("id") == null || model.get("status") === DossierStatus.STATUS_QUOTATION.value ? Number : readonlyComponent
                                            },
                                            visibility: (model) => {
                                                return isQuotationValueVisible(model) && (isSuperuser() || !model.get("id") || model.get("status") === DossierStatus.STATUS_QUOTATION.value) && (model.get("blindsPresentsType") === BlindsPresentsValues.BOTH.value || model.get("blindsPresentsType") === BlindsPresentsValues.YES.value)
                                            },
                                            label: "Posa e Installazione",
                                            placeholder: "Posa e Installazione",
                                            size: "col-sm-6",
                                            isRequired: true,
                                            props: {
                                                formatter: v => {
                                                    return formatNumber(v)
                                                }
                                            }
                                        },
                                        {
                                            property: "takenMeasures",
                                            getControl: (model) => {
                                                let readonlyComponent = ReadOnlyText;
                                                if (!model)
                                                    return readonlyComponent
                                                return model.get("id") == null || model.get("status") === DossierStatus.STATUS_QUOTATION.value ? Number : readonlyComponent
                                            },
                                            visibility: (model) => {
                                                return isQuotationValueVisible(model) && (isSuperuser() || !model.get("id") || model.get("status") === DossierStatus.STATUS_QUOTATION.value) && (model.get("blindsPresentsType") === BlindsPresentsValues.BOTH.value || model.get("blindsPresentsType") === BlindsPresentsValues.YES.value)
                                            },
                                            validator: takenMeasureValidator,
                                            getLabel: (model) => {
                                                let timeData = new Date(dateNewLabel).getTime();
                                                return model && (model.get("type") === DOSSIER_TYPE.ECOBONUS_2021_50 || model.get("type") === DOSSIER_TYPE.ECOBONUS_2022_1) ?
                                                    (model.get("creationDate") && model.get("creationDate") > timeData)
                                                    || (model.get("creationDate") == null && new Date().getTime() > timeData)?
                                                        "Eventuali Altre Spese professionali da ivare al 22%" : "Presa misure/elab tavole/elab dich. tecnica"
                                                    : "Presa misure/elab tavole/elab dich. tecnica e gest. Documenti"
                                            },
                                            getPlaceholder:(model) =>{
                                                let timeData = new Date(dateNewLabel).getTime();
                                                return model && (model.get("type") === DOSSIER_TYPE.ECOBONUS_2021_50 || model.get("type") === DOSSIER_TYPE.ECOBONUS_2022_1 )?
                                                    (model.get("creationDate") && model.get("creationDate") > timeData)
                                                    || (model.get("creationDate") == null && new Date().getTime() > timeData)?
                                                        "Eventuali Altre Spese professionali da ivare al 22%" : "Presa misure/elab tavole/elab dich. tecnica"
                                                    : "Presa misure/elab tavole/elab dich. tecnica e gest. Documenti"
                                            },
                                            size: "col-sm-6",
                                            isRequired: (model) =>{
                                                return model && (model.get("type") == DOSSIER_TYPE.ECOBONUS_2021_50 || model.get("type") == DOSSIER_TYPE.ECOBONUS_2022_1) ? false : true;
                                            },
                                            props: {
                                                formatter: v => {
                                                    return formatNumber(v)
                                                }
                                            }
                                        },
                                        // {
                                        //     property: "c_blind_taken_measures",
                                        //     control: Column,
                                        //     emptyRow: true,
                                        //     size: "col-sm-12",
                                        //     visibility: (model) => {
                                        //         let takenMeasures = optional(model.get("takenMeasures"), 0);
                                        //         if (takenMeasures === "" || takenMeasures === "-")
                                        //             takenMeasures = 0;
                                        //         let takenMeasuresWithoutBlind = optional(model.get("takenMeasuresWithoutBlind"), 0);
                                        //         if (takenMeasuresWithoutBlind === "" || takenMeasuresWithoutBlind === "-")
                                        //             takenMeasuresWithoutBlind = 0;
                                        //
                                        //         return model.get("type") === DOSSIER_TYPE.ECOBONUS_2020_50 && (model.get("blindsPresentsType") === BlindsPresentsValues.BOTH.value || model.get("blindsPresentsType") === BlindsPresentsValues.YES.value) && (parseFloat(takenMeasures) + parseFloat(takenMeasuresWithoutBlind) < 200);
                                        //     },
                                        //     noLateralPadding: true,
                                        //     props: {
                                        //         setStyle: false,
                                        //         defaultTheme: false,
                                        //     },
                                        //     fields: [
                                        //         {
                                        //             property: "spacer_blind_1",
                                        //             control: Spacer,
                                        //             label: null,
                                        //             size: "col-sm-6",
                                        //             emptyRow: true,
                                        //             props: {
                                        //                 defaultTheme: false,
                                        //             },
                                        //         },
                                        //         {
                                        //             property: "notPermittedAmount_1",
                                        //             required: false,
                                        //             control: OnlyTextInfo,
                                        //             label: null,
                                        //             size: "col-sm-6",
                                        //             emptyRow: true,
                                        //             props: {
                                        //                 showIcon: false,
                                        //                 getClassName() {
                                        //                     return "notPermittedAmount-label";
                                        //                 },
                                        //                 formatter() {
                                        //                     return "La somma totale di questo valore deve essere maggiore di 200";
                                        //                 }
                                        //             }
                                        //         },
                                        //
                                        //     ]
                                        // },
                                        {
                                            property: "spacer_blind",
                                            control: Spacer,
                                            size: "col-sm-12",
                                            emptyRow: true,
                                            className: "m-b-10 m-t-10",
                                            props: {
                                                defaultTheme: false,
                                            },
                                        },

                                        {
                                            property: "c_without_blind",
                                            control: Column,
                                            size: "col-sm-6",
                                            emptyRow: true,
                                            visibility: (model) => {
                                                return model != null && (model.get("type") === DOSSIER_TYPE.ECOBONUS_2020_50 || model.get("type") === DOSSIER_TYPE.ECOBONUS_2021_50 || model.get("type") === DOSSIER_TYPE.ECOBONUS_2022_1) && model.get("blindsPresentsType") != null && (model.get("blindsPresentsType") === BlindsPresentsValues.BOTH.value || model.get("blindsPresentsType") === BlindsPresentsValues.NO.value)
                                            },
                                            noLateralPadding: true,
                                            props: {
                                                setStyle: false
                                            },
                                            fields: [
                                                {
                                                    property: "spacer_without_blind",
                                                    control: Spacer,
                                                    label: null,
                                                    size: "col-sm-12",
                                                    emptyRow: true,
                                                    className: "m-b-10",
                                                    props: {
                                                        className: "blind-title",
                                                        defaultTheme: false,
                                                        formatter: model => {
                                                            return QUOTATION_TYPES_ONLY_BLIND.indexOf(model.get("quotationType")) === -1 ? "Fornitura infissi senza oscuranti" : "Fornitura Persiane";
                                                        }
                                                    },
                                                },

                                                {
                                                    property: "notPermittedAmountWithoutBlind",
                                                    required: false,
                                                    control: OnlyTextInfo,
                                                    visibility: (model) => {
                                                        return  isQuotationValueVisibleWithoutBlind(model) && !model.get("avoidNotPermittedSurfaceSize");
                                                    },
                                                    getLabel(model) {
                                                        if (model) {
                                                            let priceCalculatorSheet = model.get("priceCalculatorSheet")
                                                            return priceCalculatorSheet && priceCalculatorSheet.maxSizeValueWithoutBlindDescription ? priceCalculatorSheet.maxSizeValueWithoutBlindDescription : "Limite spesa massima agevolabile : - "
                                                        }

                                                        return "";
                                                    },
                                                    size: "col-sm-12",
                                                    props: {
                                                        showIcon: false,
                                                        getClassName(model) {
                                                            if (model) {
                                                                let priceCalculatorSheet = model.get("priceCalculatorSheet")

                                                                if (priceCalculatorSheet.totalNotPermittedWithoutBlind > 0)
                                                                    return "notPermittedAmount-label";
                                                                else
                                                                    return "allPermittedAmount-label";
                                                            }

                                                            return null;
                                                        },
                                                        formatter(v, model) {
                                                            if (model) {
                                                                let priceCalculatorSheet = model.get("priceCalculatorSheet")
                                                                return priceCalculatorSheet?  priceCalculatorSheet.notPermittedAmountWithoutBlindDescription : "-"
                                                            }

                                                            return "";

                                                        }
                                                    }
                                                },

                                            ]
                                        },

                                        {
                                            property: "surfaceSizeWithoutBlind",
                                            getControl: (model) => {
                                                let readonlyComponent = ReadOnlyText;
                                                if (!model)
                                                    return readonlyComponent
                                                return model.get("id") == null || model.get("status") === DossierStatus.STATUS_QUOTATION.value ? Number : readonlyComponent
                                            },
                                            visibility: (model) => {
                                                return (model.get("type") === DOSSIER_TYPE.ECOBONUS_2020_50 || model.get("type") === DOSSIER_TYPE.ECOBONUS_2021_50 || model.get("type") === DOSSIER_TYPE.ECOBONUS_2022_1) &&
                                                    model.get("blindsPresentsType") != null
                                                    && (model.get("blindsPresentsType") === BlindsPresentsValues.BOTH.value || model.get("blindsPresentsType") === BlindsPresentsValues.NO.value)
                                            },
                                            label: M("surfaceSizeMq"),
                                            placeholder: M("surfaceSizeMq"),
                                            size: "col-sm-6",
                                            isRequired: true,
                                            props: {
                                                formatter(v, model) {
                                                    let value = v
                                                    if (model && model.get("id") != null)
                                                        value = model.get("priceCalculatorSheet").surfaceSizeWithoutBlind
                                                    return formatNumber(value);
                                                }
                                            }
                                        },

                                        {
                                            property: "windowWithoutBlind",
                                            getControl: (model) => {
                                                let readonlyComponent = ReadOnlyText;
                                                if (!model)
                                                    return readonlyComponent
                                                return model.get("id") == null || model.get("status") === DossierStatus.STATUS_QUOTATION.value ? Number : readonlyComponent
                                            },
                                            visibility: (model) => {

                                                return isQuotationValueVisibleWithoutBlind(model) && (isSuperuser() || !model.get("id") || model.get("status") === DossierStatus.STATUS_QUOTATION.value) && model.get("blindsPresentsType") != null && (model.get("blindsPresentsType") === BlindsPresentsValues.BOTH.value || model.get("blindsPresentsType") === BlindsPresentsValues.NO.value)

                                            },
                                            getLabel: (model) => {
                                                return QUOTATION_TYPES_ONLY_BLIND.indexOf(model.get("quotationType")) === -1 ? "Infissi - Bene Significativo" : "Persiane";
                                            },
                                            getPlaceholder: (model) => {
                                                return QUOTATION_TYPES_ONLY_BLIND.indexOf(model.get("quotationType")) === -1 ? "Infissi - Bene Significativo" : "Persiane";
                                            },
                                            size: "col-sm-6",
                                            isRequired: true,
                                            props: {
                                                formatter: v => {
                                                    return formatNumber(v)
                                                }
                                            }
                                        },

                                        {
                                            property: "blindWithoutBlind",
                                            getControl: (model) => {
                                                let readonlyComponent = ReadOnlyText;
                                                if (!model)
                                                    return readonlyComponent
                                                return model.get("id") == null || model.get("status") === DossierStatus.STATUS_QUOTATION.value ? Number : readonlyComponent
                                            },
                                            visibility: (model) => {
                                                return isQuotationValueVisibleWithoutBlind(model) && (isSuperuser() || !model.get("id") || model.get("status") === DossierStatus.STATUS_QUOTATION.value) && model.get("blindsPresentsType") != null && (model.get("blindsPresentsType") === BlindsPresentsValues.BOTH.value || model.get("blindsPresentsType") === BlindsPresentsValues.NO.value)
                                            },
                                            label: "Beni NS, Mark-Up",
                                            placeholder: "Beni NS, Mark-Up",
                                            size: "col-sm-6",
                                            props: {
                                                formatter: v => {
                                                    return formatNumber(v)
                                                }
                                            }
                                        },
                                        {
                                            property: "installationWithoutBlind",
                                            getControl: (model) => {
                                                let readonlyComponent = ReadOnlyText;
                                                if (!model)
                                                    return readonlyComponent
                                                return model.get("id") == null || model.get("status") === DossierStatus.STATUS_QUOTATION.value ? Number : readonlyComponent
                                            },
                                            visibility: (model) => {
                                                return false;
                                                // return isQuotationValueVisibleWithoutBlind(model) && (isSuperuser() || !model.get("id") || model.get("status") === DossierStatus.STATUS_QUOTATION.value) && model.get("blindsPresentsType") != null && (model.get("blindsPresentsType") === BlindsPresentsValues.BOTH.value || model.get("blindsPresentsType") === BlindsPresentsValues.NO.value)
                                            },
                                            label: "Posa in Opera e Installazione",
                                            placeholder: "Posa in Opera e Installazione",
                                            size: "col-sm-4",
                                            isRequired: true,
                                            props: {
                                                formatter: v => {
                                                    return formatNumber(v)
                                                }
                                            }
                                        },
                                        {
                                            property: "otherWorksWithoutBlind",
                                            getControl: (model) => {
                                                let readonlyComponent = ReadOnlyText;
                                                if (!model)
                                                    return readonlyComponent
                                                return model.get("id") == null || model.get("status") === DossierStatus.STATUS_QUOTATION.value ? Number : readonlyComponent
                                            },
                                            visibility: (model) => {
                                                return isQuotationValueVisibleWithoutBlind(model) && (isSuperuser() || !model.get("id") || model.get("status") === DossierStatus.STATUS_QUOTATION.value) && model.get("blindsPresentsType") != null && (model.get("blindsPresentsType") === BlindsPresentsValues.BOTH.value || model.get("blindsPresentsType") === BlindsPresentsValues.NO.value)
                                            },
                                            label: "Posa e Installazione",
                                            placeholder: "Posa e Installazione",
                                            // placeholder: "Altre Opere e Servizi Complementari all'Installazione e alla Posa",
                                            size: "col-sm-6",
                                            isRequired: true,
                                            props: {
                                                formatter: v => {
                                                    return formatNumber(v)
                                                }
                                            }
                                        },
                                        {
                                            property: "takenMeasuresWithoutBlind",
                                            getControl: (model) => {
                                                let readonlyComponent = ReadOnlyText;
                                                if (!model)
                                                    return readonlyComponent
                                                return model.get("id") == null || model.get("status") === DossierStatus.STATUS_QUOTATION.value ? Number : readonlyComponent
                                            },
                                            visibility: (model) => {
                                                return isQuotationValueVisibleWithoutBlind(model) && (isSuperuser() || !model.get("id") || model.get("status") === DossierStatus.STATUS_QUOTATION.value) && model.get("blindsPresentsType") != null && (model.get("blindsPresentsType") === BlindsPresentsValues.BOTH.value || model.get("blindsPresentsType") === BlindsPresentsValues.NO.value)
                                            },
                                            validator: takenMeasureValidator,
                                            getLabel:(model) =>{
                                                let timeData = new Date(dateNewLabel).getTime();
                                                return model && (model.get("type") === DOSSIER_TYPE.ECOBONUS_2021_50 || model.get("type") === DOSSIER_TYPE.ECOBONUS_2022_1) ?
                                                    (model.get("creationDate") && model.get("creationDate") > timeData)
                                                    || (model.get("creationDate") == null && new Date().getTime() > timeData)?
                                                    "Eventuali Altre Spese professionali da ivare al 22%" : "Presa misure/elab tavole/elab dich. tecnica"
                                                    : "Presa misure/elab tavole/elab dich. tecnica e gest. Documenti"
                                            },
                                            getPlaceholder:(model) =>{
                                                let timeData = new Date(dateNewLabel).getTime();
                                                return model && (model.get("type") === DOSSIER_TYPE.ECOBONUS_2021_50 || model.get("type") === DOSSIER_TYPE.ECOBONUS_2022_1) ?
                                                    (model.get("creationDate") && model.get("creationDate") > timeData)
                                                    || (model.get("creationDate") == null && new Date().getTime() > timeData)?
                                                        "Eventuali Altre Spese professionali da ivare al 22%" : "Presa misure/elab tavole/elab dich. tecnica"
                                                    : "Presa misure/elab tavole/elab dich. tecnica e gest. Documenti"
                                            },
                                            size: "col-sm-6",
                                            isRequired: (model) =>{
                                                return model && (model.get("type") === DOSSIER_TYPE.ECOBONUS_2021_50 || model.get("type") === DOSSIER_TYPE.ECOBONUS_2022_1) ? false : true;
                                            },
                                            props: {
                                                formatter: v => {
                                                    return formatNumber(v)
                                                }
                                            }
                                        },

                                        {
                                            property: "acceptServiceCosts",
                                            getControl: (model) => {
                                                return (model.get("id") === null || model.get("status") === DossierStatus.STATUS_QUOTATION.value) ? YesNo : ReadOnlyText
                                            },
                                            label: "Accetta i costi del servizio",
                                            size: "col-sm-6",
                                            isRequired: true,
                                            props: {
                                                formatter(v, model) {
                                                    return "Accettati"
                                                }
                                            }
                                        },
                                        {
                                            property: "dl157",
                                            control: YesNo,
                                            label: M("dl157"),
                                            size: "col-sm-6",
                                            isRequired: true,
                                            visibility: (model) => {
                                                return model.get("type") === DOSSIER_TYPE.ECOBONUS_2021_50 && model.get("id") !== null && model.get("status") !== DossierStatus.STATUS_QUOTATION.value
                                            },
                                            props: {
                                                formatter(v, model) {
                                                    if (v != null)
                                                        return v === true ? "Si" : "No"
                                                    return ""
                                                }
                                            }
                                        },
                                        {
                                            property: "workCompletionDate",
                                            getControl: (model) => {
                                                let workCompletionDocuments = _.filter(model.get("documents"), r => WORK_COMPLETION_DOCUMENTS_CODE.includes(r.documentType.code));
                                                return model.get("workCompletionDate") == null || (getDossierStatusPosition(model.get("status")) <= getDossierStatusPosition(DossierStatus.STATUS_TO_CANDIDATE) || (workCompletionDocuments.length > 0 && (workCompletionDocuments[0].status === DocumentStatus.TO_BE_UPLOAD.value || workCompletionDocuments[0].status === DocumentStatus.TO_RECHARGE.value))) ? DateTime : ReadOnlyText;
                                             },
                                            control: DateTime,
                                            label: M("workCompletionDate"),
                                            placeholder: M("workCompletionDate"),
                                            size: "col-sm-6",
                                            emptyRow: true,
                                            visibility: (model) => {
                                                return model.get("id")
                                            },
                                            props: {
                                                formatter: v => {
                                                    return v != null ? moment(v).format("DD/MM/YYYY") : "-";
                                                }
                                            }
                                        },
                                        {
                                            property: "freeConstruction",
                                            label: M("freeConstruction"),
                                            size: "col-sm-6",
                                            isRequired: true,
                                            control: YesNo,
                                            visibility: (model) => {
                                                return model.get("type") === DOSSIER_TYPE.ECOBONUS_2022_1 && model.get("id") !== null && model.get("status") !== DossierStatus.STATUS_QUOTATION.value
                                            },
                                            props: {
                                                formatter(v, model) {
                                                    if (v != null)
                                                        return v === true ? "Si" : "No"
                                                    return ""
                                                }
                                            }
                                        },
                                        {
                                            property: "totalCostHigherTenThousand",
                                            control: YesNo,
                                            label: M("totalCostHigherTenThousand"),
                                            size: "col-sm-6",
                                            isRequired: true,
                                            visibility: (model) => {
                                                return (model &&
                                                    model.get("type") === DOSSIER_TYPE.ECOBONUS_2022_1 &&
                                                    model.get("id") !== null &&
                                                    model.get("status") !== DossierStatus.STATUS_QUOTATION.value &&
                                                    !model.get("freeConstruction"))
                                            },
                                            props: {
                                                formatter(v, model) {
                                                    if (v != null)
                                                        return v === true ? "Si" : "No"
                                                    return ""
                                                }
                                            }
                                        },
                                        {
                                            property: "notes",
                                            getControl: model => {
                                                return model.get("id") != null ? TextArea : Text;
                                            },
                                            visibility: (model) => {
                                                return isSuperuser() && model.get("id")
                                            },
                                            label: M("notes"),
                                            placeholder: M("notes"),
                                            size: "col-sm-12",
                                            emptyRow: true,
                                            props: {
                                                maxLength: 1000,
                                                height: "60px"
                                            }
                                        },
                                        {
                                            property: "fabricatorNotes",
                                            getControl: model => {
                                                return model.get("id") != null ? TextArea : Text;
                                            },

                                            label: M("fabricatorNotes"),
                                            placeholder: M("fabricatorNotes"),
                                            size: "col-sm-12",
                                            emptyRow: true,
                                            props: {
                                                maxLength: 1000,
                                                height: "60px"
                                            }
                                        },


                                        {
                                            property: "confirmQuotation",
                                            control: Button,
                                            size: "col-sm-12",
                                            emptyRow: true,
                                            visibility: (model) => {
                                                return model != null && model.get("id") != null && model.get("status") === DossierStatus.STATUS_QUOTATION.value && model.get("type") === DOSSIER_TYPE.ECOBONUS_2019;
                                            },
                                            props: {
                                                text: M("confirmQuotation"),
                                                className: "btn-orange m-b-23 m-t-27",
                                                onClick: model => {
                                                    confirmQuotation({
                                                        dossierType: model.get("type"),
                                                        dossierId: model.get("id")
                                                    })
                                                }
                                            }
                                        },
                                        {
                                            property: "c_b_1",
                                            control: Column,
                                            size: "col-md-4 col-sm-6",
                                            visibility: (model) => {
                                                return isSuperuser() && model != null && model.get("id") != null && model.get("status") !== DossierStatus.STATUS_QUOTATION.value;
                                            },
                                            noLateralPadding: false,
                                            fields: [
                                                {
                                                    property: "c_b_1_s",
                                                    control: Column,
                                                    size: "col-md-12",
                                                    className: "zero-padding border-bottom m-b-15",
                                                    noLateralPadding: false,
                                                    fields: [
                                                        {
                                                            control: Spacer,
                                                            label: null,
                                                            size: "col-8",
                                                            emptyRow: true,
                                                            props: {
                                                                className: "p-t-9",
                                                                defaultTheme: false,
                                                                content: M("canBeCandidate")
                                                            },
                                                        },
                                                        {
                                                            control: Spacer,
                                                            label: null,
                                                            size: "col-4",
                                                            emptyRow: true,
                                                            props: {
                                                                className: "p-t-9 float-right",
                                                                defaultTheme: false,
                                                                formatter: model => {
                                                                    return model.get("canBeCandidate") ? M("yes") : M("no")
                                                                }
                                                            },
                                                        },
                                                    ]
                                                },
                                            ]
                                        },
                                        {
                                            property: "c_b_2",
                                            control: Column,
                                            size: "col-md-4 col-sm-6",
                                            visibility: (model) => {
                                                return isSuperuser() && model != null && model.get("id") != null && model.get("status") !== DossierStatus.STATUS_QUOTATION.value;
                                            },
                                            noLateralPadding: false,
                                            fields: [
                                                {
                                                    property: "c_b_2_s",
                                                    control: Column,
                                                    size: "col-md-12",
                                                    className: "zero-padding border-bottom m-b-15",
                                                    noLateralPadding: false,
                                                    fields: [
                                                        {
                                                            control: Spacer,
                                                            label: null,
                                                            size: "col-8",
                                                            emptyRow: true,
                                                            props: {
                                                                className: "p-t-7",
                                                                defaultTheme: false,
                                                                content: M("candidateAE")
                                                            },
                                                        },
                                                        {
                                                            property: "candidateAE",
                                                            control: Switch,
                                                            label: null,
                                                            size: "col-4",
                                                            emptyRow: true,
                                                        },
                                                    ]
                                                },
                                            ]
                                        },
                                        {
                                            property: "c_b_3",
                                            control: Column,
                                            size: "col-md-4 col-sm-6",
                                            visibility: (model) => {
                                                return isSuperuser() && model != null && model.get("id") != null && model.get("status") !== DossierStatus.STATUS_QUOTATION.value;
                                            },
                                            noLateralPadding: false,
                                            fields: [
                                                {
                                                    property: "c_b_3_s",
                                                    control: Column,
                                                    size: "col-md-12",
                                                    className: "zero-padding border-bottom m-b-15",
                                                    noLateralPadding: false,
                                                    fields: [
                                                        {
                                                            control: Spacer,
                                                            label: null,
                                                            size: "col-8",
                                                            emptyRow: true,
                                                            props: {
                                                                className: "p-t-7",
                                                                defaultTheme: false,
                                                                content: M("candidateENEA")
                                                            },
                                                        },
                                                        {
                                                            property: "candidateENEA",
                                                            control: Switch,
                                                            label: null,
                                                            size: "col-4",
                                                            emptyRow: true,
                                                        },
                                                    ]
                                                },
                                            ]
                                        },
                                        {
                                            property: "c_b_4",
                                            control: Column,
                                            size: "col-md-4 col-sm-6",
                                            visibility: (model) => {
                                                return isSuperuser() && model != null && model.get("id") != null;
                                            },
                                            noLateralPadding: false,
                                            fields: [
                                                {
                                                    property: "c_b_4_s",
                                                    control: Column,
                                                    size: "col-md-12",
                                                    className: "zero-padding border-bottom m-b-15",
                                                    noLateralPadding: false,
                                                    fields: [
                                                        {
                                                            control: Spacer,
                                                            label: null,
                                                            size: "col-8",
                                                            emptyRow: true,
                                                            props: {
                                                                className: "p-t-7",
                                                                defaultTheme: false,
                                                                content: M("priority")
                                                            },
                                                        },
                                                        {
                                                            property: "priority",
                                                            control: Switch,
                                                            label: null,
                                                            size: "col-4",
                                                            emptyRow: true,
                                                        },
                                                    ]
                                                },
                                            ]
                                        },
                                    ]
                                },
                            ]
                        },
                        {
                            property: "column",
                            control: PriceCalculatorControl,
                            size: "col-md-4 col-sm-6",
                            className: "m-b-10",
                            noLateralPadding: false,
                            useBoostrapRow: true
                        },
                        {
                            property: "c",
                            control: Column,
                            size: "col-md-8",
                            className: "m-b-10",
                            emptyRow: true,
                            noLateralPadding: false,
                            fields: [
                                {
                                    property: "c_1",
                                    control: Column,
                                    size: "col-sm-12",
                                    className: "card",
                                    noLateralPadding: false,
                                    visibility: (model) => {
                                        return isSuperuser() && model != null && model.get("id") != null && model.get("status") !== DossierStatus.STATUS_QUOTATION.value;
                                    },
                                    fields: [
                                        {
                                            property: "code",
                                            control: Spacer,
                                            label: null,
                                            size: "col-sm-12",
                                            emptyRow: true,
                                            props: {
                                                className: "card-title",
                                                defaultTheme: false,
                                                content: M("kpmgStatus"),
                                            },
                                        },
                                        {
                                            property: "kpmgdocumentGroupsStatus",
                                            control: MultiLineText,
                                            label: null,
                                            size: "col-sm-8",
                                            emptyRow: true,
                                            className: "m-b-10",
                                            props: {
                                                defaultTheme: false,
                                                innerClass: "m-b-5"
                                            },
                                        },
                                        {
                                            property: "_sendForReview",
                                            control: Button,
                                            size: "col-sm-4",
                                            emptyRow: true,
                                            visibility: (model) => {
                                                return isSuperuser() && model != null && model.get("id") != null
                                                    // && model.get("kpmgdossierReviewCompletionDate") == null;
                                            },
                                            props: {
                                                text: M("sendToKPMG"),
                                                className: "btn-orange",
                                                onClick: model => {
                                                    KPMGSendForReview({
                                                        dossierId: model.get("id"),
                                                        dossierType: model.get("type")
                                                    })
                                                }
                                            }
                                        },
                                        {
                                            property: "_kpmgLog",
                                            control: ReadOnlyTextArea,
                                            visibility: (model) => {
                                                return isSuperuser() && model != null && model.get("id") != null;
                                            },
                                            label: "Stato ultimo invio a KPMG",
                                            placeholder: "-",
                                            size: "col-sm-12",
                                            props: {
                                                autoHeight: true
                                            }
                                        },
                                        {
                                            property: "code",
                                            control: Spacer,
                                            label: null,
                                            size: "col-sm-12",
                                            emptyRow: true,
                                            visibility: (model) => {
                                                return isSuperuser() && model != null && model.get("id") != null && model.get("kpmgdossierReviewCompletionDate") != null;
                                            },
                                            props: {
                                                defaultTheme: false,
                                                showBorderBottom: true
                                            },
                                        },
                                        {
                                            property: "kpmgdossierReviewCompletionDate",
                                            control: ReadOnlyText,
                                            visibility: (model) => {
                                                return isSuperuser() && model != null && model.get("id") != null && model.get("kpmgdossierReviewCompletionDate") != null;
                                            },
                                            label: "Data di fine revisione KPMG",
                                            placeholder: "Data di fine revisione KPMG",
                                            size: "col-sm-4",
                                            props: {
                                                formatter: v => {
                                                    return v != null ? moment(v).format("DD/MM/YYYY HH:mm") : moment(new Date()).format("DD/MM/YYYY HH:mm")
                                                }
                                            }
                                        },
                                        {
                                            property: "kpmgdossierAdEProtocolNumber",
                                            control: ReadOnlyText,
                                            visibility: (model) => {
                                                return isSuperuser() && model != null && model.get("id") != null && model.get("kpmgdossierAdEProtocolNumber") != null;
                                            },
                                            label: "N. Protocollo Agenzia delle Entrate",
                                            placeholder: "N. Protocollo Agenzia delle Entrate",
                                            size: "col-sm-4",
                                        },
                                        {
                                            property: "kpmgadeDossierAmount",
                                            control: ReadOnlyText,
                                            visibility: (model) => {
                                                return isSuperuser() && model != null && model.get("id") != null && model.get("kpmgadeDossierAmount") != 0;
                                            },
                                            label: "Ammontare della pratica",
                                            placeholder: "Ammontare della pratica",
                                            size: "col-sm-4",
                                            props: {
                                                formatter: v => {
                                                    return formatNumber(v)
                                                }
                                            }
                                        },
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    component: AreaNoCard,
                    className: "zero-padding m-t-10",
                    visibility: model => {
                        return model.get("id") != null && model.get("status") !== DossierStatus.STATUS_QUOTATION.value && model.get("type") === DOSSIER_TYPE.ECOBONUS_2019;
                    },
                    fields: [
                        {
                            property: FORM_FOOTER
                        }
                    ]
                },
                //FOOTER
                {
                    component: AreaNoCard,
                    className: "zero-padding m-t-10",
                    visibility: model => {
                        return model.get("type") === DOSSIER_TYPE.ECOBONUS_2020_50 || model.get("type") === DOSSIER_TYPE.ECOBONUS_2021_50 || model.get("type") === DOSSIER_TYPE.ECOBONUS_2022_1;
                    },
                    fields: [
                        {
                            property: "custom_footer",
                            control: DossierFormFooter,
                        }
                    ]
                },
                {
                    component: AreaNoCard,
                    className: "zero-padding",
                    visibility: model => {
                        return model.get("id") != null && model.get("status") !== DossierStatus.STATUS_QUOTATION.value;
                    },
                    fields: [
                        {
                            property: "documents",
                            control: DossierDocumentContainer,
                            label: null,
                            size: "col-sm-12",
                        }
                    ]
                },
            ]
        }
    }
}
