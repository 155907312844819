"use strict"

import React from "react";
import M from "../../strings";
import _ from "underscore";
import {format, optional, safeGet} from "../../utils/lang";
import moment from "moment";
import {
    AssignationType,
    DocumentStatus,
    DocumentTypeTypology,
    DossierStatus,
    getKPMGGroupsDescription
} from "../../model/vars";
import * as config from "../../framework/config";
import {getSessionToken, isFabricator, isGiureUser, isSuperuser} from "../../api/session";
import {isClosingAssignationType} from "./dossiers/utils";

export class DocumentRow extends React.Component {
    constructor(props) {
        super(props)
    }

    onClick() {
        if (_.isFunction(this.props.onClick)) {
            this.props.onClick(this.props.item)
        }
    }

    attachAttachment() {
        let row = this.props.row
        if (_.isFunction(this.props.attachAttachment)) {
            this.props.attachAttachment(row)
        }
    }

    approveDocument() {
        let row = this.props.row
        if (_.isFunction(this.props.approveDocument)) {
            this.props.approveDocument(row)
        }
    }

    refuseDocument() {
        let row = this.props.row
        if (_.isFunction(this.props.refuseDocument)) {
            this.props.refuseDocument(row)
        }
    }

    clearDocument() {
        let row = this.props.row
        if (_.isFunction(this.props.clearDocument)) {
            this.props.clearDocument(row)
        }
    }

    downloadTemplate(empty) {
        let row = this.props.row

        let url = format(
            "{0}/generate?dossierType={1}&documentTypeId={2}&empty={3}&__TOKEN={4}",
            config.get("document.url"),
            optional(this.props.dossierType, ""),
            row.documentTypeId,
            empty,
            encodeURIComponent(getSessionToken())
        )

        if (this.props.dossierId != null) {
            window.open(format("{0}&dossierId={1}", url, this.props.dossierId))
        }
        else if (this.props.fabricatorId)
            window.open(format("{0}&fabricatorId={1}", url, this.props.fabricatorId))
    }

    downloadAttachment() {
        let row = this.props.row
        window.open(config.get("attachment.download") + "?filename=" + row.documentType.description + "&path=" + encodeURI(row.file) + "&__TOKEN=" + encodeURIComponent(getSessionToken()))
    }

    refuseHistory() {
        if (_.isFunction(this.props.refuseHistory))
            this.props.refuseHistory(this.props.row.refuseReasonHistory)
    }

    getStatusDescription() {
        let row = this.props.row;
        let assignationType = safeGet(row.documentType, "assignationType", null);
        if (row.status === DocumentStatus.TO_BE_UPLOAD.value && row.valid) {
            return M("toBeUpload");
        } else if (row.status === DocumentStatus.UPLOADED.value && row.valid && this.hasApprovedStatus(assignationType)) {
            return format(M("uploadedDate"), moment(row.uploadDate).format("DD/MM/YYYY"));
        } else {
            if (row.status === DocumentStatus.APPROVED.value || (!this.hasApprovedStatus(assignationType) && row.status === DocumentStatus.UPLOADED.value)) {
                if (row.kpmgsentDate == null && row.kpmgapprovedDate == null && (!this.hasApprovedStatus(assignationType) && row.status === DocumentStatus.UPLOADED.value)) {
                    return format(M("uploadedDate"), moment(row.uploadDate).format("DD/MM/YYYY"));
                } else {
                    if (row.kpmgapprovedDate != null) {
                        if (row.documentType.receiveByKPMG) {
                            return format(M("receivedDate") + " " + M('approvedUser'), moment(row.kpmgapprovedDate).format("DD/MM/YYYY"), "KPMG");
                        } else {
                            return format(M("approvedDate") + " " + M('approvedUser'), moment(row.kpmgapprovedDate).format("DD/MM/YYYY"), "KPMG");
                        }
                    } else {
                        if (row.kpmgsentDate != null) {
                            return format(M("kpmgSentDate"), moment(row.kpmgsentDate).format("DD/MM/YYYY"));
                        } else {
                            return format(M("approvedDate") + (row.approverUser ? (" " + M('approvedUser')) : ""), moment(row.approvedDate).format("DD/MM/YYYY"), safeGet(row.approverUser, "username"));
                        }
                    }
                }
            } else if (!_.isUndefined(row.refuseReason) && row.refuseReason !== "") {
                var refusedUserCredential = row.refuseReasonHistory && row.refuseReasonHistory.length > 0 ? row.refuseReasonHistory[0].refuseUserCredential : "";
                return format(M("refusedDateAndReason"),refusedUserCredential , moment(row.refusedDate).format("DD/MM/YYYY"), row.refuseReason)
            } else {
                var refusedUserCredential = row.refuseReasonHistory && row.refuseReasonHistory.length > 0 ? row.refuseReasonHistory[0].refuseUserCredential : "";
                return format(M("refusedDate"), moment(row.refusedDate).format("DD/MM/YYYY"), refusedUserCredential)
            }
        }
    }

    hasApprovedStatus(assignationType) {
        return assignationType === AssignationType.PREPARATORY_DOCUMENTATION.value || assignationType === AssignationType.ENEA_PREPARATORY_DOCUMENTATION.value || assignationType === AssignationType.FABRICATOR_PROFILE.value || assignationType === AssignationType.GIURE_DOCUMENT.value;
    }

    render() {
        let row = this.props.row
        let assignationType = safeGet(row.documentType, "assignationType", null);
        let hasApprovedStatus = this.hasApprovedStatus(assignationType);
        let index = this.props.index + 1;

        index = index < 10 ? "0" + index : index
        // let status = row.status === DocumentStatus.TO_BE_UPLOAD.value && row.valid ? M("toBeUpload") : (row.status === DocumentStatus.UPLOADED.value && row.valid ? format(M("uploadedDate"), moment(row.uploadDate).format("DD/MM/YYYY")) :
        //     (row.status === DocumentStatus.APPROVED.value ?
        //         (row.kpmgapprovedDate == null ?
        //             format(M("approvedDate") + (row.approverUser ? (" " + M('approvedUser')) : ""), moment(row.approvedDate).format("DD/MM/YYYY"), safeGet(row.approverUser, "username"))
        //         :
        //             (row.kpmgsentDate == null ?
        //                 format(M("approvedDate") + " " + M('approvedUser'), moment(row.kpmgapprovedDate).format("DD/MM/YYYY"), "KPMG")
        //                     :
        //                 format(M("kpmgSentDate"), moment(row.kpmgsentDate).format("DD/MM/YYYY HH:mm"))
        //             )
        //         )
        //         :
        //         (!_.isUndefined(row.refuseReason) && row.refuseReason !== "" ? format(M("refusedDateAndReason"), moment(row.refusedDate).format("DD/MM/YYYY"), row.refuseReason) : format(M("refusedDate"), moment(row.refusedDate).format("DD/MM/YYYY")))));
        let status = this.getStatusDescription();
        let documentStatusClass = "col-12 document-status" + (row.file == null && row.valid ? " pending" : (row.valid ? "" : " not-valid"));

        let cardStyle = {};
        if (row.file == null) {
            cardStyle.backgroundColor = "#EBEBEB";
        }
        let circleClassName = "circle-document float-left " + (row.file ? (row.approvedDate != null || row.kpmgapprovedDate || !hasApprovedStatus ? " button-blue" : " button-yellow") : "");

        let imgStyle = {
            "backgroundRepeat": "no-repeat",
            "backgroundSize": "contain",
            "backgroundPosition": "center",
            "height": "70px",
            "backgroundColor": "#F2F2F2"
        };

        let preview;
        if (row.preview) {
            preview = config.get("service.url") + row.preview.replace("/", "");
        }

        let canAttach = row.file == null && (isSuperuser() || (!isClosingAssignationType(assignationType) && !isGiureUser()) || (isGiureUser() && row.documentType.giureValidationDocument) || (isGiureUser() && row.documentType.giureCanEditDocumentAttachment))

        return (
            <div className="col-12 zero-padding" onClick={this.onClick.bind(this)}>
                <div className="card m-t-10 m-b-0 p-15" style={cardStyle}>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-8 p-t-12" style={{display: "inherit"}}>
                                <div className={circleClassName}>
                                    <button onClick={(e) => {
                                        e.preventDefault()
                                    }}>
                                        {row.file != null && (row.approvedDate == null || !hasApprovedStatus) &&
                                        <i className="zmdi zmdi-check"/>}
                                        {row.file != null && row.approvedDate != null &&
                                        <i className="zmdi zmdi-check-all"/>}
                                    </button>
                                </div>
                                <div className="float-left">
                                    <div className={documentStatusClass}>
                                        <span className="fs-18"
                                              style={{fontWeight: 700, marginRight: "10px"}}>{index}</span><span
                                        className="fs-18">{row.documentType != null ? row.documentType.description : ""}</span>
                                    </div>
                                    <div className="col-12">
                                        {row.documentType != null && row.documentType.enableKPMGReview && <p className="fs-14 font-italic m-0">{format(M("enabledForKPMG"), getKPMGGroupsDescription(row.documentType.kpmggroup))}</p>}
                                        <p className="fs-14 m-0">{status}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 zero-padding">
                                <div className="dropleft float-right" style={{paddingTop: "20px"}}>
                                    <a className="dropdown-toggle document-more-vert" id="dropdownMenuButton"
                                       data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="zmdi zmdi-more-vert"/>
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        {hasApprovedStatus && row.status !== DocumentStatus.APPROVED.value && (isSuperuser() || (isGiureUser() && row.documentType.giureValidationDocument)) &&
                                        <a className={"dropdown-item " + (row.valid && row.file != null ? "" : "disabled")}
                                           onClick={this.approveDocument.bind(this)}>{M("approve")}</a>}

                                        {(isSuperuser() || (isGiureUser() && row.documentType.giureValidationDocument)) && <a className={"dropdown-item " + (row.valid && row.file != null ? "" : "disabled")}
                                                                           onClick={this.refuseDocument.bind(this)}>{M("refuse")}</a>}

                                        {(row.status !== DocumentStatus.APPROVED.value && (!isGiureUser() && row.documentType.giureValidationDocument) || (isSuperuser() || (isGiureUser() && row.documentType.giureValidationDocument)) || (isFabricator())) && <a className={"dropdown-item " + (row.file != null ? "" : "disabled")}
                                           onClick={this.clearDocument.bind(this)}>{M("remove")}</a>}
                                        {((row.documentType != null && row.documentType.typology !== DocumentTypeTypology.NO_MODEL.value && (isSuperuser() || (isGiureUser() && row.documentType.giureValidationDocument))) || row.documentType != null && row.documentType.typology === DocumentTypeTypology.DOWNLOADABLE_TEMPLATE.value) &&
                                        <a className="dropdown-item"
                                           onClick={this.downloadTemplate.bind(this, true)}>{M("downloadTemplate")}</a>}
                                        {row.documentType != null && row.documentType.typology === DocumentTypeTypology.SELF_COMPILED_DOWNLOADABLE_TEMPLATE.value &&
                                        <a className="dropdown-item"
                                           onClick={this.downloadTemplate.bind(this, false)}>{M("downloadAutofilledTemplate")}</a>}
                                        {row.refuseReasonHistory.length > 0 &&
                                        <a className="dropdown-item"
                                           onClick={this.refuseHistory.bind(this)}>{M("refuseHistory")}</a>}
                                    </div>
                                </div>

                                {(canAttach )&& <div className="attachment-box float-right"
                                                          onClick={this.attachAttachment.bind(this, row)}>
                                    <div className="add-circle-document float-left">
                                        <button onClick={(e) => {
                                            e.preventDefault()
                                        }}>
                                            <i className="zmdi zmdi-plus"/>
                                        </button>
                                    </div>
                                </div>}
                                {row.file != null &&
                                <div className="attachment-box float-right">
                                    <div onClick={this.downloadAttachment.bind(this, row)} className="input-image"
                                         style={_.assign(imgStyle, {"backgroundImage": `url("${preview}")`})}/>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
