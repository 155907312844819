"use strict"

import {
    COUNT_UNREAD,
    GET_USER_COVER_IMAGE,
    LIST_NOTIFICATION,
    MARK_ALL_UNREAD,
    TOGGLE_OPEN
} from "./types";
import {createAsyncAction} from "../utils/ajex";
import * as aj from "../aj";
import * as NotificationApi from "../api/notification";


export const toggleOpen = createAsyncAction(TOGGLE_OPEN, data => {

    aj.dispatch({
        type: TOGGLE_OPEN,
        open: data.open
    })

});

export const countUnread = createAsyncAction(COUNT_UNREAD, data => {


    NotificationApi.countUnread()
        .then(data => {
            countUnread.complete({unreadCount: data.value})
        })
        .catch(e => {

        })

})



export const getNotifications = createAsyncAction(LIST_NOTIFICATION, data => {


    aj.dispatch({
        type: LIST_NOTIFICATION
    })

    NotificationApi.getNotifications()
        .then(data => {
            getNotifications.complete({notifications: data.value})
        })
        .catch(e => {
            getNotifications.fail({notifications: data.value})
        })

})
