"use strict"

import {optional} from "../../utils/lang";
import M from "../../strings";
import {DOSSIER_TYPE, STATISTIC_EXPORT} from "../../model/vars";
import * as config from "../../framework/config";
import {getSessionToken} from "../../api/session";
import {FreeEntityForm} from "./entities/entityForm";
import {alert} from "../../plugins";
import * as _ from "underscore";
import React from "react";
const {Screen, Layout} = require("../components/layout")
const Secure = require("../components/secure")

export default class Statistics extends Screen {

    constructor(props) {
        super(props)
    }

    export(type, data) {
        if(data != null) {
            switch (type) {
                case STATISTIC_EXPORT.DOSSIER:
                    this.exportDossier(data);
                    break
            }
        }

    }

    exportDossier(data) {
        if (!data)
            data = {}
        if (!data.type) {
            alert("E' necessario selezionare la tipologia di pratica")
            return
        }

        let dossierType =  data.type;
        let startDate = data.startDate != null ? data.startDate : "";
        let endDate = data.endDate != null ? data.endDate :"";
        let customerId = data.customer != null ? data.customer.id : "";
        let fabricatorId = data.fabricator != null ? data.fabricator.id : "";
        let fabricatorCollaboratorId = data.fabricatorCollaborator != null ? data.fabricatorCollaborator.id : "";
        let priority = data.priority != null ? data.priority : "" ;
        let dl157 = data.dl157 != null? data.dl157 : false ;
        let freeConstruction = data.freeConstruction != null? data.freeConstruction : false ;
        let kpmgStartSentDate = data.kpmgStartSentDate != null ? data.kpmgStartSentDate : "";
        let kpmgEndSentDate = data.kpmgEndSentDate != null ? data.kpmgEndSentDate : "";

        let url = config.get("statistics.url") + "/exportDossier";
        let token = getSessionToken();

        location.href = url+"?__TOKEN=" + encodeURIComponent(token)+"&type=" + dossierType  + "&startDate="+startDate+"&endDate="+endDate+"&customerId="+customerId+
            "&fabricatorId="+fabricatorId+"&fabricatorCollaboratorId="+fabricatorCollaboratorId +"&priority="+priority +"&dl157="+ dl157 +"&freeConstruction="+ freeConstruction +"&kpmgStartSentDate="+ kpmgStartSentDate +"&kpmgEndSentDate="+ kpmgEndSentDate;

    }


    getReportTypes() {
        return [STATISTIC_EXPORT.DOSSIER];
    }
    getIconReport() {
        return ["zmdi zmdi-assignment"]
    }


    render() {

        let i =-1;
        let icon = this.getIconReport();
        let labelStyle = {
            "color": optional(this.props.labelColor, "#616161"),
            "marginBottom": "0px",
            "whiteSpace": "nowrap",
            "textOverflow": "ellipsis",
            "overflow": "hidden",
            "fontWeight": "500",
        }

        let reportForms = _.map(this.getReportTypes(), type => {
                i++
                let data = {};
                return (<div className="row m-b-20">
                            <div className="form-group col-sm-12 ">
                            <div className="col-sm-12">
                                <div className="col-sm-12">
                                    <p className="fs-18 p-t-20 p-b-20" style={labelStyle}><i className={icon[i]}/> {M(type)}</p>
                                </div>
                                <FreeEntityForm disableOnBeforeUnload={true} ref="customEntityForm" entity={type} entityForDescriptor={type} data={data}/>
                            </div>
                            <div className="col-sm-12">
                                <button disabled={false} onClick={this.export.bind(this, type, data)} type="button"
                                className="btn btn-primary float-right">Esporta</button>
                            </div>
                            </div>
                        </div>)
            }
        )

        return (
            <Secure>
                <Layout>
                    <div className=" block-header">
                        <h2>Esportazione</h2>

                    <br/><br/>
                    <div className="card">
                        {reportForms}
                    </div>
                    </div>
                </Layout>
            </Secure>
        )
    }

}


