"use strict"

export const UI = "UI";
export const SESSION = "SESSION";
export const ACCOUNT = "ACCOUNT";
export const GRIDS = "GRIDS"
export const SEARCH = "SEARCH"
export const ENTITIES = "ENTITIES"
export const LOOKUP = "LOOKUP"
export const MENU = "MENU"
export const SELECT = "SELECT"
export const MULTIVALUE_SETTINGS = "MULTIVALUE_SETTINGS"
export const SYSTEM = "SYSTEM"
export const DOSSIER = "DOSSIER"
export const DOSSIER_2021_BONUS_CASA = "DOSSIER_2021_BONUS_CASA"
export const DOSSIER_2022_BONUS_CASA_1 = "DOSSIER_2022_BONUS_CASA_1"
export const DOSSIER_2022_BONUS_CASA_2 = "DOSSIER_2022_BONUS_CASA_2"
export const DOSSIER_2023_75 = "DOSSIER_2023_75"

export const DOSSIER_2022 = "DOSSIER_2022"
export const FABRICATOR = "FABRICATOR"
export const DOSSIER_GRID = "DOSSIER_GRID"
export const NOTIFICATION = "NOTIFICATION"
export const QUESTIONNAIRE = "QUESTIONNAIRE"
