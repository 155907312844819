

"use strict";

import * as config from "../framework/config";
import {get} from "./utils";

export function KPMGSendForReview(dossierId, dossierType) {
    return get(config.get("kpmg.url") + "/forceKPMGSendForReview/" + dossierType + "/" + dossierId)
}

export function forceAllKPMGSend() {
    return get(config.get("kpmg.url") + "/allForceKPMGSendForReview/")
}
