"use strict"

import * as aj from "../aj/index";
import {completed, failed} from "../utils/ajex";
import * as actions from "../actions/types";
import _ from "underscore";
import {DOSSIER_2022} from "./types";


function defaultState() {
    return {
        saved:false,
        priceCalculator: {},
        canDownload: false,
        downloadUrl: undefined
    }
}

export const Dossier_2022_Store = aj.createStore(DOSSIER_2022, (state = defaultState(), action) => {

    switch (action.type) {
        case completed(actions.CONFIRM_QUOTATION_2022):
            return _.assign(state, {
                error: false,
                statusChanged: true
            });

        case failed(actions.CONFIRM_QUOTATION_2022):
            return _.assign(state, {
                error: true,
                statusChanged: false
            });

        case actions.GENERATE_QUOTATION_DOSSIER_2022:
            return _.assign(state, {
                downloadUrl: undefined,
                canDownload: true
            });
        case completed(actions.GENERATE_QUOTATION_DOSSIER_2022):
            return _.assign(state, {
                error: false,
                downloadUrl: action.downloadUrl,
            });

        case failed(actions.GENERATE_QUOTATION_DOSSIER_2022):
            return _.assign(state, {
                error: true,
                downloadUrl: undefined,
                canDownload: false
            });


        case completed(actions.GENERATE_QUOTATION_AMOUNTS_2022):
            return _.assign(state, {
                priceCalculatorSheet: action.priceCalculatorSheet
            })

        case actions.RESTORE_CAN_DOWNLOAD:
            return _.assign(state, {
                downloadUrl: undefined,
                canDownload: false
            })


    }

});
