"use strict";

import * as config from "../framework/config";
import {get, postJson} from "./utils";

export function generateQuotationDossier_2021_bonusCasa(dto) {
    return postJson(config.get("dossiers2021BonusCasa.url") + "/generateQuotation", dto)
}

export function generateQuestionnaireDossier_2021_bonusCasa(dto) {
    return postJson(config.get("dossiers2021BonusCasa.url") + "/generateQuestionnaire", dto)
}

export function updateQuestionnaire_2021_bonusCasa(dto) {
    return postJson(config.get("dossiers2021BonusCasa.url") + "/updateQuestionnaire", dto)
}

export function editDossier_2021_BonusCasa(dto) {
    return postJson(config.get("dossiers2021BonusCasa.url") + "/edit", dto)
}

export function createDossier_2021_BonusCasa(dto) {
    return postJson(config.get("dossiers2021BonusCasa.url") + "/create", dto)
}

export function generateQuotationAmounts_2021_BonusCasa(data) {
    if (data["continuationWork"] == null) {
        delete data["continuationWork"];
    }
    return get(config.get("dossiers2021BonusCasa.url") + "/quotation/amounts", data)
}

export function confirmQuotation_2021_BonusCasa(dto) {
    return postJson(config.get("dossiers2021BonusCasa.url") + "/confirmQuotation", dto)
}
