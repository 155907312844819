//Development
//var serviceBase = "http://localhost:8080/api/";

// Production/Kubernetes
var serviceBase =  "https://www.inponzio.com/api/";
//var serviceBase = "http://34.102.187.35/api/";


export function getPrivacyUrl() {
    return serviceBase + "public/documents/privacy"
}

export function getTermAndConditionsUrl() {
    return serviceBase + "public/documents/termsAndConditions"
}

function getVersion() {
    return "2.10";
}

function getCopyright() {
    return "Applica SRL, 2020";
}

module.exports = {
    "service.url": `${serviceBase}`,
    "fs.url": `${serviceBase}fs`,
    "login.url": `${serviceBase}auth/login`,
    "auth.fresh.token.url" : `${serviceBase}auth/freshToken`,
    "account.url": `${serviceBase}account`,
    "account.register.url": `${serviceBase}account/register`,
    "account.recover.url": `${serviceBase}account/recover`,
    "account.reset.url": `${serviceBase}account/reset`,
    "account.confirm.url": `${serviceBase}account/confirm`,
    "grids.url": `${serviceBase}grids`,
    "entities.url": `${serviceBase}entities`,
    "entities.delete.url": `${serviceBase}entities/delete`,
    "values.url": `${serviceBase}values`,
    "values.entities.url": `${serviceBase}values/entities`,
    "account.requestRecoveryCode.url": `${serviceBase}account/sendConfirmationCode`,
    "account.validateRecoveryCode.url": `${serviceBase}account/validateRecoveryCode`,
    "account.resetPassword.url": `${serviceBase}account/resetPassword`,
    "account.resetUserPassword.url": `${serviceBase}account/resetUserPassword`,
    "system.url": `${serviceBase}system`,
    "upload.url": `${serviceBase}upload/file`,
    "dossier.url": `${serviceBase}dossiers`,
    "dossiers2021BonusCasa.url": `${serviceBase}dossiers2021BonusCasa`,
    "dossiers2022BonusCasa_1.url": `${serviceBase}dossiers2022BonusCasa_1`,
    "dossiers2022BonusCasa_2.url": `${serviceBase}dossiers2022BonusCasa_2`,
    "dossiers2023_75.url": `${serviceBase}dossier2023_75`,
    "dossiers2022.url": `${serviceBase}dossiers2022`,
    "kpmg.url": `${serviceBase}kpmg`,
    "fabricator.url": `${serviceBase}fabricators`,
    "document.url": `${serviceBase}documents`,
    "statistics.url": `${serviceBase}statistics`,
    "notifications.url": `${serviceBase}notifications`,
    "attachment.download" : `${serviceBase}attachment/renameAndDownload`,
    "backendVersion" : getVersion(),
    "copyrightInfos" : getCopyright(),
    "privacyUrl" : getPrivacyUrl(),
    "termsAndConditionsUrl": getTermAndConditionsUrl()
}
