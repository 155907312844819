"use strict"

import * as aj from "../aj/index";
import {completed, failed} from "../utils/ajex";
import {
    HIDE_ADD_REQUIRED_POLICY_DOCUMENT_DIALOG,
    QST_GO_BACK,
    QST_GO_NEXT,
    QST_LOAD_QUESTIONNAIRE,
    QST_LOAD_QUESTIONNAIRE_DOSSIER,
    QST_SET_OPTION_ANSWER,
    QST_SET_OPTION_CHOOSE,
    QST_SET_REQUIRED_POLICY_DOCUMENT,
    QST_SET_TEXT_ANSWER,
    QST_UPLOAD_REQUIRED_POLICY,
    SHOW_ADD_REQUIRED_POLICY_DOCUMENT_DIALOG
} from "../actions/types";
import _ from "underscore";
import {QUESTIONNAIRE} from "./types";

function defaultState() {
    return {
        questionnaire: null,
        showAddRequiredPolicyDocumentDialog: false,
    }
}

const QuestionnaireStore = aj.createStore(QUESTIONNAIRE, (state = defaultState(), action) => {

    switch (action.type) {

        case completed(QST_LOAD_QUESTIONNAIRE):
        case completed(QST_LOAD_QUESTIONNAIRE_DOSSIER):
            action.questionnaire.progress = 0
            return _.assign(state, {questionnaire: action.questionnaire, error: false})

        case failed(QST_LOAD_QUESTIONNAIRE):
        case failed(QST_LOAD_QUESTIONNAIRE_DOSSIER):
            return _.assign(state, {questionnaire: null, error: true})

        case QST_GO_NEXT:
            return _.assign(state, {
                questionnaire: _.assign(state.questionnaire, {progress: state.questionnaire.progress+1})
            })

        case QST_GO_BACK:
            return _.assign(state, {
                questionnaire: _.assign(state.questionnaire, {progress: state.questionnaire.progress-1})
            })

        case QST_SET_TEXT_ANSWER: {
            state.questionnaire.questions[state.questionnaire.progress].options[action.o_index].answer = action.value
            return {
                ...state
            }
        }

        case QST_SET_OPTION_ANSWER: {
            state.questionnaire.questions[state.questionnaire.progress].shortAnswer = action.value
            return {
                ...state
            }
        }

        case QST_SET_OPTION_CHOOSE: {
            state.questionnaire.questions[state.questionnaire.progress].shortAnswer = null
            state.questionnaire.questions[state.questionnaire.progress].selectedOptionIds = [action.value]
            return {
                ...state
            }
        }

        case QST_SET_REQUIRED_POLICY_DOCUMENT: {
            state.questionnaire.requiredPolicyDocument = action.value
            return {
                ...state
            }
        }

        case SHOW_ADD_REQUIRED_POLICY_DOCUMENT_DIALOG: {
            state.showAddRequiredPolicyDocumentDialog = true;
            return {
                ...state
            }
        }

        case HIDE_ADD_REQUIRED_POLICY_DOCUMENT_DIALOG: {
            state.showAddRequiredPolicyDocumentDialog = false;
            return {
                ...state
            }
        }

        case QST_UPLOAD_REQUIRED_POLICY:
            return _.assign(state, {attachCompleted: false, loading: true});

        // case completed(QST_UPLOAD_REQUIRED_POLICY):
        //     state.questionnaire.requiredPolicyDocument = action.attachedFile;
        //     return _.assign(state, {attachCompleted: true, error: false, loading: false});
        //
        // case failed(QST_UPLOAD_REQUIRED_POLICY):
        //     return _.assign(state, {attachCompleted: false, error: true, loading: false});

        /*case QST_SET_TEXT_ANSWER: {
            debugger
            return _.assign(state, {
                questionnaire: _.assign(state.questionnaire, {
                    questions: updatedList(
                        state.questionnaire.questions,
                            q => q.number === action.q_index,
                            q => _.assign(q, {
                                options: updatedList(
                                    q.options,
                                        o => o.id === action.o_index,
                                    o => _.assign(o, {answer: action.value})
                                )
                            })
                    )
                })
            })

        }*/
    }

})

QuestionnaireStore.defaultState = defaultState;

export default QuestionnaireStore;
