"use strict"

import {alert, hideLoader, showLoader, toast} from "../plugins";
import {createAsyncAction} from "../utils/ajex";
import * as responses from "../api/responses";
import _ from "underscore";
import {
    APPROVE,
    CANDIDATE,
    COMMIT,
    CONFIRM_QUOTATION,
    CONFIRM_QUOTATION_2020_50,
    CONFIRM_QUOTATION_2021_50,
    CONFIRM_QUOTATION_2022_1,
    CREATE_DOSSIER,
    CREATE_DOSSIER_2020_50,
    CREATE_DOSSIER_2021_50,
    CREATE_DOSSIER_2022_1,
    DOSSIER_APPROVE_DOCUMENT,
    DOSSIER_ATTACH_DOCUMENT,
    DOSSIER_CALCULATE_ACTUAL_VAT,
    DOSSIER_CALCULATE_INVOICE_VAT,
    DOSSIER_CLEAR_DOCUMENT,
    DOSSIER_HIDE_ADD_DOCUMENT_DIALOG,
    DOSSIER_HIDE_BILLS_DIALOG,
    DOSSIER_HIDE_FINANCING_DIALOG,
    DOSSIER_HIDE_REFUSE_DOCUMENT_DIALOG,
    DOSSIER_HIDE_SERVICE_COSTS,
    DOSSIER_HIDE_VAT_CALCULATOR_DIALOG,
    DOSSIER_REFUSE_DOCUMENT,
    DOSSIER_SELECT_PAYMENT_FEE,
    DOSSIER_SET_PRICE_CALCULATOR_SHEET,
    DOSSIER_SHOW_ADD_DOCUMENT_DIALOG,
    DOSSIER_SHOW_BILLS_DIALOG,
    DOSSIER_SHOW_FINANCING_DIALOG,
    DOSSIER_SHOW_REFUSE_DOCUMENT_DIALOG,
    DOSSIER_SHOW_SERVICE_COSTS,
    DOSSIER_SHOW_VAT_CALCULATOR_DIALOG,
    EDIT_DOSSIER,
    EDIT_DOSSIER_2020_50,
    EDIT_DOSSIER_2021_50,
    EDIT_DOSSIER_2022_1, FORCE_ALL_KPMG_SEND_FOR_REVIEW,
    GENERATE_QUESTIONNAIRE_DOSSIER_2020_50,
    GENERATE_QUESTIONNAIRE_DOSSIER_2021_50,
    GENERATE_QUESTIONNAIRE_DOSSIER_2022_1,
    GENERATE_QUOTATION_AMOUNTS_2020_50,
    GENERATE_QUOTATION_DOSSIER_2020_50,
    GENERATE_QUOTATION_DOSSIER_2021_50,
    GENERATE_QUOTATION_DOSSIER_2022_1, GIURE_DOSSIER_APPROVE_DOCUMENT,
    GIURE_DOSSIER_CLEAR_DOCUMENT, GIURE_DOSSIER_REFUSE_DOCUMENT,
    GIURE_EVALUATION,
    KPMG_SEND_FOR_REVIEW,
    PAY_OFF,
    QUOTATION,
    REFUSE,
    RESTORE_CAN_DOWNLOAD,
    UPDATE_QUESTIONNAIRE
} from "./types";
import * as DossierApi from "../api/dossier";
import * as KPMGApi from "../api/kpmg";
import {freeEntities, getEntity, manualSavedEntities} from "./entities";
import M from "../strings";
import * as aj from "../aj";
import {validateRequiredParam} from "../utils/customUtils";
import * as ui from "../web/utils/ui";
import {DOSSIER_TYPE, getDossierEntityNameFromType} from "../model/vars";
import {format, safeGet} from "../utils/lang";
import {evaluation} from "../api/dossier";
import {forceAllKPMGSendForReview} from "../api/kpmg";

export const restoreCanDownload = aj.createAction(RESTORE_CAN_DOWNLOAD, data => {
    aj.dispatch({
        type: RESTORE_CAN_DOWNLOAD
    })
});

export const generateQuotationDossier_2021_50 = createAsyncAction(GENERATE_QUOTATION_DOSSIER_2021_50, data => {
    showLoader();
    aj.dispatch({
        type: GENERATE_QUOTATION_DOSSIER_2021_50
    });

    DossierApi.generateQuotationDossier_2021_50(data.dossierDTO)
        .then(response => {
            hideLoader();
            generateQuotationDossier_2021_50.complete({downloadUrl: response.value})
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            generateQuotationDossier_2021_50.fail()
        })
});

export const generateQuotationDossier_2022_1 = createAsyncAction(GENERATE_QUOTATION_DOSSIER_2022_1, data => {
    showLoader();
    aj.dispatch({
        type: GENERATE_QUOTATION_DOSSIER_2022_1
    });

    DossierApi.generateQuotationDossier_2022_1(data.dossierDTO)
        .then(response => {
            hideLoader();
            generateQuotationDossier_2022_1.complete({downloadUrl: response.value})
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            generateQuotationDossier_2022_1.fail()
        })
});

export const generateQuotationDossier_2020_50 = createAsyncAction(GENERATE_QUOTATION_DOSSIER_2020_50, data => {
    showLoader();
    aj.dispatch({
        type: GENERATE_QUOTATION_DOSSIER_2020_50
    });

    DossierApi.generateQuotationDossier_2020_50(data.dossierDTO)
        .then(response => {
            hideLoader();
            generateQuotationDossier_2020_50.complete({downloadUrl: response.value})
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            generateQuotationDossier_2020_50.fail()
        })
});

export const generateQuestionnaireDossier_2022_1 = createAsyncAction(GENERATE_QUESTIONNAIRE_DOSSIER_2022_1, data => {
    showLoader();
    aj.dispatch({
        type: GENERATE_QUESTIONNAIRE_DOSSIER_2022_1
    })

    DossierApi.generateQuestionnaireDossier_2022_1(data.dossierDTO)
        .then(response => {
            hideLoader();
            generateQuestionnaireDossier_2022_1.complete({downloadUrl: response.value})
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            generateQuestionnaireDossier_2022_1.fail()
        })
});

export const generateQuestionnaireDossier_2021_50 = createAsyncAction(GENERATE_QUESTIONNAIRE_DOSSIER_2021_50, data => {
    showLoader();
    aj.dispatch({
        type: GENERATE_QUESTIONNAIRE_DOSSIER_2021_50
    })

    DossierApi.generateQuestionnaireDossier_2021_50(data.dossierDTO)
        .then(response => {
            hideLoader();
            generateQuestionnaireDossier_2021_50.complete({downloadUrl: response.value})
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            generateQuestionnaireDossier_2021_50.fail()
        })
});

export const generateQuestionnaireDossier_2020_50 = createAsyncAction(GENERATE_QUESTIONNAIRE_DOSSIER_2020_50, data => {
    showLoader();
    aj.dispatch({
        type: GENERATE_QUESTIONNAIRE_DOSSIER_2020_50
    })

    DossierApi.generateQuestionnaireDossier_2020_50(data.dossierDTO)
        .then(response => {
            hideLoader();
            generateQuestionnaireDossier_2020_50.complete({downloadUrl: response.value})
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            generateQuestionnaireDossier_2020_50.fail()
        })
});

export const updateQuestionnaire = createAsyncAction(UPDATE_QUESTIONNAIRE, data => {

    showLoader();

    aj.dispatch({
        type: UPDATE_QUESTIONNAIRE
    })
    DossierApi.updateQuestionnaire(data.dossierDTO, data.dossierType)
        .then(response => {
            hideLoader();
            toast(M("saveComplete"))
            updateQuestionnaire.complete()
            if (data.callback)
                data.callback()
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            updateQuestionnaire.fail()
        })
});

export const createDossier_2021_50 = createAsyncAction(CREATE_DOSSIER_2021_50, data => {

    showLoader();

    aj.dispatch({
        type: CREATE_DOSSIER_2021_50
    })
    DossierApi.createDossier_2021_50(data.dossierDTO)
        .then(response => {
            hideLoader();
            toast(M("saveComplete"))
            freeEntities({discriminator: data.discriminator});
            createDossier_2021_50.complete()
            ui.navigate("/entities/" + getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2021_50) + "/" + response.value.id + "?type=" + DOSSIER_TYPE.ECOBONUS_2021_50)
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            createDossier_2021_50.fail()
        })
});

export const createDossier_2022_1 = createAsyncAction(CREATE_DOSSIER_2022_1, data => {

    showLoader();

    aj.dispatch({
        type: CREATE_DOSSIER_2022_1
    })
    DossierApi.createDossier_2022_1(data.dossierDTO)
        .then(response => {
            hideLoader();
            toast(M("saveComplete"))
            freeEntities({discriminator: data.discriminator});
            createDossier_2022_1.complete()
            ui.navigate("/entities/" + getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2022_1) + "/" + response.value.id + "?type=" + DOSSIER_TYPE.ECOBONUS_2022_1)
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            createDossier_2022_1.fail()
        })
});

export const createDossier_2020_50 = createAsyncAction(CREATE_DOSSIER_2020_50, data => {

    showLoader();

    aj.dispatch({
        type: CREATE_DOSSIER_2020_50
    })
    DossierApi.createDossier_2020_50(data.dossierDTO)
        .then(response => {
            hideLoader();
            toast(M("saveComplete"))
            freeEntities({discriminator: data.discriminator});
            createDossier_2020_50.complete()
            ui.navigate("/entities/" + getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2020_50) + "/" + response.value.id + "?type=" + DOSSIER_TYPE.ECOBONUS_2020_50)
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            createDossier_2020_50.fail()
        })
});

export const createDossier = createAsyncAction(CREATE_DOSSIER, data => {

    if (_.isEmpty(data.customerId)) {
        alert(M("problemOccoured"), M("customerRequired"), "error")
        return;
    }

    if (_.isEmpty(data.fabricatorId)) {
        alert(M("problemOccoured"), M("fabricatorRequired"), "error")
        return;
    }

    if (_.isEmpty(data.significantValue) || _.isEmpty(data.nonSignificantValue) || _.isEmpty(data.serviceValue)) {
        alert(M("problemOccoured"), M("priceCalculatorSheetRequired"), "error")
        return;
    }

    showLoader();

    aj.dispatch({
        type: CREATE_DOSSIER
    })
    DossierApi.createDossier(data.type, data.customerId, data.fabricatorId, data.fabricatorCollaboratorId, data.significantValue, data.nonSignificantValue, data.serviceValue, data.notes, data.serviceFeeInvoiced)
        .then(response => {
            hideLoader();
            toast(M("saveComplete"))
            freeEntities({discriminator: "entity_form_dossier"});
            createDossier.complete()
            getEntity({discriminator: "entity_form_dossier", entity: "dossier", id: response.value.id})
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            createDossier.fail()
        })
});

export const editDossier = createAsyncAction(EDIT_DOSSIER, data => {
    if (_.isEmpty(data.customerId)) {
        alert(M("problemOccoured"), M("customerRequired"), "error")
        return;
    }

    if (_.isEmpty(data.fabricatorId)) {
        alert(M("problemOccoured"), M("fabricatorRequired"), "error")
        return;
    }

    if (_.isNull(data.significantValue) || _.isNull(data.nonSignificantValue) || _.isNull(data.serviceValue)) {
        alert(M("problemOccoured"), M("priceCalculatorSheetRequired"), "error")
        return;
    }

    showLoader();

    aj.dispatch({
        type: EDIT_DOSSIER
    })
    DossierApi.editDossier(data.dossierId, data.customerId, data.fabricatorId, data.fabricatorCollaboratorId, data.significantValue, data.nonSignificantValue, data.serviceValue, data.notes, data.serviceFeeInvoiced, data.candidateAE, data.candidateENEA)
        .then(response => {
            hideLoader();
            toast(M("saveComplete"))
            manualSavedEntities({discriminator: "entity_form_dossier"});
            // freeEntities({discriminator: "entity_form_dossier"});
            editDossier.complete()
            getEntity({discriminator: "entity_form_dossier", entity: "dossier", id: response.value.id})
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            editDossier.fail()
        })
});

export const attachDocument = createAsyncAction(DOSSIER_ATTACH_DOCUMENT, data => {
    aj.dispatch({
        type: DOSSIER_ATTACH_DOCUMENT
    })
    DossierApi.attachDocument(data.dossierId, data.dossierType, data.documentTypeId, data.attachment.path)
        .then(response => {
            toast(M("documentLoaded"));
            let entityName = getDossierEntityNameFromType(data.dossierType);
            getEntity({discriminator: "entity_form_" + entityName, entity: entityName, id: data.dossierId});
            attachDocument.complete({documents: response.value.documents, status: response.value.status});
        })
        .catch(e => {
            alert("Attenzione!", responses.msg(e), "error");
            attachDocument.fail()
        })
});

export const approveDocument = createAsyncAction(DOSSIER_APPROVE_DOCUMENT, data => {
    aj.dispatch({
        type: DOSSIER_APPROVE_DOCUMENT
    })

    showLoader();
    DossierApi.approveDocument(data.dossierId, data.dossierType, data.documentTypeId)
        .then(response => {
            hideLoader();
            let entityName = getDossierEntityNameFromType(data.dossierType);
            getEntity({discriminator: "entity_form_" + entityName, entity: entityName, id: data.dossierId});
            approveDocument.complete({documents: response.value});
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            approveDocument.fail()
        })
});

export const refuseDocument = createAsyncAction(DOSSIER_REFUSE_DOCUMENT, data => {
    aj.dispatch({
        type: DOSSIER_REFUSE_DOCUMENT
    })

    showLoader();
    DossierApi.refuseDocument(data.dossierId, data.dossierType, data.documentTypeId, data.refuseReason)
        .then(response => {
            hideLoader();
            let entityName = getDossierEntityNameFromType(data.dossierType);
            getEntity({discriminator: "entity_form_" + entityName, entity: entityName, id: data.dossierId});
            refuseDocument.complete({documents: response.value});
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            refuseDocument.fail()
        })
});

export const clearDocument = createAsyncAction(DOSSIER_CLEAR_DOCUMENT, data => {
    aj.dispatch({
        type: DOSSIER_CLEAR_DOCUMENT
    })

    showLoader();
    DossierApi.clearDocument(data.dossierId, data.dossierType, data.documentTypeId)
        .then(response => {
            hideLoader();
            let entityName = getDossierEntityNameFromType(data.dossierType);
            getEntity({discriminator: "entity_form_" + entityName, entity: entityName, id: data.dossierId});
            clearDocument.complete({documents: response.value});
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            clearDocument.fail()
        })
});

export const quotation = createAsyncAction(QUOTATION, data => {
    aj.dispatch({
        type: QUOTATION
    })

    showLoader();
    DossierApi.quotation(data.dossierId, data.dossierType)
        .then(response => {
            hideLoader();
            toast("Operazione eseguita con successo!")
            quotation.complete({dossier: response.value});
            let entityName = getDossierEntityNameFromType(data.dossierType);
            getEntity({discriminator: "entity_form_" + entityName, entity: entityName, id: response.value.id});
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            quotation.fail()
        })
});

export const giureEvaluation = createAsyncAction(GIURE_EVALUATION, data => {

    showLoader();
    DossierApi.evaluation(data.dossierId, data.dossierType)
        .then(response => {
            hideLoader();
            toast("Operazione eseguita con successo!")
            giureEvaluation.complete({dossier: response.value});
            let entityName = getDossierEntityNameFromType(data.dossierType);
            getEntity({discriminator: "entity_form_" + entityName, entity: entityName, id: response.value.id});
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            giureEvaluation.fail()
        })
});

export const confirmQuotation = createAsyncAction(CONFIRM_QUOTATION, data => {
    aj.dispatch({
        type: CONFIRM_QUOTATION
    })

    showLoader();
    DossierApi.confirmQuotation(data.dossierId, data.dossierType, data.acceptServiceCosts, data.dossierDTO)
        .then(response => {
            hideLoader();
            toast("Operazione eseguita con successo!")
            confirmQuotation.complete({dossier: response.value});
            let entityName = getDossierEntityNameFromType(data.dossierType);
            getEntity({discriminator: "entity_form_" + entityName, entity: entityName, id: response.value.id});
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            confirmQuotation.fail()
        })
});

export const confirmQuotation_2020_50 = createAsyncAction(CONFIRM_QUOTATION_2020_50, data => {
    aj.dispatch({
        type: CONFIRM_QUOTATION_2020_50
    })

    showLoader();
    DossierApi.confirmQuotation_2020_50(data.dossierDTO)
        .then(response => {
            hideLoader();
            toast("Operazione eseguita con successo!")
            confirmQuotation.complete({dossier: response.value});
            let entityName = getDossierEntityNameFromType(data.dossierType);
            getEntity({discriminator: "entity_form_" + entityName, entity: entityName, id: response.value.id});
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            confirmQuotation.fail()
        })
});

export const confirmQuotation_2021_50 = createAsyncAction(CONFIRM_QUOTATION_2021_50, data => {
    aj.dispatch({
        type: CONFIRM_QUOTATION_2021_50
    })

    showLoader();
    DossierApi.confirmQuotation_2021_50(data.dossierDTO)
        .then(response => {
            hideLoader();
            toast("Operazione eseguita con successo!")
            confirmQuotation.complete({dossier: response.value});
            let entityName = getDossierEntityNameFromType(data.dossierType);
            getEntity({discriminator: "entity_form_" + entityName, entity: entityName, id: response.value.id});
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            confirmQuotation.fail()
        })
});


export const confirmQuotation_2022_1 = createAsyncAction(CONFIRM_QUOTATION_2022_1, data => {
    aj.dispatch({
        type: CONFIRM_QUOTATION_2022_1
    })

    showLoader();
    DossierApi.confirmQuotation_2022_1(data.dossierDTO)
        .then(response => {
            hideLoader();
            toast("Operazione eseguita con successo!")
            confirmQuotation.complete({dossier: response.value});
            let entityName = getDossierEntityNameFromType(data.dossierType);
            getEntity({discriminator: "entity_form_" + entityName, entity: entityName, id: response.value.id});
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            confirmQuotation.fail()
        })
});

export const commit = createAsyncAction(COMMIT, data => {
    aj.dispatch({
        type: COMMIT
    })

    showLoader();
    DossierApi.commit(data.dossierId, data.dossierType)
        .then(response => {
            hideLoader();
            commit.complete({dossier: response.value});
            let entityName = getDossierEntityNameFromType(data.dossierType);
            getEntity({discriminator: "entity_form_" + entityName, entity: entityName, id: response.value.id});
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            commit.fail()
        })
});

export const candidate = createAsyncAction(CANDIDATE, data => {
    aj.dispatch({
        type: CANDIDATE
    })

    showLoader();
    DossierApi.candidate(data.dossierId, data.dossierType)
        .then(response => {
            hideLoader();
            candidate.complete({dossier: response.value});
            let entityName = getDossierEntityNameFromType(data.dossierType);
            getEntity({discriminator: "entity_form_" + entityName, entity: entityName, id: response.value.id});
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            candidate.fail()
        })
});

export const approve = createAsyncAction(APPROVE, data => {
    aj.dispatch({
        type: APPROVE
    })

    showLoader();
    DossierApi.approve(data.dossierId, data.dossierType)
        .then(response => {
            hideLoader();
            approve.complete({dossier: response.value});
            let entityName = getDossierEntityNameFromType(data.dossierType);
            getEntity({discriminator: "entity_form_" + entityName, entity: entityName, id: response.value.id});
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            approve.fail()
        })
});

export const payOff = createAsyncAction(PAY_OFF, data => {
    aj.dispatch({
        type: PAY_OFF
    })

    showLoader();
    DossierApi.payOff(data.dossierId, data.dossierType)
        .then(response => {
            hideLoader();
            payOff.complete({dossier: response.value});
            let entityName = getDossierEntityNameFromType(data.dossierType);
            getEntity({discriminator: "entity_form_" + entityName, entity: entityName, id: response.value.id});
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            payOff.fail()
        })
});

export const refuse = createAsyncAction(REFUSE, data => {
    aj.dispatch({
        type: REFUSE
    })

    showLoader();
    DossierApi.refuse(data.dossierId, data.dossierType)
        .then(response => {
            hideLoader();
            refuse.complete({dossier: response.value});
            let entityName = getDossierEntityNameFromType(data.dossierType);
            getEntity({discriminator: "entity_form_" + entityName, entity: entityName, id: response.value.id});
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            refuse.fail()
        })
});

export const showAddDocumentDialog = aj.createAction(DOSSIER_SHOW_ADD_DOCUMENT_DIALOG, data => {
    aj.dispatch({
        type: DOSSIER_SHOW_ADD_DOCUMENT_DIALOG,
        documentType: data.documentType
    })
});

export const hideAddDocumentDialog = aj.createAction(DOSSIER_HIDE_ADD_DOCUMENT_DIALOG, data => {
    aj.dispatch({
        type: DOSSIER_HIDE_ADD_DOCUMENT_DIALOG
    })
});

export const showRefuseDocumentDialog = aj.createAction(DOSSIER_SHOW_REFUSE_DOCUMENT_DIALOG, data => {
    aj.dispatch({
        type: DOSSIER_SHOW_REFUSE_DOCUMENT_DIALOG,
        documentTypeId: data.documentTypeId
    })
});

export const hideRefuseDocumentDialog = aj.createAction(DOSSIER_HIDE_REFUSE_DOCUMENT_DIALOG, data => {
    aj.dispatch({
        type: DOSSIER_HIDE_REFUSE_DOCUMENT_DIALOG
    })
});

export const calculateActualVat = createAsyncAction(DOSSIER_CALCULATE_ACTUAL_VAT, data => {
    validateRequiredParam(data.customerType, "customerType")
    validateRequiredParam(data.desiredVat, "desiredVat", true)

    aj.dispatch({
        type: DOSSIER_CALCULATE_ACTUAL_VAT,
        desiredVat: data.desiredVat
    })

    showLoader();
    DossierApi.calculateActualVat(data.customerType, data.desiredVat)
        .then(response => {
            calculateActualVat.complete({actualVat: response.value});

        })
        .catch(e => {
            alert("Attenzione!", responses.msg(e), "error");

            calculateActualVat.fail()
        })
        .finally(() => {
            hideLoader()
        })
});

export const calculateInvoiceVat = createAsyncAction(DOSSIER_CALCULATE_INVOICE_VAT, data => {
    validateRequiredParam(data.customerType, "customerType")
    validateRequiredParam(data.vat10, "vat10", true)
    validateRequiredParam(data.vat22, "vat22", true)

    aj.dispatch({
        type: DOSSIER_CALCULATE_INVOICE_VAT,
        vat10: data.vat10,
        vat22: data.vat22
    })

    showLoader();
    DossierApi.calculateInvoiceVat(data.customerType, data.vat10, data.vat22)
        .then(response => {
            calculateInvoiceVat.complete({vatInDialog: response.value});

        })
        .catch(e => {
            alert("Attenzione!", responses.msg(e), "error");

            calculateInvoiceVat.fail()
        })
        .finally(() => {
            hideLoader()
        })
});

export const generateQuotationAmounts = createAsyncAction(GENERATE_QUOTATION_AMOUNTS_2020_50, data => {
    DossierApi.generateQuotationAmounts(data)
        .then(response => {
            generateQuotationAmounts.complete({priceCalculatorSheet: response.value});
        })
        .catch(e => {
            alert("Attenzione!", format(responses.msg(e), e.data), "error");
            generateQuotationAmounts.fail()
        })
        .finally(() => {
            hideLoader()
        })
});

export const setPriceCalculatorSheet = createAsyncAction(DOSSIER_SET_PRICE_CALCULATOR_SHEET, data => {

    validateRequiredParam(data.customerType, "customerType")
    validateRequiredParam(data.desiredVat, "desiredVat", true)
    validateRequiredParam(data.vat10, "vat10", true)
    validateRequiredParam(data.vat22, "vat22", true)
    validateRequiredParam(data.vat, "vat", true)
    validateRequiredParam(data.calculatedVat, "calculatedVat", true)

    aj.dispatch({
        type: DOSSIER_SET_PRICE_CALCULATOR_SHEET,
        desiredVat: data.desiredVat,
        vat10: data.vat10,
        vat22: data.vat22,
        vat: data.vat,
    })

    showLoader();
    DossierApi.getPriceCalculatorSheet(data.customerType, data.desiredVat, data.vat10, data.vat22, data.vat, data.calculatedVat)
        .then(response => {
            setPriceCalculatorSheet.complete({priceCalculatorSheet: response.value});

        })
        .catch(e => {
            alert("Attenzione!", format(responses.msg(e), e.data), "error");
            setPriceCalculatorSheet.fail()
        })
        .finally(() => {
            hideLoader()
        })
});

export const showVatCalculatorDialog = aj.createAction(DOSSIER_SHOW_VAT_CALCULATOR_DIALOG, data => {
    aj.dispatch({
        type: DOSSIER_SHOW_VAT_CALCULATOR_DIALOG
    })
});

export const hideVatCalculatorDialog = aj.createAction(DOSSIER_HIDE_VAT_CALCULATOR_DIALOG, data => {
    aj.dispatch({
        type: DOSSIER_HIDE_VAT_CALCULATOR_DIALOG
    })
});

export const showFinancingDialog = createAsyncAction(DOSSIER_SHOW_FINANCING_DIALOG, data => {

    aj.dispatch({
        type: DOSSIER_SHOW_FINANCING_DIALOG
    })

    validateRequiredParam(data.customerType, "customerType")
    validateRequiredParam(data.priceCalculatorSheet, "priceCalculatorSheet")

    DossierApi.simulateFinancing(data.customerType, data.priceCalculatorSheet)
        .then(response => {
            showFinancingDialog.complete({simulatedFinancing: response.value});

        })
        .catch(e => {
            alert("Attenzione!", format(responses.msg(e), e.data), "error");
            showFinancingDialog.fail()
        })
        .finally(() => {
            hideLoader()
        })
});

export const hideFinancingDialog = aj.createAction(DOSSIER_HIDE_FINANCING_DIALOG, data => {
    aj.dispatch({
        type: DOSSIER_HIDE_FINANCING_DIALOG
    })
});

export const selectPaymentFee = aj.createAction(DOSSIER_SELECT_PAYMENT_FEE, paymentFee => {

    aj.dispatch({
        type: DOSSIER_SELECT_PAYMENT_FEE,
        paymentFee
    })
});


export const showBillsDialog = aj.createAction(DOSSIER_SHOW_BILLS_DIALOG, priceCalculatorSheet => {

    if(_.isEmpty(safeGet(priceCalculatorSheet, "bills", []))) {
        new Error("priceCalculatorSheet and bills is required")
    }

    aj.dispatch({
        type: DOSSIER_SHOW_BILLS_DIALOG,
        priceCalculatorSheet: priceCalculatorSheet
    })
});

export const hideBillsDialog = aj.createAction(DOSSIER_HIDE_BILLS_DIALOG, data => {

    aj.dispatch({
        type: DOSSIER_HIDE_BILLS_DIALOG
    })
});

export const showServiceCosts = aj.createAction(DOSSIER_SHOW_SERVICE_COSTS, priceCalculatorSheet => {

    validateRequiredParam(priceCalculatorSheet, "priceCalculatorSheet")

    aj.dispatch({
        type: DOSSIER_SHOW_SERVICE_COSTS,
        priceCalculatorSheet: priceCalculatorSheet
    })
});

export const hideServiceCosts = aj.createAction(DOSSIER_HIDE_SERVICE_COSTS, data => {

    aj.dispatch({
        type: DOSSIER_HIDE_SERVICE_COSTS
    })
});

export const editDossier_2021_50 = createAsyncAction(EDIT_DOSSIER_2021_50, data => {

    showLoader();

    aj.dispatch({
        type: EDIT_DOSSIER_2021_50
    })
    DossierApi.editDossier_2021_50(data.dossierDTO)
        .then(response => {
            hideLoader();
            let entityName = getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2021_50);
            toast(M("saveComplete"))
            manualSavedEntities({discriminator: "entity_form_" + entityName});
            editDossier_2021_50.complete()
            getEntity({discriminator: "entity_form_" + entityName, entity: entityName, id: data.dossierDTO.dossierId});
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            editDossier_2021_50.fail()
        })
});

export const editDossier_2022_1 = createAsyncAction(EDIT_DOSSIER_2022_1, data => {

    showLoader();

    aj.dispatch({
        type: EDIT_DOSSIER_2022_1
    })
    DossierApi.editDossier_2022_1(data.dossierDTO)
        .then(response => {
            hideLoader();
            let entityName = getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2022_1);
            toast(M("saveComplete"))
            manualSavedEntities({discriminator: "entity_form_" + entityName});
            editDossier_2022_1.complete()
            getEntity({discriminator: "entity_form_" + entityName, entity: entityName, id: data.dossierDTO.dossierId});
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            editDossier_2022_1.fail()
        })
});

export const editDossier_2020_50 = createAsyncAction(EDIT_DOSSIER_2020_50, data => {

    showLoader();

    aj.dispatch({
        type: EDIT_DOSSIER_2020_50
    })
    DossierApi.editDossier_2020_50(data.dossierDTO)
        .then(response => {
            hideLoader();
            let entityName = getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2020_50);
            toast(M("saveComplete"))
            manualSavedEntities({discriminator: "entity_form_" + entityName});
            editDossier_2020_50.complete()
            getEntity({discriminator: "entity_form_" + entityName, entity: entityName, id: data.dossierDTO.dossierId});
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            editDossier_2020_50.fail()
        })
});

export const KPMGSendForReview = createAsyncAction(KPMG_SEND_FOR_REVIEW, data => {
    showLoader();
    aj.dispatch({
        type: KPMG_SEND_FOR_REVIEW
    });
    KPMGApi.KPMGSendForReview(data.dossierId, data.dossierType)
        .then(response => {
            hideLoader();
            toast("Invio richiesta a KPMG in corso...");
            KPMGSendForReview.complete()
            let entityName = getDossierEntityNameFromType(data.dossierType);
            getEntity({discriminator: "entity_form_" + entityName, entity: entityName, id: data.dossierId});
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            KPMGSendForReview.fail()
        })
});

export const forceAllKPMGSend = createAsyncAction(FORCE_ALL_KPMG_SEND_FOR_REVIEW, data => {
    showLoader();
    aj.dispatch({
        type: FORCE_ALL_KPMG_SEND_FOR_REVIEW
    });
    KPMGApi.forceAllKPMGSend()
        .then(() => {
            hideLoader();
            toast("Invio documenti a KPMG in corso...");
            forceAllKPMGSend.complete()
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            forceAllKPMGSend.fail()
        })
});
