"use strict"

import React from "react";
import {MULTI_FILE_MODE_SINGLE, NewMultiFile} from "../forms";
import {Dialog} from "../dialogs";
import M from "../../../strings";
import {getSessionToken} from "../../../api/session";
import * as config from "../../../framework/config";
import {safeGet} from "../../../utils/lang";
import {hideAddRequiredPolicyDocumentDialog, setRequiredPolicyDocument} from "../../../actions/questionnaire";
import {connect} from "../../utils/aj";
import QuestionnaireStore from "../../../stores/questionnaire";

export class AddRequiredPolicyDocumentDialog extends React.Component {

    constructor(props) {
        super(props)
        connect(this, QuestionnaireStore)
    }

    componentDidUpdate() {

    }

    onValueChange(newFile) {
        // uploadRequiredPolicy({
        //     attachment: newFile
        // })
        setRequiredPolicyDocument(newFile.path);
        this.onClose();
    }

    onClose() {
        hideAddRequiredPolicyDocumentDialog();
    }

    onShow() {
        //Resetta dropzone
        $(".dz-preview").remove();
    }

    downloadTemplate(e){
        e.preventDefault()
        window.open(config.get("document.url") + "/generateRequiredPolicy?customerId=" + this.props.customerId + "&__TOKEN=" + encodeURIComponent(getSessionToken()))
    }

    render() {
        let model = this.props.model;
        let loading = safeGet(this.state, "loading", false);

        return (
            <Dialog onShow={this.onShow.bind(this)} onClose={this.onClose.bind(this)} ref="addDocument" icon={"zmdi zmdi-upload"} title={M("requiredPolicy")} hidden={!this.state.showAddRequiredPolicyDocumentDialog} large="false">
                {!loading ? <div>
                    <div className="row" style={{marginBottom: "30px"}}>
                        <p className="p-l-15 p-r-15">{M("uploadDocumentInfo")}</p>
                        <div className="col-sm">
                            <button style={{width: "100%"}} onClick={this.downloadTemplate.bind(this)} className="btn btn-primary">
                                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-download m-r-10" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M.5 8a.5.5 0 0 1 .5.5V12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V8.5a.5.5 0 0 1 1 0V12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8.5A.5.5 0 0 1 .5 8z"/>
                                    <path fillRule="evenodd" d="M5 7.5a.5.5 0 0 1 .707 0L8 9.793 10.293 7.5a.5.5 0 1 1 .707.707l-2.646 2.647a.5.5 0 0 1-.708 0L5 8.207A.5.5 0 0 1 5 7.5z"/>
                                    <path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0v-8A.5.5 0 0 1 8 1z"/>
                                </svg>
                                {M("downloadTemplate")}
                            </button>
                        </div>
                    </div>
                    <div className="col-12 zero-padding" >
                        <NewMultiFile model={model} disableInitOnModelLoad={true} onValueChange={this.onValueChange.bind(this)} mode={MULTI_FILE_MODE_SINGLE}/>
                    </div>
                </div>
                :
                    <div className="preloader" style={{display: 'block'}}>
                        <svg className="pl-circular" viewBox="25 25 50 50">
                            <circle className="plc-path" cx="50" cy="50" r="20" />
                        </svg>
                    </div>
                }
            </Dialog>
        )

    }
}