import M from "../strings"
import {defaultActiveMenuItem, DOSSIER_TYPE, generateDossierItem} from "./vars";

export default [
    defaultActiveMenuItem,
    generateDossierItem(DOSSIER_TYPE.ECOBONUS_2022_BONUS_CASA_1),
    generateDossierItem(DOSSIER_TYPE.ECOBONUS_2022_BONUS_CASA_2),
    // generateDossierItem(DOSSIER_TYPE.ECOBONUS_2022_50),
    generateDossierItem(DOSSIER_TYPE.ECOBONUS_2021_50),
    generateDossierItem(DOSSIER_TYPE.ECOBONUS_2020_50),
    generateDossierItem(DOSSIER_TYPE.ECOBONUS_2019),
    generateDossierItem(DOSSIER_TYPE.ECOBONUS_2021_BONUS_CASA),
    generateDossierItem(DOSSIER_TYPE.ECOBONUS_2023_75),
    {
        id: "customers",
        icon: "zmdi zmdi-accounts-list-alt",
        text: M("customers"),
        href: "/#/entities/customer?grid=customers",
        permissions: ["customer:list"]
    },
    {
        id: "profile",
        icon: "zmdi zmdi-account-calendar",
        text: "Profilo",
        href: "/#/entities/single/fabricator",
        permissions: ["inponzio:fabricator"]
    },
    {
        id: "users",
        icon: "zmdi zmdi-accounts-alt",
        text: M("users"),
        roles: ["admin"],
        permissions: ["user:list"],
        children: [
            {
                id: "admins",
                icon: "zmdi zmdi-face",
                text: M("admins"),
                href: "/#/entities/adminUser?grid=adminUsers",
                permissions: ["user:list"]
            },
            {
                id: "endUsers",
                icon: "zmdi zmdi-male-female",
                text: M("endUser"),
                href: "/#/entities/endUser?grid=finalUsers",
                permissions: ["user:list"]
            },
            {
                id: "fabricators",
                icon: "zmdi zmdi-account-calendar",
                text: M("fabricators"),
                href: "/#/entities/fabricator?grid=fabricators",
                permissions: ["user:list"]
            },
            {
                id: "collaborators",
                icon: "zmdi zmdi-account-calendar",
                text: M("collaborators"),
                href: "/#/entities/fabricatorCollaborator?grid=fabricatorCollaborators",
                permissions: ["user:list"]
            },
            {
                id: "giure",
                icon: "zmdi zmdi-face",
                text: M("giure"),
                href: "/#/entities/giure?grid=giure",
                permissions: ["user:list"]
            }
        ]
    },
    {

        icon: "zmdi zmdi-archive",
        text: M("statistics"),
        roles: ["admin"],
        children: [
            {
                id: "statistics",
                icon: "zmdi zmdi-archive",
                text: M("exports"),
                href: "/#/statistics",
                permissions: ["admin:superuser"]
            },
            // {
            //     id: "dashboard_dossier",
            //     icon: "zmdi zmdi-archive",
            //     text: "Cruscotto pratiche",
            //     href: "/#/dashboard/entities/dashboardDossier",
            //     permissions: ["admin:superuser"]
            // },
            // {
            //     id: "dashboard_supervisor",
            //     icon: "zmdi zmdi-archive",
            //     text: "Cruscotto supervisori",
            //     href: "/#/dashboard/entities/dashboardSupervisor",
            //     permissions: ["admin:superuser"]
            // },
            // {
            //     id: "dashboard_agent",
            //     icon: "zmdi zmdi-archive",
            //     text: "Cruscotto agenti",
            //     href: "/#/dashboard/entities/dashboardAgent",
            //     permissions: ["admin:superuser"]
            // },
            // {
            //     id: "dashboard_region",
            //     icon: "zmdi zmdi-archive",
            //     text: "Cruscotto regioni",
            //     href: "/#/dashboard/entities/dashboardRegion",
            //     permissions: ["admin:superuser"]
            // },
            // {
            //     id: "dashboard_province",
            //     icon: "zmdi zmdi-archive",
            //     text: "Cruscotto province",
            //     href: "/#/dashboard/entities/dashboardProvince",
            //     permissions: ["admin:superuser"]
            // },
            // {
            //     id: "dashboard_fabricator",
            //     icon: "zmdi zmdi-archive",
            //     text: "Cruscotto serramentisti",
            //     href: "/#/dashboard/entities/dashboardFabricator",
            //     permissions: ["admin:superuser"]
            // }
        ]
    },
    {
        id: "settings",
        icon: "zmdi zmdi-wrench",
        text: M("settings"),
        roles: ["admin"],
        children: [
            {
                id: "agents",
                icon: "zmdi zmdi-folder-person",
                text: M("agents"),
                href: "/#/entities/agent",
                permissions: ["agent:list"]
            },
            {
                id: "supervisors",
                icon: "zmdi zmdi-folder-person",
                text: M("supervisors"),
                href: "/#/entities/supervisor",
                permissions: ["supervisor:list"]
            },
            {
                id: "adminCategories",
                icon: "zmdi zmdi-folder-person",
                text: M("adminUserCategories"),
                href: "/#/entities/adminUserCategory?grid=users",
                permissions: ["user:list"]
            },
            {
                id: "endusersCategories",
                icon: "zmdi zmdi-folder-person",
                text: M("endUserCategories"),
                href: "/#/entities/endUserCategory?grid=endUsers",
                permissions: ["user:list"]
            },
            {
                id: "fabricatorCategory",
                icon: "zmdi zmdi-folder-person",
                text: M("fabricatorCategories"),
                href: "/#/entities/fabricatorCategory?grid=fabricators",
                permissions: ["user:list"]
            },
            {
                id: "documentType",
                icon: "zmdi zmdi-file-text",
                text: M("documentType"),
                href: "/#/entities/documentType?grid=documentTypes",
                permissions: ["documentType:list"]
            },
            {
                id: "mailLog",
                icon: "zmdi zmdi-file-text",
                text: M("mailLog"),
                href: "/#/entities/mailLog",
                permissions: ["admin:superuser"]
            }
        ]
    },

]
