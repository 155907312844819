"use strict"

import React from "react";
import {format, optional} from "../../../utils/lang";
import {DocumentRow} from "../documentRow";
import _ from "underscore";
import {
    AssignationType,
    DossierStatus,
    getDossierStatusDescription,
    getDossierStatusPosition, WORK_COMPLETION_DOCUMENTS_CODE
} from "../../../model/vars";
import M from "../../../strings";
import {
    approveDocument,
    clearDocument,
    showAddDocumentDialog,
    showRefuseDocumentDialog
} from "../../../actions/dossier";
import {Dialog, DIALOG_RESULT_OK} from "../dialogs";
import moment from "moment";
import {isClosingAssignationType, isGiureAssignationType} from "./utils";

export class DossierDocumentContainer extends React.Component {
    constructor(props) {
        super(props)
        this.refuseHistoryReason = null;
    }

    getKey(item) {
        if (_.isFunction(this.props.keygen)) {
            return this.props.keygen(item)
        } else {
            return item.id
        }
    }

    attachAttachment(row) {
        let model = this.props.model;
        let dossierStatus = model.get("status");
        if (WORK_COMPLETION_DOCUMENTS_CODE.includes(row.documentType.code) && !model.get("canUploadWorkCompletionDocument")) {
            swal({title: M("warning"), text: M("workCompletionDateRequired"), showCancelButton: false});
        } else {
            if (dossierStatus != null && (getDossierStatusPosition(dossierStatus) === getDossierStatusPosition(DossierStatus.STATUS_APPROVED.value) || getDossierStatusPosition(dossierStatus) === getDossierStatusPosition(DossierStatus.STATUS_PAY_OFF.value))) {
                swal({
                    title: M("confirm"),
                    text: format(M("confirmChangeDossier"), getDossierStatusDescription(dossierStatus)),
                    showCancelButton: true
                })
                    .then(res => {
                        if (res.value) {
                            showAddDocumentDialog({
                                documentType: row.documentType
                            });
                        }
                    })
            } else {
                showAddDocumentDialog({
                    documentType: row.documentType
                });
            }
        }
    }

    refuseDocument(row) {
        let model = this.props.model;
        let dossierStatus = model.get("status");
        if (dossierStatus != null && (getDossierStatusPosition(dossierStatus) === getDossierStatusPosition(DossierStatus.STATUS_APPROVED.value) || getDossierStatusPosition(dossierStatus) === getDossierStatusPosition(DossierStatus.STATUS_PAY_OFF.value))){
            swal({title: M("confirm"), text: M("areYouSure"), showCancelButton: true})
                .then(res => {
                    if (res.value) {
                        showRefuseDocumentDialog({
                            documentTypeId: row.documentTypeId
                        });
                    }
                })
        } else {
            showRefuseDocumentDialog({
                documentTypeId: row.documentTypeId
            });
        }
    }

    approveDocument(row) {
        let model = this.props.model;
        swal({title: M("confirm"), text: M("areYouSure"), showCancelButton: true})
            .then(res => {
                if (res.value) {
                    approveDocument({
                        documentTypeId: row.documentTypeId,
                        dossierId: model.get("id"),
                        dossierType: model.get("type")
                    })
                }
            })
    }

    clearDocument(row) {
        let model = this.props.model;
        let dossierStatus = model.get("status");
        // if (dossierStatus != null && (getDossierStatusPosition(dossierStatus) === getDossierStatusPosition(DossierStatus.STATUS_APPROVED.value) || getDossierStatusPosition(dossierStatus) === getDossierStatusPosition(DossierStatus.STATUS_PAY_OFF.value))){
            swal({title: M("confirm"), text: M("areYouSure"), showCancelButton: true})
                .then(res => {
                    if (res.value) {
                        clearDocument({
                            documentTypeId: row.documentTypeId,
                            dossierId: model.get("id"),
                            dossierType: model.get("type")
                        })
                    }
                })
        // } else {
        //     clearDocument({
        //         documentTypeId: row.documentTypeId,
        //         dossierId: model.get("id")
        //     })
        // }
    }

    refuseHistory(refuseHistoryReason) {
        this.refuseHistoryReason = refuseHistoryReason;
        this.forceUpdate();
    }

    onClose() {
        this.refuseHistoryReason = null;
        this.forceUpdate();
    }

    render() {
        let model = this.props.model
        let field = this.props.field
        let rows = optional(model.get(field.property), []);
        let isGiureDossier = model.data.giureEvaluation
        let dossierStatus = model.get("status");
        let dossierStatusStyle = (dossierStatus === DossierStatus.STATUS_GIURE_EVALUATION.value || dossierStatus === DossierStatus.STATUS_QUOTATION.value) && model.data.giureEvaluation ? {display: "none"} : {};

        let preparatoryRows = _.filter(rows, r=>(r.documentType.assignationType === AssignationType.PREPARATORY_DOCUMENTATION.value || r.documentType.assignationType === AssignationType.ENEA_PREPARATORY_DOCUMENTATION.value));
        let closingRows = _.filter(rows, r=> isClosingAssignationType(r.documentType.assignationType));
        let giureRows = _.filter(rows, r=> isGiureAssignationType(r.documentType.assignationType));

        let preparatoryItems = preparatoryRows.map((i, index) => <DocumentRow dossierStatus={model.get("status")} dossierId={model.get("id")} dossierType={model.get("type")} key={this.getKey(i)} row={i} index={index} attachAttachment={this.attachAttachment.bind(this)} refuseDocument={this.refuseDocument.bind(this)} clearDocument={this.clearDocument.bind(this)} approveDocument={this.approveDocument.bind(this)} refuseHistory={this.refuseHistory.bind(this)}/>)
        let closingItems = closingRows.map((i, index) => <DocumentRow dossierStatus={model.get("status")} dossierId={model.get("id")} dossierType={model.get("type")} key={this.getKey(i)} row={i} index={index} attachAttachment={this.attachAttachment.bind(this)} refuseDocument={this.refuseDocument.bind(this)} clearDocument={this.clearDocument.bind(this)} refuseHistory={this.refuseHistory.bind(this)}/>)
        let giureItems = giureRows.map((i, index) => <DocumentRow dossierStatus={model.get("status")} dossierId={model.get("id")} dossierType={model.get("type")} key={this.getKey(i)} row={i} index={index} attachAttachment={this.attachAttachment.bind(this)} refuseDocument={this.refuseDocument.bind(this)} clearDocument={this.clearDocument.bind(this)} approveDocument={this.approveDocument.bind(this)} refuseHistory={this.refuseHistory.bind(this)}/>)
        let preparatoryFilesUploaded = _.filter(preparatoryRows, r=>r.file != null).length;
        let preparatoryFilesRefused = _.filter(preparatoryRows, r=>!r.valid).length;
        let preparatoryDescription = format(M("rowsDescription"), preparatoryFilesUploaded, preparatoryRows.length);
        if (preparatoryFilesRefused > 0) {
            preparatoryDescription += " - " + format(M("refusedRows"), preparatoryFilesRefused).toUpperCase();
        }

        let closingFilesUploaded = _.filter(closingRows, r=>r.file != null).length;
        let closingFilesRefused = _.filter(closingRows, r=>!r.valid).length;
        let closingDescription = format(M("rowsDescription"), closingFilesUploaded, closingRows.length);
        if (closingFilesRefused > 0) {
            closingDescription += " - " + format(M("refusedRows"), closingFilesRefused).toUpperCase();
        }

        let giureFilesUploaded = _.filter(giureRows, r=>r.file != null).length;
        let giureFilesRefused = _.filter(giureRows, r=>!r.valid).length;
        let giureDescription = format(M("rowsDescription"), giureFilesUploaded, giureRows.length);
        if (giureFilesRefused > 0) {
            giureDescription += " - " + format(M("refusedRows"), giureFilesRefused).toUpperCase();
        }

        let refuseHistoryReasonComponents = [];
        if (this.refuseHistoryReason != null && this.refuseHistoryReason.length > 0) {
            _.each(this.refuseHistoryReason, r => {
                refuseHistoryReasonComponents.push(<div className="col-12">
                    {
                        format("{0} - {1} - {2}",
                        moment(r.refusedDate).format("DD/MM/YYYY HH:mm"),
                        r.refuseUserCredential ? r.refuseUserCredential : "" ,
                        r.refusedReason + (r.byKPMG ? " " + M("refusedByKPMG") : ""))
                    }
                    <hr/>
                </div>);
            })
        }

        const buttons = [
            {
                text: M("close"),
                action: (dialog) => {
                    dialog.hide()
                },
                dialogResult: DIALOG_RESULT_OK
            }
        ]

        return (
            <div className="col-12 zero-padding m-t-15">
                <div className="row">
                    {isGiureDossier && <div className="col-lg zero-padding">
                        <div className="col-12">
                            <div className="col-12 zero-padding document-header" style={{display: "inline-block"}}>
                                <span className="text-uppercase float-left">{M("giureDocumentation")}</span>
                                <span className="text-uppercase float-right">{giureDescription}</span>
                            </div>
                            <div className="col-12 zero-padding">
                                {giureItems}
                            </div>
                        </div>
                    </div>}
                    <div className="col-lg zero-padding" style={dossierStatusStyle}>
                        <div className="col-12" style={{borderRight: "1px solid #C4C4C4"}}>
                            <div className="col-12 zero-padding document-header" style={{display: "inline-block"}}>
                                <span className="text-uppercase float-left">{M("preparatoryDocumentation")}</span>
                                <span className="text-uppercase float-right">{preparatoryDescription}</span>
                            </div>
                            <div className="col-12 zero-padding">
                                {preparatoryItems}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg zero-padding" style={dossierStatusStyle}>
                        <div className="col-12">
                            <div className="col-12 zero-padding document-header" style={{display: "inline-block"}}>
                                <span className="text-uppercase float-left">{M("closingDocumentation")}</span>
                                <span className="text-uppercase float-right">{closingDescription}</span>
                            </div>
                            <div className="col-12 zero-padding">
                                {closingItems}
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog
                    ref="dialog"
                    titleClass="modal-title text-primary"
                    title={M("refuseHistory")}
                    large={false}
                    hidden={this.refuseHistoryReason == null}
                    buttons={buttons}
                    onClose={this.onClose.bind(this)}>

                    <div className="bills-container row">
                        {refuseHistoryReasonComponents}
                    </div>

                </Dialog>
            </div>
        )
    }
}

export class DossierGiureDocumentContainer extends React.Component {
    constructor(props) {
        super(props)
        this.refuseHistoryReason = null;
    }

    getKey(item) {
        if (_.isFunction(this.props.keygen)) {
            return this.props.keygen(item)
        } else {
            return item.id
        }
    }

    attachAttachment(row) {
        let model = this.props.model;
        let dossierStatus = model.get("status");
        if (WORK_COMPLETION_DOCUMENTS_CODE.includes(row.documentType.code) && !model.get("canUploadWorkCompletionDocument")) {
            swal({title: M("warning"), text: M("workCompletionDateRequired"), showCancelButton: false});
        } else {
            if (dossierStatus != null && (getDossierStatusPosition(dossierStatus) === getDossierStatusPosition(DossierStatus.STATUS_APPROVED.value) || getDossierStatusPosition(dossierStatus) === getDossierStatusPosition(DossierStatus.STATUS_PAY_OFF.value))) {
                swal({
                    title: M("confirm"),
                    text: format(M("confirmChangeDossier"), getDossierStatusDescription(dossierStatus)),
                    showCancelButton: true
                })
                    .then(res => {
                        if (res.value) {
                            showAddDocumentDialog({
                                documentType: row.documentType
                            });
                        }
                    })
            } else {
                showAddDocumentDialog({
                    documentType: row.documentType
                });
            }
        }
    }

    refuseDocument(row) {
        let model = this.props.model;
        let dossierStatus = model.get("status");
        if (dossierStatus != null && (getDossierStatusPosition(dossierStatus) === getDossierStatusPosition(DossierStatus.STATUS_GIURE_EVALUATION.value) || getDossierStatusPosition(dossierStatus) === getDossierStatusPosition(DossierStatus.STATUS_APPROVED.value) || getDossierStatusPosition(dossierStatus) === getDossierStatusPosition(DossierStatus.STATUS_PAY_OFF.value))){
            swal({title: M("confirm"), text: M("areYouSure"), showCancelButton: true})
                .then(res => {
                    if (res.value) {
                        showRefuseDocumentDialog({
                            documentTypeId: row.documentTypeId
                        });
                    }
                })
        } else {
            showRefuseDocumentDialog({
                documentTypeId: row.documentTypeId
            });
        }
    }

    approveDocument(row) {
        let model = this.props.model;
        swal({title: M("confirm"), text: M("areYouSure"), showCancelButton: true})
            .then(res => {
                if (res.value) {
                    approveGiureDocument({
                        documentTypeId: row.documentTypeId,
                        dossierId: model.get("id"),
                        dossierType: model.get("type")
                    })
                }
            })
    }

    clearDocument(row) {
        let model = this.props.model;
        let dossierStatus = model.get("status");
        // if (dossierStatus != null && (getDossierStatusPosition(dossierStatus) === getDossierStatusPosition(DossierStatus.STATUS_APPROVED.value) || getDossierStatusPosition(dossierStatus) === getDossierStatusPosition(DossierStatus.STATUS_PAY_OFF.value))){
        swal({title: M("confirm"), text: M("areYouSure"), showCancelButton: true})
            .then(res => {
                if (res.value) {
                    clearGiureDocument({
                        documentTypeId: row.documentTypeId,
                        dossierId: model.get("id"),
                        dossierType: model.get("type")
                    })
                }
            })
        // } else {
        //     clearDocument({
        //         documentTypeId: row.documentTypeId,
        //         dossierId: model.get("id")
        //     })
        // }
    }

    refuseHistory(refuseHistoryReason) {
        this.refuseHistoryReason = refuseHistoryReason;
        this.forceUpdate();
    }

    onClose() {
        this.refuseHistoryReason = null;
        this.forceUpdate();
    }

    render() {
        let model = this.props.model
        let field = this.props.field
        let rows = optional(model.get(field.property), []);
        let isGiureDossier = model.data.giureEvaluation ? {} : {display: "none"}
        let giureRows = _.filter(rows, r=> isGiureAssignationType(r.documentType.assignationType));
        let giureItems = giureRows.map((i, index) => <DocumentRow dossierStatus={model.get("status")} dossierId={model.get("id")} dossierType={model.get("type")} key={this.getKey(i)} row={i} index={index} attachAttachment={this.attachAttachment.bind(this)} refuseDocument={this.refuseDocument.bind(this)} clearDocument={this.clearDocument.bind(this)} approveDocument={this.approveDocument.bind(this)} refuseHistory={this.refuseHistory.bind(this)}/>)
        let giureFilesUploaded = _.filter(giureRows, r=>r.file != null).length;
        let giureFilesRefused = _.filter(giureRows, r=>!r.valid).length;
        let giureDescription = format(M("rowsDescription"), giureFilesUploaded, giureRows.length);
        if (giureFilesRefused > 0) {
            giureDescription += " - " + format(M("refusedRows"), giureFilesRefused).toUpperCase();
        }

        let refuseHistoryReasonComponents = [];
        if (this.refuseHistoryReason != null && this.refuseHistoryReason.length > 0) {
            _.each(this.refuseHistoryReason, r => {
                refuseHistoryReasonComponents.push(<div className="col-12">
                    {
                        format("{0} - {1} - {2}",
                            moment(r.refusedDate).format("DD/MM/YYYY HH:mm"),
                            r.refuseUserCredential ? r.refuseUserCredential : "" ,
                            r.refusedReason + (r.byKPMG ? " " + M("refusedByKPMG") : ""))
                    }
                    <hr/>
                </div>);
            })
        }

        const buttons = [
            {
                text: M("close"),
                action: (dialog) => {
                    dialog.hide()
                },
                dialogResult: DIALOG_RESULT_OK
            }
        ]

        return (
            <div className="col-12 zero-padding m-t-15">
                <div className="row">
                    <div className="col-lg zero-padding" style={isGiureDossier}>
                        <div className="col-12">
                            <div className="col-12 zero-padding document-header" style={{display: "inline-block"}}>
                                <span className="text-uppercase float-left">{M("giureDocumentation")}</span>
                                <span className="text-uppercase float-right">{giureDescription}</span>
                            </div>
                            <div className="col-12 zero-padding">
                                {giureItems}
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog
                    ref="dialog"
                    titleClass="modal-title text-primary"
                    title={M("refuseHistory")}
                    large={false}
                    hidden={this.refuseHistoryReason == null}
                    buttons={buttons}
                    onClose={this.onClose.bind(this)}>

                    <div className="bills-container row">
                        {refuseHistoryReasonComponents}
                    </div>

                </Dialog>
            </div>
        )
    }
}
