"use strict";

import * as config from "../framework/config";
import {get, postJson} from "./utils";

export function generateQuotationDossier_2022_BonusCasa_1(dto) {
    return postJson(config.get("dossiers2022BonusCasa_1.url") + "/generateQuotation", dto)
}

export function generateQuestionnaireDossier_2022_BonusCasa_1(dto) {
    return postJson(config.get("dossiers2022BonusCasa_1.url") + "/generateQuestionnaire", dto)
}

export function updateQuestionnaire_2022_BonusCasa_1(dto) {
    return postJson(config.get("dossiers2022BonusCasa_1.url") + "/updateQuestionnaire", dto)
}

export function editDossier_2022_BonusCasa_1(dto) {
    return postJson(config.get("dossiers2022BonusCasa_1.url") + "/edit", dto)
}

export function createDossier_2022_BonusCasa_1(dto) {
    return postJson(config.get("dossiers2022BonusCasa_1.url") + "/create", dto)
}

export function generateQuotationAmounts_2022_BonusCasa_1(data) {
    if (data["continuationWork"] == null) {
        delete data["continuationWork"];
    }
    return get(config.get("dossiers2022BonusCasa_1.url") + "/quotation/amounts", data)
}

export function confirmQuotation_2022_BonusCasa_1(dto) {
    return postJson(config.get("dossiers2022BonusCasa_1.url") + "/confirmQuotation", dto)
}
