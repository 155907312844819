import _ from "underscore";
import React from "react";
import ReactDOM from "react-dom";
import * as query from "../../framework/query";
import M from "../../strings";
import {Card} from "./common";
import {optional, parseBoolean, safeGet} from "../../utils/lang";
import {Observable} from "../../aj/events";
import {isControl, isDown, isEnter, isEsc, isShift, isUp} from "../utils/keyboard";
import * as mobile from "../utils/mobile";
import * as datasource from "../../utils/datasource";
import {AssignationType, DossierStatus, getDossierStatusDescription, getDossierStatusPosition} from "../../model/vars";
import {createCell, Filters, getVisibleCells, Pagination, ResultSummary, SearchDialog} from "./grids";
import {getVisibleFilters} from "../../framework/query";
import {discriminated} from "../../utils/ajex";
import {SearchStore} from "../../stores/entities";

const EXPAND_ANIMATION_TIME = 250
const CELL_PADDING_TOP = 15
const CELL_PADDING_BOTTOM = 15

/*
 * hack to load forms when is useful but prevent circular references of modules. forms.jsx uses grids.jsx
 */

let _forms = null
function forms() {
    if (_forms == null) {
        //from this, the url is not absolute
        _forms = require("./forms")
    }

    return _forms
}



function eachChildren(root, action) {
    if (_.isArray(root)) {
        root.forEach(c => {
            action(c)

            eachChildren(c.children, action)
        })
    }
}


function clearSelection() {
    if(document.selection && document.selection.empty) {
        document.selection.empty();
    } else if(window.getSelection) {
        var sel = window.getSelection();
        sel.removeAllRanges();
    }
}

function childrenData(children, index, childrenProp) {
    if (_.isArray(children)) {
        return children.map(r => {
            return {
                data: r,
                index: index.value++,
                children: childrenData(r[childrenProp], index, childrenProp),
                selected: false
            }
        })
    }

    return null
}

export class Selection extends Observable {
    constructor(rows) {
        super()

        this.rows = rows
        this.shiftPressed = false
        this.controlPressed = false
        this.lastSelected = null
        this.rangeStartRow = null
        this.allSelected = false
        this.single = false
    }

    flatRows() {
        let flatRows = []

        let addRows = (children) => {
            if (!children) {
                return
            }
            children.forEach(c => {
                flatRows.push(c)

                if (c.expanded) {
                    addRows(c.children)
                }
            })
        }

        addRows(this.rows)

        return flatRows
    }

    handle(row, multiple) {
        let flatRows = this.flatRows()

        if (this.shiftPressed && multiple) {
            flatRows.forEach(r => r.selected = false)
            if (this.rangeStartRow == null) {
                this.rangeStartRow = this.lastSelected
                if (this.rangeStartRow == null) {
                    this.rangeStartRow = row
                }
                this.lastSelected = row
                row.selected = true
            } else {
                let startIndex = Math.min(this.rangeStartRow.index, row.index)
                let endIndex = Math.max(this.rangeStartRow.index, row.index)
                flatRows.forEach(r => {
                    if (r.index >= startIndex && r.index <= endIndex) {
                        r.selected = true
                    }
                })
                this.lastSelected = row
            }
            // } else if (this.controlPressed && !this.single) {
            //     row.selected = !row.selected
            //     this.rangeStartRow = row
            //     this.lastSelected = row
        } else {
            row.selected = !row.selected;
            this.rangeStartRow = row.selected ? row : null;
            this.lastSelected = row.selected ? row : null;
            if (!multiple) {
                flatRows.forEach(r => r.selected = false)
                row.selected = true
                this.rangeStartRow = row
                this.lastSelected = row
            }
        }
        this.allSelected = _.every(flatRows, row => row.selected);
        this.invoke("change")
    }

    getSelectedData() {
        return _.map(_.filter(this.flatRows(), r => r.selected), r => r.data)
    }

    toggleAll() {
        this.flatRows().forEach(r => r.selected = !this.allSelected)
        this.allSelected = !this.allSelected
        this.lastSelected = null
        this.rangeStartRow = null

        this.invoke("change")
    }

    clear() {
        this.flatRows().forEach(r => r.selected = false)
        this.allSelected = false
        this.lastSelected = null
        this.rangeStartRow = null

        this.invoke("change")
    }

    down() {
        let flatRows = this.flatRows()

        if (!flatRows || flatRows.length == 0) {
            return
        }

        let index = -1
        if (this.lastSelected != null) {
            index = flatRows.indexOf(this.lastSelected)
        }

        index++
        if (index >= flatRows.length) {
            index = 0
        }
        let newRow = flatRows[index]
        this.handle(newRow)
    }

    up() {
        let flatRows = this.flatRows()

        if (!flatRows || flatRows.length == 0) {
            return
        }

        let index = -1
        if (this.lastSelected != null) {
            index = flatRows.indexOf(this.lastSelected)
        }

        index--
        if (index < 0) {
            index = flatRows.length - 1
        }
        let newRow = flatRows[index]
        this.handle(newRow)
    }
}

const STANDARD_SEARCH_FORM_DESCRIPTOR = (column) => _.assign({}, {
    showInCard: false,
    fields: [
        {
            property: column.property,
            label: M("value"),
            placeholder: M("value"),
            control: forms().Text
        },
        {
            property: "_filterType",
            label: M("filterType"),
            control: forms().Select,
            props: {
                allowNull: false,
                datasource: datasource.fixed([
                    {label: M("equals"), value: "eq"},
                    {label: M("like"), value: "like"}
                ])
            }
        }
    ]
})

export class HeaderCell extends React.Component {
    constructor(props) {
        super(props)

        this.state = {sorting: false, sortDescending: false}
    }

    componentDidMount() {
        let me = $(ReactDOM.findDOMNode(this))
        let button = $(this.refs.search)

        me.mouseenter(() => {
            button
                .css({opacity: 0})
                .show()
                .stop()
                .animate({opacity: 1}, 250)
        }).mouseleave(() => {
            button
                .stop()
                .animate({opacity: 0}, 250)
        })
    }

    changeSort() {
        if (!this.props.column.sortable) {
            return
        }

        let newState = null

        if (this.state.sorting == false) {
            newState = {sorting: true, sortDescending: false}
        } else if (this.state.sortDescending == false) {
            newState = {sorting: true, sortDescending: true}
        } else {
            newState = {sorting: false, sortDescending: false}
        }

        if (this.props.query) {
            if (newState.sorting) {
                this.props.query.sort(this.props.column.property, newState.sortDescending)
            } else {
                this.props.query.unsort(this.props.column.property)
            }
        }

        this.setState(newState)
    }

    search() {
        let me = ReactDOM.findDOMNode(this)
        $(me).find(".search-dialog").modal()
    }

    render() {
        let sortClass = ""
        if (this.state.sorting && this.state.sortDescending) {
            sortClass = "sorting_desc"
        } else if (this.state.sorting && !this.state.sortDescending) {
            sortClass = "sorting_asc"
        }

        let searchButtonRight = 10
        if (sortClass != "") {
            searchButtonRight += 25
        }

        return (
            <th className={"hover " + sortClass} style={{position: "relative"}}>
                <span onClick={this.changeSort.bind(this)} className="pointer-cursor">{this.props.column.header}</span>

                {this.props.column.searchable &&
                    <a ref="search" className="btn btn-sm btn-light" href="javascript:;" onClick={this.search.bind(this)} style={{display: "none", marginTop: "-3px", position: "absolute", right: searchButtonRight}}><i className="zmdi zmdi-search"/></a>
                }

                {this.props.column.searchable &&
                    <SearchDialog column={this.props.column} query={this.props.query}/>
                }
            </th>
        )
    }
}

export class GridHeader extends React.Component {

    toggleSelectAll(){
        if (_.isFunction(this.props.toggleSelectAll)) {
            this.props.toggleSelectAll();
        }
    }



    render() {
        if (_.isEmpty(this.props.descriptor)) {
            return null
        }
        let allSelected = optional(this.props.allSelected,false);
        let id = 1
        let headerCells = getVisibleCells(this.props.descriptor.columns, this.props.entity).map(c => <HeaderCell key={id++} column={c} query={this.props.query} />)

        if (this.props.checkboxSelectEnabled)
            headerCells = _.union(
                [
                    <th key="0" className="checkbox-column" style={{position: "relative"}}>
                        <div className="grid-cell-container">
                            <i
                                className={"fs-16 zmdi " + ((allSelected) ? "zmdi-check-square" : "zmdi-square-o")}
                                onClick={this.toggleSelectAll.bind(this)}
                                role={"button"}
                            ></i>
                        </div>
                    </th>
                ],
                headerCells
            )

        return (
            <thead key={"thead"}>
                <tr>{headerCells}</tr>
            </thead>
        )
    }
}

export class Row extends React.Component {
    constructor(props) {
        super(props)
    }

    doubleClick(e) {
        if (_.isFunction(this.props.onDoubleClick)) {
            this.props.onDoubleClick(this.props.row)
            e.stopPropagation()
            e.preventDefault()
            clearSelection()
        }
    }

    onMouseDown(e) {
        if ( $(e.target).attr('data-toggle') === "popover" ) return false;
        if (_.isFunction(this.props.onMouseDown)) {
            this.props.onMouseDown(this.props.row)
            e.stopPropagation()
        }
    }

    onCheckboxSelect(e){
        if (_.isFunction(this.props.onCheckboxSelect)) {
            this.props.onCheckboxSelect(this.props.row)
            e.stopPropagation()
        }
    }

    componentDidMount() {
        let expandedNow = this.props.row.expandedNow || false
        if (expandedNow) {
            let me = ReactDOM.findDOMNode(this)
            this.props.row.expandedNow = undefined
            $(me)
                .find("td")
                .css({paddingTop: 0, paddingBottom: 0})
                .stop()
                .animate({paddingTop: CELL_PADDING_TOP, paddingBottom: CELL_PADDING_BOTTOM}, EXPAND_ANIMATION_TIME)
                .end()
                .find(".grid-cell-container")
                .hide()
                .slideDown(EXPAND_ANIMATION_TIME)

        }
    }

    componentDidUpdate() {
        let collapsedNow = this.props.row.collapsedNow || false
        if (collapsedNow) {
            let me = ReactDOM.findDOMNode(this)
            this.props.row.collapsedNow = undefined
            $(me)
                .find("td")
                .stop()
                .animate({paddingTop: 0, paddingBottom: 0}, EXPAND_ANIMATION_TIME)
                .end()
                .find(".grid-cell-container")
                .slideUp(EXPAND_ANIMATION_TIME)
        }
    }

    render() {
        if (_.isEmpty(this.props.descriptor)) {
            return null
        }

        let onExpand = (row) => {
            if (_.isFunction(this.props.onExpand)) {
                this.props.onExpand(row)
            }
        }
        let firstElement = true
        let key = 1

        let cells = getVisibleCells(this.props.descriptor.columns, this.props.entity).map(c => {
            let cell = createCell(c, this.props.row, firstElement, onExpand, c.props)
            firstElement = false
            return <td key={key++} className={c.tdClassName}><div className="grid-cell-container">{cell}</div></td>
        })

        if (this.props.checkboxSelectEnabled) {
            cells=_.union(
                [<td className="checkbox-column" key={"0"} ><div className="grid-cell-container">
                    <i
                        className={"fs-16 zmdi " + ((this.props.row.selected) ? "zmdi-check-square" : "zmdi-square-o")}
                        onClick={this.onCheckboxSelect.bind(this)}
                        role={"button"}
                    ></i>
                </div></td>],
                cells
            )
        }

        let className = `level-${this.props.row.level} ` + (this.props.row.selected ? "selected" : "")
        let rowClassName = this.props.descriptor.rowClassName
        if (rowClassName) {
            if (_.isFunction(rowClassName)) {
                className += " " + rowClassName(this.props.row.data)
            } else {
                className += " " + rowClassName
            }
        }

        return (
            <tr onClick={this.onMouseDown.bind(this)} onDoubleClick={this.doubleClick.bind(this)} className={className}>{cells}</tr>
        )
    }
}

export class GridBody extends React.Component {

    onRowCheckboxSelect(row) {
        if (_.isFunction(this.props.onRowCheckboxSelect)) {
            this.props.onRowCheckboxSelect(row)
        }
    }

    onRowMouseDown(row) {
        if (_.isFunction(this.props.onRowMouseDown)) {
            this.props.onRowMouseDown(row)
        }
    }

    onRowDoubleClick(row) {
        if (_.isFunction(this.props.onRowDoubleClick)) {
            this.props.onRowDoubleClick(row)
        }
    }

    onRowExpand(row) {
        if (_.isFunction(this.props.onRowExpand)) {
            this.props.onRowExpand(row)
        }
    }

    render() {
        if (_.isEmpty(this.props.descriptor)) {
            return null
        }

        let rows = this.props.data.rows || []
        let rowElements = []
        let level = this.props.level || 0
        let index = 0
        let addElements = (children, level, parentKey) => {
            let key = 1
            children.forEach(r => {
                r.index = index++
                r.level = level
                let element = (
                    <Row
                        key={parentKey + "_" + key++}
                        descriptor={this.props.descriptor}
                        row={r}
                        query={this.props.query}
                        onMouseDown={this.onRowMouseDown.bind(this)}
                        onDoubleClick={this.onRowDoubleClick.bind(this)}
                        onExpand={this.onRowExpand.bind(this)}
                        checkboxSelectEnabled={this.props.checkboxSelectEnabled}
                        onCheckboxSelect={this.onRowCheckboxSelect.bind(this)}
                        entity={this.props.entity}/>
                )

                rowElements.push(element)

                if (!_.isEmpty(r.children)) {
                    if (r.expanded) {
                        addElements(r.children, level + 1, parentKey + "_" + key)
                    }
                }
            })
        }

        addElements(rows, level, "root")

        return (
            <tbody>
                {rowElements}
            </tbody>
        )
    }
}

export class FooterCell extends React.Component {
    render() {
        return (
            <th>
                {this.props.column.header}
            </th>
        )
    }
}

export class GridFooter extends React.Component {
    render() {
        if (_.isEmpty(this.props.descriptor)) {
            return null
        }

        let id = 1
        let footerCells = getVisibleCells(this.props.descriptor.columns, this.props.entity).map(c => <FooterCell key={id++} column={c} query={this.props.query} />)

        if (this.props.checkboxSelectEnabled) {
            footerCells =_.union(
                [<td className="checkbox-column" key={"0"} ></td>],
                footerCells
            )
        }

        return (
            <tfoot>
            <tr>{footerCells}</tr>
            </tfoot>
        )
    }
}

export class Cell extends React.Component {
    getValue() {
        let column = this.props.column
        let property = this.props.property
        let row = this.props.row

        return row.data[property]
    }
}

export class StatusCell extends Cell {
    toggleExpand(e) {
        if (_.isFunction(this.props.onExpand)) {
            this.props.onExpand(this.props.row)
            e.preventDefault()
            e.stopPropagation()
            e.nativeEvent.stopImmediatePropagation()
        }
    }

    getDraftWidth(status){
        let documents = safeGet(this.props.row.data, "documents", [])
        let preparatoryDocuments = _.filter(documents, d=> (d.documentType.assignationType === AssignationType.PREPARATORY_DOCUMENTATION.value || d.documentType.assignationType === AssignationType.ENEA_PREPARATORY_DOCUMENTATION.value));
        if (getDossierStatusPosition(status) > getDossierStatusPosition(DossierStatus.STATUS_DRAFT.value)){
            return "100%";
        }
        return ((100 * _.filter(preparatoryDocuments, d=>d.file != null && d.valid).length)/preparatoryDocuments.length) + "%";
    }

    getColorByStatus(status){
        switch (status) {
            case DossierStatus.STATUS_GIURE_EVALUATION.value:
                return "#03BB85";
            case DossierStatus.STATUS_QUOTATION.value:
                return "#F2981A";
            case DossierStatus.STATUS_DRAFT.value:
                return "#116EC7";
            case DossierStatus.STATUS_TO_CANDIDATE.value:
                return "#116EC7";
            case DossierStatus.STATUS_CANDIDATED.value:
                return "#063868";
            case DossierStatus.STATUS_APPROVED.value:
                return "#063868";
            case DossierStatus.STATUS_PAY_OFF.value:
                return "#00C634";
            case DossierStatus.STATUS_REFUSED.value:
                return "#FF535A";
        }
    }

    render() {
        let marginLeft = 30 * (this.props.row.level || 0)
        let icon = "zmdi "
        if (!this.props.row.expanded) {
            icon += " zmdi-plus"
        } else {
            icon += " zmdi-minus"
        }

        let caret = !_.isEmpty(this.props.row.children) && this.props.firstElement ?
            <a style={{marginLeft: marginLeft, marginRight: 20}} href="javascript:;" className="expand-button" onClick={this.toggleExpand.bind(this)} onMouseDown={(e) => e.stopPropagation()}>
                <i className={"c-black " + icon} />
            </a> : null

        let style = {}
        if (caret == null && this.props.row.level > 0 && this.props.firstElement) {
            style.marginLeft = marginLeft + 20
        }

        let status = safeGet(this.props.row.data, "status", null);
        let color = this.getColorByStatus(status);
        let statusStyle = {
            color: color,
            marginLeft: "2px"
        };

        let statusPosition = getDossierStatusPosition(status);

        let draftWidth = this.getDraftWidth(status);

        return (
            <div style={{width: "321px"}}>{caret}
                <div>
                    <div className="col-12 zero-padding float-right">
                        <span className="text-uppercase status-text-grid" style={statusStyle}>{getDossierStatusDescription(status)}</span>
                    </div>
                    <div className="col-12 zero-padding" style={{display: "flex", marginTop: "5px"}}>
                        <div className="rounded-line-grid" style={{backgroundColor: color, width: "20%"}} />
                        <div style={{width: "60%", position: "relative", marginRight: "4px"}}>
                            <div className="rounded-line-grid" style={{width: "100%", position: "absolute"}} />
                            <div className="rounded-line-grid" style={{backgroundColor: color, width: draftWidth, position: "absolute"}} />
                        </div>
                        <div className="rounded-line-grid" style={{backgroundColor: statusPosition >= getDossierStatusPosition(DossierStatus.STATUS_TO_CANDIDATE.value) ? color : "#d7d7d7", width: "5%"}} />
                        <div className="rounded-line-grid" style={{backgroundColor: statusPosition >= getDossierStatusPosition(DossierStatus.STATUS_CANDIDATED.value) ? color : "#d7d7d7", width: "5%"}} />
                        <div className="rounded-line-grid" style={{backgroundColor: statusPosition >= getDossierStatusPosition(DossierStatus.STATUS_APPROVED.value) ? color : "#d7d7d7", width: "5%"}} />
                        <div className="rounded-line-grid" style={{backgroundColor: statusPosition >= getDossierStatusPosition(DossierStatus.STATUS_PAY_OFF.value) || statusPosition >= getDossierStatusPosition(DossierStatus.STATUS_REFUSED.value) ? color : "#d7d7d7", width: "5%"}} />
                    </div>
                </div>
            </div>
        )
    }
}

export class QuickSearch extends React.Component {
    constructor(props) {
        super(props)

        this._onChange = _.debounce((keyword) => {
            if (!_.isEmpty(this.props.query)) {
                this.props.query.setKeyword(keyword)
            }
        }, 500)
        this.initialValue = this.getInitialValue();
    }

    getInitialValue() {
        let state = optional(discriminated(optional(SearchStore.state, {}), this.props.discriminator), {});
        if (state ) {
            return optional(state.query, {}).keyword;
        }

        return null;
    }

    componentDidMount() {
        const me = ReactDOM.findDOMNode(this);
        if (this.initialValue)
            $(me).find("input[type=search]").val(this.initialValue)

        $(me).find("input[type=search]")
            .focus(() => {
                $(me).find(".quick-search").addClass("quick-search__active");
            })
            .blur(() => {
                $(me).find(".quick-search").removeClass("quick-search__active");
            })

    }

    componentWillUnmount() {

    }

    onChange(e) {
        this._onChange(e.target.value)
    }

    onKeyDown(e) {
        if (isEnter(e.which)) {
            e.preventDefault()
        }
    }

    render() {

        let placeholder = optional(this.props.placeholder, M("search"));

        return (
            <div className="quick-search-container">
                <div className="quick-search">
                    <i className="zmdi zmdi-search pull-left" />
                    <div className="quick-search-input-container">
                        <input type="search" onKeyDown={this.onKeyDown.bind(this)} onChange={this.onChange.bind(this)} placeholder={placeholder} />
                        <div className="form-control__bar"/>
                    </div>
                </div>
            </div>
        )
    }
}

export class CustomDossierGrid extends React.Component {
    constructor(props) {
        super(props)

        this.selection = null
        this.state = {rows: null}

        this.initSelection(props)

        this.id = Math.random();
    }

    getTotalRows() {
        let totalRows = parseInt(this.props.data.totalRows)
        return totalRows
    }

    onKeyPress(e) {

    }

    onBlur() {
        if (this.selection) {
            this.selection.shiftPressed = false
            this.selection.controlPressed = false
        }
    }

    onKeyDown(e) {
        let me = ReactDOM.findDOMNode(this)
        if (this.selection != null) {
            if (isShift(e.which)) {
                me.onselectstart = function() { return false }
                this.selection.shiftPressed = true
                e.preventDefault()
                return
            } else if (isControl(e.which)) {
                this.selection.controlPressed = true
                e.preventDefault()
                return
            } else if (isUp(e.which)) {
                this.selection.up()
                e.preventDefault()
                return
            } else if (isDown(e.which)) {
                this.selection.down()
                e.preventDefault()
                return
            } else if (isEsc(e.which)) {
                this.selection.clear()
                e.preventDefault()
                return
            }
        }

        if (_.isFunction(this.props.onKeyDown)) {
            this.props.onKeyDown(e)
        }
    }

    onKeyUp(e) {
        let me = ReactDOM.findDOMNode(this)
        if (this.selection != null) {
            if (isShift(e.which)) {
                me.onselectstart = null
                this.selection.shiftPressed = false
                e.preventDefault()
                return
            } else if (isControl(e.which)) {
                this.selection.controlPressed = false
                e.preventDefault()
                return
            }
        }

        if (_.isFunction(this.props.onKeyUp)) {
            this.props.onKeyUp(e)
        }

    }

    onRowMouseDown(row) {
        let selectionEnabled = optional(parseBoolean(this.props.selectionEnabled), true)
        if (selectionEnabled) {
            this.selection.handle(row, false)
        } else {
            if (_.isFunction(this.props.onRowClick)) {
                this.props.onRowClick(row.data)
            }
        }
    }

    onRowCheckboxSelect(row) {
        let selectionEnabled = optional(parseBoolean(this.props.checkboxSelectEnabled), true)
        if (!selectionEnabled) {
            return
        }

        this.selection.handle(row, true)
    }

    onRowDoubleClick(row) {
        if (_.isFunction(this.props.onRowDoubleClick)) {
            this.props.onRowDoubleClick(row.data)
        }
    }

    onRowExpand(row) {
        let expanded = !row.expanded

        if (expanded) {
            eachChildren(row.children, r => r.expandedNow = true)
        } else {
            eachChildren(row.children, r => r.collapsedNow = true)
        }
        if (!expanded) {
            this.forceUpdate()

            setTimeout(() => {
                row.expanded = expanded
                this.forceUpdate()
            }, EXPAND_ANIMATION_TIME)
        } else {
            row.expanded = expanded
            this.forceUpdate()
        }

        if (_.isFunction(this.props.onRowExpand)) {
            this.props.onRowExpand(row.data, expanded)
        }
    }

    initSelection(props) {
        let selectionEnabled = optional(parseBoolean(props.selectionEnabled), true) || optional(parseBoolean(this.props.checkboxSelectEnabled), true)
        if (!selectionEnabled) {
            return
        }


        let rows = props.data && props.data.rows
        if (rows) {
            this.selection = new Selection(rows)
            this.selection.single = props.selectionMode === "single"
            this.selection.on("change", () => {
                this.setState(this.state)
                if (_.isFunction(this.props.onSelectionChanged)) {
                    this.props.onSelectionChanged(this.selection.getSelectedData())
                }
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        this.initSelection(nextProps)

        let rows = nextProps.data && nextProps.data.rows
        this.setState(_.assign(this.state, {rows}))
    }

    getAllSelected(){
        let allSelected = false;
        if (this.selection) {
            return this.selection.allSelected;
        }
        return allSelected;
    }

    toggleSelectAll() {
        let selectionEnabled = optional(parseBoolean(this.props.selectionEnabled), true) || optional(parseBoolean(this.props.checkboxSelectEnabled), true)
        if (!selectionEnabled) {
            return
        }


        if (this.selection) {
            this.selection.toggleAll()
        }
    }

    clearSelection() {
        let selectionEnabled = optional(parseBoolean(this.props.selectionEnabled), true) || optional(parseBoolean(this.props.checkboxSelectEnabled), true)
        if (!selectionEnabled) {
            return
        }

        if (this.selection) {
            this.selection.clear()
        }
    }

    getSelection() {
        let selectionEnabled = optional(parseBoolean(this.props.selectionEnabled), true) || optional(parseBoolean(this.props.checkboxSelectEnabled), true)
        if (!selectionEnabled) {
            return
        }

        if (this.selection) {
            return this.selection.getSelectedData()
        } else {
            return null
        }
    }

    getTotalPages() {
        if (!this.props.data || !this.props.data.rows || !this.props.query) {
            return 1
        }

        let totalPages = parseInt(Math.ceil(this.props.data.totalRows / this.props.query.rowsPerPage))
        return totalPages
    }

    render() {
        if (_.isEmpty(this.props.descriptor)) {
            return null
        }

        let allSelected = this.getAllSelected();

        //customization properties
        let quickSearchEnabled = optional(parseBoolean(this.props.quickSearchEnabled), false)
        let quickSearchPlaceholder = optional(this.props.quickSearchPlaceholder, M("search"))
        let headerVisible = optional(parseBoolean(this.props.headerVisible), true)
        let footerVisible = optional(parseBoolean(this.props.footerVisible), true)
        let summaryVisible = optional(parseBoolean(this.props.summaryVisible), true)
        let noResultsVisible = optional(parseBoolean(this.props.noResultsVisible), true)
        //let selectionEnabled = optional(parseBoolean(this.props.selectionEnabled), true)
        let paginationEnabled = optional(parseBoolean(this.props.paginationEnabled), true)
        let tableClassName = optional(this.props.tableClassName, "table table-striped table-hover")
        let noResultsText = optional(this.props.noResultsText, M("noResults"))

        let myQuery = optional(this.props.query, query.create())
        let showFilters = getVisibleFilters(myQuery).length > 0
        let hasResults = (this.props.data && this.props.data.rows) ? this.props.data.rows.length > 0 : false
        let hasPagination = this.getTotalPages() > 1
        let Container = optional(parseBoolean(this.props.showInCard), true) ? Card : NoCard
        let descriptor = mobile.isMobile()
            ? _.assign({}, this.props.descriptor, {columns: _.union(this.props.descriptor.columns, [{
                cell: ActionsCell,
                tdClassName: "grid-actions",
                actions: [
                    {icon: "zmdi zmdi-edit", action: (row) => {
                        if (_.isFunction(this.props.onRowDoubleClick)) {
                            this.props.onRowDoubleClick(row)
                        }
                    }}
                ],
                props: {
                    showAlways: true
                }
            }])})
            : this.props.descriptor

        let checkboxSelectEnabled = optional(parseBoolean(this.props.checkboxSelectEnabled), false);

        return (
            <div className="grid" tabIndex="0" onBlur={this.onBlur.bind(this)} onKeyPress={this.onKeyPress.bind(this)} onKeyUp={this.onKeyUp.bind(this)} onKeyDown={this.onKeyDown.bind(this)}>
                <Container>
                    <div>
                        {quickSearchEnabled &&
                            <QuickSearch placeholder={quickSearchPlaceholder} discriminator={this.props.discriminator} query={myQuery} />
                        }

                        {showFilters &&
                            <Filters query={myQuery} />
                        }

                        <div className="clearfix"></div>

                        {hasResults ?
                            <div className="with-result table-responsive">
                                <table className={tableClassName}>
                                    {headerVisible &&
                                        <GridHeader
                                            entity={this.props.entity}
                                            allSelected = {allSelected}
                                            toggleSelectAll={this.toggleSelectAll.bind(this)}
                                            checkboxSelectEnabled={checkboxSelectEnabled}
                                            descriptor={descriptor}
                                            query={myQuery}/>
                                    }
                                    <GridBody
                                        entity={this.props.entity}
                                        checkboxSelectEnabled={checkboxSelectEnabled}
                                        descriptor={descriptor}
                                        data={this.props.data}
                                        query={myQuery}
                                        onRowExpand={this.onRowExpand.bind(this)}
                                        onRowMouseDown={this.onRowMouseDown.bind(this)}
                                        onRowDoubleClick={this.onRowDoubleClick.bind(this)}
                                        onRowCheckboxSelect={this.onRowCheckboxSelect.bind(this)}
                                    />
                                    {footerVisible &&
                                        <GridFooter checkboxSelectEnabled={checkboxSelectEnabled} descriptor={descriptor} entity={this.props.entity}/>
                                    }
                                </table>

                                {hasPagination && paginationEnabled &&
                                    <div className="pull-right m-20">
                                        <Pagination data={this.props.data} query={myQuery} />
                                    </div>
                                }

                                {summaryVisible &&
                                    <ResultSummary query={myQuery} data={this.props.data} />
                                }

                                <div className="clearfix"></div>
                            </div>
                            : //no results
                            noResultsVisible &&
                            <div className="no-results text-center p-30">
                                <h1><i className="zmdi zmdi-info-outline" /></h1>
                                <h4>{noResultsText}</h4>
                            </div>
                        }
                    </div>
                </Container>
            </div>
        )
    }
}
