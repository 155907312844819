"use strict"

import React from "react";
import M from "../../strings";
import * as _ from "underscore";
import {
    goBack
} from "../../actions/questionnaire";
import {alert} from "../../plugins";
import * as ui from "../utils/ui";
import {FreeEntityForm} from "./entities/entityForm";

const {Screen, Layout} = require("../components/layout")
const Secure = require("../components/secure")

export default class SelectCustomerScreen extends Screen {

    constructor(props) {
        super(props);

        this.dossierType = this.props.dossierType;
        this.data = {}
    }


    onCancel(){
        ui.navigate("/entities/dossier/list/" + this.dossierType)
    }

    onConfirm() {
        if (!this.data || !this.data.customer)
            alert("Attenzione", "E' necessario selezionare un cliente.").then(r => {})
        else
            location.href ="#/questionnaire/" + this.dossierType + "?customerId=" + this.data.customer.id;
    }

    render() {



        return (
            <Secure>
                <Layout hideFooter={true}>

                    <div className="questionnaire block-header">
                        <div className="card questionnaire__content ">
                            <h3>Seleziona cliente</h3>
                            <FreeEntityForm disableOnBeforeUnload={true} entity={"customerSelection"} entityForDescriptor={"customerSelection"} data={this.data} />
                        </div>
                        <SelectCustomerFooter
                            onCancel={this.onCancel.bind(this)}
                            onConfirm={this.onConfirm.bind(this)}
                        />
                    </div>

                </Layout>
            </Secure>
        )
    }
}


export const SelectCustomerFooter = (props) => {

    function onClickCancel() {
        if (_.isFunction(props.onCancel)) {
            props.onCancel()
        }
    }

    function onClickBack(){
        goBack()
    }

    function onConfirm() {
        if (_.isFunction(props.onConfirm)) {
            props.onConfirm()
        }
    }

    return (
        <div className="questionnaire__footer ">
            <div className="btn-actions-bar">
                <button type="button" className="btn btn-cancel float-left" onClick={onClickCancel}>
                    {M("cancel")}
                </button>
                <button type="submit" className="btn btn-warning" onClick={onConfirm} style={{width:"212px"}}>
                    {M("next")}
                </button>
            </div>


        </div>
    )
}

