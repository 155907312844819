import M from "../../../strings";
import {alert} from "../../../plugins";
import {TextCell, TextCellWithSubText} from "../grids";
import {
    AreaNoCard,
    Button,
    Column,
    DateTime,
    MultiLineText,
    Number,
    ReadOnlyText,
    ReadOnlyTextArea,
    Select,
    Spacer,
    Switch,
    Text,
    TextArea,
    YesNo
} from "../forms";
import {ValuesLookupContainer, ValuesSelectContainer} from "../containers";
import {
    AssignationType,
    CompanyCustomType,
    CustomerType,
    DocumentStatus,
    DOSSIER_TYPE,
    DossierServiceProvided,
    DossierStatus,
    DossierStatusDatasource,
    getCustomerTypeDescription,
    getDossierEntityNameFromType,
    getDossierStatusPosition,
    getKPMGStatusDescription,
    KPMGStatusDatasource,
    SelectYesNoDatasource,
    OperationType,
    WORK_COMPLETION_DOCUMENTS_CODE, dateNewLabel
} from "../../../model/vars";
import * as datasource from "../../../utils/datasource";
import {StatusCell} from "../customDossierGrids";
import {DossierStore} from "../../../stores/dossier";
import {updateFormData} from "../../../actions/entities";
import _ from "underscore";
import {format, isDifferent, optional, parseBoolean} from "../../../utils/lang";
import {isSuperuser} from "../../../api/session";
import {DossierAddDocumentDialog} from "../dossiers/dossierAddDocumentDialog";
import {DossierRefuseDocumentDialog} from "../dossiers/dossierRefuseDocumentDialog";
import {KPMGSendForReview, restoreCanDownload} from "../../../actions/dossier";
import {DossierDocumentContainer} from "../dossiers/dossierDocumentContainer";
import moment from "moment";
import * as config from "../../../framework/config";
import {formatEuroNumber, formatNumber} from "../dossiers/utils";
import {valuesSelectContainerField} from "../../commonFields";
import {Dossier_2022_BonusCasa_1_FormFooter} from "./dossier_2022_bonusCasa_1_Form";
import {Dossier_2022_bonusCasa_1_priceCalculatorControl} from "./dossier_2022_bonusCasa_1_priceCalculatorControl";
import {Dossier_2022_BonusCasa_1_Store} from "../../../stores/dossier_2022_bonusCasa";
import {generateQuotationAmounts_2022_BonusCasa_1} from "../../../actions/dossier_2022_bonusCasa";

export const dossier_2022_bonusCasa_1_Entity = {
    grid: {
        canDelete: () => {
            return false;
        },
        getTitle: (type) => {
            return M(type)
        },
        descriptor: {
            columns: [
                {
                    property: "dossierCode",
                    header: M("#"),
                    cell: TextCellWithSubText,
                    sortable: true,
                    searchable: true,
                    searchForm: {
                        searchTitle: M("filterByDate"),
                        showInCard: false,
                        fields: [
                            {
                                property: "_dateFrom",
                                label: M("from"),
                                control: Text,
                                filterType: "eq",
                            },
                            {
                                property: "_dateTo",
                                label: M("to"),
                                control: Text,
                                filterType: "eq",
                            }
                        ]
                    },
                    props: {
                        formatterTitle: (v) => {
                            return v != null ? v : "";
                        },
                        formatterSubtitle: (v, data) => {
                            return v != null ? moment(data.creationDate).format("DD/MM/YYYY") : "";
                        }
                    }
                },
                {
                    property: "customer",
                    header: M("customer"),
                    cell: TextCellWithSubText,
                    sortable: true,
                    searchable: true,
                    searchForm: {
                        showInCard: false,
                        fields: [
                            {
                                property: "customerId",
                                label: M("customer"),
                                control: ValuesSelectContainer,
                                filterType: "eq",
                                props: {
                                    id: "dossier_customer",
                                    mode: "single",
                                    allowNull: true,
                                    searchEnabled: true,
                                    collection: "customers",
                                    getSingleItemLabel: (value) => {
                                        return value.name
                                    },
                                    getSingleItemValue: (value) => {
                                        return value.id
                                    },
                                    formatter: v => {
                                        return v != null ? v.name : "";
                                    }
                                }
                            },
                        ]
                    },
                    props: {
                        formatterTitle: (v) => {
                            return v != null ? v.name : "";
                        },
                        formatterSubtitle: (v) => {
                            return v != null ? getCustomerTypeDescription(v.subjectType) : "";
                        }
                    }
                },
                {
                    property: "fabricator",
                    header: M("fabricator"),
                    cell: TextCellWithSubText,
                    sortable: true,
                    searchable: true,
                    searchForm: {
                        showInCard: false,
                        fields: [
                            {
                                property: "fabricatorId",
                                label: M("fabricator"),
                                control: ValuesSelectContainer,
                                filterType: "eq",
                                props: {
                                    id: "dossier_fabricator",
                                    mode: "single",
                                    allowNull: true,
                                    searchEnabled: true,
                                    collection: "fabricators",
                                    getSingleItemLabel: (value) => {
                                        return value.businessName
                                    },
                                    getSingleItemValue: (value) => {
                                        return value.id
                                    },
                                    formatter: v => {
                                        return v != null ? v.businessName : "";
                                    }
                                }
                            },
                            {
                                property: "fabricatorCategoryId",
                                label: M("referenceToUserCategory"),
                                control: ValuesSelectContainer,
                                filterType: "eq",
                                props: {
                                    id: "fabricator_category",
                                    mode: "single",
                                    allowNull: true,
                                    searchEnabled: true,
                                    collection: "fabricatorCategories",
                                    getSingleItemLabel: (value) => {
                                        return value.description
                                    },
                                    getSingleItemValue: (value) => {
                                        return value.id
                                    },
                                    formatter: v => {
                                        return v != null ? v.description : "";
                                    }
                                }
                            },
                        ]
                    },
                    props: {
                        formatterTitle: (v) => {
                            return v != null ? v.businessName : "";
                        },
                        formatterSubtitle: (v) => {
                            return v != null && v.category? v.category.description : "";
                        }
                    }
                },
                {
                    property: "recommendedPrice",
                    header: M("amount"),
                    cell: TextCell,
                    sortable: true,
                    searchable: false,

                    props: {
                        formatter: v => {

                            let value = v;
                            if (v && v.recommendedRetailPrice) {
                                value = v.recommendedRetailPrice
                            }
                            return formatEuroNumber(value)
                        }
                    }
                },
                {
                    property: "canBeCandidate",
                    header: M("canBeCandidate"),
                    cell: TextCell,
                    sortable: true,
                    searchable: true,
                    isVisible(data) {
                        return isSuperuser()
                    },
                    searchForm: {
                        showInCard: false,
                        fields: [
                            {
                                property: "canBeCandidate",
                                label: M("canBeCandidate"),
                                control: Select,
                                filterType: "eq",
                                props: {
                                    allowNull: true,
                                    datasource: datasource.fixed([
                                        {label: M("yes"), value: true},
                                        {label: M("no"), value: false},
                                    ]),
                                },
                            }
                        ]
                    },
                    props: {
                        formatter: v => {
                            return v != null && v ? M("yes") : M("no");
                        }
                    }
                },
                {
                    property: "candidateAE",
                    header: M("candidateAE"),
                    cell: TextCell,
                    sortable: true,
                    searchable: true,
                    isVisible(data) {
                        return isSuperuser()
                    },
                    searchForm: {
                        showInCard: false,
                        fields: [
                            {
                                property: "candidateAE",
                                label: M("candidateAE"),
                                control: Select,
                                filterType: "eq",
                                props: {
                                    allowNull: true,
                                    datasource: datasource.fixed([
                                        {label: M("yes"), value: true},
                                        {label: M("no"), value: false},
                                    ]),
                                },
                            }
                        ]
                    },
                    props: {
                        formatter: v => {
                            return v != null && v ? M("yes") : M("no");
                        }
                    }
                },
                {
                    property: "candidateENEA",
                    header: M("candidateENEA"),
                    cell: TextCell,
                    sortable: true,
                    isVisible(data) {
                        return isSuperuser()
                    },
                    searchable: true,
                    searchForm: {
                        showInCard: false,
                        fields: [
                            {
                                property: "candidateENEA",
                                label: M("candidateENEA"),
                                control: Select,
                                filterType: "eq",
                                props: {
                                    allowNull: true,
                                    datasource: datasource.fixed([
                                        {label: M("yes"), value: true},
                                        {label: M("no"), value: false},
                                    ]),
                                },
                            }
                        ]
                    },
                    props: {
                        formatter: v => {
                            return v != null && v ? M("yes") : M("no");
                        }
                    }
                },
                {
                    property: "serviceFeeInvoiced",
                    header: M("serviceFeeInvoiced"),
                    cell: TextCell,
                    sortable: true,
                    isVisible(data) {
                        return isSuperuser()
                    },
                    searchable: true,
                    searchForm: {
                        showInCard: false,
                        fields: [
                            {
                                property: "serviceFeeInvoiced",
                                label: M("serviceFeeInvoiced"),
                                control: Select,
                                filterType: "eq",
                                props: {
                                    allowNull: true,
                                    datasource: datasource.fixed([
                                        {label: M("yes"), value: true},
                                        {label: M("no"), value: false},
                                    ]),
                                },
                            }
                        ]
                    },
                    props: {
                        formatter: v => {
                            return v != null && v ? M("yes") : M("no");
                        }
                    }
                },
                {
                    property: "_province",
                    header: M("province"),
                    cell: TextCell,
                    sortable: true,
                    searchable: true,
                },
                {
                    property: "_region",
                    header: M("region"),
                    cell: TextCell,
                    sortable: true,
                    searchable: true,
                },
                {
                    property: "_preparatoryDocumentations",
                    header: M("preparatoryDocumentsShort"),
                    cell: TextCell,
                    sortable: true,
                    searchable: true,
                    searchForm: {
                        showInCard: false,
                        fields: [
                            {
                                property: "_filterType",
                                label: "Doc. propedeutica - Tipo filtro",
                                control: Select,
                                hide: true,
                                props: {
                                    allowNull: false,
                                    datasource: datasource.fixed([
                                        {label: M("equalsTo"), value: "eq"},
                                        {label: M("lowerThan"), value: "lt"},
                                        {label: M("greaterThan"), value: "gt"}
                                    ])
                                }
                            },
                            {
                                property: "_preparatoryDocumentations",
                                label: M("_preparatoryDocumentations"),
                                control: Number,
                            },


                            {
                                property: "_approvedDocuments_filterType",
                                label: M("_approvedDocuments_filterType"),
                                control: Select,
                                hide: true,
                                props: {
                                    allowNull: false,
                                    datasource: datasource.fixed([
                                        {label: M("equalsTo"), value: "eq"},
                                        {label: M("lowerThan"), value: "lt"},
                                        {label: M("greaterThan"), value: "gt"}
                                    ])
                                }
                            },

                            {
                                property: "_approvedDocumentationsNumber",
                                label: M("_approvedDocumentationsNumber"),
                                control: Number,
                            },

                            {
                                property: "_documentTypeSet",
                                label: M("documentType"),
                                control: ValuesSelectContainer,
                                filterType: "eq",
                                props: {
                                    id: "dossier_documentType",
                                    mode: "single",
                                    allowNull: true,
                                    searchEnabled: true,
                                    collection: "documentTypes?types=" + AssignationType.ENEA_PREPARATORY_DOCUMENTATION.value + ";" + AssignationType.PREPARATORY_DOCUMENTATION.value,
                                    getSingleItemLabel: (value) => {
                                        return value.fullDescription
                                    },
                                    getSingleItemValue: (value) => {
                                        return value.id
                                    },
                                    formatter: v => {
                                        return v != null ? v.fullDescription : "";
                                    }
                                }
                            },

                        ]
                    },
                },
                {
                    property: "_closingDocumentations",
                    header: M("closingDocumentsShort"),
                    cell: TextCell,
                    sortable: false,
                    searchable: true,
                    searchForm: {
                        showInCard: false,
                        fields: [
                            {
                                property: "_approvedClosingDocuments_filterType",
                                label: M("_uploadClosingDocuments_filterType"),
                                control: Select,
                                hide: true,
                                props: {
                                    allowNull: false,
                                    datasource: datasource.fixed([
                                        {label: M("equalsTo"), value: "eq"},
                                        {label: M("lowerThan"), value: "lt"},
                                        {label: M("greaterThan"), value: "gt"}
                                    ])
                                }
                            },
                            {
                                property: "_approvedDocumentationsClosingNumber",
                                label: M("_uploadDocumentationsClosingNumber"),
                                control: Number,
                            },
                            {
                                property: "_documentTypeClosingSet",
                                label: M("documentType"),
                                control: ValuesSelectContainer,
                                filterType: "eq",
                                props: {
                                    id: "dossier_closingDocumentType",
                                    mode: "single",
                                    allowNull: true,
                                    searchEnabled: true,
                                    getCollection: (model) =>{
                                        return "documentTypes?types=" + AssignationType.PUBLIC_CLOSING_DOCUMENTATION.value + ";" + AssignationType.PUBLIC_CLOSING_DOCUMENTATION.value;
                                    },
                                    getSingleItemLabel: (value) => {
                                        return value.fullDescription
                                    },
                                    getSingleItemValue: (value) => {
                                        return value.id
                                    },
                                    formatter: v => {
                                        return v != null ? v.fullDescription : "";
                                    }
                                }
                            },

                        ]
                    },
                },
                {
                    property: "workCompletionDate",
                    header: M("workCompletionDate"),
                    cell: TextCell,
                    sortable: true,
                    searchable: false,
                    props: {
                        formatter: v => {
                            return v != null ? moment(v).format("MM/DD/YYYY") : "-"
                        }
                    }
                },
                {
                    property: "kpmgstatus",
                    header: M("KPMGStatus"),
                    cell: TextCell,
                    sortable: false,
                    isVisible: () => isSuperuser(),
                    searchable: true,
                    props: {
                        formatter: v => {
                            return v != null ? getKPMGStatusDescription(v) : "-"
                        }
                    },
                    searchForm: {
                        showInCard: false,
                        fields: [
                            {
                                property: "KPMGStatus",
                                label: M("KPMGStatus"),
                                control: Select,
                                filterType: "eq",
                                props: {
                                    allowNull: true,
                                    datasource: KPMGStatusDatasource,
                                },
                            }
                        ]
                    },
                },
                {
                    property: "priority",
                    header: M("priority"),
                    cell: TextCell,
                    sortable: false,
                    isVisible(data) {
                        return isSuperuser()
                    },
                    searchable: true,
                    searchForm: {
                        showInCard: false,
                        fields: [
                            {
                                property: "priority",
                                label: M("priority"),
                                control: Select,
                                filterType: "eq",
                                props: {
                                    allowNull: true,
                                    datasource: datasource.fixed([
                                        {label: M("yes"), value: true},
                                        {label: M("no"), value: false},
                                    ]),
                                },
                            }
                        ]
                    },
                    props: {
                        formatter: v => {
                            return v != null && v ? M("yes") : M("no");
                        }
                    }
                },
                {
                    property: "status",
                    header: M("status"),
                    cell: StatusCell,
                    sortable: false,
                    searchable: true,
                    searchForm: {
                        showInCard: false,
                        fields: [
                            {
                                property: "status",
                                label: M("status"),
                                control: Select,
                                filterType: "eq",
                                props: {
                                    allowNull: true,
                                    datasource: DossierStatusDatasource,
                                },
                            }
                        ]
                    },
                },
            ]
        }
    },
    form: {
        title: M("dossierEcoBonus"),
        getActions(data) {
            return ["back", "save", "save-go-back", "revisions"];
        },
        stores: [
            {
                store: DossierStore,
                callback(model, state) {
                    if (state.dossier) {
                        updateFormData({
                            data: state.dossier,
                            discriminator: "entity_form_dossier"
                        })
                    }
                }
            }
        ],
        descriptor: {
            stores: [DossierStore, Dossier_2022_BonusCasa_1_Store],
            showFormFooter: false,
            formUpdateFunction: (newState, oldState, model) => {
                if (newState.canDownload && newState.downloadUrl != null) {
                    restoreCanDownload();
                    window.open(config.get("service.url") + newState.downloadUrl, "_blank");
                }

                if (newState && newState.completed) {
                    if (!_.isEmpty(newState.documents) && isDifferent(newState.documents, oldState.documents)) {
                        model.set("documents", newState.documents);
                        model.invalidateForm();
                    }
                }

                if (isDifferent(newState.priceCalculatorSheet, oldState.priceCalculatorSheet)) {
                    //Set: setto i nuovi valori
                    model.set("priceCalculatorSheet", newState.priceCalculatorSheet);

                    if (newState.priceCalculatorSheet.message != null && newState.priceCalculatorSheet.message.length > 0 && (oldState.priceCalculatorSheet == null || newState.priceCalculatorSheet.message !== oldState.priceCalculatorSheet.message)) {
                        Swal.fire({
                            title: M("warning"),
                            icon: 'error',
                            html: newState.priceCalculatorSheet.message,
                            showCloseButton: true,
                            focusConfirm: false,
                        })
                    }
                    model.invalidateForm();
                }
            },
            onModelLoadFirstTime: (model) => {
                model.on("property:change", (property, value) => {

                    switch (property) {
                        case "_fabricator":
                            model.set("_fabricatorCollaborator", null);
                            model.invalidateForm();
                            break;
                        case "continuationWork":
                        case "continuationWorkValue":
                        case "serviceProvided":
                        case "estateTypeAndUse":
                        case "operationType":
                        case "window":
                        case "blind":
                        case "installation":
                        case "takenMeasures":
                        case "customer":

                            let subjectType = "";
                            let customer = model.get("_customer");
                            if (customer != null) {
                                subjectType = customer.subjectType;
                            }
                            let operationType = model.get("operationType");
                            if (operationType == null) {
                                operationType = "";
                            }
                            let estateTypeAndUse = model.get("estateTypeAndUse");
                            if (estateTypeAndUse == null) {
                                estateTypeAndUse = "";
                            }
                            let serviceProvided = model.get("serviceProvided");
                            if (serviceProvided == null) {
                                serviceProvided = "";
                            }

                            let continuationWork = model.get("continuationWork");

                            if (property === "continuationWork" && !parseBoolean(optional(continuationWork, false))) {
                                model.set("continuationWorkValue", null);
                            } else if (property === "serviceProvided" && serviceProvided === DossierServiceProvided.ONLY_SUPPLY.value) {
                                model.set("installation", null);
                                model.set("takenMeasures", null);
                            }

                            let continuationWorkValue = optional(model.get("continuationWorkValue"), 0);
                            if (continuationWorkValue === "" || continuationWorkValue === "-")
                                continuationWorkValue = 0;
                            let window = optional(model.get("window"), 0);
                            if (window === "" || window === "-")
                                window = 0;
                            let blind = optional(model.get("blind"), 0);
                            if (blind === "" || blind === "-")
                                blind = 0;
                            let installation =optional( model.get("installation"), 0);
                            if (installation === "" || installation === "-")
                                installation = 0;
                            let takenMeasures = optional(model.get("takenMeasures"), 0);
                            if (takenMeasures === "" || takenMeasures === "-")
                                takenMeasures = 0;

                            let skipErrorsIfIncomplete = true;
                            let fabricatorId = model.get("_fabricator") != null ? model.get("_fabricator").id : null;

                            model.propagateValidationException = false;
                            model.resetValidation()
                            model.validate()
                            let dossierCreationDate = model.get("creationDate") != null ? model.get("creationDate") : new Date().getTime();
                            generateQuotationAmounts_2022_BonusCasa_1({subjectType, operationType, estateTypeAndUse, serviceProvided, window, blind, installation, takenMeasures, continuationWork, continuationWorkValue, dossierCreationDate, skipErrorsIfIncomplete});
                            model.propagateValidationException = true;
                            model.invalidateForm()
                            break;
                        case "_customer" :
                            if(model && model.data &&
                                model.data._customer &&
                                model.data._customer.subjectType == CustomerType.SUBJECT_TYPE_LEGAL_PERSON.value){
                                var companyCustomType = model.data._customer.companyCustomType;
                                if(_.isEmpty(companyCustomType)){
                                    alert("La tipologia azienda non è stata settata per il cliente " + model.data._customer.name + ". Si prega di andare nella sezione Cliente ed aggiornare la relativa anagrafica.");
                                    model.set("_customer", null);
                                    model.validate()
                                }else if(companyCustomType == CompanyCustomType.SPA.value || companyCustomType == CompanyCustomType.SAPA.value ||
                                    companyCustomType == CompanyCustomType.SRL.value || companyCustomType == CompanyCustomType.SRLS.value){
                                    alert("Il Bonus Casa non è applicabile alle persone giuridiche che hanno una tipologia azienda S.p.a, S.a.p.a, S.r.l., S.r.l.s.");
                                    model.set("_customer", null);
                                    model.validate()
                                }
                                model.invalidateForm()
                            }
                            break
                    }


                });
            },
            areas: [
                {
                    component: AreaNoCard,
                    className: "zero-padding",
                    fields: [
                        {
                            property: "addDocumentDialog",
                            control: DossierAddDocumentDialog,
                            emptyRow: true
                        },
                        {
                            property: "refuseDocumentDialog",
                            control: DossierRefuseDocumentDialog,
                            emptyRow: true
                        },
                        {
                            property: "c",
                            control: Column,
                            size: "col-md-8",
                            className: "m-b-10",
                            noLateralPadding: false,
                            fields: [
                                {
                                    property: "c_1",
                                    control: Column,
                                    size: "col-sm-12",
                                    className: "card",
                                    noLateralPadding: false,
                                    fields: [
                                        {
                                            property: "c_1",
                                            control: Column,
                                            size: "col-md-4 col-sm-6",
                                            className: "zero-padding",
                                            noLateralPadding: false,
                                            fields: [
                                                {
                                                    property: "code",
                                                    control: Spacer,
                                                    label: M("dossierEcoBonus"),
                                                    size: "col-sm-12",
                                                    emptyRow: true,
                                                    className: "m-b-10",
                                                    props: {
                                                        className: "ecobonus-code",
                                                        defaultTheme: false,
                                                        formatter: v => {
                                                            return v != null ? v.get("dossierCode") : ""
                                                        }
                                                    },
                                                },
                                            ]
                                        },
                                        {
                                            property: "c_2",
                                            control: Column,
                                            size: "col-md-4 col-sm-6",
                                            className: "zero-padding",
                                            noLateralPadding: false,
                                            fields: [
                                                {
                                                    property: "type",
                                                    control: ReadOnlyText,
                                                    label: M("type"),
                                                    placeholder: M("type"),
                                                    size: "col-sm-12",
                                                    props: {
                                                        formatter: v => {
                                                            return M(v)
                                                        }
                                                    }
                                                },
                                            ]
                                        },
                                        {
                                            property: "c_3",
                                            control: Column,
                                            size: "col-md-4 col-sm-6",
                                            className: "zero-padding",
                                            noLateralPadding: false,
                                            fields: [
                                                {
                                                    property: "creationDate",
                                                    control: ReadOnlyText,
                                                    label: M("creationDate"),
                                                    placeholder: M("creationDate"),
                                                    size: "col-sm-12",
                                                    props: {
                                                        formatter: v => {
                                                            return v != null ? moment(v).format("DD/MM/YYYY HH:mm") : moment(new Date()).format("DD/MM/YYYY HH:mm")
                                                        }
                                                    }
                                                },
                                            ]
                                        },
                                        {
                                            property: "_customer",
                                            label: M("customer"),
                                            getControl: (model) => {
                                                //Il cliente non è modificabile in fase di modifica della pratica oppure in caso di creazione delle pratiche 2020_50 nel quale viene pre-selezionato a monte
                                                let readonly =  model.get("id") !== null;
                                                /*
                                                if (!readonly && model.get("_customer")) {
                                                    readonly = true;
                                                }*/
                                                return readonly? ReadOnlyText: ValuesLookupContainer
                                            },
                                            size: "col-md-4 col-sm-6",
                                            isRequired: true,
                                            props: {
                                                id: "dossier_customer",
                                                mode: "single",
                                                collection: "customers",
                                                selectionGrid: {
                                                    columns: [
                                                        {property: "code", header: M("code"), cell: TextCell},
                                                        {property: "name", header: M("name"), cell: TextCell}
                                                    ]
                                                },
                                                popupGrid: {
                                                    columns: [
                                                        {property: "code", header: M("code"), cell: TextCell},
                                                        {property: "name", header: M("name"), cell: TextCell}
                                                    ]
                                                },
                                                formatter: v => {
                                                    return v != null ? v.name : "";
                                                }
                                            }
                                        },
                                        {
                                            property: "_fabricator",
                                            label: M("fabricator"),
                                            isRequired: true,
                                            getControl: (model) => {
                                                return model.get("id") == null && isSuperuser() ? ValuesLookupContainer : ReadOnlyText
                                            },
                                            size: "col-md-4 col-sm-6",
                                            props: {
                                                id: "dossier_fabricator",
                                                mode: "single",
                                                collection: "fabricators",
                                                selectionGrid: {
                                                    columns: [
                                                        {property: "code", header: M("code"), cell: TextCell},
                                                        {
                                                            property: "businessName",
                                                            header: M("businessName"),
                                                            cell: TextCell
                                                        },
                                                        {
                                                            property: "completedProfile",
                                                            header: M("completedProfile"),
                                                            cell: TextCell,
                                                            props: {
                                                                formatter: v => {
                                                                    return v ? M("yes") : M("no");
                                                                }
                                                            }
                                                        }
                                                    ]
                                                },
                                                popupGrid: {
                                                    columns: [
                                                        {property: "code", header: M("code"), cell: TextCell},
                                                        {
                                                            property: "businessName",
                                                            header: M("businessName"),
                                                            cell: TextCell
                                                        },
                                                        {
                                                            property: "completedProfile",
                                                            header: M("completedProfile"),
                                                            cell: TextCell,
                                                            props: {
                                                                formatter: v => {
                                                                    return v ? M("yes") : M("no");
                                                                }
                                                            }
                                                        }
                                                    ]
                                                },
                                                formatter: v => {
                                                    return v != null ? v.businessName : "";
                                                }
                                            }
                                        },
                                        {
                                            property: "_fabricatorCollaborator",
                                            label: M("collaborator"),
                                            isRequired: true,
                                            getControl: (model) => {
                                                return model.get("id") == null && model.get("_fabricator") != null && isSuperuser() ? ValuesLookupContainer : ReadOnlyText
                                            },
                                            size: "col-md-4 col-sm-6",
                                            props: {
                                                id: "dossier_fabricator_collaborator",
                                                mode: "single",
                                                getCollection: (model) => {
                                                    return "collaborators/" + model.get("_fabricator").id;
                                                },
                                                selectionGrid: {
                                                    columns: [
                                                        {property: "code", header: M("code"), cell: TextCell},
                                                        {property: "name", header: M("name"), cell: TextCell},
                                                        {
                                                            property: "lastname",
                                                            header: M("lastname"),
                                                            cell: TextCell
                                                        },
                                                    ]
                                                },
                                                popupGrid: {
                                                    columns: [
                                                        {property: "code", header: M("code"), cell: TextCell},
                                                        {property: "name", header: M("name"), cell: TextCell},
                                                        {
                                                            property: "lastname",
                                                            header: M("lastname"),
                                                            cell: TextCell
                                                        },
                                                    ]
                                                },
                                                formatter: v => {
                                                    return v != null ? v.lastname + " " + v.name : "";
                                                }
                                            }
                                        },
                                        {
                                            property: "address",
                                            getControl: (model) => {
                                                return model.get("id") == null || model.get("status") === DossierStatus.STATUS_QUOTATION.value ? Text : ReadOnlyText
                                            },
                                            //control: Text,
                                            label: M("buildingAddress"),
                                            placeholder: M("buildingAddress"),
                                            size: "col-sm-4",
                                            isRequired: true,
                                        },
                                        {
                                            property: "city",
                                            getControl: (model) => {
                                                return model.get("id") == null || model.get("status") === DossierStatus.STATUS_QUOTATION.value ? Text : ReadOnlyText
                                            },
                                            //control: Text,
                                            label: M("buildingCity"),
                                            placeholder: M("buildingCity"),
                                            size: "col-sm-4",
                                            isRequired: true,
                                        },
                                        {
                                            property: "province",
                                            getControl: (model) => {
                                                return model.get("id") == null || model.get("status") === DossierStatus.STATUS_QUOTATION.value ? Text : ReadOnlyText
                                            },
                                            //control: Text,
                                            label: M("buildingProvince"),
                                            placeholder: M("province"),
                                            size: "col-sm-4",
                                            isRequired: true,
                                        },

                                        valuesSelectContainerField({
                                            property: "operationType",
                                            isRequired: true,
                                            itemLabel: "label",
                                            itemValue: "value",
                                            collection: "dossierOperationType?discriminator=2022_BonusCasa_1",
                                            size: "col-sm-12",
                                            getControl: (model) => {
                                                return model.get("id") == null || model.get("status") === DossierStatus.STATUS_QUOTATION.value ? ValuesSelectContainer : ReadOnlyText
                                            },
                                            formatter(v, model) {
                                                if (model && model.get("id") != null)
                                                    return model.get("priceCalculatorSheet").operationTypeDescription
                                                return  v != null ? v["fullDescription"] : ""
                                            }
                                        }),

                                        valuesSelectContainerField({
                                            property: "estateTypeAndUse",
                                            isRequired: true,
                                            itemLabel: "label",
                                            itemValue: "value",
                                            collection: "estateTypeAndUse",
                                            size: "col-sm-6",
                                            getControl: (model) => {
                                                return model.get("id") == null || model.get("status") === DossierStatus.STATUS_QUOTATION.value ? ValuesSelectContainer : ReadOnlyText
                                            },
                                            formatter(v, model) {
                                                if (model && model.get("id") != null)
                                                    return model.get("priceCalculatorSheet").estateTypeAndUseDescription
                                                return  v != null ? v["fullDescription"] : ""
                                            }
                                        }),

                                        valuesSelectContainerField({
                                            property: "serviceProvided",
                                            isRequired: true,
                                            itemLabel: "label",
                                            itemValue: "value",
                                            collection: "dossierServiceProvided",
                                            size: "col-sm-6",
                                            getControl: (model) => {
                                                return model.get("id") == null || model.get("status") === DossierStatus.STATUS_QUOTATION.value ? ValuesSelectContainer : ReadOnlyText
                                            },
                                            formatter(v, model) {
                                                if (model && model.get("id") != null)
                                                    return model.get("priceCalculatorSheet").serviceProvidedDescription
                                                return  v != null ? v["fullDescription"] : ""
                                            }
                                        }),

                                        {
                                            property: "continuationWork",
                                            label: M("continuationWork"),
                                            getControl: (model) => {
                                                return model.get("id") == null || model.get("status") === DossierStatus.STATUS_QUOTATION.value ? Select : ReadOnlyText
                                            },
                                            size: "col-sm-6",
                                            props: {
                                                allowNull: true,
                                                nullText: M("noAnswer"),
                                                datasource: SelectYesNoDatasource,
                                                formatter: v => {
                                                    return v ? M("yes") : M("no");
                                                }
                                            },
                                        },

                                        {
                                            property: "spacer_continuationWork",
                                            control: Spacer,
                                            size: "col-sm-6",
                                            emptyRow: true,
                                            className: "m-b-10 m-t-10",
                                            visibility: (model) => {
                                                return model.get("continuationWork") == null || parseBoolean(model.get("continuationWork")) === false;
                                            },
                                            props: {
                                                defaultTheme: false,
                                            },
                                        },

                                        {
                                            property: "continuationWorkValue",
                                            getControl: (model) => {
                                                let readonlyComponent = ReadOnlyText;
                                                if (!model)
                                                    return readonlyComponent
                                                return model.get("id") == null || model.get("status") === DossierStatus.STATUS_QUOTATION.value ? Number : readonlyComponent
                                            },
                                            visibility: (model) => {
                                                return model.get("continuationWork") != null && parseBoolean(model.get("continuationWork")) === true;
                                            },
                                            getLabel: (model) => {
                                                return model && model.get("_customer") &&
                                                model.get("_customer").subjectType == CustomerType.SUBJECT_TYPE_PHYSICAL_PERSON.value ? M("continuationWorkValue") : M("continuationWorkValueNoVat")
                                            },
                                            getPlaceholder: (model) => {
                                                return model && model.get("_customer") &&
                                                model.get("_customer").subjectType == CustomerType.SUBJECT_TYPE_PHYSICAL_PERSON.value ? M("continuationWorkValue") : M("continuationWorkValueNoVat")
                                            },
                                            //label: M("continuationWorkValue"),
                                            //placeholder: M("continuationWorkValue"),
                                            size: "col-sm-6",
                                            isRequired: true,
                                            props: {
                                                formatter: v => {
                                                    return formatNumber(v)
                                                }
                                            }
                                        },

                                        {
                                            property: "window",
                                            getControl: (model) => {
                                                let readonlyComponent = ReadOnlyText;
                                                if (!model)
                                                    return readonlyComponent
                                                return model.get("id") == null || model.get("status") === DossierStatus.STATUS_QUOTATION.value ? Number : readonlyComponent
                                            },
                                            visibility: (model) => {
                                                return (isSuperuser() || !model.get("id") || model.get("status") === DossierStatus.STATUS_QUOTATION.value)
                                            },
                                            getLabel: (model) => {
                                                let alternativeLabel = false;
                                                if (model && model.get("priceCalculatorSheet") && model.get("priceCalculatorSheet").operationType){
                                                    let operationType = model.get("priceCalculatorSheet").operationType;
                                                    if((DossierServiceProvided.ONLY_SUPPLY.value == model.get("serviceProvided") &&
                                                        (OperationType.ORDINARY_MAINTENANCE.value == operationType ||
                                                        OperationType.EXTRAORDINARY_MAINTENANCE.value == operationType ||
                                                        OperationType.SELF_DECLARATION.value == operationType  )) ||
                                                        (OperationType.CONSERVATIVE_RESTORATION.value == operationType || OperationType.BUILDING_RENOVATION.value == operationType)){
                                                        alternativeLabel = true;
                                                    }
                                                }
                                                return alternativeLabel ? "Infissi (€)" :"Infissi - Bene Significativo (€)";
                                            },
                                            getPlaceholder: (model) => {
                                                let alternativeLabel = false;
                                                if (model && model.get("priceCalculatorSheet") && model.get("priceCalculatorSheet").operationType){
                                                    let operationType = model.get("priceCalculatorSheet").operationType;
                                                    if((DossierServiceProvided.ONLY_SUPPLY.value == model.get("serviceProvided") &&
                                                        (OperationType.ORDINARY_MAINTENANCE.value == operationType ||
                                                            OperationType.EXTRAORDINARY_MAINTENANCE.value == operationType ||
                                                            OperationType.SELF_DECLARATION.value == operationType  )) ||
                                                        (OperationType.CONSERVATIVE_RESTORATION.value == operationType || OperationType.BUILDING_RENOVATION.value == operationType)){
                                                        alternativeLabel = true;
                                                    }
                                                }
                                                return alternativeLabel ? "Infissi (€)" :"Infissi - Bene Significativo (€)";
                                            },
                                            //label: "Infissi - Bene Significativo (€)",
                                            //placeholder: "Infissi - Bene Significativo (€)",
                                            size: "col-sm-6",
                                            isRequired: false,
                                            props: {
                                                formatter: v => {
                                                    return formatNumber(v)
                                                }
                                            }
                                        },

                                        {
                                            property: "blind",
                                            getControl: (model) => {
                                                let readonlyComponent = ReadOnlyText;
                                                if (!model)
                                                    return readonlyComponent
                                                return model.get("id") == null || model.get("status") === DossierStatus.STATUS_QUOTATION.value ? Number : readonlyComponent
                                            },
                                            visibility: (model) => {
                                                return (isSuperuser() || !model.get("id") || model.get("status") === DossierStatus.STATUS_QUOTATION.value)
                                            },
                                            getLabel: (model) => {
                                                return model != null && model.get("priceCalculatorSheet") != null ? model.get("priceCalculatorSheet").blindLabel : "Oscuranti, Beni NS, Mark-Up (€)";
                                            },
                                            getPlaceholder: (model) => {
                                                return model != null && model.get("priceCalculatorSheet") != null ? model.get("priceCalculatorSheet").blindLabel : "Oscuranti, Beni NS, Mark-Up (€)";
                                            },
                                            size: "col-sm-6",
                                            props: {
                                                formatter: v => {
                                                    return formatNumber(v)
                                                }
                                            }
                                        },
                                        {
                                            property: "installation",
                                            getControl: (model) => {
                                                let readonlyComponent = ReadOnlyText;
                                                if (!model)
                                                    return readonlyComponent
                                                return model.get("id") == null || model.get("status") === DossierStatus.STATUS_QUOTATION.value ? Number : readonlyComponent
                                            },
                                            visibility: (model) => {
                                                return model.get("serviceProvided") === DossierServiceProvided.SUPPLY_AND_INSTALLATION.value && (isSuperuser() || !model.get("id") || model.get("status") === DossierStatus.STATUS_QUOTATION.value)
                                            },
                                            label: "Posa in Opera e Installazione (€)",
                                            placeholder: "Posa in Opera e Installazione (€)",
                                            size: "col-sm-6",
                                            isRequired: true,
                                            props: {
                                                formatter: v => {
                                                    return formatNumber(v)
                                                }
                                            }
                                        },

                                        {
                                            property: "takenMeasures",
                                            getControl: (model) => {
                                                let readonlyComponent = ReadOnlyText;
                                                if (!model)
                                                    return readonlyComponent
                                                return model.get("id") == null || model.get("status") === DossierStatus.STATUS_QUOTATION.value ? Number : readonlyComponent
                                            },
                                            visibility: (model) => {
                                                return model.get("serviceProvided") === DossierServiceProvided.SUPPLY_AND_INSTALLATION.value && (isSuperuser() || !model.get("id") || model.get("status") === DossierStatus.STATUS_QUOTATION.value)
                                            },
                                            getLabel: (model) => {
                                                let timeData = new Date(dateNewLabel).getTime();
                                                return (model && model.get("creationDate") && model.get("creationDate") > timeData)
                                                || (model.get("creationDate") == null && new Date().getTime() > timeData)?
                                                     "Eventuali Altre Spese professionali da ivare al 22%" : "Presa misure/elab tavole/elab dich. tecnica"
                                            },
                                            getPlaceholder: (model) => {
                                                let timeData = new Date(dateNewLabel).getTime();
                                                return (model && model.get("creationDate") && model.get("creationDate") > timeData)
                                                || (model.get("creationDate") == null && new Date().getTime() > timeData)?
                                                    "Eventuali Altre Spese professionali da ivare al 22%" : "Presa misure/elab tavole/elab dich. tecnica"
                                            },
                                            size: "col-sm-6",
                                            isRequired: false,
                                            props: {
                                                formatter: v => {
                                                    return formatNumber(v)
                                                }
                                            }
                                        },

                                        {
                                            property: "acceptServiceCosts",
                                            getControl: (model) => {
                                                return (model.get("id") === null || model.get("status") === DossierStatus.STATUS_QUOTATION.value) ? YesNo : ReadOnlyText
                                            },
                                            label: "Accetta i costi del servizio",
                                            size: "col-sm-6",
                                            isRequired: true,
                                            props: {
                                                formatter(v, model) {
                                                    return "Accettati"
                                                }
                                            }
                                        },
                                        {
                                            property: "workCompletionDate",
                                            getControl: (model) => {
                                                let workCompletionDocuments = _.filter(model.get("documents"), r => WORK_COMPLETION_DOCUMENTS_CODE.includes(r.documentType.code));
                                                return model.get("workCompletionDate") == null || (getDossierStatusPosition(model.get("status")) <= getDossierStatusPosition(DossierStatus.STATUS_TO_CANDIDATE) || (workCompletionDocuments.length > 0 && (workCompletionDocuments[0].status === DocumentStatus.TO_BE_UPLOAD.value || workCompletionDocuments[0].status === DocumentStatus.TO_RECHARGE.value))) ? DateTime : ReadOnlyText;
                                             },
                                            control: DateTime,
                                            label: M("workCompletionDate"),
                                            placeholder: M("workCompletionDate"),
                                            size: "col-sm-6",
                                            emptyRow: true,
                                            visibility: (model) => {
                                                return model.get("id")
                                            },
                                            props: {
                                                formatter: v => {
                                                    return v != null ? moment(v).format("DD/MM/YYYY") : "-";
                                                }
                                            }
                                        },
                                        {
                                            property: "totalCostHigherTenThousand",
                                            control: YesNo,
                                            label: M("totalCostHigherTenThousand"),
                                            size: "col-sm-6",
                                            isRequired: true,
                                            props: {
                                                formatter(v, model) {
                                                    if (v != null)
                                                        return v === true ? "Si" : "No"
                                                    return ""
                                                }
                                            }
                                        },
                                        {
                                            property: "notes",
                                            getControl: model => {
                                                return model.get("id") != null ? TextArea : Text;
                                            },
                                            visibility: (model) => {
                                                return isSuperuser() && model.get("id")
                                            },
                                            label: M("notes"),
                                            placeholder: M("notes"),
                                            size: "col-sm-12",
                                            emptyRow: true,
                                            props: {
                                                maxLength: 1000,
                                                height: "60px"
                                            }
                                        },
                                        {
                                            property: "fabricatorNotes",
                                            getControl: model => {
                                                return model.get("id") != null ? TextArea : Text;
                                            },

                                            label: M("fabricatorNotes"),
                                            placeholder: M("fabricatorNotes"),
                                            size: "col-sm-12",
                                            emptyRow: true,
                                            props: {
                                                maxLength: 1000,
                                                height: "60px"
                                            }
                                        },

                                        {
                                            property: "c_b_1",
                                            control: Column,
                                            size: "col-md-4 col-sm-6",
                                            visibility: (model) => {
                                                return isSuperuser() && model != null && model.get("id") != null && model.get("status") !== DossierStatus.STATUS_QUOTATION.value;
                                            },
                                            noLateralPadding: false,
                                            fields: [
                                                {
                                                    property: "c_b_1_s",
                                                    control: Column,
                                                    size: "col-md-12",
                                                    className: "zero-padding border-bottom m-b-15",
                                                    noLateralPadding: false,
                                                    fields: [
                                                        {
                                                            control: Spacer,
                                                            label: null,
                                                            size: "col-8",
                                                            emptyRow: true,
                                                            props: {
                                                                className: "p-t-9",
                                                                defaultTheme: false,
                                                                content: M("canBeCandidate")
                                                            },
                                                        },
                                                        {
                                                            control: Spacer,
                                                            label: null,
                                                            size: "col-4",
                                                            emptyRow: true,
                                                            props: {
                                                                className: "p-t-9 float-right",
                                                                defaultTheme: false,
                                                                formatter: model => {
                                                                    return model.get("canBeCandidate") ? M("yes") : M("no")
                                                                }
                                                            },
                                                        },
                                                    ]
                                                },
                                            ]
                                        },
                                        {
                                            property: "c_b_2",
                                            control: Column,
                                            size: "col-md-4 col-sm-6",
                                            visibility: (model) => {
                                                return isSuperuser() && model != null && model.get("id") != null && model.get("status") !== DossierStatus.STATUS_QUOTATION.value;
                                            },
                                            noLateralPadding: false,
                                            fields: [
                                                {
                                                    property: "c_b_2_s",
                                                    control: Column,
                                                    size: "col-md-12",
                                                    className: "zero-padding border-bottom m-b-15",
                                                    noLateralPadding: false,
                                                    fields: [
                                                        {
                                                            control: Spacer,
                                                            label: null,
                                                            size: "col-8",
                                                            emptyRow: true,
                                                            props: {
                                                                className: "p-t-7",
                                                                defaultTheme: false,
                                                                content: M("candidateAE")
                                                            },
                                                        },
                                                        {
                                                            property: "candidateAE",
                                                            control: Switch,
                                                            label: null,
                                                            size: "col-4",
                                                            emptyRow: true,
                                                        },
                                                    ]
                                                },
                                            ]
                                        },
                                        {
                                            property: "c_b_3",
                                            control: Column,
                                            size: "col-md-4 col-sm-6",
                                            visibility: (model) => {
                                                return isSuperuser() && model != null && model.get("id") != null && model.get("status") !== DossierStatus.STATUS_QUOTATION.value;
                                            },
                                            noLateralPadding: false,
                                            fields: [
                                                {
                                                    property: "c_b_3_s",
                                                    control: Column,
                                                    size: "col-md-12",
                                                    className: "zero-padding border-bottom m-b-15",
                                                    noLateralPadding: false,
                                                    fields: [
                                                        {
                                                            control: Spacer,
                                                            label: null,
                                                            size: "col-8",
                                                            emptyRow: true,
                                                            props: {
                                                                className: "p-t-7",
                                                                defaultTheme: false,
                                                                content: M("candidateENEA")
                                                            },
                                                        },
                                                        {
                                                            property: "candidateENEA",
                                                            control: Switch,
                                                            label: null,
                                                            size: "col-4",
                                                            emptyRow: true,
                                                        },
                                                    ]
                                                },

                                            ]
                                        },
                                        {
                                            property: "c_b_3",
                                            control: Column,
                                            size: "col-md-4 col-sm-6",
                                            visibility: (model) => {
                                                return isSuperuser() && model != null && model.get("id") != null && model.get("status") !== DossierStatus.STATUS_QUOTATION.value;
                                            },
                                            noLateralPadding: false,
                                            fields: [
                                                {
                                                    property: "c_b_3_s",
                                                    control: Column,
                                                    size: "col-md-12",
                                                    className: "zero-padding border-bottom m-b-15",
                                                    noLateralPadding: false,
                                                    fields: [
                                                        {
                                                            control: Spacer,
                                                            label: null,
                                                            size: "col-8",
                                                            emptyRow: true,
                                                            props: {
                                                                className: "p-t-7",
                                                                defaultTheme: false,
                                                                content: M("communicationENEA")
                                                            },
                                                        },
                                                        {
                                                            property: "communicationENEA",
                                                            control: Switch,
                                                            label: null,
                                                            size: "col-4",
                                                            emptyRow: true,
                                                        },
                                                    ]
                                                },
                                            ]
                                        },
                                        {
                                            property: "c_b_4",
                                            control: Column,
                                            size: "col-md-4 col-sm-6",
                                            visibility: (model) => {
                                                return isSuperuser() && model != null && model.get("id") != null;
                                            },
                                            noLateralPadding: false,
                                            fields: [
                                                {
                                                    property: "c_b_4_s",
                                                    control: Column,
                                                    size: "col-md-12",
                                                    className: "zero-padding border-bottom m-b-15",
                                                    noLateralPadding: false,
                                                    fields: [
                                                        {
                                                            control: Spacer,
                                                            label: null,
                                                            size: "col-8",
                                                            emptyRow: true,
                                                            props: {
                                                                className: "p-t-7",
                                                                defaultTheme: false,
                                                                content: M("priority")
                                                            },
                                                        },
                                                        {
                                                            property: "priority",
                                                            control: Switch,
                                                            label: null,
                                                            size: "col-4",
                                                            emptyRow: true,
                                                        },
                                                    ]
                                                },
                                            ]
                                        },
                                    ]
                                },
                            ]
                        },
                        {
                            property: "column",
                            control: Dossier_2022_bonusCasa_1_priceCalculatorControl,
                            size: "col-md-4 col-sm-6",
                            className: "m-b-10",
                            noLateralPadding: false,
                            useBoostrapRow: true
                        },
                        {
                            property: "c_",
                            control: Column,
                            size: "col-md-8",
                            className: "m-b-10",
                            emptyRow: true,
                            noLateralPadding: false,
                            fields: [
                                {
                                    property: "c_1",
                                    control: Column,
                                    size: "col-sm-12",
                                    className: "card",
                                    noLateralPadding: false,
                                    visibility: (model) => {
                                        return isSuperuser() && model != null && model.get("id") != null && model.get("status") !== DossierStatus.STATUS_QUOTATION.value;
                                    },
                                    fields: [
                                        {
                                            property: "code",
                                            control: Spacer,
                                            label: null,
                                            size: "col-sm-12",
                                            emptyRow: true,
                                            props: {
                                                className: "card-title",
                                                defaultTheme: false,
                                                content: M("kpmgStatus"),
                                            },
                                        },
                                        {
                                            property: "kpmgdocumentGroupsStatus",
                                            control: MultiLineText,
                                            label: null,
                                            size: "col-sm-8",
                                            emptyRow: true,
                                            className: "m-b-10",
                                            props: {
                                                defaultTheme: false,
                                                innerClass: "m-b-5"
                                            },
                                        },
                                        {
                                            property: "_sendForReview",
                                            control: Button,
                                            size: "col-sm-4",
                                            emptyRow: true,
                                            visibility: (model) => {
                                                return isSuperuser() && model != null && model.get("id") != null
                                                    // && model.get("kpmgdossierReviewCompletionDate") == null;
                                            },
                                            props: {
                                                text: M("sendToKPMG"),
                                                className: "btn-orange",
                                                onClick: model => {
                                                    KPMGSendForReview({
                                                        dossierId: model.get("id"),
                                                        dossierType: model.get("type")
                                                    })
                                                }
                                            }
                                        },
                                        {
                                            property: "_kpmgLog",
                                            control: ReadOnlyTextArea,
                                            visibility: (model) => {
                                                return isSuperuser() && model != null && model.get("id") != null;
                                            },
                                            label: "Stato ultimo invio a KPMG",
                                            placeholder: "-",
                                            size: "col-sm-12",
                                            props: {
                                                autoHeight: true

                                            }
                                        },
                                        {
                                            property: "code",
                                            control: Spacer,
                                            label: null,
                                            size: "col-sm-12",
                                            emptyRow: true,
                                            visibility: (model) => {
                                                return isSuperuser() && model != null && model.get("id") != null && model.get("kpmgdossierReviewCompletionDate") != null;
                                            },
                                            props: {
                                                defaultTheme: false,
                                                showBorderBottom: true
                                            },
                                        },
                                        {
                                            property: "kpmgdossierReviewCompletionDate",
                                            control: ReadOnlyText,
                                            visibility: (model) => {
                                                return isSuperuser() && model != null && model.get("id") != null && model.get("kpmgdossierReviewCompletionDate") != null;
                                            },
                                            label: "Data di fine revisione KPMG",
                                            placeholder: "Data di fine revisione KPMG",
                                            size: "col-sm-4",
                                            props: {
                                                formatter: v => {
                                                    return v != null ? moment(v).format("DD/MM/YYYY HH:mm") : moment(new Date()).format("DD/MM/YYYY HH:mm")
                                                }
                                            }
                                        },
                                        {
                                            property: "kpmgdossierAdEProtocolNumber",
                                            control: ReadOnlyText,
                                            visibility: (model) => {
                                                return isSuperuser() && model != null && model.get("id") != null && model.get("kpmgdossierAdEProtocolNumber") != null;
                                            },
                                            label: "N. Protocollo Agenzia delle Entrate",
                                            placeholder: "N. Protocollo Agenzia delle Entrate",
                                            size: "col-sm-4",
                                        },
                                        {
                                            property: "kpmgadeDossierAmount",
                                            control: ReadOnlyText,
                                            visibility: (model) => {
                                                return isSuperuser() && model != null && model.get("id") != null && model.get("kpmgadeDossierAmount") != 0;
                                            },
                                            label: "Ammontare della pratica",
                                            placeholder: "Ammontare della pratica",
                                            size: "col-sm-4",
                                            props: {
                                                formatter: v => {
                                                    return formatNumber(v)
                                                }
                                            }
                                        },
                                    ]
                                }
                            ]
                        }
                    ]
                },


                //FOOTER
                {
                    component: AreaNoCard,
                    className: "zero-padding m-t-10",
                    fields: [
                        {
                            property: "custom_footer",
                            control: Dossier_2022_BonusCasa_1_FormFooter,
                        }
                    ]
                },
                {
                    component: AreaNoCard,
                    className: "zero-padding",
                    visibility: model => {
                        return model.get("id") != null && model.get("status") !== DossierStatus.STATUS_QUOTATION.value;
                    },
                    fields: [
                        {
                            property: "documents",
                            control: DossierDocumentContainer,
                            label: null,
                            size: "col-sm-12",
                        }
                    ]
                },
            ]
        }
    }
}
