import React from "react";
import {EntitiesStore} from "../../../stores/entities";
import {connectDiscriminated} from "../../utils/aj";
import AbstractEntitiesGrid from "./abstractEntitiesGrid";
import {updateQuery} from "../../../actions/entities";

export default class EntitiesGrid extends AbstractEntitiesGrid {
    constructor(props) {
        super(props)


        this.discriminator = "entity_grid_" + this.getEntity()

        let _query = this.getInitialQuery();

        this.state = {grid: null, result: null, query: _query}
        this.state.query.on("change", () => {
            updateQuery({discriminator: this.discriminator, query: this.state.query});
            this.onQueryChanged()
        })

        connectDiscriminated(this.discriminator, this, [EntitiesStore])
    }
}

