import React from "react";
import ReactDOM from "react-dom";
import {Index} from "./components/layout";
import Login from "./screens/login";
import Register from "./screens/register";
import Recover from "./screens/recover";
import RegistrationOk from "./screens/registrationOk";
import Confirm from "./screens/confirm";
import * as ui from "./utils/ui";
import * as plugins from "./pluginsimpl";
import {resumeSession} from "../actions/session";
import * as keyboard from "./utils/keyboard";
import {SessionStore} from "../stores/session";
import {DocumentTypeGrid, DossierGrid, EntitiesGrid, EntityForm, RevisionGrid} from "./screens/entities";
import {getUserProfileImage} from "../actions/ui";
import {setupMenu} from "../actions/menu";
import * as NotificationCenter from "../utils/notificationCenter";
import DossierForm from "./components/dossiers/dossierForm";
import Home from "./screens/home";
import Statistics from "./screens/statistics";
import QuestionnaireScreen from "./screens/questionnaire";
import {DOSSIER_TYPE, getDossierEntityNameFromType} from "../model/vars";
import SelectCustomerScreen from "./screens/selectCustomer";
import Dossier_2021_BonusCasa_Form from "./components/dossier_2021_bonusCasa/dossier_2021_bonusCasa_Form";
import Dossier_2022_Form from "./components/dossiers_2022/dossier_2022_Form";
import Dossier_2022_BonusCasa_1_Form
	from "./components/dossier_2022_bonusCasa_1/dossier_2022_bonusCasa_1_Form";
import Dossier_2022_BonusCasa_2_Form from "./components/dossier_2022_bonusCasa_2/dossier_2022_bonusCasa_2_Form";
import Dossier_2023_75_Form from "./components/dossier2023_75/dossier_2023_75_Form";

export default function main() {
	/* Register plugins */
	plugins.register()

	/* Admin routes */
    ui.addRoute("/entities/dossier/list/:dossierType", params => ui.changeScreen(<DossierGrid key={params.entity + "_" + params.dossierType} type={params.dossierType} entity={getDossierEntityNameFromType(params.dossierType)}/>))
	ui.addRoute("/entities/"+ getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2019)  + "/:entityId", params => ui.changeScreen(<DossierForm type={DOSSIER_TYPE.ECOBONUS_2019} entity={getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2019)} entityId={params.entityId} params={params}/>))
	ui.addRoute("/entities/"+ getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2020_50) + "/:entityId", params => ui.changeScreen(<DossierForm type={DOSSIER_TYPE.ECOBONUS_2020_50} entity={getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2020_50)} entityId={params.entityId} params={params}/>))
	ui.addRoute("/entities/"+ getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2021_50) + "/:entityId", params => ui.changeScreen(<DossierForm type={DOSSIER_TYPE.ECOBONUS_2021_50} entity={getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2021_50)} entityId={params.entityId} params={params}/>))
	ui.addRoute("/entities/"+ getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2022_1) + "/:entityId", params => ui.changeScreen(<DossierForm type={DOSSIER_TYPE.ECOBONUS_2022_1} entity={getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2022_1)} entityId={params.entityId} params={params}/>))
	ui.addRoute("/entities/"+ getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2021_BONUS_CASA) + "/:entityId", params => ui.changeScreen(<Dossier_2021_BonusCasa_Form type={DOSSIER_TYPE.ECOBONUS_2021_BONUS_CASA} entity={getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2021_BONUS_CASA)} entityId={params.entityId} params={params} key={getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2021_BONUS_CASA) +"_" + params.entityId}/>))
	ui.addRoute("/entities/"+ getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2022_BONUS_CASA_1) + "/:entityId", params => ui.changeScreen(<Dossier_2022_BonusCasa_1_Form type={DOSSIER_TYPE.ECOBONUS_2022_BONUS_CASA_1} entity={getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2022_BONUS_CASA_1)} entityId={params.entityId} params={params} key={getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2022_BONUS_CASA_1) +"_" + params.entityId}/>))
	ui.addRoute("/entities/"+ getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2022_BONUS_CASA_2) + "/:entityId", params => ui.changeScreen(<Dossier_2022_BonusCasa_2_Form type={DOSSIER_TYPE.ECOBONUS_2022_BONUS_CASA_1} entity={getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2022_BONUS_CASA_2)} entityId={params.entityId} params={params} key={getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2022_BONUS_CASA_2) +"_" + params.entityId}/>))
	ui.addRoute("/entities/"+ getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2022_BONUS_CASA) + "/:entityId", params => ui.changeScreen(<Dossier_2022_Form type={DOSSIER_TYPE.ECOBONUS_2022_BONUS_CASA} entity={getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2022_BONUS_CASA)} entityId={params.entityId} params={params} key={getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2022_BONUS_CASA) +"_" + params.entityId}/>))
	ui.addRoute("/entities/"+ getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2022_50) + "/:entityId", params => ui.changeScreen(<Dossier_2022_Form type={DOSSIER_TYPE.ECOBONUS_2022_50} entity={getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2022_50)} entityId={params.entityId} params={params} key={getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2022_50) +"_" + params.entityId}/>))
	ui.addRoute("/entities/"+ getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2023_75) + "/:entityId", params => ui.changeScreen(<Dossier_2023_75_Form type={DOSSIER_TYPE.ECOBONUS_2023_75} entity={getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2023_75)} entityId={params.entityId} params={params} key={getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2023_75) +"_" + params.entityId}/>))

	ui.addRoute("/entities/documentType", params => ui.changeScreen(<DocumentTypeGrid key={params.entity} entity="documentType"/>))
	// ui.addRoute("/entities/documentType/:entityId", params => ui.changeScreen(<DocumentForm key={params.entity} entity="documentType" entityId={params.entityId} params={params}/>))
	ui.addRoute("/dashboard/entities/:dashboardEntity", params => ui.changeScreen(<EntitiesGrid key={params.dashboardEntity} entity={params.dashboardEntity} />))
	ui.addRoute("/entities/:entity", params => ui.changeScreen(<EntitiesGrid key={params.entity} entity={params.entity} />))
	ui.addRoute("/entities/:entity/:entityId", params => ui.changeScreen(<EntityForm key={params.entity} entity={params.entity} entityId={params.entityId} params={params}/>))
	ui.addRoute("/:entity/:entityId", params => ui.changeScreen(<EntityForm key={params.entity} entity={params.entity} entityId={params.entityId} params={params}/>))
	ui.addRoute("/entities/single/:entity", params => ui.changeScreen(<EntityForm key={params.entity} entity={params.entity} entityId="_" params={params}/>))
	ui.addRoute("/revision/:entity/:entityId", params => ui.changeScreen(<RevisionGrid key={params.entity} entityId={params.entityId}  entity={params.entity} params={params} />))
	ui.addRoute("/recover", params => ui.changeScreen(<Recover />))
	ui.addRoute("/statistics", params => ui.changeScreen(<Statistics />))
	ui.addRoute("/customerSelection/:dossierType", params => ui.changeScreen(<SelectCustomerScreen dossierType={params.dossierType} />))
	ui.addRoute("/questionnaire/:dossierType", params => ui.changeScreen(<QuestionnaireScreen {...params} />))

	/* Account routes */
	ui.addRoute("/login", params => ui.changeScreen(<Login />))
	ui.addRoute("/register", params => ui.changeScreen(<Register />))
	ui.addRoute("/recover", params => ui.changeScreen(<Recover />))
	ui.addRoute("/registrationComplete", params => ui.changeScreen(<RegistrationOk />))

	ui.addRoute("/confirm", params => ui.changeScreen(<Confirm activationCode={params.activationCode}/>))

	/* home route */
	ui.addRoute("/", params => ui.changeScreen(<Home />))

	/* Attach keyboard for global key bindings */
	keyboard.attach()

	/* render main index page into dom */
	ReactDOM.render(<Index />, document.getElementById("entry-point"))

	/** session actions catching */
	NotificationCenter.addObserver("login", (user) => {
		getUserProfileImage();
		setupMenu({user});
	})

	/* Avoid going in screens that require login before trying session resume */
	let owner = {}
	SessionStore.subscribe(owner, state => {
	    if (state.resumeComplete) {
	        SessionStore.unsubscribe(owner)
	        ui.startNavigation()
	    }
	})

	/* automatic login, if possible */
	resumeSession()
}
