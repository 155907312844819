"use strict"


import React from "react";
import {
    approve,
    candidate,
    commit,
    confirmQuotation,
    confirmQuotation_2020_50,
    confirmQuotation_2021_50, confirmQuotation_2022_1, giureEvaluation,
    payOff,
    quotation,
    refuse
} from "../../../actions/dossier";
import {
    AssignationType,
    DOSSIER_TYPE,
    DossierStatus,
    getDossierStatusDescription,
    getDossierStatusPosition, GiureDossierStatus
} from "../../../model/vars";
import {format, safeGet} from "../../../utils/lang";
import _ from "underscore";
import M from "../../../strings";
import {isGiureUser, isSuperuser} from "../../../api/session";
import {generateDossierDTOFromData} from "./utils";
import {
    confirmQuotation_2021_BonusCasa,
} from "../../../actions/dossier_2021_bonusCasa";
import {confirmQuotation_2022} from "../../../actions/dossier_2022";
import {confirmQuotation_2022_BonusCasa_1} from "../../../actions/dossier_2022_bonusCasa";
import {confirmQuotation_2022_BonusCasa_2} from "../../../actions/dossier_2022_2_bonusCasa";
import {evaluation} from "../../../api/dossier";


export class DossierStatusComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    changeState(newState) {
        let dossierId = safeGet(this.props.data, "id", null);
        let type = safeGet(this.props.data, "type", null);
        let currentStatus = safeGet(this.props.data, "status", null);
        let candidateAE = safeGet(this.props.data, "candidateAE", null);
        let candidateENEA = safeGet(this.props.data, "candidateENEA", null);
        let callback;
        let showDialog = false;
        let dialogText = format(M("confirmChangeDossier"), getDossierStatusDescription(currentStatus));
        if (newState === currentStatus){
            return;
        }
        if ((currentStatus === DossierStatus.STATUS_APPROVED.value && getDossierStatusPosition(newState) < getDossierStatusPosition(DossierStatus.STATUS_APPROVED.value)) || currentStatus === DossierStatus.STATUS_PAY_OFF.value || currentStatus === DossierStatus.STATUS_REFUSED.value){
            showDialog = true;
        }

        if (newState !== DossierStatus.STATUS_REFUSED.value && getDossierStatusPosition(currentStatus) <= getDossierStatusPosition(DossierStatus.STATUS_TO_CANDIDATE.value) && getDossierStatusPosition(newState) > getDossierStatusPosition(DossierStatus.STATUS_TO_CANDIDATE.value) && (!candidateAE || !candidateENEA)){
            showDialog = true;
            dialogText = M("confirmChangeDossierWithChangeFlag");
        }

        switch (newState) {
            case DossierStatus.STATUS_GIURE_EVALUATION.value:
                callback = ()=>giureEvaluation({
                    dossierId: dossierId,
                    dossierType: type
                })
                break;
            case DossierStatus.STATUS_QUOTATION.value:
                callback = ()=>quotation({
                    dossierId: dossierId,
                    dossierType: type
                })
                break;
            case DossierStatus.STATUS_DRAFT.value:
                let dossierDTO = generateDossierDTOFromData(this.props.data);
                if (type === DOSSIER_TYPE.ECOBONUS_2021_BONUS_CASA) {
                    callback = ()=>confirmQuotation_2021_BonusCasa({
                        dossierType: type,
                        dossierDTO: dossierDTO,
                    })
                } else if (type === DOSSIER_TYPE.ECOBONUS_2022_BONUS_CASA_1) {
                    callback = ()=>confirmQuotation_2022_BonusCasa_1({
                        dossierType: type,
                        dossierDTO: dossierDTO,
                    })
                } else if (type === DOSSIER_TYPE.ECOBONUS_2022_BONUS_CASA_2) {
                    callback = ()=>confirmQuotation_2022_BonusCasa_2({
                        dossierType: type,
                        dossierDTO: dossierDTO,
                    })
                } else if (type === DOSSIER_TYPE.ECOBONUS_2020_50) {
                    callback = ()=>confirmQuotation_2020_50({
                        dossierType: type,
                        dossierDTO: dossierDTO,
                    });
                } else if (type === DOSSIER_TYPE.ECOBONUS_2021_50){
                    callback = () => confirmQuotation_2021_50({
                        dossierId: dossierId,
                        dossierType: type,
                        dossierDTO: dossierDTO,
                    });
                } else if (type === DOSSIER_TYPE.ECOBONUS_2022_1){
                    callback = () => confirmQuotation_2022_1({
                        dossierId: dossierId,
                        dossierType: type,
                        dossierDTO: dossierDTO,
                    });
                } else if (type === DOSSIER_TYPE.ECOBONUS_2022_BONUS_CASA || type === DOSSIER_TYPE.ECOBONUS_2022_50)  {
                    callback = () => confirmQuotation_2022({
                        dossierType: type,
                        dossierDTO: dossierDTO,
                    })
                }
                else {
                    callback = () => confirmQuotation({
                        dossierId: dossierId,
                        dossierType: type
                    });
                }
                break;
            case DossierStatus.STATUS_TO_CANDIDATE.value:
                callback = ()=>commit({
                    dossierId: dossierId,
                    dossierType: type
                });
                break;
            case DossierStatus.STATUS_CANDIDATED.value:
                callback = ()=>candidate({
                    dossierId: dossierId,
                    dossierType: type
                });
                break;
            case DossierStatus.STATUS_APPROVED.value:
                callback = ()=>approve({
                    dossierId: dossierId,
                    dossierType: type
                });
                break;
            case DossierStatus.STATUS_PAY_OFF.value:
                callback = ()=>payOff({
                    dossierId: dossierId,
                    dossierType: type
                });
                break;
            case DossierStatus.STATUS_REFUSED.value:
                callback = ()=>refuse({
                    dossierId: dossierId,
                    dossierType: type
                });
                break;
        }
        if (showDialog) {
            swal({title: M("confirm"), text: dialogText, showCancelButton: true})
                .then(res => {
                    if (res.value) {
                        callback();
                    }
                })
        } else {
            callback();
        }
    }

    getColorByStatus(status){
        switch (status) {
            case DossierStatus.STATUS_GIURE_EVALUATION.value:
                return "#03BB85";
            case DossierStatus.STATUS_QUOTATION.value:
                return "#F2981A";
            case DossierStatus.STATUS_DRAFT.value:
                return "#116EC7";
            case DossierStatus.STATUS_TO_CANDIDATE.value:
                return "#116EC7";
            case DossierStatus.STATUS_CANDIDATED.value:
                return "#063868";
            case DossierStatus.STATUS_APPROVED.value:
                return "#063868";
            case DossierStatus.STATUS_PAY_OFF.value:
                return "#00C634";
            case DossierStatus.STATUS_REFUSED.value:
                return "#FF535A";
        }
    }

    /*STATUS_DRAFT: {
        label: M("draft"),
        value: "draft"
    },
    STATUS_TO_CANDIDATE: {
        label: M("toCandidate"),
        value: "to-candidate"
    },
    STATUS_CANDIDATED: {
        label: M("candidated"),
        value: "candidated"
    },
    STATUS_APPROVED: {
        label: M("approved"),
        value: "approved"
    },
    STATUS_PAY_OFF: {
        label: M("payOff"),
        value: "pay-off"
    },*/

    getDropdownItems(status) {
        let items = [];
        let size = _.size(DossierStatus);
        let index = 0;
        let color = this.getColorByStatus(status);
        const filteredDossierStatus = {};

        for (const key in DossierStatus) {
            if (key !== 'STATUS_DRAFT' && key !== 'STATUS_TO_CANDIDATE' && key !== 'STATUS_CANDIDATED' && key !== 'STATUS_APPROVED' && key !== 'STATUS_PAY_OFF') {
                filteredDossierStatus[key] = DossierStatus[key];
            }
        }
        const dossierStatus = isGiureUser() ? filteredDossierStatus : DossierStatus
        _.each((dossierStatus), d => {
            let hasStatus = getDossierStatusPosition(status) >= getDossierStatusPosition(d.value);
            let circleClassName = "circle-status float-left ";
            let statusTextStyle = {
                padding: "12px 8px",
                color: hasStatus ? color : "#AAAAAA"
            };
            items.push(
                <div className="dropdown-item cursor-pointer" onClick={this.changeState.bind(this, d.value)} style={{display: "inline-flex", paddingTop: "0px", paddingBottom: "0px", background: hasStatus ? "#FFFFFF" : "#F4F4F4", height: "48px", marginTop: d.value === DossierStatus.STATUS_REFUSED.value ? "10px" : "0"}}>
                    <div className={circleClassName} style={{paddingTop: "0px"}}>
                        {index !== 0 && d.value !== DossierStatus.STATUS_REFUSED.value && <div style={{width: "2px", height: "8px", backgroundColor: hasStatus ? color : "#AAAAAA", display: "block", margin: "0 auto"}}/>}
                        <button style={{marginTop: index === 0 || d.value === DossierStatus.STATUS_REFUSED.value ? "8px" : "0", backgroundColor: hasStatus ? color : "#AAAAAA"}} onClick={(e)=>{e.preventDefault()}}>
                            <i style={{color: hasStatus ? "#FFFFFF" : "#F4F4F4"}} className="zmdi zmdi-check" />
                        </button>
                        {index !== (size-1) && d.value !== DossierStatus.STATUS_PAY_OFF.value && d.value !== DossierStatus.STATUS_REFUSED.value && <div style={{width: "2px", height: "8px", backgroundColor: hasStatus ? color : "#AAAAAA", display: "block", margin: "0 auto"}}/> }
                    </div>
                    <span className="text-uppercase status-text" style={statusTextStyle}>{d.label}</span>
                </div>
            )
            index++;
        })
        if (isGiureUser() && (status === DossierStatus.STATUS_DRAFT.value || status === DossierStatus.STATUS_TO_CANDIDATE.value || status === DossierStatus.STATUS_CANDIDATED || status === DossierStatus.STATUS_APPROVED || status === DossierStatus.STATUS_PAY_OFF))
            items = [];
        return items;
    }

    getDraftWidth(){
        let data = this.props.data;
        let status = safeGet(data, "status", null);
        let documents = safeGet(data, "documents", []);
        let preparatoryDocuments = _.filter(documents, d=> (d.documentType.assignationType === AssignationType.PREPARATORY_DOCUMENTATION.value || d.documentType.assignationType === AssignationType.ENEA_PREPARATORY_DOCUMENTATION.value));

        if (getDossierStatusPosition(status) > getDossierStatusPosition(DossierStatus.STATUS_DRAFT.value)){
            return "100%";
        }
        return ((100 * _.filter(preparatoryDocuments, d=> d.file != null && d.valid).length)/preparatoryDocuments.length) + "%";
    }

    render() {
        let data = this.props.data;
        let status = safeGet(data, "status", null);
        let color = this.getColorByStatus(status);
        let statusStyle = {
            color: color,
            marginLeft: "2px"
        };

        let statusPosition = getDossierStatusPosition(status);

        let draftWidth = this.getDraftWidth();

        let dropdownItems = this.getDropdownItems(status);

        return(
            <div className="dropdown cursor-pointer">
                {/*<a className="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">*/}
                <div className="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <div className="row">
                        <div className="col-10">
                            <div className="col-12 float-right">
                                <span className="text-uppercase status-text" style={statusStyle}>{getDossierStatusDescription(status)}</span>
                            </div>
                            <div className="col-12" style={{display: "flex", marginTop: "5px"}}>
                                <div className="rounded-line" style={{backgroundColor: color, width: "20%"}} />
                                <div style={{width: "60%", position: "relative", marginRight: "4px"}}>
                                    <div className="rounded-line" style={{width: "100%", position: "absolute"}} />
                                    <div className="rounded-line" style={{backgroundColor: color, width: draftWidth, position: "absolute"}} />
                                </div>
                                <div className="rounded-line" style={{backgroundColor: statusPosition >= getDossierStatusPosition(DossierStatus.STATUS_TO_CANDIDATE.value) ? color : "#d7d7d7", width: "5%"}} />
                                <div className="rounded-line" style={{backgroundColor: statusPosition >= getDossierStatusPosition(DossierStatus.STATUS_CANDIDATED.value) ? color : "#d7d7d7", width: "5%"}} />
                                <div className="rounded-line" style={{backgroundColor: statusPosition >= getDossierStatusPosition(DossierStatus.STATUS_APPROVED.value) ? color : "#d7d7d7", width: "5%"}} />
                                <div className="rounded-line" style={{backgroundColor: statusPosition >= getDossierStatusPosition(DossierStatus.STATUS_PAY_OFF.value) || statusPosition >= getDossierStatusPosition(DossierStatus.STATUS_REFUSED.value) ? color : "#d7d7d7", width: "5%"}} />
                            </div>
                        </div>
                        {
                           (isSuperuser() || (isGiureUser() && statusPosition !== 2 && statusPosition !== 3 && statusPosition !== 4 && statusPosition !== 5 && statusPosition !== 6)) && <div className="col-2">
                                <i style={{fontSize: "24px", marginTop: "5px"}} className="zmdi zmdi-caret-down" />
                            </div>
                        }

                    </div>
                </div>
                {(isSuperuser() || (isGiureUser())) && <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {dropdownItems}
                </div>}

            </div>
        )
    }


}
