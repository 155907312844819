import _ from "underscore";
import M from "../strings";
import {TextCell} from "./components/grids";

const entities = {

    dossier: {
        grid: {
            title: "Cruscotto pratiche",
            descriptor: {
                columns: [
                    {property: "code", header: M("code"), cell: TextCell, sortable: false, searchable: false},
                    {property: "fabricatorName", header: M("fabricator"), cell: TextCell, sortable: false, searchable: false},
                    {property: "customerName", header: M("customer"), cell: TextCell, sortable: false, searchable: false},
                    {property: "totalAmountDescription", header: "Spesa complessiva", cell: TextCell, sortable: false, searchable: false},
                    {property: "totalDecreaseDescription", header: "Detrazione spettante", cell: TextCell, sortable: false, searchable: false},
                    {property: "region", header: M("region"), cell: TextCell, sortable: false, searchable: false},
                    {property: "province", header: M("province"), cell: TextCell, sortable: false, searchable: false},
                    {property: "agentName", header: "Agente", cell: TextCell, sortable: false, searchable: false},
                    {property: "supervisorName", header: "Capo zona", cell: TextCell, sortable: false, searchable: false},
                    {property: "status", header: M("status"), cell: TextCell, sortable: false, searchable: false}
                ]
            }
        }
    },

    supervisor: {
        grid: {
            title: "Cruscotto capi zona",
            descriptor: {
                columns: [
                    {property: "name", header: M("supervisor"), cell: TextCell, sortable: false, searchable: false},
                    {property: "dossierCounter", header: M("dossierCounter"), cell: TextCell, sortable: false, searchable: false},
                    {property: "totalAmountDescription", header: M("totalAmount"), cell: TextCell, sortable: false, searchable: false},
                    {property: "totalDecreaseDescription", header: M("totalDecrease"), cell: TextCell, sortable: false, searchable: false}
                ]
            }
        }
    },

    agent: {
        grid: {
            title: "Cruscotto agenti",
            descriptor: {
                columns: [
                    {property: "name", header: M("agent"), cell: TextCell, sortable: false, searchable: false},
                    {property: "dossierCounter", header: M("dossierCounter"), cell: TextCell, sortable: false, searchable: false},
                    {property: "totalAmountDescription", header: M("totalAmount"), cell: TextCell, sortable: false, searchable: false},
                    {property: "totalDecreaseDescription", header: M("totalDecrease"), cell: TextCell, sortable: false, searchable: false}
                ]
            }
        }
    },

    region: {
        title: "Cruscotto regioni",
        grid: {
            descriptor: {
                columns: [
                    {property: "name", header: M("region"), cell: TextCell, sortable: false, searchable: false},
                    {property: "dossierCounter", header: M("dossierCounter"), cell: TextCell, sortable: false, searchable: false},
                    {property: "totalAmountDescription", header: M("totalAmount"), cell: TextCell, sortable: false, searchable: false},
                    {property: "totalDecreaseDescription", header: M("totalDecrease"), cell: TextCell, sortable: false, searchable: false}
                ]
            }
        }
    },

    province: {
        grid: {
            title: "Cruscotto province",
            descriptor: {
                columns: [
                    {property: "name", header: M("province"), cell: TextCell, sortable: false, searchable: false},
                    {property: "dossierCounter", header: M("dossierCounter"), cell: TextCell, sortable: false, searchable: false},
                    {property: "totalAmountDescription", header: M("totalAmount"), cell: TextCell, sortable: false, searchable: false},
                    {property: "totalDecreaseDescription", header: M("totalDecrease"), cell: TextCell, sortable: false, searchable: false}
                ]
            }
        }
    },

    fabricator: {
        grid: {
            title: "Cruscotto serramentisti",
            descriptor: {
                columns: [
                    {property: "name", header: M("fabricator"), cell: TextCell, sortable: false, searchable: false},
                    {property: "dossierCounter", header: M("dossierCounter"), cell: TextCell, sortable: false, searchable: false},
                    {property: "totalAmountDescription", header: M("totalAmount"), cell: TextCell, sortable: false, searchable: false},
                    {property: "totalDecreaseDescription", header: M("totalDecrease"), cell: TextCell, sortable: false, searchable: false}
                ]
            }
        }

    }
}


export default entities
