

"use strict";

import * as config from "../framework/config";
import {get, post, postJson} from "./utils";
import {DOSSIER_TYPE} from "../model/vars";

export function generateQuotationDossier_2021_50(dto) {
    return postJson(config.get("dossier.url") + "/generateQuotation/" + DOSSIER_TYPE.ECOBONUS_2021_50, dto)
}

export function generateQuotationDossier_2022_1(dto) {
    return postJson(config.get("dossier.url") + "/generateQuotation/" + DOSSIER_TYPE.ECOBONUS_2022_1, dto)
}

export function generateQuotationDossier_2020_50(dto) {
    return postJson(config.get("dossier.url") + "/generateQuotation/" + DOSSIER_TYPE.ECOBONUS_2020_50, dto)
}

export function updateQuestionnaire(dossierDTO, dossierType) {
    return postJson(config.get("dossier.url") + "/updateQuestionnaire/" + dossierType, dossierDTO)
}

export function editDossier_2020_50(dto) {
    return postJson(config.get("dossier.url") + "/edit/" + DOSSIER_TYPE.ECOBONUS_2020_50, dto)
}

export function editDossier_2021_50(dto) {
    return postJson(config.get("dossier.url") + "/edit/" + DOSSIER_TYPE.ECOBONUS_2021_50, dto)
}

export function editDossier_2022_1(dto) {
    return postJson(config.get("dossier.url") + "/edit/" + DOSSIER_TYPE.ECOBONUS_2022_1, dto)
}

export function generateQuestionnaireDossier_2020_50(dto) {
    return postJson(config.get("dossier.url") + "/generateQuestionnaire/" + DOSSIER_TYPE.ECOBONUS_2020_50, dto)
}

export function generateQuestionnaireDossier_2021_50(dto) {
    return postJson(config.get("dossier.url") + "/generateQuestionnaire/" + DOSSIER_TYPE.ECOBONUS_2021_50, dto)
}

export function generateQuestionnaireDossier_2022_1(dto) {
    return postJson(config.get("dossier.url") + "/generateQuestionnaire/" + DOSSIER_TYPE.ECOBONUS_2022_1, dto)
}

export function createDossier_2020_50(dto) {
    return postJson(config.get("dossier.url") + "/create/" + DOSSIER_TYPE.ECOBONUS_2020_50, dto)
}

export function createDossier_2021_50(dto) {
    return postJson(config.get("dossier.url") + "/create/" + DOSSIER_TYPE.ECOBONUS_2021_50, dto)
}

export function createDossier_2022_1(dto) {
    return postJson(config.get("dossier.url") + "/create/" + DOSSIER_TYPE.ECOBONUS_2022_1, dto)
}

export function createDossier(type, customerId, fabricatorId, fabricatorCollaboratorId, significantValue, nonSignificantValue, serviceValue, notes, serviceFeeInvoiced) {
    return post(config.get("dossier.url") + "/quotation", {type, customerId, fabricatorId, fabricatorCollaboratorId, significantValue, nonSignificantValue, serviceValue, notes, serviceFeeInvoiced})
}

export function editDossier(dossierId, customerId, fabricatorId, fabricatorCollaboratorId, significantValue, nonSignificantValue, serviceValue, notes, serviceFeeInvoiced, candidateAE, candidateENEA) {
    return post(config.get("dossier.url") + "/" + dossierId + "/edit", {customerId, fabricatorId, fabricatorCollaboratorId, significantValue, nonSignificantValue, serviceValue, notes, serviceFeeInvoiced, candidateAE, candidateENEA})
}

export function attachDocument(dossierId, dossierType, documentTypeId, path) {
    return post(config.get("dossier.url") + "/" + dossierId + "/attachPath/" + documentTypeId, {path, dossierType})
}

export function approveDocument(dossierId, dossierType, documentTypeId) {
    return post(config.get("dossier.url") + "/" + dossierId + "/approve/" + documentTypeId, {dossierType})
}

export function approveGiureDocument(dossierId, dossierType, documentTypeId) {
    return post(config.get("dossier.url") + "/" + dossierId + "/approveGiure/" + documentTypeId, {dossierType})
}

export function refuseDocument(dossierId, dossierType, documentTypeId, refuseReason) {
    return post(config.get("dossier.url") + "/" + dossierId + "/refuse/" + documentTypeId, {refuseReason, dossierType})
}

export function refuseGiureDocument(dossierId, dossierType, documentTypeId, refuseReason) {
    return post(config.get("dossier.url") + "/" + dossierId + "/refuseGiure/" + documentTypeId, {refuseReason, dossierType})
}

export function clearDocument(dossierId, dossierType, documentTypeId) {
    return post(config.get("dossier.url") + "/" + dossierId + "/clear/" + documentTypeId, {dossierType})
}

export function clearGiureDocument(dossierId, dossierType, documentTypeId) {
    return post(config.get("dossier.url") + "/" + dossierId + "/clearGiure/" + documentTypeId, {dossierType})
}

export function quotation(dossierId, dossierType) {
    return post(config.get("dossier.url") + "/" + dossierId + "/quotation", {dossierType})
}

export function evaluation(dossierId, dossierType) {
    return post(config.get("dossier.url") + "/" + dossierId + "/evaluation", {dossierType})
}


export function generateQuotationAmounts(data) {
    return get(config.get("dossier.url") + "/" + data.dossierType + "/quotation/amounts", data)
}

export function confirmQuotation(dossierId, dossierType, acceptServiceCosts = false, dto) {
    return post(config.get("dossier.url") + "/" + dossierId + "/confirmQuotation", dto)
}

export function confirmQuotation_2020_50(dto) {
    return postJson(config.get("dossier.url") + "/confirmQuotation/" + DOSSIER_TYPE.ECOBONUS_2020_50, dto)
}

export function confirmQuotation_2021_50(dto) {
    return postJson(config.get("dossier.url") + "/confirmQuotation/" + DOSSIER_TYPE.ECOBONUS_2021_50, dto)
}

export function confirmQuotation_2022_1(dto) {
    return postJson(config.get("dossier.url") + "/confirmQuotation/" + DOSSIER_TYPE.ECOBONUS_2022_1, dto)
}

export function commit(dossierId, dossierType) {
    return post(config.get("dossier.url") + "/" + dossierId + "/commit", {dossierType: dossierType})
}

export function candidate(dossierId, dossierType) {
    return post(config.get("dossier.url") + "/" + dossierId + "/candidate", {dossierType: dossierType})
}

export function approve(dossierId, dossierType) {
    return post(config.get("dossier.url") + "/" + dossierId + "/approve", {dossierType: dossierType})
}

export function payOff(dossierId, dossierType) {
    return post(config.get("dossier.url") + "/" + dossierId + "/payOff", {dossierType: dossierType})
}

export function refuse(dossierId, dossierType) {
    return post(config.get("dossier.url") + "/" + dossierId + "/refuse", {dossierType: dossierType})
}

export function questionnaireForDossier(dossierId, dossierType) {
    return get(config.get("dossier.url")  + "/" + dossierType + "/" + dossierId + "/questionnaireArtifact")
}

export function questionnaire(dossierType) {
    return get(config.get("dossier.url") + "/questionnaire/" + dossierType)
}

export function uploadRequiredPolicy(path) {
    return post(config.get("dossier.url") + "/uploadRequiredPolicy", {path})
}

export function calculateActualVat(customerType, desiredVat) {
    return get(config.get("dossier.url") + "/" + DOSSIER_TYPE.ECOBONUS_2020_50 + "/quotation/actualVat", {customerType, desiredVat})
}

export function calculateInvoiceVat(customerType, vat10, vat22) {
    return get(config.get("dossier.url") + "/" + DOSSIER_TYPE.ECOBONUS_2020_50 + "/quotation/vatCalculator", {customerType, vat10, vat22})
}

export function getPriceCalculatorSheet(customerType, desiredVat, vat10, vat22, vat, calculatedVat) {
    return get(config.get("dossier.url") + "/" + DOSSIER_TYPE.ECOBONUS_2020_50 + "/quotation/finalValues", {customerType, desiredVat, vat10, vat22, vat, calculatedVat})
}

export function simulateFinancing(customerType, priceCalculatorSheet) {
    return postJson(config.get("dossier.url") + "/" + DOSSIER_TYPE.ECOBONUS_2020_50 + "/quotation/simulateFinancing", {customerType, priceCalculatorSheet})
}
