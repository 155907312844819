"use strict"

import * as aj from "../aj/index";
import {completed, failed} from "../utils/ajex";
import * as actions from "../actions/types";
import _ from "underscore";
import {NOTIFICATION} from "./types";


export const NotificationStore = aj.createStore(NOTIFICATION, (state = {notifications: [],  open: false}, action) => {

    switch (action.type) {
        case actions.LIST_NOTIFICATION:
            return _.assign(state, {notifications: []});

        case completed(actions.LIST_NOTIFICATION):
            return _.assign(state, { notifications: action.notifications, error: false});

        case failed(actions.LIST_NOTIFICATION):
            return _.assign(state, { notifications: [], error: true});

        case completed(actions.COUNT_UNREAD):
            return _.assign(state, { unreadCount: action.unreadCount });

        case actions.TOGGLE_OPEN:
            return _.assign(state, { open: action.open });
    }

});
