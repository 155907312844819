import {DateCell, Grid, resultToGridData} from "../../components/grids";
import {HeaderBlock} from "../../components/common";
import {optional} from "../../../utils/lang";
import {TextCell} from "../grids";
import M from "../../../strings";
import React from "react";
import {getUserDeviceStatusDescription} from "../../../model/vars";
import * as ui from "../../utils/ui";

export function generateGridUrl(userDeviceId, parentId, parentName) {
    let concatenateEntityId = "?fabricatorCollaboratorId=" + parentId + "&fabricatorCollaboratorName=" + parentName
    return "/entities/userDevice/" + userDeviceId + concatenateEntityId
}

export class DeviceGrid extends React.Component {

    constructor(props) {
        super(props)
    }

    onGridRowDoubleClick(row){
        ui.navigate(generateGridUrl(row.id,  this.props.model.get("id"), this.props.model.get("lastname") + " " + this.props.model.get("name")));
    }

    render() {
        let field = this.props.field
        let model = this.props.model
        let data = optional(model.get(field.property), []);
        let result = {
            rows: data,
            totalRows: data.length
        };
        let descriptor = {
            columns: [
                {
                    property: "code",
                    header: M("code"),
                    cell: TextCell,
                    sortable: false,
                    searchable: false
                },
                {
                    property: "deviceId",
                    header: M("id"),
                    cell: TextCell,
                    sortable: false,
                    searchable: false
                },
                {
                    property: "manufacturer",
                    header: M("manufacturer"),
                    cell: TextCell,
                    sortable: false,
                    searchable: false,
                    props: {
                        formatter: v => {
                            return v != null ? v : "-"
                        }
                    }
                },
                {
                    property: "model",
                    header: M("model"),
                    cell: TextCell,
                    sortable: false,
                    searchable: false,
                    props: {
                        formatter: v => {
                            return v != null ? v : "-"
                        }
                    }
                },
                {
                    property: "validityStart",
                    header: M("startDate"),
                    cell: DateCell,
                    sortable: false,
                    searchable: false
                },
                {
                    property: "validityEnd",
                    header: M("endDate"),
                    cell: DateCell,
                    sortable: false,
                    searchable: false
                },
                {
                    property: "requestDate",
                    header: M("requestDate"),
                    cell: DateCell,
                    sortable: false,
                    searchable: false
                },
                {
                    property: "status",
                    header: M("status"),
                    cell: TextCell,
                    sortable: false,
                    searchable: false,
                    props: {
                        formatter: v => {
                            return v != null ? getUserDeviceStatusDescription(v) : "";
                        }
                    }
                },
            ]
        };

        let actions = [];

        return (
            <div className="col-xs-12">

                {this.props.model.data.id && <div className="col-xs-12"><HeaderBlock
                    projectId={this.props.model.data.id} actions={actions}/></div>}
                <Grid
                    ref="grid"
                    descriptor={descriptor}
                    data={resultToGridData(result)}
                    onRowDoubleClick={this.onGridRowDoubleClick.bind(this)}
                    quickSearchEnabled="false"
                    paginationEnabled="false"
                    selectionEnabled="false"
                    summaryVisible="false"
                    showInCard="false"
                    footerVisible="false"
                />
            </div>
        )
    }
    }