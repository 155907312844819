"use strict"

import {connect} from "../../utils/aj";
import React from "react";
import {Dialog, DIALOG_RESULT_OK} from "../dialogs";
import {safeGet} from "../../../utils/lang";
import M from "../../../strings";
import {hideFinancingDialog, selectPaymentFee} from "../../../actions/dossier";
import {DossierStore} from "../../../stores/dossier";
import * as _ from "underscore";
import {currencyFormat} from "../../../utils/customUtils";

export class SimulateFinancingDialog extends React.Component {
    constructor(props){
        super(props)

        connect(this, DossierStore, {financingDialogHidden: true})
    }

    onClose() {
        hideFinancingDialog()
    }

    onClick(paymentFee, e) {
        e.preventDefault()
        console.log(JSON.stringify(paymentFee))
        selectPaymentFee(paymentFee)
    }

    render() {

        const paymentFees = _.map(this.state.paymentFees, fee => {
            return (
                <div key={"payment_fee_"+fee.months} className={"fee_box "+(fee.months === this.state.currentFee.months ? "selected" : "")} onClick={this.onClick.bind(this, fee)}>
                    {fee.months} <span className="fee_small">RATE</span>
                </div>
            )
        })
        
        const buttons = [
            {
                text: M("close"),
                action: (dialog) => {
                    dialog.hide()
                },
                dialogResult: DIALOG_RESULT_OK
            }
        ]

        return (
            <div className="financing-dialog">
                <Dialog
                    ref="dialog"
                    title={M("simulateFinancing")}
                    medium={true}
                    hidden={this.state.financingDialogHidden}
                    buttons={buttons}
                    onClose={this.onClose.bind(this)}>

                    <div className="text-center">
                        {paymentFees}
                    </div>

                    <p className="m-t-20 text-orange">{M("simulatedFeeAmount")}</p>
                    <span className="fs-36 text-orange">{currencyFormat(safeGet(this.state.currentFee, "value", 0))}</span>
                </Dialog>
            </div>

        )
    }

}