"use strict"


import {createAsyncAction} from "../utils/ajex";
import {dispatch} from "../aj";
import {
    HIDE_ADD_REQUIRED_POLICY_DOCUMENT_DIALOG,
    QST_GO_BACK,
    QST_GO_NEXT,
    QST_LOAD_QUESTIONNAIRE,
    QST_LOAD_QUESTIONNAIRE_DOSSIER,
    QST_SET_OPTION_ANSWER,
    QST_SET_OPTION_CHOOSE,
    QST_SET_REQUIRED_POLICY_DOCUMENT,
    QST_SET_TEXT_ANSWER,
    QST_UPLOAD_REQUIRED_POLICY,
    SHOW_ADD_REQUIRED_POLICY_DOCUMENT_DIALOG
} from "./types";
import {alert, hideLoader, showLoader} from "../plugins";
import * as DossierApi from "../api/dossier"
import * as responses from "../api/responses";
import M from "../strings";


export const loadQuestionnaireForDossier = createAsyncAction(QST_LOAD_QUESTIONNAIRE_DOSSIER,  (data) => {

    if (!data.dossierId) {
        throw new Error("dossierId is required")
    }

    dispatch({
        type: QST_LOAD_QUESTIONNAIRE_DOSSIER
    });

    showLoader();
    DossierApi.questionnaireForDossier(data.dossierId, data.dossierType)
        .then(response => {
            loadQuestionnaireForDossier.complete({questionnaire: response.value})

        })
        .catch(e => {
            alert(M("ooops"), responses.msg(e), "error")

            loadQuestionnaireForDossier.fail()
        })
        .finally( () => hideLoader())
});

export const loadQuestionnaire = createAsyncAction(QST_LOAD_QUESTIONNAIRE,  (dossierType) => {

    if (!dossierType) {
        throw new Error("dossierType is required")
    }

    dispatch({
        type: QST_LOAD_QUESTIONNAIRE
    });

    showLoader();
    DossierApi.questionnaire(dossierType)
        .then(response => {
            loadQuestionnaire.complete({questionnaire: response.value})

        })
        .catch(e => {
            alert(M("ooops"), responses.msg(e), "error")

            loadQuestionnaire.fail()
        })
        .finally( () => hideLoader())
});

export const goNext = () => {
    dispatch({
        type: QST_GO_NEXT,
    })
}

export const goBack = () => {
    dispatch({
        type: QST_GO_BACK,
    })
}

export const setQuestionFieldAnswerProperty = (o_index, value) => {
    dispatch({
        type: QST_SET_TEXT_ANSWER,
        o_index,
        value
    });
}

export const setOptionAnswer = (value) => {
    dispatch({
        type: QST_SET_OPTION_ANSWER,
        value
    });
}

export const setOptionChoose = (value) => {
    dispatch({
        type: QST_SET_OPTION_CHOOSE,
        value
    })
}

export const setRequiredPolicyDocument = (value) => {
    dispatch({
        type: QST_SET_REQUIRED_POLICY_DOCUMENT,
        value
    })
}

export const showAddRequiredPolicyDocumentDialog = () => {
    dispatch({
        type: SHOW_ADD_REQUIRED_POLICY_DOCUMENT_DIALOG
    })
}

export const hideAddRequiredPolicyDocumentDialog = () => {
    dispatch({
        type: HIDE_ADD_REQUIRED_POLICY_DOCUMENT_DIALOG
    })
}

export const uploadRequiredPolicy = createAsyncAction(QST_UPLOAD_REQUIRED_POLICY,  (dossierType) => {

    dispatch({
        type: QST_UPLOAD_REQUIRED_POLICY
    });

    DossierApi.uploadRequiredPolicy(dossierType)
        .then(response => {
            uploadRequiredPolicy.complete({questionnaire: response.value})

        })
        .catch(e => {
            alert(M("ooops"), responses.msg(e), "error")

            uploadRequiredPolicy.fail()
        })
});