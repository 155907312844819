"use strict"

import {connect} from "../../utils/aj";
import React from "react";
import {Dialog, DIALOG_RESULT_OK} from "../dialogs";
import {safeGet} from "../../../utils/lang";
import M from "../../../strings";
import {hideBillsDialog} from "../../../actions/dossier";
import {DossierStore} from "../../../stores/dossier";
import * as _ from "underscore";

export class BillsDialog extends React.Component {
    constructor(props){
        super(props)

        connect(this, DossierStore, {billsDialogHidden: true})
    }

    onClose() {
        hideBillsDialog()
    }

    render() {

        const bills = safeGet(this.state.priceCalculatorSheet, "bills", [])
        let i = 0;
        const billsComponent = _.map(bills, bill => {
            let billComponents = _.map(bill.bills, b => <Bill key={b.type + Math.random()} bill={b} /> )
            let values = _.map(bill.values, v => <BillValue key={v.type + Math.random()} value={v} /> )
            i++
            let classColumn = "m-t-20 col-sm-6 " + (bills.length > 2 ? "col-md-4 " : "col-md-6 ") + (i !== bills.length ? "br-right" : "")

            return (
                <div className={classColumn} key={"bill_component_"+Math.random()}>
                    <h5 className="text-uppercase m-b-20 text-primary">{bill.title}</h5>
                    {billComponents}
                    {values}
                    {bill.type === "taxes" &&
                    <h6 className="text-uppercase m-b-20">{"+ EURO 2,00 MARCA DA BOLLO"}</h6>}
                </div>
            )
        })


        const buttons = [
            {
                text: M("close"),
                action: (dialog) => {
                    dialog.hide()
                },
                dialogResult: DIALOG_RESULT_OK
            }
        ]

        return (
            <div className="bills-dialog">
                <Dialog
                    ref="dialog"
                    titleClass="modal-title text-primary"
                    title={M("billValues")}
                    large={true}
                    hidden={this.state.billsDialogHidden}
                    buttons={buttons}
                    onClose={this.onClose.bind(this)}>

                    <div className="bills-container row">
                        {billsComponent}
                    </div>

                </Dialog>
            </div>

        )
    }

}

class Bill extends React.Component {
    render() {
        let i = 0;
        const billsComponent = _.map(this.props.bill.values, v => <BillValue key={v.type + Math.random()} value={v} /> )
        i++

        return (
            <div className="col-sm-12 zero-padding">
                <h6 className="text-uppercase m-b-20">{this.props.bill.title}</h6>
                {billsComponent}
            </div>
        )
    }
}

class BillValue extends React.Component {
    render() {
        return (
            <div className="form-group">
                <label style={{width:"100%"}}>{this.props.value.description}</label>
                <div className="input-group m-b-0">
                    <input readOnly="true" type="text" className="form-control input-sm" value={this.props.value.value} style={{opacity:1}} />
                </div>
                <i className="form-group__bar"></i>
            </div>
        )
    }
}