"use strict"

import * as aj from "../aj/index";
import {completed, failed} from "../utils/ajex";
import * as actions from "../actions/types";
import {
    DOSSIER_HIDE_FINANCING_DIALOG,
    DOSSIER_HIDE_VAT_CALCULATOR_DIALOG,
    DOSSIER_SELECT_PAYMENT_FEE,
    DOSSIER_SHOW_FINANCING_DIALOG,
    DOSSIER_SHOW_VAT_CALCULATOR_DIALOG
} from "../actions/types";
import _ from "underscore";
import {DOSSIER} from "./types";

function defaultState() {
    return {
        saved:false,
        priceCalculator: {},
        canDownload: false,
        downloadUrl: undefined
    }
}

export const DossierStore = aj.createStore(DOSSIER, (state = defaultState(), action) => {

    switch (action.type) {
        case completed(actions.CREATE_DOSSIER):
        case completed(actions.EDIT_DOSSIER):
            return _.assign(state, {
                error: false,
                validationError: false,
                validationResult: null
            });

        case failed(actions.CREATE_DOSSIER):
        case failed(actions.EDIT_DOSSIER):
            return _.assign(state, {
                error: true,
                validationError: action.validationError,
                validationResult: action.validationResult
            });

        case actions.DOSSIER_SHOW_ADD_DOCUMENT_DIALOG:
            return _.assign(state, {attachCompleted: false, showAddDocumentDialog: true, documentType: action.documentType});
        case actions.DOSSIER_HIDE_ADD_DOCUMENT_DIALOG:
            return _.assign(state, {attachCompleted: false, showAddDocumentDialog: false, documentType: null});
        case actions.DOSSIER_SHOW_REFUSE_DOCUMENT_DIALOG:
            return _.assign(state, {showRefuseDocumentDialog: true, documentTypeId: action.documentTypeId});
        case actions.DOSSIER_HIDE_REFUSE_DOCUMENT_DIALOG:
            return _.assign(state, {showRefuseDocumentDialog: false, documentTypeId: null, refuseReason: ""});
        case actions.DOSSIER_ATTACH_DOCUMENT:
            return _.assign(state, {attachCompleted: false, documents: null, loading: true});
        case actions.DOSSIER_APPROVE_DOCUMENT:
        case actions.DOSSIER_REFUSE_DOCUMENT:
        case actions.DOSSIER_CLEAR_DOCUMENT:
            return _.assign(state, {completed: false, documents: null});
        case completed(actions.DOSSIER_ATTACH_DOCUMENT):
            return _.assign(state, {
                error: false,
                completed: true,
                documents: action.documents,
                status: action.status,
                loading: false,
                attachCompleted: true
            });
        case completed(actions.DOSSIER_APPROVE_DOCUMENT):
        case completed(actions.DOSSIER_REFUSE_DOCUMENT):
        case completed(actions.DOSSIER_CLEAR_DOCUMENT):
            return _.assign(state, {
                error: false,
                completed: true,
                documents: action.documents,
                status: action.status,
                loading: false,
            });

        case failed(actions.DOSSIER_ATTACH_DOCUMENT):
            return _.assign(state, {
                error: false,
                completed: true,
                documents: action.documents,
                status: action.status,
                loading: false,
                attachCompleted: false,
            });
        case failed(actions.DOSSIER_APPROVE_DOCUMENT):
        case failed(actions.DOSSIER_REFUSE_DOCUMENT):
        case failed(actions.DOSSIER_CLEAR_DOCUMENT):
            return _.assign(state, {
                error: true,
                completed: false,
                documents: null,
                loading: false
            });
        case completed(actions.GIURE_EVALUATION):
        case completed(actions.QUOTATION):
        case completed(actions.CONFIRM_QUOTATION):
        case completed(actions.CONFIRM_QUOTATION_2020_50):
        case completed(actions.CONFIRM_QUOTATION_2021_50):
        case completed(actions.CONFIRM_QUOTATION_2022_1):
        case completed(actions.COMMIT):
        case completed(actions.CANDIDATE):
        case completed(actions.APPROVE):
        case completed(actions.PAY_OFF):
        case completed(actions.REFUSE):
            return _.assign(state, {
                error: false,
                statusChanged: true
            });
        case failed(actions.GIURE_EVALUATION):
        case failed(actions.QUOTATION):
        case failed(actions.CONFIRM_QUOTATION):
        case failed(actions.CONFIRM_QUOTATION_2020_50):
        case failed(actions.CONFIRM_QUOTATION_2021_50):
        case failed(actions.CONFIRM_QUOTATION_2022_1):
        case failed(actions.COMMIT):
        case failed(actions.CANDIDATE):
        case failed(actions.APPROVE):
        case failed(actions.PAY_OFF):
        case failed(actions.REFUSE):
            return _.assign(state, {
                error: true,
                statusChanged: false
            });


        case completed(actions.DOSSIER_CALCULATE_ACTUAL_VAT):
            return _.assign(state, {
                calculatedVat: action.actualVat,
                completed: true,
            })

        case completed(actions.DOSSIER_CALCULATE_INVOICE_VAT):
            return _.assign(state, {
                vatInDialog: action.vatInDialog,
                completed: true,
            })

        case completed(actions.DOSSIER_SET_PRICE_CALCULATOR_SHEET):
            return _.assign(state, {
                priceCalculatorSheet: action.priceCalculatorSheet,
                completed: true,
            })

        case DOSSIER_SHOW_VAT_CALCULATOR_DIALOG: {
            return _.assign(state, {calculatorDialogHidden: false})
        }
        case DOSSIER_HIDE_VAT_CALCULATOR_DIALOG: {
            return _.assign(state, {calculatorDialogHidden: true})
        }

        case DOSSIER_SHOW_FINANCING_DIALOG: {
            return _.assign(state, {financingDialogHidden: false})
        }

        case completed(DOSSIER_SHOW_FINANCING_DIALOG): {
            const paymentFees = []
            if (action.simulatedFinancing.sixtyPaymentFee > 0) {
                paymentFees.push({months: 6, value: action.simulatedFinancing.sixtyPaymentFee})
            }
            if (action.simulatedFinancing.twelvePaymentFee > 0) {
                paymentFees.push({months: 12, value: action.simulatedFinancing.twelvePaymentFee})
            }
            if (action.simulatedFinancing.twentyFourPaymentFee > 0) {
                paymentFees.push({months: 24, value: action.simulatedFinancing.twentyFourPaymentFee})
            }
            if (action.simulatedFinancing.thirtySixPaymentFee > 0) {
                paymentFees.push({months: 36, value: action.simulatedFinancing.thirtySixPaymentFee})
            }
            if (action.simulatedFinancing.fourtyEightPaymentFee > 0) {
                paymentFees.push({months: 48, value: action.simulatedFinancing.fourtyEightPaymentFee})
            }

            return _.assign(state, {
                paymentFees: paymentFees,
                currentFee: paymentFees[0]
            })
        }

        case DOSSIER_SELECT_PAYMENT_FEE: {
            return _.assign(state, {currentFee: action.paymentFee})
        }

        case DOSSIER_HIDE_FINANCING_DIALOG: {
            return _.assign(state, {financingDialogHidden: true, simulatedFinancing: null})
        }

        case actions.GENERATE_QUOTATION_DOSSIER_2020_50:
        case actions.GENERATE_QUOTATION_DOSSIER_2021_50:
        case actions.GENERATE_QUOTATION_DOSSIER_2022_1:
        case actions.GENERATE_QUESTIONNAIRE_DOSSIER_2020_50:
        case actions.GENERATE_QUESTIONNAIRE_DOSSIER_2021_50:
        case actions.GENERATE_QUESTIONNAIRE_DOSSIER_2022_1:
            return _.assign(state, {
                downloadUrl: undefined,
                canDownload: true
            });
        case completed(actions.GENERATE_QUOTATION_DOSSIER_2020_50):
        case completed(actions.GENERATE_QUOTATION_DOSSIER_2021_50):
        case completed(actions.GENERATE_QUOTATION_DOSSIER_2022_1):
        case completed(actions.GENERATE_QUESTIONNAIRE_DOSSIER_2020_50):
        case completed(actions.GENERATE_QUESTIONNAIRE_DOSSIER_2021_50):
        case completed(actions.GENERATE_QUESTIONNAIRE_DOSSIER_2022_1):
            return _.assign(state, {
                error: false,
                downloadUrl: action.downloadUrl,
            });

        case failed(actions.GENERATE_QUOTATION_DOSSIER_2020_50):
        case failed(actions.GENERATE_QUOTATION_DOSSIER_2021_50):
        case failed(actions.GENERATE_QUOTATION_DOSSIER_2022_1):
        case failed(actions.GENERATE_QUESTIONNAIRE_DOSSIER_2020_50):
        case failed(actions.GENERATE_QUESTIONNAIRE_DOSSIER_2021_50):
        case failed(actions.GENERATE_QUESTIONNAIRE_DOSSIER_2022_1):
            return _.assign(state, {
                error: true,
                downloadUrl: undefined,
                canDownload: false
            });
        case actions.RESTORE_CAN_DOWNLOAD:
            return _.assign(state, {
                downloadUrl: undefined,
                canDownload: false
            })


        case completed(actions.GENERATE_QUOTATION_AMOUNTS_2020_50):
            return _.assign(state, {
                priceCalculatorSheet: action.priceCalculatorSheet
            })

        case actions.DOSSIER_SHOW_BILLS_DIALOG: {
            return _.assign(state, {billsDialogHidden: false, priceCalculatorSheet: action.priceCalculatorSheet})
        }
        case actions.DOSSIER_HIDE_BILLS_DIALOG: {
            return _.assign(state, {billsDialogHidden: true})
        }

        case actions.DOSSIER_SHOW_SERVICE_COSTS: {
            return _.assign(state, {serviceCostsDialogHidden: false, priceCalculatorSheet: action.priceCalculatorSheet})
        }
        case actions.DOSSIER_HIDE_SERVICE_COSTS: {
            return _.assign(state, {serviceCostsDialogHidden: true})
        }

        case actions.KPMG_SEND_FOR_REVIEW:
            return _.assign(state, {
                downloadUrl: undefined,
                canDownload: true
            });
        case completed(actions.KPMG_SEND_FOR_REVIEW):
            return _.assign(state, {
                error: false,
            });
        case failed(actions.KPMG_SEND_FOR_REVIEW):
            return _.assign(state, {
                error: true
            });

        case actions.FORCE_ALL_KPMG_SEND_FOR_REVIEW:
            return _.assign(state, {
                downloadUrl: undefined,
                canDownload: true
            });
        case completed(actions.FORCE_ALL_KPMG_SEND_FOR_REVIEW):
            return _.assign(state, {
                error: false,
            });
        case failed(actions.FORCE_ALL_KPMG_SEND_FOR_REVIEW):
            return _.assign(state, {
                error: true
            });
    }

});
