import _ from "underscore";
import {QuestionType} from "./vars";
import {alert} from "../plugins";
import M from "../strings";

export class QuestionnaireModel {
    
    constructor(data) {
        _.assign(this, data);
    }

    static of(data) {
        return new QuestionnaireModel(data);
    }
    
    get currentQuestion() {
        return this.questions[this.progress];
    }

    getQuestionnaireForSave() {
        let questionnaire = JSON.parse(JSON.stringify(this))
        questionnaire.questionnaireId = questionnaire.id
        questionnaire.id = null;
        questionnaire.multipleChoiceQuestions = [];
        questionnaire.multipleTextQuestions = [];
        _.each(questionnaire.questions, (q) => {
            switch (q.type) {
                case QuestionType.MULTIPLE_TEXT:
                    questionnaire.multipleTextQuestions.push(q)
                    break;
                case QuestionType.MULTIPLE_CHOICE:
                    questionnaire.multipleChoiceQuestions.push(q)
                    break;
            }
        });

        return questionnaire;
    }

    isOptionSelected(option) {
        return _.any(this.currentQuestion.selectedOptionIds, id => id === option.id)
    }

    get isCurrentQuestionComplete() {
        const type = this.currentQuestion.type
        let complete = true

        //Check campi obbligatori compilati
        if (this.currentQuestion.type === QuestionType.MULTIPLE_CHOICE) {
            if (_.isEmpty(this.currentQuestion.selectedOptionIds)) {
                alert(M("ooops"), M("requiredSelectOptionAlert"), "error")
                complete = false
            }

            _.each(this.currentQuestion.options, o => {
                if (o.shortAnswerRequired && this.isOptionSelected(o) && _.isEmpty(this.currentQuestion.shortAnswer)) {
                    alert(M("ooops"), M("requiredSelectOptionAlert"), "error")
                    complete = false
                }
            })
        }

        if (type === QuestionType.MULTIPLE_TEXT && _.any(_.filter(this.currentQuestion.options, o => o.required), op => _.isEmpty(op.answer))) {
            alert(M("ooops"), M("requiredFieldsAlert"), "error")
            complete = false
        }

        return complete
    }
    
}


export class QuestionModel {

    constructor(data) {
        _.assign(this, data);
    }

    static of(data) {
        return new QuestionModel(data);
    }

    get formattedNumber() {
        const number = this.number || 0;
        return String(number).padStart(4, "0");
    }
}