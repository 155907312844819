"use strict"

import {connect} from "../../utils/aj";
import {DossierStore} from "../../../stores/dossier";
import {calculateInvoiceVat, hideVatCalculatorDialog, hideServiceCosts} from "../../../actions/dossier";
import React from "react";
import {Dialog, DIALOG_RESULT_CANCEL, DIALOG_RESULT_OK} from "../dialogs";
import M from "../../../strings";
import {optional, safeGet} from "../../../utils/lang";
import {FormBody, Model, Number, ReadOnlyText} from "../forms";
import _ from "underscore";
import {HeaderBlock} from "../common";
import { currencyFormat } from "../../../utils/customUtils";
import { formatEuroNumber } from "./utils";

export class ServiceCostsDialog extends React.Component {

    constructor(props){
        super(props)

        connect(this, DossierStore, {serviceCostsDialogHidden: true})
        this.model = new Model()
    }

    onClose() {
        hideServiceCosts()
    }

    render() {

        if (this.state.priceCalculatorSheet) {
            this.model.set("serviceCosts", optional(this.state.priceCalculatorSheet.serviceCosts, "-"))
        }

        const dialogFormDescriptor = {
            showInCard: true,
            fields: [
                {
                    property: "serviceCosts",
                    control: ReadOnlyText,
                    label: M("serviceCostsLabel"),
                    props: {
                        formatter: v => {
                            return formatEuroNumber(v)
                        }
                    }
                }
            ]
        }

        this.model.descriptor = dialogFormDescriptor

        const dialogButtons = [
            {
                text: M("close"),
                action: (dialog) => dialog.hide(),
                dialogResult: DIALOG_RESULT_CANCEL
            }
        ]

        return (
            <Dialog
                ref="dialog"
                title={optional(this.props.title, M("serviceCosts"))}
                buttons={dialogButtons}
                noPadding="true"
                hidden={this.state.serviceCostsDialogHidden}
                onClose={this.onClose.bind(this)}>

                <FormBody descriptor={dialogFormDescriptor} model={this.model} />

            </Dialog>
        )
    }
}
