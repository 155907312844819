"use strict"

import * as React from "react";
import {Column, ReadOnlyText, Switch} from "../forms";
import {format} from "../../../utils/lang";
import {CustomerType, DOSSIER_TYPE} from "../../../model/vars";
import M from "../../../strings";
import {currencyFormat} from "../../../utils/customUtils";
import {isSuperuser} from "../../../api/session";
import {formatEuroNumber, getCardColorFromModel} from "./utils";

export class PriceCalculatorControl extends Column {

    getFields() {
        let fields = []
        switch (this.props.model.get("type")) {
            case DOSSIER_TYPE.ECOBONUS_2019:
                fields.push(
                    {
                        property: "column_ecobonus_2019",
                        control: Column,
                        size: "col-sm-12",
                        className: "card-colored",
                        noLateralPadding: false,
                        useBoostrapRow: true,
                        fields: [
                            {
                                property: "recommendedPrice",
                                control: ReadOnlyText,
                                label: M("recommendedRetailPrice"),
                                placeholder: M("recommendedRetailPrice"),
                                size: "col-sm-12",
                                props: {
                                    formatter: v => {
                                        return v != null ? currencyFormat(v.recommendedRetailPrice) : ""
                                    }
                                }
                            },
                            {
                                property: "recommendedPrice",
                                control: ReadOnlyText,
                                getLabel: model => {
                                    return model != null && model.get("recommendedPrice") ? format(M("netAmountToBePaid"), model.get("recommendedPrice").discount) : M("priceDiscounted");
                                },
                                placeholder: M("priceDiscounted"),
                                size: "col-sm-12",
                                props: {
                                    formatter: v => {
                                        return v != null ? currencyFormat(v.netAmountToBePaid) : ""
                                    }
                                }
                            },
                            {
                                property: "serviceCost",
                                control: ReadOnlyText,
                                label: M("initiativeCost"),
                                placeholder: M("initiativeCost"),
                                size: "col-sm-12",
                                props: {
                                    formatter: v => {
                                        return v != null ? currencyFormat(v.initiativeCost) : ""
                                    }
                                }
                            },
                            {
                                property: "serviceCost",
                                control: ReadOnlyText,
                                label: M("fabricatorPayOff"),
                                placeholder: M("fabricatorPayOff"),
                                size: "col-sm-12",
                                visibility: (model) => {
                                    return isSuperuser()
                                },
                                props: {
                                    formatter: v => {
                                        return v != null ? currencyFormat(v.fabricatorPayOff) : ""
                                    }
                                }
                            },
                            {
                                property: "serviceFeeInvoiced",
                                control: Switch,
                                label: M("serviceFeeInvoiced"),
                                size: "col-sm-12",
                                visibility: (model) => {return isSuperuser()},
                                props: {
                                    formatter: v => {
                                        return v != null && v ? M("yes") : M("no");
                                    }
                                }
                            },
                        ]
                    },
                )
                break

            case DOSSIER_TYPE.ECOBONUS_2020_50:
            case DOSSIER_TYPE.ECOBONUS_2021_50:
            case DOSSIER_TYPE.ECOBONUS_2022_1:

                let fields_2020_50 = [
                    {
                        property: "totalWorkAmount",
                        control: ReadOnlyText,
                        getLabel(model) {
                            let label = "Spesa complessiva della fornitura";
                            if (model.get("_customer").subjectType === CustomerType.SUBJECT_TYPE_LEGAL_PERSON.value)
                                label += " IVA esclusa"
                            else 
                                label += " IVA inclusa"
                            return label;
                        },
                        size: "col-sm-12 ",
                        props: {
                            getExtraClassName(model) {
                                return "quotation-value"
                            },
                            formatter(v, model) {
                                if (model) {
                                    let priceCalculatorSheet = model.get("priceCalculatorSheet")
                                    return priceCalculatorSheet && priceCalculatorSheet.totalWorkAmount ? formatEuroNumber(priceCalculatorSheet.totalWorkAmount ):  "-"
                                }
                            }
                        }
                    },

                    {
                        property: "totalDecrease",
                        control: ReadOnlyText,
                        label: "Detrazione spettante",
                        size: "col-sm-12 ",
                        props: {
                            getExtraClassName(model) {
                                return "quotation-value"
                            },
                            formatter(v, model) {
                                if (model) {
                                    let priceCalculatorSheet = model.get("priceCalculatorSheet")
                                    return priceCalculatorSheet && priceCalculatorSheet.totalDecrease ? formatEuroNumber((-1 * priceCalculatorSheet.totalDecrease))  :  "-"
                                }
                            }
                        }
                    },
                ]

                if (!this.props.model.get("avoidNotPermittedSurfaceSize")) {
                    //se persone giuridiche va mostrata la spesa agevolabile senza iva altrimenti va inclusa
                    if (this.props.model.get("_customer").subjectType === CustomerType.SUBJECT_TYPE_LEGAL_PERSON.value) {
                        fields_2020_50.push(
                            {
                                property: "totalNotPermitted",
                                control: ReadOnlyText,
                                label: "Spesa non agevolabile IVA esclusa",
                                size: "col-sm-12 ",
                                props: {
                                    getExtraClassName(model) {
                                        return "quotation-value"
                                    },
                                    formatter(v, model) {
                                        if (model) {
                                            let priceCalculatorSheet = model.get("priceCalculatorSheet")
                                            return priceCalculatorSheet && priceCalculatorSheet.totalNotPermitted ? formatEuroNumber(priceCalculatorSheet.totalNotPermitted) : "-"
                                        }
                                    }
                                }
                            }
                        )
                    } else {
                        fields_2020_50.push(
                            {
                                property: "totalNotPermittedWhitVat",
                                control: ReadOnlyText,
                                label: "Spesa non agevolabile IVA inclusa",
                                size: "col-sm-12 ",
                                props: {
                                    getExtraClassName(model) {
                                        return "quotation-value"
                                    },
                                    formatter(v, model) {
                                        if (model) {
                                            let priceCalculatorSheet = model.get("priceCalculatorSheet")
                                            return priceCalculatorSheet && priceCalculatorSheet.totalNotPermittedWhitVat ? formatEuroNumber(priceCalculatorSheet.totalNotPermittedWhitVat) : "-"
                                        }
                                    }
                                }
                            }
                        )
                    }
                }
                    
                fields_2020_50.push(
                
                    {
                        property: "amountToPay",
                        control: ReadOnlyText,
                        getLabel(model) {
                            let label = "Importo netto da pagare";
                            if (model.get("_customer").subjectType === CustomerType.SUBJECT_TYPE_LEGAL_PERSON.value)
                                label = label + " +iva di legge"
                            return label;
                        },
                        size: "col-sm-12 ",
                        props: {
                            getExtraClassName(model) {
                                return "quotation-value bold"
                            },
                            formatter(v, model) {
                                if (model) {
                                    let priceCalculatorSheet = model.get("priceCalculatorSheet")
                                    return priceCalculatorSheet && priceCalculatorSheet.amountToPay ? formatEuroNumber(priceCalculatorSheet.amountToPay):  "-"
                                }
                            }
                        }
                    },                    
                )

                    
                fields.push(
                    {
                        property: "column_ecobonus_2020_50",
                        control: Column,
                        size: "col-sm-12",
                        getClassName(model) {
                            return getCardColorFromModel(model)
                        },
                        noLateralPadding: false,
                        useBoostrapRow: true,
                        fields: fields_2020_50
                    }
                )
                break
        }

        return fields
    }



    renderExtra() {
        return null
    }

}

