"use strict"

import React from "react";
import {Layout} from "../layout"
import EntityForm from "../../screens/entities/entityForm";
import {KPMGSendForReview, showBillsDialog, showFinancingDialog, showServiceCosts} from "../../../actions/dossier";
import {format, safeGet} from "../../../utils/lang";
import M from "../../../strings";
import {DOSSIER_TYPE, DossierStatus, getDossierEntityNameFromType} from "../../../model/vars";
import {alert} from "../../../plugins";
import {deleteEntities} from "../../../actions/entities";
import * as _ from "underscore";
import {generateDossierDTOFromData, generateDossierDTOFromModel, getCardColorFromModel} from "../dossiers/utils";
import {Control} from "../forms";
import {get} from "../../../framework/config";
// import {SimulateFinancingDialog} from "./simulateFinancingDialog";
// import {BillsDialog} from "./billsDialog";
// import {ServiceCostsDialog} from "../serviceCostsDialog";
import * as ui from "../../utils/ui";
import {
    confirmQuotation_2023_75,
    createDossier_2023_75,
    editDossier_2023_75,
    generateQuestionnaireDossier_2023_75,
    generateQuotationDossier_2023_75
} from "../../../actions/dossier_2023_75";
import {BillsDialog} from "../dossiers/billsDialog";
import {ServiceCostsDialog} from "../dossiers/serviceCostsDialog";
import {DossierStatusComponent} from "../dossiers/dossierStatus";
import {isGiureUser} from "../../../api/session";

export class Dossier_2023_75_FormFooter extends Control {
    constructor(props) {
        super(props);
        this.state = {};
    }


    onQuestionnaireEdit() {
        let data = this.props.model.data
        ui.navigate("questionnaire/" + data.type + "?dossierId=" + data.id + "&customerId=" + data.customerId)
    }

    onConfirm() {
        let data = this.props.model.data
        //conferma preventivo
        confirmQuotation_2023_75({
            dossierType: data.type,
            dossierDTO: generateDossierDTOFromData(data),
        })
    }

    onSave() {
        let data = this.props.model.data
        //conferma preventivo
        let dossierDTO =  generateDossierDTOFromData(data)

        let creation = dossierDTO.dossierId === null;
        dossierDTO.skipValidation = creation || (data.status === DossierStatus.STATUS_QUOTATION.value);

        if (!creation) {
            editDossier_2023_75({
                discriminator: "entity_form_" + getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2023_75),
                dossierDTO: generateDossierDTOFromData(data)
            })
        } else {
            createDossier_2023_75({
                dossierDTO: dossierDTO,
                discriminator: "entity_form_dossier" + getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2023_75),
            });
        }
    }

    //il documento è stato necessariamente caricato nel sistema
    downloadRequiredPolicyDocument(e) {
        e.preventDefault()
        let model = this.props.model;
        let path = "";
        if (model.get("questionnaire") != null || model.get("requiredPolicyDocumentPath")) {
            if (model.get("id") === null) {
                path = this.props.model.get("questionnaire").getQuestionnaireForSave().requiredPolicyDocument
            } else {
                path = this.props.model.get("requiredPolicyDocumentPath");
            }

            window.open(get("service.url") + path, "_blank");
        } else {
            alert(M("privacyNotLoaded"));
        }
    }

    //il documento è stato necessariamente caricato nel sistema
    downloadGuideDocument(e) {
        e.preventDefault()
        let model = this.props.model;
        let path = "";
        if (model.get("guideDocumentPath")) {
            path = model.get("guideDocumentPath");
            window.open(get("service.url") + path, "_blank");
        } else {
            alert(M("guideDocumentNotLoaded"));
        }
    }

    downloadQuestionnaireDocument(e) {
        e.preventDefault()
        let model = this.props.model;
        if (model.get("priceCalculatorSheet") == null) {
            alert(M("requiredFieldsRequired"));
        } else if(!model.get("hasArtifact")){
            alert(M("questionnaireNotUpload"));
        }else {
            generateQuestionnaireDossier_2023_75({
                discriminator: "entity_form_dossier",
                dossierDTO: generateDossierDTOFromModel(model)
            })
        }
    }

    showFinancialDialog(e) {
        e.preventDefault()
        let model = this.props.model;
        if (model.get("priceCalculatorSheet") != null) {
            showFinancingDialog({
                customerType: this.getCustomerType(),
                priceCalculatorSheet: model.get("priceCalculatorSheet")
            })
        } else {
            alert(M("requiredFieldsRequired"));
        }
    }

    showBillsDialog(e) {
        e.preventDefault()
        let model = this.props.model;
        if (model.get("priceCalculatorSheet") != null) {
            showBillsDialog(model.get("priceCalculatorSheet"))
        } else {
            alert(M("requiredFieldsRequired"));
        }
    }

    showServiceCosts(e) {
        e.preventDefault()
        let model = this.props.model;
        if (model.get("priceCalculatorSheet") != null) {
            showServiceCosts(model.get("priceCalculatorSheet"))
        } else {
            alert(M("requiredFieldsRequired"));
        }
    }

    getCustomerType() {
        const customer = this.props.model.get("_customer")
        if (!customer){
            alert(M("customerRequired"))
            return null;
        }
        return customer.subjectType
    }


    downloadQuotationDocument(e) {
        e.preventDefault()
        let model = this.props.model;
        if (model.get("priceCalculatorSheet") != null) {
            generateQuotationDossier_2023_75({
                discriminator: "entity_form_dossier",
                dossierDTO: generateDossierDTOFromModel(model)
            })
        } else {
            alert(M("requiredFieldsRequired"));
        }
    }

    askKPMGReview() {
        let model = this.props.model;
        // if (model.get("kpmgstatus") === KPMGStatus.APPROVED.value || model.get("kpmgstatus") === KPMGStatus.SENT.value)
        //     return;
        swal({title: M("confirm"), text: M("areYouSureSendKPMG"), showCancelButton: true})
            .then(res => {
                if (res.value) {
                    KPMGSendForReview({
                        dossierId: model.get("id"),
                        dossierType: model.get("type")
                    })
                }
            })
    }

    render() {
        let model = this.props.model;
        let className = (getCardColorFromModel(model) + " dossier-custom-footer btn-actions-bar")
        let saveText = this.generateSaveButtonText();
        let leftButtonsClass = "btn-grey btn btn-outline-border float-left "
        let leftButtons = []
        if (model && model.data)
            leftButtons.push(
                <button className={leftButtonsClass} onClick={this.showBillsDialog.bind(this)}>
                    {M("billValues")}
                </button>,
                <button className={leftButtonsClass} onClick={this.showServiceCosts.bind(this)}>
                    {M("serviceCosts")}
                </button>
            )
        if(model && model.data && model.data.id != null){
            leftButtons.push(
                <button className={leftButtonsClass} onClick={this.downloadQuotationDocument.bind(this)}>
                    {M("priceQuotation")}
                </button>,
                <button className={leftButtonsClass} onClick={this.downloadQuestionnaireDocument.bind(this)}>
                    {M("questionnaire")}
                </button>,
                <button className={leftButtonsClass} onClick={this.downloadRequiredPolicyDocument.bind(this)}>
                    {M("privacy")}
                </button>,
                <button className={leftButtonsClass} onClick={this.downloadGuideDocument.bind(this)}>
                    {M("guide")}
                </button>,
            )
        }

        let saveButton =  <button type="submit" className="btn btn-grey primary" onClick={this.onSave.bind(this)}>
            {saveText}
        </button>;
        if (this.props.model.get("status") === DossierStatus.STATUS_QUOTATION.value) {
            let hasArtifact = this.props.model.get("hasArtifact")
            leftButtons.push(<button type="submit" className="btn btn-grey primary" onClick={this.onQuestionnaireEdit.bind(this)}>
                {hasArtifact ? "Modifica questionario" : "Compila questionario"}
            </button>,)
            leftButtons.push(saveButton)
            leftButtons.push(
                <button type="submit" className="btn btn-grey primary" onClick={this.onConfirm.bind(this)}>
                    Conferma preventivo
                </button>)
        } else {
            // if (isSuperuser() && model.get("id") && getDossierStatusPosition(model.get("status")) > getDossierStatusPosition(DossierStatus.STATUS_QUOTATION.value)) {
            //     leftButtons.push(
            //         <div className="btn btn-grey primary" onClick={this.askKPMGReview.bind(this)}>
            //             <p className="m-b-0" style={{marginTop: "-2px"}}>Richiesta KPMG</p>
            //             <p className="m-b-0" style={{marginTop: "-4px", fontSize: "10px"}}>{getKPMGStatusDescription(this.props.model.get("kpmgstatus"))}</p>
            //         </div>,)
            // }
            leftButtons.push(saveButton)
        }

        return (

            <div className={className}>
                {leftButtons}
            </div>

        );
    }

    generateSaveButtonText() {
        let model = this.props.model;
        if (!model || model.get("id") === null)
            return "Crea";
        return "Salva";

    }
}

class HeaderDossier extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    deleteDossier() {
        let data = this.props.data;
        let status = safeGet(data, "status", null);
        let type = safeGet(data, "type", DOSSIER_TYPE.ECOBONUS_2023_75);

        if (status === DossierStatus.STATUS_PAY_OFF.value) {
            alert(M("warning"), M("deleteNotAllowedForStatusPayOff"), "warning")
        } else {
            swal({title: M("confirm"), text: M("areYouSure"), showCancelButton: true})
                .then(res => {
                    //TODO invece del goBack = true -> introdurre la callback che va indietro alla lista pratiche (che dipende dal tipo)
                    if (res.value) {
                        deleteEntities({
                            goBack: true,
                            discriminator: "entity_form_dossier",
                            entity: getDossierEntityNameFromType(type),
                            ids: [safeGet(data, "id", null)]
                        });
                    }
                })
        }
    }

    render() {
        let data = this.props.data;
        let code = safeGet(data, "dossierCode", null);
        let title = null;
        if (code != null)
            title = format(M("dossierNumber"), code);

        return (
            <div>
                <div className="header-actions-dossier fixed-top">
                    <div className="row">
                        <div className="col-md-7 col-sm-5 col-4 float-left" style={{paddingLeft: "30px"}}>
                            <span style={{fontSize: "20px"}}>{title}</span>
                        </div>
                        <div className="col-md-4 col-sm-6 col-7 float-right">
                            <DossierStatusComponent
                                data={this.props.data}/>
                        </div>
                        <div className="col-1 float-right">
                            <div className="dropdown dossier-more-vert float-right">
                                <a className="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown"
                                   aria-haspopup="true" aria-expanded="false">
                                    <i className="zmdi zmdi-more-vert"/>
                                </a>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a className="dropdown-item"
                                       onClick={this.deleteDossier.bind(this)}>{M("deleteDossier")}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default class Dossier_2023_75_Form extends EntityForm {
    constructor(props) {
        super(props);
        this.type = props.params ? props.params.type : null
        this.willGoBack = false;
    }

    getGridUrl(data) {
        return "/entities/" + this.getEntity() + "/list/" + this.type
    }

    onSubmit(data) {
    }

    getData() {
        let data = safeGet(this.state, "data", {});
        return _.assign(data, {questionnaire: this.props.questionnaire});
    }

    render() {
        let descriptor = this.getDescriptor()
        let component = this.getFormComponent()
        let selectedTab = this.props.params.selectedTab;
        let data = this.getData();

        let id = safeGet(data, "id", null);

        let style = {
            marginTop: id != null ? "30px" : ""
        };

        return (
            <Layout>

                <BillsDialog />
                <ServiceCostsDialog />

                {id && <HeaderDossier
                    data={data}
                />}

                {React.createElement(component, {
                    ref: "form",
                    descriptor: descriptor,
                    data: data,
                    selectedTab: selectedTab,
                    onSubmit: this.onSubmit.bind(this),
                    onCancel: this.onCancel.bind(this),
                    style: style
                })}
            </Layout>
        )
    }
}
