"use strict"

import * as config from "../framework/config";
import {post} from "./utils";

export function attachDocument(fabricatorId, documentTypeId, path) {
    return post(config.get("fabricator.url") + "/" + fabricatorId + "/attachPath/" + documentTypeId, {path})
}

export function approveDocument(fabricatorId, documentTypeId) {
    return post(config.get("fabricator.url") + "/" + fabricatorId + "/approve/" + documentTypeId)
}

export function refuseDocument(fabricatorId, documentTypeId, refuseReason) {
    return post(config.get("fabricator.url") + "/" + fabricatorId + "/refuse/" + documentTypeId, {refuseReason})
}

export function clearDocument(fabricatorId, documentTypeId) {
    return post(config.get("fabricator.url") + "/" + fabricatorId + "/clear/" + documentTypeId)
}