"use strict";


import {Layout} from "../../components/layout";
import M from "../../../strings";
import {ActionsMatcher, FloatingButton} from "../../components/common";
import entities from "../../entities";
import AbstractEntitiesGrid from "./abstractEntitiesGrid";
import {format, optional, safeGet} from "../../../utils/lang";
import {PasswordText, Text} from "../../components/forms";
import _ from "underscore";
import React from "react";
import {DOSSIER_TYPE, DossierStatus} from "../../../model/vars";
import {CustomDossierGrid} from "../../components/customDossierGrids";
import {loadEntities} from "../../../actions/entities";
import {connect} from "../../utils/aj";
import {DossierGridStore} from "../../../stores/dossierGrid";
import {getDossierCount} from "../../../actions/dossierGrid";
import {forceAllKPMGSend} from "../../../actions/dossier";
import {getLoggedUser, isFabricator, isGiureUser, isSuperuser} from "../../../api/session";

class StatusFilter extends React.Component {
    constructor(props){
        super(props);
    }

    onClick() {
        let status = safeGet(this.props, "status", null);
        if(_.isFunction(this.props.onClick)){
            this.props.onClick(status);
        }
    }

    render() {
        debugger;
        let status = safeGet(this.props, "status", null);
        let label = status != null ? status.label : M("allStates");
        let query = this.props.query;
        let active = false;
        if (query.filters.length > 0){
            if (status != null) {
                active = _.filter(query.filters, f=>f.property === "status" && f.value === status.value).length > 0
            }
        } else {
            if (status == null)
                active = true;
        }

        let cardStyle = {
            backgroundColor: active ? "#063868" : "#EBEBEB"
        }

        let textStyle = {
            color: active ? "#FFFFFF" : "#AAAAAA"
        }

        return (
            <div className="col-md-2">
                <div className="card status-filter" style={cardStyle} onClick={this.onClick.bind(this)}>
                    <div className="row">
                        <span className="status-filter-label" style={textStyle}>{label}</span>
                        <span className="status-filter-value" style={textStyle}>{this.props.count}</span>
                    </div>
                </div>
            </div>
        )
    }
}

export default class AbstractDossierGrid  extends AbstractEntitiesGrid {

    constructor(props) {
        super(props)

        this.type = props.type
        if (_.isEmpty(this.getEntity())) {
            throw new Error("Please specify entity for form")
        }


        connect(this, DossierGridStore, this.state);
    }

    getInitialQuery() {
        let query = super.getInitialQuery();

        query.eq("type", this.type)
        query.hideFilter("type")

        return query
    }

    componentDidMount() {
        loadEntities({discriminator: this.discriminator, entity: this.getEntity(), query: this.state.query});
        getDossierCount({type: this.type});
    }

    canCreate() {
        // let dossierCount = safeGet(this.state, "dossierCount", null);

        if (isSuperuser())
            return true

        if (isFabricator()) {
            switch (this.type) {
                case DOSSIER_TYPE.ECOBONUS_2019:
                case DOSSIER_TYPE.ECOBONUS_2021_50:
                case DOSSIER_TYPE.ECOBONUS_2021_BONUS_CASA:
                case DOSSIER_TYPE.ECOBONUS_2022_BONUS_CASA_1:
                case DOSSIER_TYPE.ECOBONUS_2022_1:
                case DOSSIER_TYPE.ECOBONUS_2020_50:
                case DOSSIER_TYPE.ECOBONUS_2022_50:
                case DOSSIER_TYPE.ECOBONUS_2022_BONUS_CASA:
                case DOSSIER_TYPE.ECOBONUS_2022_BONUS_CASA_2:
                    return false;
                case DOSSIER_TYPE.ECOBONUS_2023_75:
                    return false
            }
        }

        if (isGiureUser())
            return false;

        return true
    }

    forceAllSendToKPMG() {
        return forceAllKPMGSend();
    }

    getActions() {
        let self = this;
        let defaultActions = [

        ];

        if (isSuperuser() && self.type === "2023_75") {
            defaultActions.push({
                id: "forceAllSendToKPMG",
                type: "button",
                icon: "zmdi zmdi-refresh-alt",
                tooltip: "Invia tutti i documenti a KPMG",
                action: () => { this.forceAllSendToKPMG() }
            })
        }

        let grid = entities[this.getEntity()].grid
        let matcher = new ActionsMatcher(defaultActions)
        return matcher.match(grid.actions)
    }

    getDialogDescriptor() {
        return {
            className: "fe-form",
            useBootstrapRow: true,
            showInCard: false,
            showFormFooter: false,
            fields: [
                {
                    property: "username",
                    control: Text,
                    label: M("username"),
                    placeholder: M("username"),
                    size: "col-md-6",
                },
                {
                    property: "password",
                    control: PasswordText,
                    label: M("password"),
                    placeholder: M("password"),
                    size: "col-md-6",
                },
            ]
        }
    }

    onRowClick(row) {
        if (_.isFunction(this.props.onRowClick))
            this.props.onRowClick(row);
    }

    filter(status){
        let query = safeGet(this.state, "query", null);

        query.die();
        query.setPage(1)
        query.live();

        if (status != null){
            query.eq("status", status.value)
        } else {
            query.unfilter("status");
        }
    }


    getTitle() {
        let grid = entities[this.getEntity()].grid
        if (_.isFunction(grid.getTitle))
            return grid.getTitle(this.type)
        return optional(grid.title, "List")
    }

    generateFilters() {
        let components = [];
        let dossierCount = this.state.dossierCount;
        components.push(
            <StatusFilter count={dossierCount.all} onClick={this.filter.bind(this)} query={this.state.query}/>
        )
        _.each(DossierStatus, d=> {
            components.push(
                <StatusFilter count={dossierCount[d.value.replace("-","")]} status={d} onClick={this.filter.bind(this)} query={this.state.query}/>
            )
        })
        return components;
    }

    getEditUrl(data) {
        let grid = entities[this.getEntity()].grid
        if (_.isFunction(grid.editUrl)) {
            return format(grid.editUrl(data))
        } else if (!_.isEmpty(grid.editUrl)) {
            return format(grid.editUrl, data.id)
        } else {
            return `/entities/${this.getEntity()}/${data.id}?type=` + this.type
        }
    }


    getCreateUrl() {
        let grid = entities[this.getEntity()].grid
        let createUrl = grid.createUrl
        if (_.isFunction(createUrl)) {
            createUrl = createUrl()
        }

        if (this.type === DOSSIER_TYPE.ECOBONUS_2020_50 || this.type === DOSSIER_TYPE.ECOBONUS_2021_50 || this.type === DOSSIER_TYPE.ECOBONUS_2022_1) {
            return "/customerSelection/" + this.type
        }
        else return optional(createUrl, `/entities/${this.getEntity()}/new?type=` + this.type)
    }

    render() {
        debugger;
        let descriptor = this.getDescriptor();
        let data = this.getData();
        let filters = null;
        if (this.state.dossierCount != null)
            filters = this.generateFilters();
        let header = this.generateHeaderBlock();

        return (
            <Layout>
                {header}
                <div className="row">
                    {filters}
                </div>
                <CustomDossierGrid
                    ref="grid"
                    descriptor={descriptor}
                    discriminator = {this.discriminator}
                    data={data}
                    hideFilters={this.hideFilters()}
                    query={this.state.query}
                    entity={this.props.entity}
                    tableClassName="table table-dossier table-bordered table-hover"
                    onRowDoubleClick={this.onGridRowDoubleClick.bind(this)}
                    quickSearchEnabled={true}
                    checkboxSelectEnabled={false}
                    selectionEnabled="true"
                    selectionMode="single"
                    quickSearchPlaceholder={M("searchCode")}
                    onRowClick={this.onRowClick.bind(this)}
                />
                {this.canCreate(this.type) &&
                <FloatingButton icon="zmdi zmdi-plus" onClick={this.createEntity.bind(this)} />
                }
            </Layout>
        )
    }
}
