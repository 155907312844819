"use strict"
import React from "react";
import EntitiesGrid from "./entitiesGrid";
import M from "../../../strings";
import * as _ from "underscore";


const FILTER_TYPES = {
    // DOSSIER_2022_BONUS_CASA: "DOSSIER_2022_BONUS_CASA",
    // DOSSIER_2022_50: "DOSSIER_2022_50",
    DOSSIER_2023_75: "DOSSIER_2023_75",
    DOSSIER_2022_1: "DOSSIER_2022_1",
    DOSSIER_2022_BONUS_CASA_1: "DOSSIER_2022_BONUS_CASA_1",
    DOSSIER_2022_BONUS_CASA_2: "DOSSIER_2022_BONUS_CASA_2",
    DOSSIER_2021_BONUS_CASA: "DOSSIER_2021_BONUS_CASA",
    DOSSIER_2021_50: "DOSSIER_2021_50",
    DOSSIER_2020_50: "DOSSIER_2020_50",
    DOSSIER_2019: "DOSSIER_2019",
    OTHER: "OTHER"
}

export default class DocumentTypesGrid extends EntitiesGrid {
    constructor(props) {
        super(props)

    }

    filter(type) {
        this.state.query.page = 1;
        this.state.query.eq("filterType", type)
    }

    generatePreHeader() {
        let productionLinesFilters = _.map(FILTER_TYPES, type => {
            return 	<button key={type} type="button" style={{marginRight: "5px"}} className="btn btn-primary m-r-5" onClick={this.filter.bind(this, type)}>{M(type)}</button>
        });
        return <div className="col-sm-12">
            <div className="production-items-filters">
                <div className="pull-left">
                    {productionLinesFilters}
                </div>
            </div>
        </div>
    }
}
