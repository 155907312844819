import React from "react";
import * as ui from "../utils/ui";
import {getLoggedUser, isFabricator} from "../../api/session";
import {
    generateLoggedUserFirstScreenDossierType
} from "../../model/vars";
import {alert} from "../../plugins";

const { Screen, Layout } = require("../components/layout")
const Secure = require("../components/secure")

export default class Home extends Screen {

    componentDidMount() {
        let user = getLoggedUser();
        if (user && !user.needToChangePassword) {
            //Alcuni utenti potrebbero non essere abilitati a tutte le tipologie di pratiche

            let dossierType = generateLoggedUserFirstScreenDossierType();

            ui.navigate("/entities/dossier/list/" + dossierType)
        }
    }

    render() {
        return (
            <Secure>
                <Layout>
                    {/*<div className="card">*/}
                        {/*Home Screen*/}
                    {/*</div>*/}
                </Layout>
            </Secure>
        )
    }
}

