"use strict"

import * as aj from "../aj/index";
import {completed, failed} from "../utils/ajex";
import * as actions from "../actions/types";
import _ from "underscore";
import {FABRICATOR} from "./types";


export const FabricatorStore = aj.createStore(FABRICATOR, (state = {}, action) => {

    switch (action.type) {
        case actions.FABRICATOR_SHOW_ADD_DOCUMENT_DIALOG:
            return _.assign(state, {showAddDocumentDialog: true, documentType: action.documentType});
        case actions.FABRICATOR_HIDE_ADD_DOCUMENT_DIALOG:
            return _.assign(state, {showAddDocumentDialog: false, documentType: null});
        case actions.FABRICATOR_SHOW_REFUSE_DOCUMENT_DIALOG:
            return _.assign(state, {showRefuseDocumentDialog: true, documentTypeId: action.documentTypeId});
        case actions.FABRICATOR_HIDE_REFUSE_DOCUMENT_DIALOG:
            return _.assign(state, {showRefuseDocumentDialog: false, documentTypeId: null});
        case actions.FABRICATOR_ATTACH_DOCUMENT:
        case actions.FABRICATOR_APPROVE_DOCUMENT:
        case actions.FABRICATOR_REFUSE_DOCUMENT:
        case actions.FABRICATOR_CLEAR_DOCUMENT:
            return _.assign(state, {completed: false, documents: null});
        case completed(actions.FABRICATOR_ATTACH_DOCUMENT):
        case completed(actions.FABRICATOR_APPROVE_DOCUMENT):
        case completed(actions.FABRICATOR_REFUSE_DOCUMENT):
        case completed(actions.FABRICATOR_CLEAR_DOCUMENT):
            return _.assign(state, {
                error: false,
                completed: true,
                fabricator: action.fabricator
            });

        case failed(actions.FABRICATOR_ATTACH_DOCUMENT):
        case failed(actions.FABRICATOR_APPROVE_DOCUMENT):
        case failed(actions.FABRICATOR_REFUSE_DOCUMENT):
        case failed(actions.FABRICATOR_CLEAR_DOCUMENT):
            return _.assign(state, {
                error: true,
                completed: false,
                fabricator: null
            });

    }

});
