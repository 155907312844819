import {AssignationType, DOSSIER_TYPE, DossierStatus} from "../../../model/vars";

export function isClosingAssignationType(assignationType) {
    return [AssignationType.PRIVATE_CLOSING_DOCUMENTATION.value, AssignationType.PUBLIC_CLOSING_DOCUMENTATION.value].indexOf(assignationType) !== -1
}

export function isGiureAssignationType(assignationType) {
    return [AssignationType.GIURE_DOCUMENT.value].indexOf(assignationType) !== -1;
}

export function generateDossierDTOFromData(data) {
    if (!data)
        data = {}
    let dossierId = data["id"]
    let customer = data["_customer"];
    let customerId = customer ? customer.id : data["customerId"];
    let fabricator = data["_fabricator"];
    let fabricatorCollaborator = data["_fabricatorCollaborator"];
    let priceCalculatorSheet = data["priceCalculatorSheet"];
    let serviceFeeInvoiced = data["serviceFeeInvoiced"];
    let candidateAE = data["candidateAE"];
    let candidateENEA = data["candidateENEA"];
    let blindsPresentsType = data["blindsPresentsType"];
    let priority = data["priority"];
    let communicationENEA = data["communicationENEA"];
    let address = data["address"];
    let city = data["city"];
    let province = data["province"];
    let cadastralCategory = data["cadastralCategory"];
    let cadastralMunicipalCode = data["cadastralMunicipalCode"];
    let cadastralSheet = data["cadastralSheet"];
    let particle = data["particle"];
    let subParticle = data["subParticle"];
    let propertyEnjoymentTitle = data["propertyEnjoymentTitle"];
    let dl157 = data["dl157"]
    let freeConstruction = data["freeConstruction"]
    let totalCostHigherTenThousand = data["totalCostHigherTenThousand"]
    let buildingTitleOpeningDate = data["buildingTitleOpeningDate"]
    let isDelimitingHeatedWithNotHeated = data["isDelimitingHeatedWithNotHeated"]
    let estateTypeAndUse = data["estateTypeAndUse"]
    let giureEvaluation = data["giureEvaluation"]
    let giureOk = data["giureOk"]


    return {
        dossierId: dossierId? dossierId: null,
        acceptServiceCosts: data["acceptServiceCosts"],
        questionnaireArtifact: data["questionnaire"]?  data["questionnaire"].getQuestionnaireForSave() : {},
        customerId: customerId,
        fabricatorId: fabricator != null ? fabricator.id : null,
        fabricatorCollaboratorId: fabricatorCollaborator != null ? fabricatorCollaborator.id : null,
        notes: data["notes"],
        fabricatorNotes: data["fabricatorNotes"],
        priceCalculatorSheet: priceCalculatorSheet,
        candidateENEA: candidateENEA,
        communicationENEA: communicationENEA,
        candidateAE: candidateAE,
        priority: priority,
        serviceFeeInvoiced: serviceFeeInvoiced,
        blindsPresentsType: blindsPresentsType,
        workCompletionDate: data["workCompletionDate"],
        address: address,
        city:city,
        province:province,
        cadastralCategory:cadastralCategory,
        cadastralMunicipalCode:cadastralMunicipalCode,
        cadastralSheet:cadastralSheet,
        particle:particle,
        subParticle:subParticle,
        propertyEnjoymentTitle:propertyEnjoymentTitle,
        dl157:dl157,
        freeConstruction: freeConstruction,
        totalCostHigherTenThousand: totalCostHigherTenThousand,
        buildingTitleOpeningDate: buildingTitleOpeningDate,
        isDelimitingHeatedWithNotHeated: isDelimitingHeatedWithNotHeated,
        estateTypeAndUse: estateTypeAndUse,
        giureEvaluation: giureEvaluation,
        giureOk: giureOk

    }
}

export function generateDossierDTOFromModel(model) {
    return generateDossierDTOFromData(model.data)
}


export function isQuotationValueVisible(model) {
    if (model) {
        return (model.get("type") === DOSSIER_TYPE.ECOBONUS_2020_50 || model.get("type") === DOSSIER_TYPE.ECOBONUS_2021_50 || model.get("type") === DOSSIER_TYPE.ECOBONUS_2022_1) && model.get("climaticZone") && model.get("surfaceSize") &&  model.get("quotationType") && (model.get("blindsPresentsType") !== null && model.get("blindsPresentsType") !== "")
    }
    return false;
}

export function isQuotationValueVisibleWithoutBlind(model) {
    if (model) {
        return (model.get("type") === DOSSIER_TYPE.ECOBONUS_2020_50 || model.get("type") === DOSSIER_TYPE.ECOBONUS_2021_50 || model.get("type") === DOSSIER_TYPE.ECOBONUS_2022_1) && model.get("climaticZone") && model.get("surfaceSizeWithoutBlind") &&  model.get("quotationType") && (model.get("blindsPresentsType") !== null && model.get("blindsPresentsType") !== "")
    }
    return false;
}

export function formatEuroNumber(value) {
    if (value) {
        const formatter = new Intl.NumberFormat('it-IT', {
            style: 'currency',
            currency: 'EUR'
        })

        return formatter.format(value)
    }
    return "-"
}

export function formatNumber(value) {
    if (value) {
        return new Intl.NumberFormat('it-IT', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value)
    }
    return "-"
}


export function getCardColorFromModel(model) {
    let className = "card-colored"
    if (!model || !model.get("id"))
        className = className + " c-grey"
    else if (model.get("status") === DossierStatus.STATUS_QUOTATION.value)
        className = className + " c-warning"
    else if (model.get("status") === DossierStatus.STATUS_GIURE_EVALUATION.value)
        className = className + " c-evaluation"

    return className;
}
