import * as aj from "../aj/index";
import {createAsyncAction} from "../utils/ajex";
import * as SessionApi from "../api/session";
import {isFabricator} from "../api/session";
import {alert, hideLoader, showLoader, toast} from "../plugins";
import M from "../strings";
import _ from "underscore";
import {LOGIN, LOGOUT, RESUME_SESSION} from "./types";
import * as NotificationCenter from "../utils/notificationCenter";
import * as ui from "../web/utils/ui";
import {generateDossierItem, generateLoggedUserFirstScreenDossierType} from "../model/vars";
import * as responses from "../api/responses";
import {setActiveMenuItem} from "./menu";

export const login = createAsyncAction(LOGIN, data => {
    if (_.isEmpty(data.mail) || _.isEmpty(data.password)) {
        alert(M("problemOccoured"), M("mailAndPasswordRequired"), "warning")
        return;
    }

    aj.dispatch({
        type: LOGIN
    })

    showLoader()
    SessionApi.start(data.mail, data.password)
        .then(user => {
            hideLoader()
            toast(M("welcome") + " " + user.name);

            login.complete({user})
            performLoginUserAction(user)


            if (isFabricator()) {
                let message = "A seguito di un aggiornamento del sistema siete pregati di riscaricare i documenti relativi ad offerta e preventivo, grazie!";
                if (user.completeProfile === false) {
                    message = message.concat("<br/><hr/>").concat("Carica la documentazione firmata (nel profilo) per poter creare preventivi e pratiche");
                }
                message = message.concat("<br/><hr/>").concat("E' disponibile per le pratiche in compilazione, il documento WP_IL_2020_0 utile per la procedura da seguire per lavori iniziati precedentemente alla pubblicazione in G.U. del decreto del Mise del 06/08/2020 (Rif. Mail di Cinzia Guardiani del 08/09/2020 avente ad oggetto ***Autodichiarazione inizio lavori)");
                message = message.concat("<br/><hr/>").concat("I documenti \"Riferimenti Normativi 2020\" e \"Istruzioni Operative 2020\" sono stati aggiornati. E' necessario entrare in Profilo, riscaricare i due modelli, leggere, firmare e allegare.");

                Swal.fire({
                    title: M("warning"),
                    icon: 'warning',
                    html: message,
                    showCloseButton: true,
                    focusConfirm: false,
                })
            }

            if (!user.needToChangePassword) {

                let dossierType = generateLoggedUserFirstScreenDossierType();
                setActiveMenuItem({
                    item: generateDossierItem(dossierType)
                });

                ui.navigate("/entities/dossier/list/" + dossierType)
            }

        })
        .catch(e => {
            hideLoader()
            alert(M("ooops"), responses.msg(e), "error")

            login.fail()
        })
});

function performLoginUserAction (user) {
    NotificationCenter.invoke("login", user);
}

export const resumeSession = createAsyncAction(RESUME_SESSION, data => {
    aj.dispatch({
        type: RESUME_SESSION
    })

    SessionApi.resume()
        .then(user => {
            hideLoader()
            toast(M("welcome") + " " + user.name);

            resumeSession.complete({user})
            
            performLoginUserAction(user)
        })
        .catch(e => {
            hideLoader()

            resumeSession.fail()
        })
});

export const logout = aj.createAction(LOGOUT, data => {
    SessionApi.destroy()
        .then(() => {
            aj.dispatch({
                type: LOGOUT
            })

            NotificationCenter.invoke("logout");
        })
});

