import _ from "underscore";
import {ActionsCell, CheckCell, MultiTextCell, TextCell, TextCellWithSubText} from "./components/grids";
import {check, sanitize} from "../libs/validator";
import {
    AreaNoCard,
    Column,
    Image,
    Mail,
    MULTI_FILE_MODE_SINGLE, MultiFile,
    NewMultiFile,
    Number, ReadOnlyHTML,
    ReadOnlyText,
    Select,
    Spacer, Switch,
    Text,
    YesNo
} from "./components/forms";
import {EntitiesLookupContainer, ValuesLookupContainer, ValuesSelectContainer} from "./components/containers";
import M from "../strings";
import {
    AssignationType,
    AssignationTypeDatasource,
    CustomerType,
    CustomerTypeDatasource,
    DocumentTypeCustomerTypeDatasource,
    DocumentTypeTypology,
    DocumentTypeTypologyDatasource, DOSSIER_TYPE,
    DossierStatus,
    DossierTypeDatasource,
    getAssignationTypeDescription,
    getCustomerTypeDescription,
    getDocumentTypeTypologyDescription,
    KPMGGroupsDatasource,
    Sex,
    SexDatasource,
    UserDeviceStatusDatasource
} from "../model/vars";
import * as datasource from "../utils/datasource";
import moment from "moment";
import {FabricatorDocumentContainer} from "./components/fabricators/fabricatorDocumentContainer";
import {getSessionToken, hasPermission, isSuperuser} from "../api/session";
import {resetUserPassword} from "../actions/account";
import {FabricatorAddDocumentDialog} from "./components/fabricators/fabricatorAddDocumentDialog";
import {FabricatorRefuseDocumentDialog} from "./components/fabricators/fabricatorRefuseDocumentDialog";
import {updateFormData} from "../actions/entities";
import {FabricatorStore} from "../stores/fabricator";
import {DeviceGrid} from "./components/grids/deviceGrid";
import {entitySelectContainerField, valuesSelectContainerField} from "./commonFields";
import {dossierEntity} from "./dossierEntities";
import entitiesDashboard from "./entitiesDashboard";
import {dossier_2021_bonusCasa_Entity} from "./components/dossier_2021_bonusCasa/dossier_2021_bonusCasa_Entities";
import {dossier_2022} from "./components/dossiers_2022/dossier_2022_Entities";
import * as config from "../framework/config";
import {dossier_2022_bonusCasa_1_Entity} from "./components/dossier_2022_bonusCasa_1/dossier_2022_bonusCasa_1_Entities";
import {dossier_2022_bonusCasa_2_Entity} from "./components/dossier_2022_bonusCasa_2/dossier_2022_bonusCasa_2_Entities";
import {format, safeGet} from "../utils/lang";
import {toast} from "../plugins";
import {downloadFileFromUrl} from "../utils/customUtils";
import {dossier_2023_75_Entity} from "./components/dossier2023_75/dossier_2023_75_Entities";
import {Query} from "../framework/query";


const entities = {
    user: {
        grid: {
            title: M("adminUsersList"),
            descriptor: {
                columns: _.union([
                    {property: "code", header: M("code"), cell: TextCell, sortable: true, searchable: true},
                    {property: "lastname", header: M("lastname"), cell: TextCell, sortable: true, searchable: true},
                    {property: "name", header: M("name"), cell: TextCell, sortable: true, searchable: false},
                ], getPersonGridColumns())
            }
        },
        form: {
            title: M("editAdmin"),
            getActions(data) {
                let actions = ["back", "save", "save-go-back", "revisions"];
                // if (hasPermission("canResetPassword")) {
                // 	if (data && data.id) {
                // 		actions.push({
                // 			type: "button",
                // 			icon: "zmdi zmdi-brush",
                // 			tooltip: "Reset password",
                // 			action: () => {
                // 				swal({
                // 					title: M("confirm"),
                // 					text: "Verrà impostata una nuova password ed inviata all'indirizzo mail dell'utente",
                // 					showCancelButton: true
                // 				})
                // 				.then((res) => {
                // 					if (res.value) {
                // 						resetUserPassword({id: data.id})
                // 						if (data.id === getLoggedUser().id) {
                // 							swal({
                // 								title: M("confirm"),
                // 								text: "La tua password è stata resettata. Dovrai eseguire un nuovo accesso",
                // 								showCancelButton: false
                // 							})
                // 							.then((res) => {
                // 								if (res.value) {
                // 									logout();
                // 									ui.navigate("/login")
                // 								}
                // 							})
                // 						}
                // 					}
                // 				})
                // 				.catch((e) => {
                // 					logger.i(e)
                // 				})
                //
                // 			}
                // 		})
                // 	}
                // }
                return actions
            },
            descriptor: {
                areas: _.union([
                    {
                        title: M("generalInformations"),
                        subtitle: null,
                        fields: [
                            {
                                property: "name",
                                control: Text,
                                label: M("name"),
                                placeholder: M("name"),
                                size: "col-sm-4",
                            },
                            {
                                property: "lastname",
                                control: Text,
                                label: M("lastname"),
                                placeholder: M("lastname"),
                                size: "col-sm-4",
                            },
                            {
                                property: "_category",
                                label: M("referenceToUserCategory"),
                                control: EntitiesLookupContainer,
                                size: "col-sm-4",
                                props: {
                                    id: "user_category",
                                    mode: "single",
                                    entity: "adminUserCategory",
                                    selectionGrid: {
                                        columns: [
                                            {property: "code", header: M("code"), cell: TextCell},
                                            {property: "description", header: M("description"), cell: TextCell}
                                        ]
                                    },
                                    popupGrid: {
                                        columns: [
                                            {property: "code", header: M("code"), cell: TextCell},
                                            {property: "description", header: M("description"), cell: TextCell}
                                        ]
                                    }
                                }
                            },
                            {
                                property: "phoneNumber",
                                control: Text,
                                label: M("phoneNumber"),
                                placeholder: M("phoneNumber"),
                                size: "col-sm-4",
                            },
                        ]
                    }
                ], getPersonFormAreas())
            }
        }
    },

    role: {
        grid: {
            title: M("rolesList"),
            subtitle: M("rolesListDescription"),
            quickSearchEnabled: true,
            descriptor: {
                columns: [
                    {property: "role", header: "Role", cell: TextCell, sortable: true, searchable: true}
                ]
            }
        },
        form: {
            title: "Edit role",
            subtitle: null,
            descriptor: {
                onModelLoadFirstTime: (model) => {
                    if (model.get("id") == null) {
                        model.set("active", true)
                    }
                },
                fields: [
                    {
                        property: "role",
                        control: Text,
                        label: M("role"),
                        placeholder: M("nameOfRole"),
                        sanitizer: value => sanitize(value).trim(),
                        validator: value => check(value).notEmpty()
                    },
                    {
                        property: "_permissions",
                        label: M("permissions"),
                        placeholder: M("selectPermissions"),
                        control: ValuesLookupContainer,
                        //sanitizer: value => _.map(value, v => v.value),
                        validator: value => check(value).notEmpty(),
                        props: {
                            id: "role_permissions",
                            collection: "permissions",
                            mode: "multiple",
                            selectionGrid: {
                                columns: [
                                    {property: "label", header: M("name"), cell: TextCell}
                                ]
                            },
                            popupGrid: {
                                columns: [
                                    {property: "label", header: M("name"), cell: TextCell}
                                ]
                            }
                        }

                    }
                ]
            }
        }
    },

    adminUser: {
        grid: {
            title: M("adminUsersList"),
            descriptor: {
                columns: _.union([
                    {
                        property: "code",
                        header: M("code"),
                        cell: TextCell,
                        sortable: true,
                        searchable: true,
                        searchForm: {
                            showInCard: false,
                            fields: [
                                {
                                    property: "code",
                                    label: M("code"),
                                    control: Number,
                                    filterType: "eq",
                                    isInteger: true
                                }
                            ]
                        }
                    },
                    {property: "lastname", header: M("lastname"), cell: TextCell, sortable: true, searchable: true},
                    {property: "name", header: M("name"), cell: TextCell, sortable: true, searchable: true}
                ], getPersonGridColumns("adminUserCategory"))
            }
        },
        form: {
            getTitle: (data) => {
                if (data != null && data.id != null)
                    return M("editAdmin");
                else
                    return M("createAdmin");
            },
            getActions(data) {
                return getPersonActions(data);
            },
            descriptor: {
                onModelLoadFirstTime: (model) => {
                    if (model.get("id") == null) {
                        model.set("active", true)
                    }
                },
                areas: [
                    {
                        component: AreaNoCard,
                        className: "zero-padding",
                        fields: [
                            {
                                property: "c",
                                control: Column,
                                size: "col-sm-8",
                                className: "m-b-30",
                                noLateralPadding: false,
                                fields: [
                                    {
                                        property: "c_1",
                                        control: Column,
                                        size: "col-sm-12",
                                        className: "card",
                                        noLateralPadding: false,
                                        fields: [
                                            {
                                                property: "name",
                                                control: Text,
                                                label: M("name"),
                                                placeholder: M("name"),
                                                size: "col-sm-6",
                                                isRequired: true,
                                            },
                                            {
                                                property: "lastname",
                                                control: Text,
                                                label: M("lastname"),
                                                placeholder: M("lastname"),
                                                size: "col-sm-6",
                                                isRequired: true,
                                            },
                                            {
                                                property: "_category",
                                                label: M("referenceToUserCategory"),
                                                control: ValuesLookupContainer,
                                                size: "col-sm-4",
                                                isRequired: true,
                                                props: {
                                                    id: "user_category",
                                                    mode: "single",
                                                    collection: "adminUserCategories",
                                                    selectionGrid: {
                                                        columns: [
                                                            {property: "code", header: M("code"), cell: TextCell},
                                                            {
                                                                property: "description",
                                                                header: M("description"),
                                                                cell: TextCell
                                                            }
                                                        ]
                                                    },
                                                    popupGrid: {
                                                        columns: [
                                                            {property: "code", header: M("code"), cell: TextCell},
                                                            {
                                                                property: "description",
                                                                header: M("description"),
                                                                cell: TextCell
                                                            }
                                                        ]
                                                    }
                                                }
                                            },
                                            {
                                                property: "phoneNumber",
                                                control: Text,
                                                label: M("phoneNumber"),
                                                placeholder: M("phoneNumber"),
                                                size: "col-sm-4"
                                            },
                                            {
                                                property: "active",
                                                control: YesNo,
                                                label: M("active"),
                                                size: "col-sm-4",
                                                sanitizer: (value) => sanitize(value).toBoolean()
                                            },
                                        ]
                                    }
                                ]
                            },
                            {
                                property: "column",
                                control: Column,
                                size: "col-sm-4",
                                className: "m-b-30",
                                noLateralPadding: false,
                                useBoostrapRow: true,
                                fields: [
                                    {
                                        property: "column_1",
                                        control: Column,
                                        size: "col-sm-12",
                                        className: "card-colored p-t-10",
                                        noLateralPadding: false,
                                        useBoostrapRow: true,
                                        fields: [
                                            {
                                                property: "label",
                                                control: Spacer,
                                                size: "col-sm-12",
                                                emptyRow: true,
                                                className: "m-b-10",
                                                props: {
                                                    className: "card-title",
                                                    defaultTheme: false,
                                                    formatter: v => {
                                                        return M("accountInformations");
                                                    }
                                                },
                                            },
                                            {
                                                property: "mail",
                                                control: Mail,
                                                label: M("loginEmail"),
                                                placeholder: M("loginEmail"),
                                                size: "col-sm-12"
                                            },
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        }
    },

    endUser: {
        grid: {
            title: M("endUsersList"),
            descriptor: {
                columns: _.union([
                    {
                        property: "code",
                        header: M("code"),
                        cell: TextCell,
                        sortable: true,
                        searchable: true,
                        searchForm: {
                            showInCard: false,
                            fields: [
                                {
                                    property: "code",
                                    label: M("code"),
                                    control: Number,
                                    filterType: "eq",
                                    isInteger: true
                                }
                            ]
                        }
                    },
                    {property: "lastname", header: M("lastname"), cell: TextCell, sortable: true, searchable: true},
                    {property: "name", header: M("name"), cell: TextCell, sortable: true, searchable: true},
                    {property: "sex", header: M("sex"), cell: TextCell, sortable: true, searchable: true}
                ], getPersonGridColumns("endUserCategory"))
            }
        },
        form: {
            getTitle: (data) => {
                if (data != null && data.id != null)
                    return M("editEndUser");
                else
                    return M("createEndUser");
            },
            getActions(data) {
                return getPersonActions(data);
            },
            descriptor: {
                onModelLoadFirstTime: (model) => {
                    if (model.get("id") == null) {
                        model.set("active", true)
                    }
                },
                areas: [
                    {
                        component: AreaNoCard,
                        className: "zero-padding",
                        fields: [
                            {
                                property: "c",
                                control: Column,
                                size: "col-sm-8",
                                className: "m-b-30",
                                noLateralPadding: false,
                                fields: [
                                    {
                                        property: "c_1",
                                        control: Column,
                                        size: "col-sm-12",
                                        className: "card",
                                        noLateralPadding: false,
                                        fields: [
                                            {
                                                property: "_avatar",
                                                control: Image,
                                                label: M("image"),
                                                size: "col-sm-4"
                                            },
                                            {
                                                property: "c_2",
                                                control: Column,
                                                size: "col-sm-8",
                                                noLateralPadding: false,
                                                fields: [
                                                    {
                                                        property: "name",
                                                        control: Text,
                                                        label: M("name"),
                                                        placeholder: M("name"),
                                                        size: "col-12",
                                                        isRequired: true,
                                                    },
                                                    {
                                                        property: "lastname",
                                                        control: Text,
                                                        label: M("lastname"),
                                                        placeholder: M("lastname"),
                                                        size: "col-12",
                                                        isRequired: true,
                                                    },
                                                ],
                                            },
                                            {
                                                property: "_birthDate",
                                                control: Text,
                                                label: M("birthDate"),
                                                placeholder: M("birthDate"),
                                                size: "col-sm-4",
                                            },
                                            {
                                                property: "sex",
                                                control: Select,
                                                size: "col-sm-4",
                                                label: M("sex"),
                                                placeholder: M("sex"),
                                                props: {
                                                    id: "sex",
                                                    allowNull: true,
                                                    multiple: false,
                                                    datasource: datasource.fixed(
                                                        [
                                                            {label: "M", value: "M"},
                                                            {label: "F", value: "F"},
                                                        ]
                                                    ),
                                                }
                                            },
                                            {
                                                property: "_category",
                                                label: M("referenceToUserCategory"),
                                                control: ValuesLookupContainer,
                                                size: "col-sm-4",
                                                isRequired: true,
                                                props: {
                                                    id: "user_category",
                                                    mode: "single",
                                                    collection: "endUserCategories",
                                                    selectionGrid: {
                                                        columns: [
                                                            {property: "code", header: M("code"), cell: TextCell},
                                                            {
                                                                property: "description",
                                                                header: M("description"),
                                                                cell: TextCell
                                                            }
                                                        ]
                                                    },
                                                    popupGrid: {
                                                        columns: [
                                                            {property: "code", header: M("code"), cell: TextCell},
                                                            {
                                                                property: "description",
                                                                header: M("description"),
                                                                cell: TextCell
                                                            }
                                                        ]
                                                    }
                                                }
                                            },
                                            {
                                                property: "active",
                                                control: YesNo,
                                                label: M("active"),
                                                size: "col-sm-4",
                                                sanitizer: (value) => sanitize(value).toBoolean()
                                            },
                                        ]
                                    }
                                ]
                            },
                            {
                                property: "column",
                                control: Column,
                                size: "col-sm-4",
                                className: "m-b-30",
                                noLateralPadding: false,
                                useBoostrapRow: true,
                                fields: [
                                    {
                                        property: "column_1",
                                        control: Column,
                                        size: "col-sm-12",
                                        className: "card-colored p-t-10",
                                        noLateralPadding: false,
                                        useBoostrapRow: true,
                                        fields: [
                                            {
                                                property: "label",
                                                control: Spacer,
                                                size: "col-sm-12",
                                                emptyRow: true,
                                                className: "m-b-10",
                                                props: {
                                                    className: "card-title",
                                                    defaultTheme: false,
                                                    formatter: v => {
                                                        return M("accountInformations");
                                                    }
                                                },
                                            },
                                            {
                                                property: "mail",
                                                control: Mail,
                                                label: M("loginEmail"),
                                                placeholder: M("loginEmail"),
                                                size: "col-sm-12"
                                            },
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        }
    },

    fabricator: {
        grid: {
            title: M("fabricatorList"),
            quickSearchEnabled: true,
            descriptor: {
                columns: _.union([
                    {
                        property: "code",
                        header: M("code"),
                        cell: TextCell,
                        sortable: true,
                        searchable: true,
                        searchForm: {
                            showInCard: false,
                            fields: [
                                {
                                    property: "code",
                                    label: M("code"),
                                    control: Number,
                                    filterType: "eq",
                                    isInteger: true
                                }
                            ]
                        }
                    },
                    {
                        property: "businessName",
                        header: M("businessName"),
                        cell: TextCell,
                        sortable: true,
                        searchable: true
                    },
                    {
                        property: "address",
                        header: M("province"),
                        cell: TextCell,
                        sortable: true,
                        searchable: true,
                        props: {
                            formatter: v => {
                                return v != null ? v.province : "";
                            }
                        }
                    },
                    {
                        property: "address",
                        header: M("municipality"),
                        cell: TextCell,
                        sortable: true,
                        searchable: true,
                        props: {
                            formatter: v => {
                                return v != null ? v.municipality : "";
                            }
                        }
                    },
                    {
                        property: "dossierTypesDescription",
                        header: "Tipologie pratiche",
                        cell: TextCell,
                        sortable: true,
                        searchable: true
                    },
                    {
                        property: "_collaborators",
                        header: "Collaboratori",
                        cell: MultiTextCell,
                        sortable: false,
                        searchable: false
                    },
                ], getPersonGridColumns("fabricatorCategory"))
            }
        },
        form: {

            getTitle: (data) => {
                if (data != null && data.id != null)
                    return M("editFabricator");
                else
                    return M("createFabricator");
            },
            /*
            getActions(data) {
                return getPersonActions(data);
            },*/
            descriptor: {
                visibility: (field, model, descriptor) => {
                    switch (field.property) {
                        case "dossierTypes":
                        case "active":
                        case "_category":
                        case "notes":
                        case "agentId":
                        case "maxDossierNumber":
                        case "enableGiureEvaluation":
                            return isSuperuser();
                        default:
                            return true;
                    }
                },
                onModelLoadFirstTime: (model) => {
                    if (model.get("id") == null) {
                        model.set("active", true);
                        model.set("appEnabled", true);
                    }
                },
                stores: [FabricatorStore],
                formUpdateFunction: (newState, oldState, model) => {
                    if (oldState.fabricator != newState.fabricator) {
                        updateFormData({
                            data: newState.fabricator,
                            discriminator: "entity_form_fabricator"
                        })
                    }
                },
                areas: [
                    {
                        //component: AreaNoCard,
                        //className: "zero-padding",
                        fields: [
                            {
                                property: "addDocumentDialog",
                                control: FabricatorAddDocumentDialog,
                                emptyRow: true
                            },
                            {
                                property: "refuseDocumentDialog",
                                control: FabricatorRefuseDocumentDialog,
                                emptyRow: true
                            },
                            {
                                property: "code",
                                getControl: (model) => {
                                    return (model.get("id") == null || isSuperuser()) ? Number : ReadOnlyText;
                                },
                                label: M("code"),
                                placeholder: M("code"),
                                size: "col-sm-4",
                                isRequired: true,
                            },
                            {
                                property: "businessName",
                                control: Text,
                                label: M("businessName"),
                                placeholder: M("businessName"),
                                size: "col-sm-4",
                                isRequired: true,
                            },
                            {
                                property: "_category",
                                label: M("referenceToUserCategory"),
                                control: ValuesLookupContainer,
                                size: "col-sm-4",
                                isRequired: true,
                                props: {
                                    id: "user_category",
                                    mode: "single",
                                    collection: "fabricatorCategories",
                                    selectionGrid: {
                                        columns: [
                                            {property: "code", header: M("code"), cell: TextCell},
                                            {
                                                property: "description",
                                                header: M("description"),
                                                cell: TextCell
                                            }
                                        ]
                                    },
                                    popupGrid: {
                                        columns: [
                                            {property: "code", header: M("code"), cell: TextCell},
                                            {
                                                property: "description",
                                                header: M("description"),
                                                cell: TextCell
                                            }
                                        ]
                                    }
                                }
                            },

                            {
                                property: "referent",
                                control: Text,
                                label: M("referent"),
                                placeholder: M("referent"),
                                size: "col-sm-4",
                            },

                            {
                                property: "phoneNumber",
                                control: Text,
                                label: M("phoneNumber"),
                                placeholder: M("phoneNumber"),
                                size: "col-sm-4",
                            },
                            {
                                property: "fiscalCode",
                                control: Text,
                                label: M("fiscalCode"),
                                placeholder: M("fiscalCode"),
                                size: "col-sm-4",
                            },
                            {
                                property: "vatCode",
                                control: Text,
                                label: M("vatCode"),
                                placeholder: M("vatCode"),
                                size: "col-sm-4",
                                isRequired: true,
                            },
                            entitySelectContainerField({
                                property: "agentId",
                                entity: "agent",
                                label: "Agente",
                                size: "col-sm-4",
                                mode: "single",

                            }),
                            {
                                property: "active",
                                control: YesNo,
                                label: M("active"),
                                size: "col-sm-4",
                                sanitizer: (value) => sanitize(value).toBoolean()
                            },

                            {
                                property: "enableGiureEvaluation",
                                control: YesNo,
                                label: M("enableGiureEvaluation"),
                                size: "col-sm-4",
                                sanitizer: (value) => sanitize(value).toBoolean()
                            },

                            valuesSelectContainerField({
                                property: "dossierTypes",
                                isRequired: true,
                                collection: "dossierType",
                                itemValue: "value",
                                itemLabel: "label",
                                size: "col-sm-4",
                                mode: "multiple",
                                visibility: () => {
                                    return isSuperuser()
                            }
                            }),
                            {
                                property: "maxDossierNumber",
                                control: Number,
                                label: M("maxDossierNumber"),
                                placeholder: M("maxDossierNumber"),
                                size: "col-sm-4",
                                isRequired: true,
                                props: {
                                    onlyInteger:true
                                }

                            },
                            {
                                property: "notes",
                                control: Text,
                                label: M("notes"),
                                placeholder: M("notes"),
                                size: "col-sm-12",
                                props: {
                                    maxLength: 1000
                                }
                            },
                            // {
                            //     property: "column",
                            //     control: Column,
                            //     size: "col-sm-4",
                            //     className: "m-b-30",
                            //     noLateralPadding: false,
                            //     useBoostrapRow: true,
                            //     fields: [
                            //         {
                            //             property: "column_1",
                            //             control: Column,
                            //             size: "col-sm-12",
                            //             className: "card-colored p-t-10",
                            //             noLateralPadding: false,
                            //             useBoostrapRow: true,
                            //             fields: [
                            //                 {
                            //                     property:"label",
                            //                     control: Spacer,
                            //                     size: "col-sm-12",
                            //                     emptyRow: true,
                            //                     className: "m-b-10",
                            //                     props: {
                            //                         className: "card-title",
                            //                         defaultTheme: false,
                            //                         formatter: v => {
                            //                             return M("accountInformations");
                            //                         }
                            //                     },
                            //                 },
                            //                 {
                            //                     property: "mail",
                            //                     control: Mail,
                            //                     label: M("loginEmail"),
                            //                     placeholder: M("loginEmail"),
                            //                     size: "col-sm-12"
                            //                 },
                            //                 {
                            //                     property: "appEnabled",
                            //                     control: YesNo,
                            //                     label: M("appEnabled"),
                            //                     size: "col-sm-12",
                            //                     props: {
                            //                         className: "yesno-inverse"
                            //                     }
                            //                 },
                            //             ]
                            //         }
                            //     ]
                            // }
                        ],
                    },

                    {
                        title: "Informazioni sede legale",
                        subtitle: null,
                        fields:[
                            {
                                property: "_country",
                                control: Text,
                                label: M("country"),
                                placeholder: M("country"),
                                size: "col-sm-4",
                                isRequired: true,
                            },
                            {
                                property: "_region",
                                control: Text,
                                label: M("region"),
                                placeholder: M("region"),
                                size: "col-sm-4",
                                isRequired: true,
                            },
                            {
                                property: "_province",
                                control: Text,
                                label: M("province"),
                                placeholder: M("province"),
                                size: "col-sm-4",
                                isRequired: true,
                            },
                            {
                                property: "_postalCode",
                                control: Text,
                                label: M("postalCode"),
                                placeholder: M("postalCode"),
                                size: "col-sm-4",
                                isRequired: true,
                            },
                            {
                                property: "_municipality",
                                control: Text,
                                label: M("municipality"),
                                placeholder: M("municipality"),
                                size: "col-sm-4",
                                isRequired: true,
                            },
                            {
                                property: "_address",
                                control: Text,
                                label: M("address"),
                                placeholder: M("address"),
                                size: "col-sm-4",
                                isRequired: true,
                            },
                        ]
                    },

                    {
                        title: M("legalPersonInformations"),
                        subtitle: null,
                        fields: [
                            {
                                property: "name",
                                control: Text,
                                label: M("name"),
                                placeholder: M("name"),
                                size: "col-sm-4",
                            },
                            {
                                property: "lastname",
                                control: Text,
                                label: M("lastname"),
                                placeholder: M("lastname"),
                                size: "col-sm-4",
                            },
                            {
                                property: "_birthDate",
                                control: Text,
                                label: M("birthDate"),
                                placeholder: M("birthDate"),
                                size: "col-sm-4",
                            },
                            {
                                property: "birthPlace",
                                control: Text,
                                label: M("birthPlace"),
                                placeholder: M("birthPlace"),
                                size: "col-sm-4",
                            },
                            {
                                property: "refFiscalCode",
                                control: Text,
                                label: M("fiscalCode"),
                                placeholder: M("fiscalCode"),
                                size: "col-sm-4",
                            },

                            // valuesSelectContainerField({
                            //     property: "dossierTypes",
                            //     isRequired: true,
                            //     collection: "dossierType",
                            //     itemValue: "value",
                            //     itemLabel: "label",
                            //     size: "col-sm-4",
                            //     mode: "multiple"
                            // }),

                        ]
                    },
                    {
                        component: AreaNoCard,
                        className: "zero-padding",
                        visibility: model => {
                            return model.get("id") != null && model.get("documents").length > 0;
                        },
                        fields: [
                            {
                                property: "documents",
                                control: FabricatorDocumentContainer,
                                label: null,
                                size: "col-sm-12",
                            }
                        ]
                    },
                ],
            }
        }
    },

    mailLog: {
        grid: {
            canDelete: false,
            canCreate: false,
            canEdit: true,
            title: M("mailLog"),
            subtitle: null,
            initialQuery: new Query().sort("date", true).setPage(1).setRowsPerPage(100),
            descriptor: {

                columns: [
                    {property: "subject", header: M("subject"), cell: TextCell, sortable: false, searchable: false},
                    {property: "sender", header: M("sender"), cell: TextCell, sortable: false, searchable: true},
                    {property: "recipient", header: M("recipient"), cell: TextCell, sortable: false, searchable: true},
                    {
                        property: "date", header: M("date"), cell: TextCell, sortable: true, searchable: false,
                        props: {
                            formatter: v => {
                                return v !== null ? moment(v).format("DD/MM/YYYY HH:mm") : ""
                            }
                        }
                    },
                    {
                        property: "log", header: M("status"), cell: TextCell, sortable: true, searchable: false,
                        props: {
                            formatter: v => {
                                return v != null ? (v === "OK" ? "OK" : "ERRORE") : ""
                            }
                        }
                    },
                ]
            }
        },
        form: {
            title: M("mailLog"),
            subtitle: null,
            canSave: () => {
                return false
            },
            descriptor: {
                canSave: () => {
                    return false
                },
                areas: [
                    {
                        title: M("generalInformations"),
                        subtitle: null,
                        fields: [
                            {
                                property: "subject",
                                control: ReadOnlyText,
                                label: M("subject"),
                                placeholder: M("subject"),
                                size: "col-sm-6"
                            },
                            {
                                property: "date",
                                control: ReadOnlyText,
                                label: M("date"),
                                placeholder: M("date"),
                                size: "col-sm-6",
                                props: {
                                    formatter: v => {
                                        return v !== null ? moment(v).format("DD/MM/YYYY HH:mm") : ""
                                    }
                                }
                            },
                            {
                                property: "sender",
                                control: ReadOnlyText,
                                label: M("sender"),
                                placeholder: M("sender"),
                                size: "col-sm-5"
                            },
                            {
                                property: "recipient",
                                control: ReadOnlyText,
                                label: M("recipient"),
                                placeholder: M("recipient"),
                                size: "col-sm-5"
                            },
                            {
                                property: "log",
                                control: ReadOnlyText,
                                label: M("status"),
                                placeholder: M("status"),
                                size: "col-sm-2",
                                props: {
                                    formatter: v => {
                                        return v != null ? (v === "OK" ? "OK" : "ERRORE") : ""
                                    }
                                }
                            },
                        ]
                    },
                    {
                        title: M("text"),
                        subtitle: null,
                        fields: [
                            {
                                property: "text",
                                control: ReadOnlyHTML,
                                label: null,
                                placeholder: M("text"),
                                size: "col-sm-12"
                            },
                        ]
                    },
                    {
                        title: M("attachments"),
                        subtitle: null,
                        visibility: model => {
                            return model.get("_attachments") != null && model.get("_attachments").length > 0;
                        },
                        fields: [
                            {
                                title: "",
                                label: null,
                                property: "_attachments",
                                control: MultiFile,
                                props: {
                                    download: (state) => {
                                        downloadFromFileServer(state)
                                    },
                                    filesNumber: 10,
                                    canDelete: false,
                                    readOnly: true,
                                }
                            }
                        ]
                    },
                ]
            }
        }
    },

    fabricatorCollaborator: {
        grid: {
            title: M("collaboratorList"),
            descriptor: {
                columns: [
                    {
                        property: "code",
                        header: M("code"),
                        cell: TextCell,
                        sortable: true,
                        searchable: true,
                        searchForm: {
                            showInCard: false,
                            fields: [
                                {
                                    property: "code",
                                    label: M("code"),
                                    control: Number,
                                    filterType: "eq",
                                    isInteger: true
                                }
                            ]
                        }
                    },
                    {
                        property: "user",
                        header: M("mail"),
                        cell: TextCell,
                        sortable: true,
                        searchable: true,
                        props: {
                            formatter: (u) => {

                                return u ? u.mail : null
                            }
                        }
                    },
                    {property: "lastname", header: M("lastname"), cell: TextCell, sortable: true, searchable: true},
                    {property: "name", header: M("name"), cell: TextCell, sortable: true, searchable: true},
                    {
                        property: "_fabricator",
                        header: M("fabricator"),
                        cell: TextCell,
                        sortable: true,
                        searchable: true,
                        searchForm: {
                            showInCard: false,
                            fields: [
                                {
                                    property: "fabricatorId",
                                    label: M("fabricator"),
                                    control: ValuesSelectContainer,
                                    filterType: "eq",
                                    props: {
                                        id: "dossier_fabricator",
                                        mode: "single",
                                        allowNull: true,
                                        searchEnabled: true,
                                        collection: "fabricators",
                                        getSingleItemLabel: (value) => {
                                            return value.businessName
                                        },
                                        getSingleItemValue: (value) => {
                                            return value.id
                                        },
                                        formatter: v => {
                                            return v != null ? v.businessName : "";
                                        }
                                    }
                                },
                            ]
                        },
                        props: {
                            formatter: v => {
                                return v != null ? v.businessName : "";
                            }
                        }
                    },
                    {
                        property: "active",
                        header: M("active"),
                        cell: CheckCell,
                        sortable: true,
                        searchable: true,
                        searchForm: {
                            showInCard: false,
                            fields: [
                                {
                                    property: "active",
                                    label: M("status"),
                                    control: Select,
                                    filterType: "eq",
                                    props: {
                                        allowNull: true,
                                        datasource: datasource.fixed([
                                            {label: M("active"), value: true},
                                            {label: M("notActive"), value: false},
                                        ]),
                                    },
                                }
                            ]
                        },
                    },
                    {
                        property: "appEnabled",
                        header: "Abilitato all'app",
                        cell: CheckCell,
                        sortable: true,
                        searchable: true,
                        searchForm: {
                            showInCard: false,
                            fields: [
                                {
                                    property: "appEnabled",
                                    label: M("appEnabled"),
                                    control: Select,
                                    filterType: "eq",
                                    props: {
                                        allowNull: true,
                                        datasource: datasource.fixed([
                                            {label: M("yes"), value: true},
                                            {label: M("no"), value: false},
                                        ]),
                                    },
                                }
                            ]
                        },
                    },
                    {
                        property: "_devicesDescriptions",
                        header: M("pairings"),
                        cell: MultiTextCell,
                        sortable: false,
                        searchable: false
                    },
                    {
                        property: "_dossierCount",
                        header: M("dossiers"),
                        cell: TextCell,
                        sortable: false,
                        searchable: false
                    },
                ]
            }
        },
        form: {
            getTitle: (data) => {
                if (data != null && data.id != null)
                    return M("editCollaborator");
                else
                    return M("createCollaborator");
            },
            getActions(data) {
                return getPersonActions(data);
            },
            descriptor: {
                onModelLoadFirstTime: (model) => {
                    if (model.get("id") == null) {
                        model.set("active", true);
                        model.set("appEnabled", true);
                        model.set("owner", false);
                    }
                },
                areas: [
                    {
                        title: M("generalInformations"),
                        subtitle: null,
                        fields: [
                            {
                                property: "code",
                                control: ReadOnlyText,
                                label: M("code"),
                                placeholder: M("code"),
                                size: "col-sm-2",
                            },
                            {
                                property: "name",
                                control: Text,
                                label: M("name"),
                                placeholder: M("name"),
                                size: "col-sm-5",
                                isRequired: true,
                            },
                            {
                                property: "lastname",
                                control: Text,
                                label: M("lastname"),
                                placeholder: M("lastname"),
                                size: "col-sm-5",
                                isRequired: true,
                            },

                            {
                                property: "_fabricator",
                                label: M("fabricator"),
                                control: ValuesLookupContainer,
                                size: "col-sm-4",
                                isRequired: true,
                                props: {
                                    id: "collaborator_fabricator",
                                    mode: "single",
                                    collection: "fabricators",
                                    selectionGrid: {
                                        columns: [
                                            {property: "code", header: M("code"), cell: TextCell},
                                            {property: "businessName", header: M("businessName"), cell: TextCell}
                                        ]
                                    },
                                    popupGrid: {
                                        columns: [
                                            {property: "code", header: M("code"), cell: TextCell},
                                            {property: "businessName", header: M("businessName"), cell: TextCell}
                                        ]
                                    },
                                    formatter: v => {
                                        return v != null ? v.businessName : "";
                                    }
                                }
                            },
                            {
                                property: "owner",
                                control: YesNo,
                                label: M("responsible"),
                                size: "col-sm-4",
                                sanitizer: (value) => sanitize(value).toBoolean()
                            },

                            {
                                property: "telephoneNumber",
                                control: Text,
                                label: M("phoneNumber"),
                                placeholder: M("phoneNumber"),
                                size: "col-sm-4",
                                isRequired: true,
                            },
                            {
                                property: "mail",
                                control: Mail,
                                label: M("loginEmail"),
                                placeholder: M("loginEmail"),
                                size: "col-sm-4",
                                isRequired: true,
                            },
                            {
                                property: "active",
                                control: YesNo,
                                label: M("active"),
                                size: "col-sm-4",
                                sanitizer: (value) => sanitize(value).toBoolean()
                            },
                            {
                                property: "appEnabled",
                                control: YesNo,
                                label: M("appEnabled"),
                                size: "col-sm-4",
                                sanitizer: (value) => sanitize(value).toBoolean()
                            },

                        ]
                    },
                    {
                        visibility(model) {
                            return model.get("id") != null;
                        },
                        tabs: [
                            {
                                id: "device-tab",
                                title: M("devices"),
                                fields: [
                                    {
                                        property: "_devices",
                                        control: DeviceGrid,
                                    }

                                ]

                            },
                        ]
                    },
                ]
            }
        }
    },

    giure: {
        grid: {
            title: M("giureList"),
            descriptor: {
                columns: [
                    {
                        property: "user",
                        header: M("mail"),
                        cell: TextCell,
                        sortable: true,
                        searchable: true,
                        props: {
                            formatter: (u) => {
                                return u ? u.mail : null
                            }
                        }
                    },
                    {property: "lastname", header: M("lastname"), cell: TextCell, sortable: true, searchable: true},
                    {property: "name", header: M("name"), cell: TextCell, sortable: true, searchable: true},
                    {
                        property: "active",
                        header: M("active"),
                        cell: CheckCell,
                        sortable: true,
                        searchable: true,
                        searchForm: {
                            showInCard: false,
                            fields: [
                                {
                                    property: "active",
                                    label: M("status"),
                                    control: Select,
                                    filterType: "eq",
                                    props: {
                                        allowNull: true,
                                        datasource: datasource.fixed([
                                            {label: M("active"), value: true},
                                            {label: M("notActive"), value: false},
                                        ]),
                                    },
                                }
                            ]
                        },
                    }
                ]
            }
        },
        form: {
            getTitle: (data) => {
                if (data != null && data.id != null)
                    return M("editGiure");
                else
                    return M("createGiure");
            },
            getActions(data) {
                return getPersonActions(data);
            },
            descriptor: {
                onModelLoadFirstTime: (model) => {
                    if (model.get("id") == null) {
                        model.set("active", true);
                    }
                },
                areas: [
                    {
                        title: M("generalInformations"),
                        subtitle: null,
                        fields: [
                            {
                                property: "name",
                                control: Text,
                                label: M("name"),
                                placeholder: M("name"),
                                size: "col-sm-5",
                                isRequired: true,
                            },
                            {
                                property: "lastname",
                                control: Text,
                                label: M("lastname"),
                                placeholder: M("lastname"),
                                size: "col-sm-5",
                                isRequired: true,
                            },

                            {
                                property: "mail",
                                control: Mail,
                                label: M("loginEmail"),
                                placeholder: M("loginEmail"),
                                size: "col-sm-4",
                                isRequired: true,
                            },
                            {
                                property: "active",
                                control: YesNo,
                                label: M("active"),
                                size: "col-sm-4",
                                sanitizer: (value) => sanitize(value).toBoolean()
                            }

                        ]
                    }
                ]
            }
        }
    },

    customer: {
        grid: {
            title: M("customerList"),
            quickSearchEnabled: true,
            descriptor: {
                columns: [
                    {
                        property: "code",
                        header: M("code"),
                        cell: TextCell,
                        sortable: true,
                        searchable: true,
                        searchForm: {
                            showInCard: false,
                            fields: [
                                {
                                    property: "code",
                                    label: M("code"),
                                    control: Number,
                                    filterType: "eq",
                                    isInteger: true
                                }
                            ]
                        }
                    },
                    {
                        property: "subjectType",
                        header: M("type"),
                        cell: TextCell,
                        sortable: true,
                        searchable: true,
                        searchForm: {
                            showInCard: false,
                            fields: [
                                {
                                    property: "subjectType",
                                    label: M("type"),
                                    control: Select,
                                    filterType: "eq",
                                    props: {
                                        allowNull: true,
                                        datasource: CustomerTypeDatasource,
                                    },
                                }
                            ]
                        },
                        props: {
                            formatter: v => {
                                return v != null ? getCustomerTypeDescription(v) : "";
                            }
                        }
                    },
                    {
                        property: "name",
                        header: M("name"),
                        cell: TextCell,
                        sortable: true,
                        searchable: true,
                        searchForm: {
                            showInCard: false,
                            fields: [
                                {
                                    property: "name",
                                    label: M("name"),
                                    control: Text,
                                    filterType: "like"
                                }
                            ]
                        }
                    },
                    {
                        property: "fabricator",
                        header: M("fabricator"),
                        cell: TextCell,
                        sortable: true,
                        searchable: true,
                        searchForm: {
                            showInCard: false,
                            fields: [
                                {
                                    property: "fabricatorId",
                                    label: M("fabricator"),
                                    control: ValuesSelectContainer,
                                    filterType: "eq",
                                    props: {
                                        id: "dossier_fabricator",
                                        mode: "single",
                                        allowNull: true,
                                        searchEnabled: true,
                                        collection: "fabricators",
                                        getSingleItemLabel: (value) => {
                                            return value.businessName
                                        },
                                        getSingleItemValue: (value) => {
                                            return value.id
                                        },
                                        formatter: v => {
                                            return v != null ? v.businessName : "";
                                        }
                                    }
                                },
                            ]
                        },
                        props: {
                            formatter: v => {
                                return v != null ? v.businessName : "";
                            }
                        }
                    },
                    {
                        property: "active",
                        header: M("active"),
                        cell: CheckCell,
                        sortable: true,
                        searchable: true,
                        searchForm: {
                            showInCard: false,
                            fields: [
                                {
                                    property: "active",
                                    label: M("status"),
                                    control: Select,
                                    filterType: "eq",
                                    props: {
                                        allowNull: true,
                                        datasource: datasource.fixed([
                                            {label: M("active"), value: true},
                                            {label: M("notActive"), value: false},
                                        ]),
                                    },
                                }
                            ]
                        },
                    },
                ]
            }
        },
        form: {
            title: M("editCustomer"),
            getTitle: (data) => {
                if (data != null && data.id != null)
                    return M("editCustomer");
                else
                    return M("createCustomer");
            },
            getActions(data) {
                let actions = ["back", "save", "save-go-back", "revisions"];
                if (data && data.id) {
                    actions.push({
                        type: "button",
                        icon: "zmdi zmdi-download",
                        tooltip: M("Scarica template privacy"),
                        action: () => {
                            window.open(config.get("document.url") + "/generateRequiredPolicy?customerId=" + data.id + "&__TOKEN=" + encodeURIComponent(getSessionToken()))
                        }
                    })
                }

                return actions;
            },
            descriptor: {
                onModelLoadFirstTime: (model) => {
                    model.on("property:change", (property, value) => {
                        if (property === "subjectType" || property === "sex") {
                            model.set("_address", null);
                            model.set("_city", null);
                            model.invalidateForm()
                        }
                    })
                    if (model.get("id") == null) {
                        model.set("active", true);
                        model.set("subjectType", CustomerType.SUBJECT_TYPE_PHYSICAL_PERSON.value);
                        model.set("sex", Sex.M.value)
                    }

                },
                visibility: (field, model, descriptor) => {
                    switch (field.property) {
                        case "firstName":
                        case "lastName":
                        case "sex":
                        case "_birthCountry":
                        case "_birthRegion":
                        case "_birthProvince":
                        case "_birthPostalCode":
                        case "_birthMunicipality":
                        case "_birthDate":
                            return (model != null && model.get("subjectType") === CustomerType.SUBJECT_TYPE_PHYSICAL_PERSON.value);
                        case "vatCode":
                        case "vatCodeDisabled":
                        case "socialReason":
                            return (model != null && model.get("subjectType") === CustomerType.SUBJECT_TYPE_LEGAL_PERSON.value);
                        default:
                            return true;
                    }
                },
                areas: [
                    {
                        title: M("generalInformations"),
                        subtitle: null,
                        fields: [
                            {
                                property: "code",
                                control: ReadOnlyText,
                                label: M("code"),
                                placeholder: M("code"),
                                size: "col-sm-3",
                            },
                            {
                                property: "subjectType",
                                control: Select,
                                size: "col-sm-3",
                                label: M("type"),
                                placeholder: M("type"),
                                props: {
                                    id: "customer",
                                    allowNull: false,
                                    multiple: false,
                                    datasource: CustomerTypeDatasource,
                                }
                            },
                            {
                                property: "_fabricator",
                                label: M("createdBy"),
                                getControl: () => {
                                    return isSuperuser() ? ValuesLookupContainer : ReadOnlyText
                                },
                                size: "col-sm-3",
                                isRequired: true,
                                props: {
                                    id: "customer_fabricator",
                                    mode: "single",
                                    collection: "fabricators",
                                    selectionGrid: {
                                        columns: [
                                            {property: "code", header: M("code"), cell: TextCell},
                                            {property: "businessName", header: M("businessName"), cell: TextCell}
                                        ]
                                    },
                                    popupGrid: {
                                        columns: [
                                            {property: "code", header: M("code"), cell: TextCell},
                                            {property: "businessName", header: M("businessName"), cell: TextCell}
                                        ]
                                    },
                                    formatter: v => {
                                        return v != null ? v.businessName : "";
                                    }
                                }
                            },
                            {
                                property: "active",
                                control: YesNo,
                                label: M("active"),
                                size: "col-sm-3",
                                sanitizer: (value) => sanitize(value).toBoolean()
                            },
                        ]
                    },
                    {
                        title: M("customerInformations"),
                        subtitle: null,
                        fields: [
                            {
                                property: "firstName",
                                control: Text,
                                label: M("name"),
                                placeholder: M("name"),
                                size: "col-sm-6",
                                isRequired: true,
                            },
                            {
                                property: "lastName",
                                control: Text,
                                label: M("lastname"),
                                placeholder: M("lastname"),
                                size: "col-sm-6",
                                isRequired: true,
                            },
                            {
                                property: "sex",
                                control: Select,
                                size: "col-sm-4",
                                label: M("sex"),
                                placeholder: M("sex"),
                                isRequired: true,
                                props: {
                                    id: "sex",
                                    allowNull: false,
                                    multiple: false,
                                    datasource: SexDatasource,
                                }
                            },
                            {
                                property: "_birthDate",
                                control: Text,
                                label: M("birthDate"),
                                placeholder: M("birthDate"),
                                size: "col-sm-4",
                                isRequired: true,
                            },
                            {
                                property: "socialReason",
                                control: Text,
                                label: M("businessName"),
                                placeholder: M("businessName"),
                                size: "col-sm-12",
                                isRequired: true,
                            },
                            {
                                property: "vatCodeDisabled",
                                control: YesNo,
                                label: M("vatCodeDisabled"),
                                size: "col-sm-4",
                                sanitizer: (value) => sanitize(value).toBoolean()
                            },
                            {
                                property: "vatCode",
                                control: Text,
                                label: M("vatCode"),
                                placeholder: M("vatCode"),
                                size: "col-sm-4",
                                isRequired: model => {
                                    return model && !model.get("vatCodeDisabled")
                                }
                            },
                            {
                                property: "fiscalCode",
                                control: Text,
                                label: M("fiscalCode"),
                                placeholder: M("fiscalCode"),
                                size: "col-sm-4",
                                isRequired: model => {
                                    return model && model.get("subjectType") === CustomerType.SUBJECT_TYPE_PHYSICAL_PERSON.value || (model.get("subjectType") === CustomerType.SUBJECT_TYPE_LEGAL_PERSON.value && model.get("vatCodeDisabled"))
                                }
                            },
                            {
                                property: "phoneNumber",
                                control: Text,
                                label: M("phoneNumber"),
                                placeholder: M("phoneNumber"),
                                size: "col-sm-4",
                                isRequired: true,
                            },
                            {
                                property: "mail",
                                control: Mail,
                                label: M("mail"),
                                placeholder: M("mailAddress"),
                                size: "col-sm-4"
                            },
                            valuesSelectContainerField({
                                property: "companyCustomType",
                                isRequired: false,
                                collection: "companyCustomType",
                                itemValue: "value",
                                itemLabel: "label",
                                size: "col-sm-4",
                            }),
                        ]
                    },
                    {
                        title: M("placeOfBirth"),
                        subtitle: null,
                        visibility: model => {
                            return model != null && model.get("subjectType") === CustomerType.SUBJECT_TYPE_PHYSICAL_PERSON.value
                        },
                        fields: [
                            // {
                            //     property: "_birthCity",
                            //     label: M("placeOfBirth"),
                            //     size: "col-sm-4",
                            //     control: ValuesLookupContainer,
                            //     formatter: v => {
                            //         return v !== null ? v.description + ", " + (v.province !== null ? v.province.description : "") + ", " + v.cap : ""
                            //     },
                            //     props: {
                            //         id: "birth_city_province",
                            //         mode: "single",
                            //         getCollection: (data) => {
                            //             return "cities"
                            //         },
                            //         selectionGrid: {
                            //             columns: [
                            //                 {property: "description", header: M("name"), cell: TextCell},
                            //                 {property: "cap", header: M("cap"), cell: TextCell},
                            //                 {
                            //                     property: "province",
                            //                     header: M("province"),
                            //                     cell: TextCell,
                            //                     props: {
                            //                         formatter: (value) => {
                            //                             return value ? value.description : ""
                            //                         }
                            //                     }
                            //                 },
                            //             ]
                            //         },
                            //         popupGrid: {
                            //             columns: [
                            //                 {property: "description", header: M("name"), cell: TextCell},
                            //                 {property: "cap", header: M("cap"), cell: TextCell},
                            //                 {
                            //                     property: "province",
                            //                     header: M("province"),
                            //                     cell: TextCell,
                            //                     props: {
                            //                         formatter: (value) => {
                            //                             return value ? value.description : ""
                            //                         }
                            //                     }
                            //                 },
                            //             ]
                            //         }
                            //     }
                            // },
                            {
                                property: "_birthCountry",
                                control: Text,
                                label: M("country"),
                                placeholder: M("country"),
                                size: "col-sm-4",
                                isRequired: true,
                            },
                            {
                                property: "_birthRegion",
                                control: Text,
                                label: M("region"),
                                placeholder: M("region"),
                                size: "col-sm-4",
                                isRequired: true,
                            },
                            {
                                property: "_birthProvince",
                                control: Text,
                                label: M("province"),
                                placeholder: M("province"),
                                size: "col-sm-4",
                                isRequired: true,
                            },
                            {
                                property: "_birthPostalCode",
                                control: Text,
                                label: M("postalCode"),
                                placeholder: M("postalCode"),
                                size: "col-sm-4",
                                isRequired: true,
                            },
                            {
                                property: "_birthMunicipality",
                                control: Text,
                                label: M("municipality"),
                                placeholder: M("municipality"),
                                size: "col-sm-4",
                                isRequired: true,
                            },
                        ]
                    },
                    {
                        getTitle: model => {
                            return model.get("subjectType") === CustomerType.SUBJECT_TYPE_PHYSICAL_PERSON.value ? M("residence") : M("registeredOffice")
                        },
                        subtitle: null,
                        fields: [
                            // {
                            //     property: "_city",
                            //     getLabel: model => {
                            //         return model.get("subjectType") === CustomerType.SUBJECT_TYPE_PHYSICAL_PERSON.value ? M("residence") : M("registeredOffice")
                            //     },
                            //     size: "col-sm-4",
                            //     control: ValuesLookupContainer,
                            //     formatter: v => {
                            //         return v !== null ? v.description + ", " + (v.province !== null ? v.province.description : "") + ", " + v.cap : ""
                            //     },
                            //     props: {
                            //         id: "address_city_province",
                            //         mode: "single",
                            //         getCollection: (data) => {
                            //             return "cities"
                            //         },
                            //         selectionGrid: {
                            //             columns: [
                            //                 {property: "description", header: M("name"), cell: TextCell},
                            //                 {property: "cap", header: M("cap"), cell: TextCell},
                            //                 {
                            //                     property: "province",
                            //                     header: M("province"),
                            //                     cell: TextCell,
                            //                     props: {
                            //                         formatter: (value) => {
                            //                             return value ? value.description : ""
                            //                         }
                            //                     }
                            //                 },
                            //             ]
                            //         },
                            //         popupGrid: {
                            //             columns: [
                            //                 {property: "description", header: M("name"), cell: TextCell},
                            //                 {property: "cap", header: M("cap"), cell: TextCell},
                            //                 {
                            //                     property: "province",
                            //                     header: M("province"),
                            //                     cell: TextCell,
                            //                     props: {
                            //                         formatter: (value) => {
                            //                             return value ? value.description : ""
                            //                         }
                            //                     }
                            //                 },
                            //             ]
                            //         }
                            //     }
                            // },
                            {
                                property: "_country",
                                control: Text,
                                label: M("country"),
                                placeholder: M("country"),
                                size: "col-sm-4",
                            },
                            {
                                property: "_region",
                                control: Text,
                                label: M("region"),
                                placeholder: M("region"),
                                size: "col-sm-4",
                            },
                            {
                                property: "_province",
                                control: Text,
                                label: M("province"),
                                placeholder: M("province"),
                                size: "col-sm-4",
                            },
                            {
                                property: "_postalCode",
                                control: Text,
                                label: M("postalCode"),
                                placeholder: M("postalCode"),
                                size: "col-sm-4",
                            },
                            {
                                property: "_municipality",
                                control: Text,
                                label: M("municipality"),
                                placeholder: M("municipality"),
                                size: "col-sm-4",
                            },
                            {
                                property: "_address",
                                control: Text,
                                label: M("address"),
                                placeholder: M("address"),
                                size: "col-sm-4",
                            },
                        ]
                    },
                    {
                        title: M("legalRepresentativeInformations"),
                        subtitle: M("subtitleLegalRepresentativeInformations"),
                        visibility: model => {
                            return model != null && model.get("subjectType") === CustomerType.SUBJECT_TYPE_LEGAL_PERSON.value
                        },
                        fields: [
                            {
                                property: "legalFirstName",
                                control: Text,
                                label: M("name"),
                                placeholder: M("name"),
                                size: "col-sm-6",
                                isRequired: true,
                            },
                            {
                                property: "legalLastName",
                                control: Text,
                                label: M("lastname"),
                                placeholder: M("lastname"),
                                size: "col-sm-6",
                                isRequired: true,
                            },
                            {
                                property: "_legalCountry",
                                control: Text,
                                label: M("country"),
                                placeholder: M("country"),
                                size: "col-sm-4",
                                isRequired: true,
                            },
                            {
                                property: "_legalRegion",
                                control: Text,
                                label: M("region"),
                                placeholder: M("region"),
                                size: "col-sm-4",
                                isRequired: true,
                            },
                            {
                                property: "_legalProvince",
                                control: Text,
                                label: M("province"),
                                placeholder: M("province"),
                                size: "col-sm-4",
                                isRequired: true,
                            },
                            {
                                property: "_legalPostalCode",
                                control: Text,
                                label: M("postalCode"),
                                placeholder: M("postalCode"),
                                size: "col-sm-4",
                            },
                            {
                                property: "_legalMunicipality",
                                control: Text,
                                label: M("municipality"),
                                placeholder: M("municipality"),
                                size: "col-sm-4",
                                isRequired: true,
                            },
                            {
                                property: "_legalBirthDate",
                                control: Text,
                                label: M("birthDate"),
                                placeholder: M("birthDate"),
                                size: "col-sm-4",
                                isRequired: true,
                            },
                            {
                                property: "legalFiscalCode",
                                control: Text,
                                label: M("fiscalCode"),
                                placeholder: M("fiscalCode"),
                                size: "col-sm-4",
                                isRequired: true,
                            },
                        ]
                    },
                    {
                        title: M("privacy"),
                        subtitle: null,
                        // visibility: model => {
                        //     return model != null && model.get("id") !== null && model.get("id") !== ""
                        // },
                        fields: [
                            {
                                property: "_privacyDocument",
                                size: "col-sm-12",
                                control: NewMultiFile,
                                label: null,
                                title: "",
                                isRequired: () => true,
                                props: {
                                    maxFiles: 1,
                                    acceptedFiles: ".docx, .pdf",
                                    mode: MULTI_FILE_MODE_SINGLE,
                                    addRemoveLinks: () => {
                                        return true;
                                    }
                                }
                            }
                        ]
                    }
                ]
            }
        }
    },

    agent: {
        grid: {
            title: M("agents"),
            quickSearchEnabled: true,
            descriptor: {
                columns: [
                    {property: "code", header: M("code"), cell: TextCell, sortable: true, searchable: true},
                    {property: "name", header: M("name"), cell: TextCell, sortable: true, searchable: true},
                    {property: "lastname", header: M("lastname"), cell: TextCell, sortable: true, searchable: true},
                    {
                        property: "supervisor",
                        header: M("supervisor"),
                        cell: TextCell,
                        sortable: true,
                        searchable: true,
                        props: {
                            formatter(v) {
                                return v? v.fullDescription : null
                            }
                        }
                    }
                ]
            }
        },
        form: {
            title: "Crea/Modifica agente",
            subtitle: null,
            descriptor: {
                fields: [
                    {
                        property: "code",
                        control: ReadOnlyText,
                        label: M("code"),
                        size: "col-sm-4",
                        placeholder: M("code")
                    },
                    {
                        property: "name",
                        control: Text,
                        isRequired: true,
                        label: M("name"),
                        size: "col-sm-4",
                        placeholder: M("name")
                    },
                    {
                        property: "lastname",
                        control: Text,
                        isRequired: true,
                        label: M("lastname"),
                        size: "col-sm-4",
                        placeholder: M("lastname")
                    },
                    entitySelectContainerField({
                        property: "supervisorId",
                        entity: "supervisor",
                        label: "Supervisore",
                        mode: "single"
                    }),
                ]
            }
        }
    },

    supervisor: {
        grid: {
            title: M("supervisors"),
            quickSearchEnabled: true,
            descriptor: {
                columns: [
                    {property: "code", header: M("code"), cell: TextCell, sortable: true, searchable: true},
                    {property: "name", header: M("name"), cell: TextCell, sortable: true, searchable: true},
                    {property: "lastname", header: M("lastname"), cell: TextCell, sortable: true, searchable: true}
                ]
            }
        },
        form: {
            title: "Crea/Modifica supervisore",
            subtitle: null,
            descriptor: {
                fields: [
                    {
                        property: "code",
                        control: ReadOnlyText,
                        label: M("code"),
                        size: "col-sm-4",
                        placeholder: M("code")
                    },
                    {
                        property: "name",
                        control: Text,
                        isRequired: true,
                        label: M("name"),
                        size: "col-sm-4",
                        placeholder: M("name")
                    },
                    {
                        property: "lastname",
                        control: Text,
                        isRequired: true,
                        label: M("lastname"),
                        size: "col-sm-4",
                        placeholder: M("lastname")
                    }
                ]
            }
        }
    },

    documentType: {
        grid: {
            title: M("documentTypeList"),
            quickSearchEnabled: true,
            descriptor: {
                columns: [
                    {property: "code", header: M("code"), cell: TextCell, sortable: true, searchable: true},
                    {
                        property: "description",
                        header: M("description"),
                        cell: TextCell,
                        sortable: true,
                        searchable: true
                    },
                    {
                        property: "assignationType",
                        header: M("assignationType"),
                        cell: TextCell,
                        sortable: true,
                        searchable: true,
                        searchForm: {
                            showInCard: false,
                            fields: [
                                {
                                    property: "assignationType",
                                    label: M("assignationType"),
                                    control: Select,
                                    filterType: "eq",
                                    props: {
                                        datasource: AssignationTypeDatasource,
                                    },
                                }
                            ]
                        },
                        props: {
                            formatter: v => {
                                return v != null ? getAssignationTypeDescription(v) : "";
                            }
                        }
                    },
                    {
                        property: "typology",
                        header: M("typology"),
                        cell: TextCell,
                        sortable: true,
                        searchable: false,
                        props: {
                            formatter: v => {
                                return v != null ? getDocumentTypeTypologyDescription(v) : "";
                            }
                        }
                    },
                    {
                        property: "dossierType",
                        header: M("dossierType"),
                        cell: TextCell,
                        sortable: true,
                        searchable: false,
                        props: {
                            formatter: v => {
                                return v != null ? M(v) : "";
                            }
                        }
                    },
                    {
                        property: "number",
                        header: M("documentTypeNumberGrid"),
                        cell: TextCell,
                        sortable: true,
                        searchable: false,
                        props: {
                            formatter: v => {
                                return v != null ? M(v) : "";
                            }
                        }
                    },
                    {
                        property: "template",
                        header: M("downloadableTemplate"),
                        cell: CheckCell,
                        sortable: true,
                        searchable: false,
                        props: {
                            formatter: v => {
                                return v != null;
                            }
                        }
                    },
                    {
                        property: "enableKPMGReview",
                        header: M("kpmg"),
                        cell: CheckCell,
                        sortable: true,
                        searchable: true,
                        searchForm: {
                            showInCard: false,
                            fields: [
                                {
                                    property: "enableKPMGReview",
                                    label: M("enableKPMGReview"),
                                    control: Select,
                                    filterType: "eq",
                                    props: {
                                        allowNull: true,
                                        datasource: datasource.fixed([
                                            {label: M("active"), value: true},
                                            {label: M("notActive"), value: false},
                                        ]),
                                    },
                                }
                            ]
                        },
                    },
                    {
                        property: "active",
                        header: M("active"),
                        cell: CheckCell,
                        sortable: true,
                        searchable: true,
                        searchForm: {
                            showInCard: false,
                            fields: [
                                {
                                    property: "active",
                                    label: M("status"),
                                    control: Select,
                                    filterType: "eq",
                                    props: {
                                        allowNull: true,
                                        datasource: datasource.fixed([
                                            {label: M("active"), value: true},
                                            {label: M("notActive"), value: false},
                                        ]),
                                    },
                                }
                            ]
                        },
                    },
                    {
                        property: "giureValidationDocument",
                        header: M("giureValidationDocument"),
                        cell: CheckCell,
                        sortable: true,
                        searchable: true,
                        searchForm: {
                            showInCard: false,
                            fields: [
                                {
                                    property: "giureValidationDocument",
                                    label: M("status"),
                                    control: Select,
                                    filterType: "eq",
                                    props: {
                                        allowNull: true,
                                        datasource: datasource.fixed([
                                            {label: M("active"), value: true},
                                            {label: M("notActive"), value: false},
                                        ]),
                                    },
                                }
                            ]
                        },
                    },
                    {
                        property: "giureCanEditDocumentAttachment",
                        header: M("giureCanEditDocumentAttachment"),
                        cell: CheckCell,
                        sortable: true,
                        searchable: true,
                        searchForm: {
                            showInCard: false,
                            fields: [
                                {
                                    property: "giureCanEditDocumentAttachment",
                                    label: M("status"),
                                    control: Select,
                                    filterType: "eq",
                                    props: {
                                        allowNull: true,
                                        datasource: datasource.fixed([
                                            {label: M("active"), value: true},
                                            {label: M("notActive"), value: false},
                                        ]),
                                    },
                                }
                            ]
                        },
                    },
                ]
            }
        },
        form: {
            getTitle: (data) => {
                if (data != null && data.id != null)
                    return M("editDocumentType");
                else
                    return M("createDocumentType");
            },
            subtitle: null,
            descriptor: {
                onModelLoadFirstTime: (model) => {
                    if (model.get("id") == null) {
                        model.set("active", true)
                    }
                    model.on("property:change", (property, value) => {
                        if (property === "assignationType" || property === "typology") {
                            model.invalidateForm()
                        }
                        if (property === "enableKPMGReview"){
                            if (!model.get("enableKPMGReview")){
                                model.set("kpmggroup", null);
                                model.set("receiveByKPMG", false);
                            }
                            model.invalidateForm();
                        }
                    })
                },
                visibility: (field, model, descriptor) => {
                    switch (field.property) {
                        case "dossierType":
                            return model != null && model.get("assignationType") != null
                        case "customerType":
                            return (model != null && model.get("assignationType") != null && (model.get("assignationType") === AssignationType.PREPARATORY_DOCUMENTATION.value || model.get("assignationType") === AssignationType.ENEA_PREPARATORY_DOCUMENTATION.value || model.get("assignationType") === AssignationType.PUBLIC_CLOSING_DOCUMENTATION.value || model.get("assignationType") === AssignationType.QUESTIONNAIRE.value));
                        case "quotation":
                            return model != null && (model.get("assignationType") == null || model.get("assignationType") !== AssignationType.QUESTIONNAIRE.value);
                        case "kpmggroup":
                        case "receiveByKPMG":
                        case "enableImmediateKPMGReview":
                            return model != null && model.get("enableKPMGReview")
                        default:
                            return true;
                    }
                },
                areas: [
                    {
                        title: M("generalInformations"),
                        subtitle: null,
                        fields: [
                            {
                                property: "code",
                                getControl: (model) =>{

                                    return model && model.get("id")? ReadOnlyText : Text
                                },
                                label: M("code"),
                                placeholder: M("code"),
                                size: "col-sm-3",
                                isRequired: true,
                            },
                            {
                                property: "description",
                                control: Text,
                                label: M("documentName"),
                                placeholder: M("documentName"),
                                size: "col-sm-3",
                                isRequired: true,
                            },
                            {
                                property: "active",
                                control: YesNo,
                                label: M("active"),
                                size: "col-sm-3",
                                sanitizer: (value) => sanitize(value).toBoolean()
                            },
                            {
                                property: "number",
                                control: Number,
                                label: M("documentTypeNumber"),
                                placeholder: M("documentTypeNumber"),
                                size: "col-sm-3",
                                isRequired: false,
                                props: {
                                    onlyInteger: true
                                }
                            },
                            {
                                property: "information",
                                control: Text,
                                label: M("information"),
                                placeholder: M("information"),
                                size: "col-sm-12"
                            },
                            {
                                property: "assignationType",
                                control: Select,
                                size: "col-sm-4",
                                label: M("assignationType"),
                                isRequired: true,
                                props: {
                                    id: "customer",
                                    allowNull: true,
                                    multiple: false,
                                    datasource: AssignationTypeDatasource,
                                }
                            },

                            valuesSelectContainerField({
                                property: "dossierType",
                                isRequired: true,
                                collection: "dossierType",
                                itemValue: "value",
                                itemLabel: "label",
                                size: "col-sm-4",
                            }),
                            {
                                property: "customerType",
                                control: Select,
                                size: "col-sm-4",
                                label: M("customerType"),
                                isRequired: true,
                                props: {
                                    allowNull: true,
                                    multiple: false,
                                    datasource: DocumentTypeCustomerTypeDatasource,
                                }
                            },
                            {
                                property: "typology",
                                control: Select,
                                size: "col-sm-4",
                                label: M("typology"),
                                isRequired: true,
                                props: {
                                    id: "typology",
                                    allowNull: true,
                                    multiple: false,
                                    datasource: DocumentTypeTypologyDatasource,
                                }
                            },
                            {
                                property: "quotation",
                                control: YesNo,
                                label: M("quotation"),
                                size: "col-sm-4",
                                sanitizer: (value) => sanitize(value).toBoolean()
                            },
                            {
                                property: "enableKPMGReview",
                                control: YesNo,
                                label: M("enableKPMGReview"),
                                size: "col-sm-4",
                                sanitizer: (value) => sanitize(value).toBoolean()
                            },
                            {
                                property: "kpmggroup",
                                control: Select,
                                size: "col-sm-4",
                                label: M("kpmgGroup"),
                                isRequired: true,
                                props: {
                                    id: "kpmggroup",
                                    allowNull: true,
                                    multiple: false,
                                    datasource: KPMGGroupsDatasource,
                                }
                            },
                            {
                                property: "receiveByKPMG",
                                control: YesNo,
                                label: M("receiveByKPMG"),
                                size: "col-sm-4",
                                sanitizer: (value) => sanitize(value).toBoolean()
                            },
                            {
                                property: "enableImmediateKPMGReview",
                                control: YesNo,
                                label: M("enableImmediateKPMGReview"),
                                size: "col-sm-4",
                                sanitizer: (value) => sanitize(value).toBoolean()
                            },
                            {
                                property: "giureValidationDocument",
                                control: YesNo,
                                label: M("giureValidationDocument"),
                                size: "col-sm-4",
                                sanitizer: (value) => sanitize(value).toBoolean()
                            },
                            {
                                property: "giureCanEditDocumentAttachment",
                                control: YesNo,
                                label: M("giureCanEditDocumentAttachment"),
                                size: "col-sm-4",
                                sanitizer: (value) => sanitize(value).toBoolean()
                            },
                            {
                                property: "_template",
                                size: "col-sm-12",
                                control: NewMultiFile,
                                label: M("template"),
                                title: "",
                                isRequired: model => {
                                    return model && model.get("typology") != null && model.get("typology") !== DocumentTypeTypology.NO_MODEL.value;
                                },
                                props: {
                                    maxFiles: 1,
                                    acceptedFiles: ".docx, .pdf",
                                    mode: MULTI_FILE_MODE_SINGLE,
                                    addRemoveLinks: () => {
                                        return true;
                                    }
                                }
                            }
                        ]
                    }
                ]
            }
        }
    },

    dossier: dossierEntity,

    dossier_2020_50: dossierEntity,

    dossier_2021_50: dossierEntity,

    dossier_2022_1: dossierEntity,

    dossier_2021_BonusCasa: dossier_2021_bonusCasa_Entity,

    dossier_2022_BonusCasa_1: dossier_2022_bonusCasa_1_Entity,

    dossier_2022_BonusCasa_2: dossier_2022_bonusCasa_2_Entity,

    dossier_2022_BonusCasa: dossier_2022,

    dossier_2022_50: dossier_2022,

    dossier_2023_75: dossier_2023_75_Entity,

    userDevice: {
        form: {
            gridUrl: (data, params) => {
                if (params.fabricatorCollaboratorId) {
                    return "entities/fabricatorCollaborator/" + params.fabricatorCollaboratorId + "?selectedTab=device-tab"
                }
            },
            getTitle: (data, params) => {
                let items = [];
                if (data) {
                    if (params.fabricatorCollaboratorId) {
                        let fabricatorCollaboratorName = params.fabricatorCollaboratorName
                        let fabricatorCollaboratorId = params.fabricatorCollaboratorId
                        items.push({
                            title: decodeURI(fabricatorCollaboratorName),
                            url: "entities/fabricatorCollaborator/" + fabricatorCollaboratorId
                        });
                    }

                    if (data.id != null)
                        items.push({title: M("editUserDevice")});
                    else
                        items.push({title: M("createUserDevice")});
                }
                return items;
            },
            actions: ["back", "save", "save-go-back", "revisions"],
            descriptor: {
                areas: [
                    {
                        title: M("generalInformations"),
                        subtitle: null,
                        fields: [
                            {
                                property: "code",
                                control: ReadOnlyText,
                                label: M("code"),
                                placeholder: M("code"),
                                size: "col-sm-4",
                            },
                            {
                                property: "deviceId",
                                control: ReadOnlyText,
                                label: M("id"),
                                placeholder: M("id"),
                                size: "col-sm-4",
                            },
                            {
                                property: "manufacturer",
                                control: ReadOnlyText,
                                label: M("manufacturer"),
                                placeholder: M("manufacturer"),
                                size: "col-sm-4",
                            },
                            {
                                property: "model",
                                control: ReadOnlyText,
                                label: M("model"),
                                placeholder: M("model"),
                                size: "col-sm-4",
                            },
                            {
                                property: "validityStart",
                                control: ReadOnlyText,
                                label: M("startDate"),
                                placeholder: M("startDate"),
                                size: "col-sm-4",
                                props: {
                                    formatter: v => {
                                        return v != null ? moment(v).format("DD/MM/YYYY") : "-";
                                    }
                                }
                            },
                            {
                                property: "validityEnd",
                                control: ReadOnlyText,
                                label: M("endDate"),
                                placeholder: M("endDate"),
                                size: "col-sm-4",
                                props: {
                                    formatter: v => {
                                        return v != null ? moment(v).format("DD/MM/YYYY") : "-";
                                    }
                                }
                            },
                            {
                                property: "status",
                                control: Select,
                                size: "col-sm-4",
                                label: M("status"),
                                placeholder: M("status"),
                                props: {
                                    id: "status",
                                    allowNull: false,
                                    multiple: false,
                                    datasource: UserDeviceStatusDatasource,
                                }
                            },
                        ]
                    },
                ]
            }
        }
    },

    dossierExport: {
        form: {
            title: null,
            descriptor: {
                onModelLoadFirstTime: (model) => {
                    model.on("property:change", (property, value) => {
                        if (property === "fabricator") {
                            model.set("fabricatorCollaborator", null);
                            model.invalidateForm()
                        }
                    });
                },
                showFormFooter: false,
                areas: [
                    {
                        component: AreaNoCard,
                        subtitle: null,
                        fields: [
                            valuesSelectContainerField({
                                property: "type",
                                isRequired: true,
                                allowNull: false,
                                collection: "dossierType",
                                itemValue: "value",
                                itemLabel: "label",
                                size: "col-sm-6",
                            }),
                            {
                                property: "startDate",
                                control: Text,
                                label: M("startDate"),
                                placeholder: M("startDate"),
                                size: "col-sm-6",
                            },
                            {
                                property: "endDate",
                                control: Text,
                                label: M("endDate"),
                                placeholder: M("endDate"),
                                size: "col-sm-6",
                            },
                            /*{
                                property: "kpmgStartSentDate",
                                control: Text,
                                label: M("Data inizio invio KPMG (DD/MM/YYYY)"),
                                placeholder: M("Data inizio invio KPMG (DD/MM/YYYY)"),
                                size: "col-sm-6"
                            },
                            {
                                property: "kpmgEndSentDate",
                                control: Text,
                                label: M("Data fine invio KPMG (DD/MM/YYYY)"),
                                placeholder: M("Data fine invio KPMG (DD/MM/YYYY)"),
                                size: "col-sm-6"
                            },*/
                            {
                                property: "customer",
                                label: M("customer"),
                                getControl: (model) => {
                                    return model.get("id") == null || model.get("status") === DossierStatus.STATUS_QUOTATION.value ? ValuesLookupContainer : ReadOnlyText
                                },
                                size: "col-sm-6",
                                isRequired: false,
                                props: {
                                    id: "dossier_customer",
                                    mode: "single",
                                    collection: "customers",
                                    selectionGrid: {
                                        columns: [
                                            {property: "code", header: M("code"), cell: TextCell},
                                            {property: "name", header: M("name"), cell: TextCell}
                                        ]
                                    },
                                    popupGrid: {
                                        columns: [
                                            {property: "code", header: M("code"), cell: TextCell},
                                            {property: "name", header: M("name"), cell: TextCell}
                                        ]
                                    },
                                    formatter: v => {
                                        return v != null ? v.name : "";
                                    }
                                }
                            },
                            {
                                property: "fabricator",
                                label: M("fabricator"),
                                isRequired: false,
                                getControl: (model) => {
                                    return model.get("id") == null || model.get("status") === DossierStatus.STATUS_QUOTATION.value ? ValuesLookupContainer : ReadOnlyText
                                },
                                size: "col-sm-6",
                                props: {
                                    id: "dossier_fabricator",
                                    mode: "single",
                                    collection: "fabricators",
                                    selectionGrid: {
                                        columns: [
                                            {property: "code", header: M("code"), cell: TextCell},
                                            {property: "businessName", header: M("businessName"), cell: TextCell},
                                            {
                                                property: "completedProfile",
                                                header: M("completedProfile"),
                                                cell: TextCell,
                                                props: {
                                                    formatter: v => {
                                                        return v ? M("yes") : M("no");
                                                    }
                                                }
                                            }
                                        ]
                                    },
                                    popupGrid: {
                                        columns: [
                                            {property: "code", header: M("code"), cell: TextCell},
                                            {property: "businessName", header: M("businessName"), cell: TextCell},
                                            {
                                                property: "completedProfile",
                                                header: M("completedProfile"),
                                                cell: TextCell,
                                                props: {
                                                    formatter: v => {
                                                        return v ? M("yes") : M("no");
                                                    }
                                                }
                                            }
                                        ]
                                    },
                                    formatter: v => {
                                        return v != null ? v.businessName : "";
                                    }
                                }
                            },
                            {
                                property: "fabricatorCollaborator",
                                label: M("collaborator"),
                                isRequired: false,
                                getControl: (model) => {
                                    return (model.get("id") == null || model.get("status") === DossierStatus.STATUS_QUOTATION.value) && model.get("fabricator") != null ? ValuesLookupContainer : ReadOnlyText
                                },
                                size: "col-sm-6",
                                props: {
                                    id: "dossier_fabricator_collaborator",
                                    mode: "single",
                                    getCollection: (model) => {
                                        return "collaborators/" + model.get("fabricator").id;
                                    },
                                    selectionGrid: {
                                        columns: [
                                            {property: "code", header: M("code"), cell: TextCell},
                                            {property: "name", header: M("name"), cell: TextCell},
                                            {property: "lastname", header: M("lastname"), cell: TextCell},
                                        ]
                                    },
                                    popupGrid: {
                                        columns: [
                                            {property: "code", header: M("code"), cell: TextCell},
                                            {property: "name", header: M("name"), cell: TextCell},
                                            {property: "lastname", header: M("lastname"), cell: TextCell},
                                        ]
                                    },
                                    formatter: v => {
                                        return v != null ? v.lastname + " " + v.name : "";
                                    }
                                }
                            },
                            {
                                property: "priority",
                                label: M("priority"),
                                control: Select,
                                size: "col-sm-6",
                                props: {
                                    allowNull: true,
                                    multiple: false,
                                    datasource:  datasource.fixed([
                                        {label: M("yes"), value: true},
                                        {label: M("no"), value: false}
                                    ])
                                }
                            },
                            {
                                property: "dl157",
                                control: Select,
                                label: M("dl157"),
                                size: "col-sm-6",
                                props: {
                                    allowNull: true,
                                    multiple: false,
                                    datasource:  datasource.fixed([
                                        {label: M("yes"), value: true},
                                        {label: M("no"), value: false}
                                    ])
                                }
                            },{
                                control: Select,
                                property: "freeConstruction",
                                label: M("freeConstruction"),
                                size: "col-sm-6",
                                props: {
                                    allowNull: true,
                                    multiple: false,
                                    datasource:  datasource.fixed([
                                        {label: M("yes"), value: true},
                                        {label: M("no"), value: false}
                                    ])
                                }
                            },
                        ]
                    },
                ]
            }
        }
    },

    customerSelection: {
        form: {
            title: null,
            descriptor: {
                showFormFooter: false,
                areas: [
                    {
                        component: AreaNoCard,
                        subtitle: null,
                        fields: [
                            {
                                property: "customer",
                                label: M("customer"),
                                getControl: (model) => {
                                    return ValuesLookupContainer
                                },
                                size: "col-sm-6",
                                isRequired: true,
                                props: {
                                    id: "dossier_customer",
                                    mode: "single",
                                    collection: "customers",
                                    createEntity: true,
                                    entity: "customer",
                                    selectionGrid: {
                                        columns: [
                                            {property: "code", header: M("code"), cell: TextCell},
                                            {property: "name", header: M("name"), cell: TextCell}
                                        ]
                                    },
                                    popupGrid: {
                                        columns: [
                                            {property: "code", header: M("code"), cell: TextCell},
                                            {property: "name", header: M("name"), cell: TextCell}
                                        ]
                                    },
                                    formatter: v => {
                                        return v != null ? v.name : "";
                                    }
                                }
                            },
                        ]
                    },
                ]
            }
        }
    },

    adminUserCategory: getUsersCategories(M("adminUserCategories"), M("editAdminUsersCategory"), M("createAdminUsersCategory")),
    endUserCategory: getUsersCategories(M("endUserCategories"), M("editEndUsersCategory"), M("createEndUsersCategory")),

    fabricatorCategory: {
        grid: {
            title: M("fabricatorCategories"),
            descriptor: {
                columns: [
                    {property: "code", header: M("code"), cell: TextCell, sortable: true, searchable: false},
                    {
                        property: "description",
                        header: M("description"),
                        cell: TextCell,
                        sortable: true,
                        searchable: false
                    },
                    {
                        property: "maxDossierNumber",
                        header: M("maxDossierNumber"),
                        cell: TextCell,
                        sortable: true,
                        searchable: false
                    },
                    {property: "active", header: M("active"), cell: CheckCell, sortable: true, searchable: false}
                ]
            }
        },
        form: {
            getTitle: (data) => {
                if (data != null && data.id != null)
                    return M("editFabricatorsCategory");
                else
                    return M("createFabricatorsCategory");
            },
            descriptor: {
                onModelLoadFirstTime: (model) => {
                    if (model.get("id") == null) {
                        model.set("active", true);
                        model.set("maxDossierNumber", 0);
                    }
                },
                areas: [
                    {
                        title: M("generalInformations"),
                        subtitle: null,
                        fields: [
                            {
                                property: "code",
                                control: ReadOnlyText,
                                label: M("code"),
                                placeholder: M("code"),
                                size: "col-sm-4"
                            },
                            {
                                property: "active",
                                control: YesNo,
                                label: M("active"),
                                size: "col-sm-4",
                                sanitizer: (value) => sanitize(value).toBoolean()
                            },
                            {
                                property: "maxDossierNumber",
                                control: Text,
                                label: M("maxDossierNumber"),
                                placeholder: M("maxDossierNumber"),
                                size: "col-sm-4",
                                isRequired: true,
                                props: {
                                    maxLength: 200
                                }
                            },
                            {
                                property: "description",
                                control: Text,
                                label: M("description"),
                                placeholder: M("description"),
                                size: "col-sm-12",
                                isRequired: true,
                                props: {
                                    maxLength: 200
                                }
                            },
                            {
                                property: "_activeDossierType",
                                label: M("activeDossierType"),
                                control: Select,
                                filterType: "eq",
                                size: "col-sm-6",
                                props: {
                                    allowNull: true,
                                    datasource: DossierTypeDatasource,
                                },
                                visibility: (model) => {
                                    return isSuperuser() && model.get("id") != null;
                                }
                            }
                        ]
                    }
                ]
            }
        }
    },


      backup: {
        grid: {
            quickSearchEnabled: true,
            title: M("backup_plural"),
            subtitle: null,
            canEdit: false,
            descriptor: {
                columns: [
                    {
                        property: "code",
                        header: M("code"),
                        cell: TextCell,
                        sortable: true,
                        searchable: true
                    },

                    {
                        property: "status",
                        header: M("status"),
                        cell: TextCellWithSubText,
                        sortable: false,
                        searchable: false,
                        props: {
                            titleBold: true,
                            formatterTitle(v, data) {
                                if (data.inProgress)
                                    return "In corso"
                                else {
                                    if (data.completed)
                                        return "Completato"
                                    else
                                        return "Non completato"
                                }
                            },
                            formatterSubtitle(v, data) {
                                let dates = [format("Inizio: {0}", moment(data.date).format("DD/MM/YYYY, HH:mm"))]

                                if (data.completed)
                                    dates.push(format("Completamento: {0}", moment(data.completionDate).format("DD/MM/YYYY, HH:mm")))

                                return dates.join(", ")

                            }
                        }
                    },
                    {
                        property: "note",
                        header: M("note"),
                        cell: TextCell,
                        sortable: true,
                        searchable: true
                    },

                    {
                        property: "_actions",
                        cell: ActionsCell,
                        sortable: false,
                        searchable: false,
                        getActions: (data) => {

                            let items = [
                                {
                                    id: "multipleAction-enable",
                                    label: "Scarica",
                                    action: () => {
                                        toast(M("operationInProgress"))
                                        location.href = config.get("service.url") + safeGet( data, "value", {}).path
                                    }
                                },
                            ]

                            if (items.length > 0) {
                                return [
                                    {
                                        id: "CREATION_MULTIPLE_ACTION",
                                        type: "dropdown",
                                        dropdownClass: "dropdown-menu-right",
                                        icon: "zmdi zmdi-more-vert",
                                        items: items
                                    }

                                ]
                            }

                            return []

                        }

                    },
                ]
            }
        },
        form: {
            title: M("backup"),

            subtitle: null,
            descriptor: {
                showInCard: false,
                fields: [
                ]
            }
        }
    },



    dashboardDossier: entitiesDashboard.dossier,
    dashboardSupervisor: entitiesDashboard.supervisor,
    dashboardAgent: entitiesDashboard.agent,
    dashboardRegion: entitiesDashboard.region,
    dashboardProvince: entitiesDashboard.province,
    dashboardFabricator: entitiesDashboard.fabricator,

}

function getPersonGridColumns(entityForSearch) {
    return [
        {
            property: "_category",
            header: M("category"),
            cell: TextCell,
            sortable: true,
            searchable: true,
            searchForm: {
                showInCard: false,
                fields: [
                    {
                        property: "categoryId",
                        label: M("referenceToUserCategory"),
                        control: EntitiesLookupContainer,
                        props: {
                            id: "user_category",
                            mode: "single",
                            entity: entityForSearch,
                            selectionGrid: {
                                columns: [
                                    {property: "code", header: M("code"), cell: TextCell},
                                    {property: "description", header: M("description"), cell: TextCell}
                                ]
                            },
                            popupGrid: {
                                columns: [
                                    {property: "code", header: M("code"), cell: TextCell},
                                    {property: "description", header: M("description"), cell: TextCell}
                                ]
                            }
                        }
                    },
                ]
            },
            props: {
                formatter: v => {
                    return v != null ? v.description : "";
                }
            }
        },
        {
            property: "active",
            header: M("active"),
            cell: CheckCell,
            sortable: true,
            searchable: true,
            searchForm: {
                showInCard: false,
                fields: [
                    {
                        property: "active",
                        label: M("status"),
                        control: Select,
                        filterType: "eq",
                        props: {
                            allowNull: true,
                            datasource: datasource.fixed([
                                {label: M("active"), value: true},
                                {label: M("notActive"), value: false},
                            ]),
                        },
                    }
                ]
            },
        }
    ]
}

function getPersonFormAreas() {
    return [
        {
            property: "column",
            control: Column,
            size: "col-sm-4",
            className: "m-b-30",
            noLateralPadding: false,
            useBoostrapRow: true,
            fields: [
                {
                    property: "column_1",
                    control: Column,
                    size: "col-sm-12",
                    className: "card-colored p-t-10",
                    noLateralPadding: false,
                    useBoostrapRow: true,
                    fields: [
                        {
                            property: "mail",
                            control: Mail,
                            label: M("mail"),
                            placeholder: M("mailAddress"),
                            size: "col-sm-12"
                        },
                        // {
                        //     property: "password",
                        //     control: PasswordText,
                        //     label: M("password"),
                        //     placeholder: M("password"),
                        //     size: "col-sm-12",
                        //     sanitizer: value => sanitize(value).trim()
                        // },
                        {
                            property: "active",
                            control: YesNo,
                            label: M("active"),
                            size: "col-sm-12",
                            sanitizer: (value) => sanitize(value).toBoolean()
                        },
                        {
                            property: "notes",
                            control: Text,
                            label: M("notes"),
                            placeholder: M("notes"),
                            size: "col-sm-12",
                            props: {
                                maxLength: 1000
                            }
                        },
                    ]
                }
            ]
        }
    ]
}

function getPersonActions(data) {
    let actions = ["back", "save", "save-go-back", "revisions"];
    if (hasPermission(["canResetPassword"])) {
        if (data && data.id) {
            actions.push({
                type: "button",
                icon: "zmdi zmdi-brush",
                tooltip: M("resetPassword"),
                action: () => {

                    swal({
                        title: M("confirm"),
                        text: M("confirmResetPassword"),
                        showCancelButton: true
                    })
                        .then(() => {
                            resetUserPassword({id: data.userId})
                        })
                        .catch((e) => {
                            logger.i(e)
                        })

                }
            })
        }
    }

    return actions
}

function getUsersCategories(gridTitle, editFormTitle, createFormTitle) {
    return {
        grid: {
            title: gridTitle,
            descriptor: {
                columns: [
                    {property: "code", header: M("code"), cell: TextCell, sortable: true, searchable: false},
                    {
                        property: "description",
                        header: M("description"),
                        cell: TextCell,
                        sortable: true,
                        searchable: false
                    },
                    {property: "active", header: M("active"), cell: CheckCell, sortable: true, searchable: false}
                ]
            }
        },
        form: {
            getTitle: (data) => {
                if (data != null && data.id != null)
                    return editFormTitle;
                else
                    return createFormTitle;
            },
            descriptor: {
                onModelLoadFirstTime: (model) => {
                    if (model.get("id") == null) {
                        model.set("active", true)
                    }
                },
                areas: [
                    {
                        title: M("generalInformations"),
                        subtitle: null,
                        fields: [
                            {
                                property: "code",
                                control: ReadOnlyText,
                                label: M("code"),
                                placeholder: M("code"),
                                size: "col-sm-6"
                            },
                            {
                                property: "active",
                                control: YesNo,
                                label: M("active"),
                                size: "col-sm-6",
                                sanitizer: (value) => sanitize(value).toBoolean()
                            },
                            {
                                property: "description",
                                control: Text,
                                label: M("description"),
                                placeholder: M("description"),
                                size: "col-sm-12",
                                isRequired: true,
                                props: {
                                    maxLength: 200
                                }
                            },
                        ]
                    }
                ]
            }
        }
    }
}


export default entities
