"use strict"

import {alert, hideLoader, showLoader, toast} from "../plugins";
import {createAsyncAction} from "../utils/ajex";
import * as responses from "../api/responses";
import {
    CONFIRM_QUOTATION_2021_BONUS_CASA,
    CREATE_DOSSIER_2021_BONUS_CASA,
    EDIT_DOSSIER_2021_BONUS_CASA,
    GENERATE_QUESTIONNAIRE_DOSSIER_2021_BONUS_CASA,
    GENERATE_QUOTATION_AMOUNTS_2021_BONUS_CASA,
    GENERATE_QUOTATION_DOSSIER_2021_BONUS_CASA,
    UPDATE_QUESTIONNAIRE_2021_BONUS_CASA
} from "./types";
import * as DossierApi from "../api/dossier_2021_bonusCasa";
import {freeEntities, getEntity, manualSavedEntities} from "./entities";
import M from "../strings";
import * as aj from "../aj";
import * as ui from "../web/utils/ui";
import {DOSSIER_TYPE, getDossierEntityNameFromType} from "../model/vars";
import {format} from "../utils/lang";

export const generateQuotationDossier_2021_bonusCasa = createAsyncAction(GENERATE_QUOTATION_DOSSIER_2021_BONUS_CASA, data => {
    showLoader();
    aj.dispatch({
        type: GENERATE_QUOTATION_DOSSIER_2021_BONUS_CASA
    });

    DossierApi.generateQuotationDossier_2021_bonusCasa(data.dossierDTO)
        .then(response => {
            hideLoader();
            generateQuotationDossier_2021_bonusCasa.complete({downloadUrl: response.value})
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            generateQuotationDossier_2021_bonusCasa.fail()
        })
});

export const generateQuestionnaireDossier_2021_bonusCasa = createAsyncAction(GENERATE_QUESTIONNAIRE_DOSSIER_2021_BONUS_CASA, data => {
    showLoader();
    aj.dispatch({
        type: GENERATE_QUESTIONNAIRE_DOSSIER_2021_BONUS_CASA
    })

    DossierApi.generateQuestionnaireDossier_2021_bonusCasa(data.dossierDTO)
        .then(response => {
            hideLoader();
            if (response.value === null) {
                alert("Attenzione!", responses.msg({responseCode: 1034}), "error");
                generateQuestionnaireDossier_2021_bonusCasa.fail()
            } else {
                generateQuestionnaireDossier_2021_bonusCasa.complete({downloadUrl: response.value})
            }
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            generateQuestionnaireDossier_2021_bonusCasa.fail()
        })
});

export const updateQuestionnaire_2021_bonusCasa = createAsyncAction(UPDATE_QUESTIONNAIRE_2021_BONUS_CASA, data => {

    showLoader();

    aj.dispatch({
        type: UPDATE_QUESTIONNAIRE_2021_BONUS_CASA
    })
    DossierApi.updateQuestionnaire_2021_bonusCasa(data.dossierDTO)
        .then(response => {
            hideLoader();
            toast(M("saveComplete"))
            updateQuestionnaire_2021_bonusCasa.complete()
            if (data.callback)
                data.callback()
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            updateQuestionnaire_2021_bonusCasa.fail()
        })
});

export const createDossier_2021_BonusCasa = createAsyncAction(CREATE_DOSSIER_2021_BONUS_CASA, data => {

    showLoader();

    aj.dispatch({
        type: CREATE_DOSSIER_2021_BONUS_CASA
    })
    DossierApi.createDossier_2021_BonusCasa(data.dossierDTO)
        .then(response => {
            hideLoader();
            toast(M("saveComplete"))
            freeEntities({discriminator: data.discriminator});
            createDossier_2021_BonusCasa.complete()
            ui.navigate("/entities/" + getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2021_BONUS_CASA) + "/" + response.value.id + "?type=" + DOSSIER_TYPE.ECOBONUS_2021_BONUS_CASA)
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            createDossier_2021_BonusCasa.fail()
        })
});

export const confirmQuotation_2021_BonusCasa = createAsyncAction(CONFIRM_QUOTATION_2021_BONUS_CASA, data => {
    aj.dispatch({
        type: CONFIRM_QUOTATION_2021_BONUS_CASA
    })

    showLoader();
    DossierApi.confirmQuotation_2021_BonusCasa(data.dossierDTO)
        .then(response => {
            hideLoader();
            toast("Operazione eseguita con successo!")
            confirmQuotation_2021_BonusCasa.complete({dossier: response.value});
            let entityName = getDossierEntityNameFromType(data.dossierType);
            getEntity({discriminator: "entity_form_" + entityName, entity: entityName, id: response.value.id});
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            confirmQuotation_2021_BonusCasa.fail()
        })
});

export const generateQuotationAmounts_2021_BonusCasa = createAsyncAction(GENERATE_QUOTATION_AMOUNTS_2021_BONUS_CASA, data => {
    DossierApi.generateQuotationAmounts_2021_BonusCasa(data)
        .then(response => {
            generateQuotationAmounts_2021_BonusCasa.complete({priceCalculatorSheet: response.value});
        })
        .catch(e => {
            alert("Attenzione!", format(responses.msg(e), e.data), "error");
            generateQuotationAmounts_2021_BonusCasa.fail()
        })
        .finally(() => {
            hideLoader()
        })
});

export const editDossier_2021_BonusCasa = createAsyncAction(EDIT_DOSSIER_2021_BONUS_CASA, data => {

    showLoader();

    aj.dispatch({
        type: EDIT_DOSSIER_2021_BONUS_CASA
    })
    DossierApi.editDossier_2021_BonusCasa(data.dossierDTO)
        .then(response => {
            hideLoader();
            let entityName = getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2021_BONUS_CASA);
            toast(M("saveComplete"))
            manualSavedEntities({discriminator: "entity_form_" + entityName});
            editDossier_2021_BonusCasa.complete()
            getEntity({discriminator: "entity_form_" + entityName, entity: entityName, id: data.dossierDTO.dossierId});
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            editDossier_2021_BonusCasa.fail()
        })
});