"use strict";

import * as config from "../framework/config";
import {get, postJson} from "./utils";

export function generateQuotationDossier_2023_75(dto) {
    return postJson(config.get("dossiers2023_75.url") + "/generateQuotation", dto)
}

export function generateQuestionnaireDossier_2023_75(dto) {
    return postJson(config.get("dossiers2023_75.url") + "/generateQuestionnaire", dto)
}

export function updateQuestionnaire_2023_75(dto) {
    return postJson(config.get("dossiers2023_75.url") + "/updateQuestionnaire", dto)
}

export function editDossier_2023_75(dto) {
    return postJson(config.get("dossiers2023_75.url") + "/edit", dto)
}

export function createDossier_2023_75(dto) {
    return postJson(config.get("dossiers2023_75.url") + "/create", dto)
}

export function generateQuotationAmounts_2023_75(data) {
    return get(config.get("dossiers2023_75.url") + "/quotation/amounts", data)
}

export function confirmQuotation_2023_75(dto) {
    return postJson(config.get("dossiers2023_75.url") + "/confirmQuotation", dto)
}
