"use strict"

import React from "react";
import {format, optional} from "../../../utils/lang";
import {DocumentRow} from "../documentRow";
import _ from "underscore";
import M from "../../../strings";
import {
    approveDocument,
    clearDocument,
    showAddDocumentDialog,
    showRefuseDocumentDialog
} from "../../../actions/fabricator";
import {DOSSIER_TYPE} from "../../../model/vars";
import {toast} from "../../../plugins";
import {Dialog, DIALOG_RESULT_OK} from "../dialogs";
import moment from "moment";

export class FabricatorDocumentContainer extends React.Component {
    constructor(props) {
        super(props)
    }

    getKey(item) {
        if (_.isFunction(this.props.keygen)) {
            return this.props.keygen(item)
        } else {
            return item.id || item.documentTypeId
        }
    }

    attachAttachment(row) {
        if (row.documentType.dossierType === DOSSIER_TYPE.ECOBONUS_2019) {
            toast("Impossibile modificare la documentazione per le pratiche 2019")
            return;
        }
        showAddDocumentDialog({
            documentType: row.documentType
        });
    }

    refuseDocument(row) {
        showRefuseDocumentDialog({
            documentTypeId: row.documentTypeId
        });
    }

    approveDocument(row) {
        let model = this.props.model;
        swal({title: M("confirm"), text: M("areYouSure"), showCancelButton: true})
            .then(res => {
                if (res.value) {
                    approveDocument({
                        documentTypeId: row.documentTypeId,
                        fabricatorId: model.get("id")
                    })
                }
            })
    }

    clearDocument(row) {
        if (row.documentType.dossierType === DOSSIER_TYPE.ECOBONUS_2019) {
            toast("Impossibile modificare la documentazione per le pratiche 2019")
            return;
        }
        let model = this.props.model;
        swal({title: M("confirm"), text: M("areYouSure"), showCancelButton: true})
            .then(res => {
                if (res.value) {
                    clearDocument({
                        documentTypeId: row.documentTypeId,
                        fabricatorId: model.get("id")
                    })
                }
            })
    }

    refuseHistory(refuseHistoryReason) {
        this.refuseHistoryReason = refuseHistoryReason;
        this.forceUpdate();
    }

    onClose() {
        this.refuseHistoryReason = null;
        this.forceUpdate();
    }

    render() {
        let model = this.props.model;
        let field = this.props.field;
        let rows = optional(model.get(field.property), []);

        let items = rows.map((i, index) => <DocumentRow fabricatorId={model.get("id")} key={this.getKey(i)} row={i} index={index} attachAttachment={this.attachAttachment.bind(this)} refuseDocument={this.refuseDocument.bind(this)} clearDocument={this.clearDocument.bind(this)} approveDocument={this.approveDocument.bind(this)} refuseHistory={this.refuseHistory.bind(this)}/>)

        let filesUploaded = _.filter(rows, r=>r.file != null).length;
        let filesRefused = _.filter(rows, r=>!r.valid).length;
        let description = format(M("rowsDescription"), filesUploaded, rows.length);
        if (filesRefused > 0) {
            description += " - " + format(M("refusedRows"), filesRefused).toUpperCase();
        }

        let refuseHistoryReasonComponents = [];
        if (this.refuseHistoryReason != null && this.refuseHistoryReason.length > 0) {
            _.each(this.refuseHistoryReason, r => {
                refuseHistoryReasonComponents.push(<div className="col-12">
                    {format("{0} - {1}", moment(r.refusedDate).format("DD/MM/YYYY HH:mm"), r.refusedReason)}
                    <hr/>
                </div>);
            })
        }

        const buttons = [
            {
                text: M("close"),
                action: (dialog) => {
                    dialog.hide()
                },
                dialogResult: DIALOG_RESULT_OK
            }
        ]

        return (
            <div className="col-12 zero-padding m-t-15">
                <div className="row">
                    <div className="col-lg zero-padding">
                        <div className="col-12">
                            <div className="col-12 zero-padding document-header" style={{display: "inline-block"}}>
                                <span className="text-uppercase float-left">{M("fabricatorDocumentation")}</span>
                                <span className="text-uppercase float-right">{description}</span>
                            </div>
                            <div className="col-12 zero-padding">
                                {items}
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog
                    ref="dialog"
                    titleClass="modal-title text-primary"
                    title={M("refuseHistory")}
                    large={false}
                    hidden={this.refuseHistoryReason == null}
                    buttons={buttons}
                    onClose={this.onClose.bind(this)}>

                    <div className="bills-container row">
                        {refuseHistoryReasonComponents}
                    </div>

                </Dialog>
            </div>
        )
    }
}