import M from "../strings";
import _ from "underscore"
import * as datasource from "../utils/datasource";
import {hasPermission, isSuperuser} from "../api/session";

export const CustomerType = {
    SUBJECT_TYPE_PHYSICAL_PERSON: {
        label: M("physicalPerson"),
        value: "physical-person"
    },
    SUBJECT_TYPE_LEGAL_PERSON: {
        label: M("legalPerson"),
        value: "legal-person"
    },
};

export const dateNewLabel = "07/01/2021"; //data (MM/DD/YYYY) dalla quale la modifica del cambio delle stringhe e rincaro deve cambiare

export const OperationType = {
    ORDINARY_MAINTENANCE: {
        label: M("ordinaryMaintenance"),
        value: "ordinaryMaintenance"
    },
    EXTRAORDINARY_MAINTENANCE: {
        label: M("extraordinaryMaintenance"),
        value: "extraordinaryMaintenance"
    },
    SELF_DECLARATION: {
        label: M("selfDeclaration"),
        value: "selfDeclaration"
    },
    CONSERVATIVE_RESTORATION: {
        label: M("conservativeRestoration"),
        value: "conservativeRestoration"
    },
    BUILDING_RENOVATION: {
        label: M("buildingRenovation"),
        value: "buildingRenovation"
    },
};

export const CompanyCustomType = {
    SOLE_PROPRIETORSHIP: {
        label: M("soleProprietorship"),
        value: "soleProprietorship"
    },
    SS: {
        label: M("ss"),
        value: "ss"
    },
    SNC: {
        label: M("snc"),
        value: "snc"
    },
    SAS: {
        label: M("sas"),
        value: "sas"
    },
    SPA: {
        label: M("spa"),
        value: "spa"
    },
    SAPA: {
        label: M("sapa"),
        value: "sapa"
    },
    SRL: {
        label: M("srl"),
        value: "srl"
    },
    SRLS: {
        label: M("srls"),
        value: "srls"
    },
};

export const CustomerTypeDatasource = datasource.fixed(_.map(CustomerType,f => {return {value: f.value, label: f.label}}));

export const Sex = {
    M: {
        label: M("M"),
        value: "M"
    },
    F: {
        label: M("F"),
        value: "F"
    },
};

export const SexDatasource = datasource.fixed(_.map(Sex,f => {return {value: f.value, label: f.label}}));

export function getCustomerTypeDescription(customerType) {
    let customerTypes = _.values(CustomerType).filter(f => f.value === customerType);
    if (!_.isEmpty(customerTypes))
        return customerTypes[0].label;
    else
        return ""
}

export const AssignationType = {
    FABRICATOR_PROFILE: {
        label: M("fabricatorProfile"),
        value: "fabricator-profile"
    },
    PREPARATORY_DOCUMENTATION: {
        label: M("preparatoryDocumentation1AE"),
        value: "preparatory-documentation"
    },
    GIURE_DOCUMENT: {
        label: M("giure"),
        value: "giure"
    },
    ENEA_PREPARATORY_DOCUMENTATION: {
        label: M("eneaPreparatoryDocumentation"),
        value: "enea-preparatory-documentation"
    },
    PRIVATE_CLOSING_DOCUMENTATION: {
        label: M("privateClosingDocumentation"),
        value: "private-closing-documentation"
    },
    PUBLIC_CLOSING_DOCUMENTATION: {
        label: M("publicClosingDocumentation"),
        value: "public-closing-documentation"
    },
    QUESTIONNAIRE: {
        label: M("questionnaire"),
        value: "questionnaire"
    },
    REQUIRED_POLICY: {
        label: M("requiredPolicy"),
        value: "required-policy"
    },
};

export const AssignationTypeDatasource = datasource.fixed(_.map(AssignationType,f => {return {value: f.value, label: f.label}}));

export const DocumentTypeCustomerType = {
    CUSTOMER_TYPE_ALL: {
        label: M("all"),
        value: "customer-type-all"
    },
    CUSTOMER_TYPE_PHYSICAL_PERSON: {
        label: M("physicalPerson"),
        value: "customer-type-physical-person"
    },
    CUSTOMER_TYPE_LEGAL_PERSON: {
        label: M("legalPerson"),
        value: "customer-type-legal-person"
    },
};

export const DocumentTypeCustomerTypeDatasource = datasource.fixed(_.map(DocumentTypeCustomerType,f => {return {value: f.value, label: f.label}}));


export function getAssignationTypeDescription(assignationType) {
    let assignationTypes = _.values(AssignationType).filter(f => f.value === assignationType);
    if (!_.isEmpty(assignationTypes))
        return assignationTypes[0].label;
    else
        return ""
}

export const DocumentTypeTypology = {
    NO_MODEL: {
        label: M("noModel"),
        value: "no-model"
    },
    DOWNLOADABLE_TEMPLATE: {
        label: M("downloadableTemplate"),
        value: "downloadable-template"
    },
    SELF_COMPILED_DOWNLOADABLE_TEMPLATE: {
        label: M("selfCompiledDownloadableTemplate"),
        value: "self-compiled-downloadable-template"
    },
};

export function getDocumentTypeTypologyDescription(tipology) {
    let types = _.values(DocumentTypeTypology).filter(f => f.value === tipology);
    if (!_.isEmpty(types))
        return types[0].label;
    else
        return ""
}

export const DocumentTypeTypologyDatasource = datasource.fixed(_.map(DocumentTypeTypology,f => {return {value: f.value, label: f.label}}));

export const DocumentStatus = {
    TO_BE_UPLOAD: {
        label: M("toBeUpload"),
        value: "to-be-upload"
    },
    UPLOADED: {
        label: M("uploaded"),
        value: "uploaded"
    },
    TO_RECHARGE: {
        label: M("toRecharge"),
        value: "to-recharge"
    },
    APPROVED: {
        label: M("approved"),
        value: "approved"
    },
};
export const DossierStatus = {
    STATUS_GIURE_EVALUATION: {
        label: M("giureEvaluation"),
        value: "giureEvaluation"
    },
    STATUS_QUOTATION: {
        label: M("quotation"),
        value: "quotation"
    },
    STATUS_DRAFT: {
        label: M("draft"),
        value: "draft"
    },
    STATUS_TO_CANDIDATE: {
        label: M("toCandidate"),
        value: "to-candidate"
    },
    STATUS_CANDIDATED: {
        label: M("candidated"),
        value: "candidated"
    },
    STATUS_APPROVED: {
        label: M("approved"),
        value: "approved"
    },
    STATUS_PAY_OFF: {
        label: M("payOff"),
        value: "pay-off"
    },
    STATUS_REFUSED: {
        label: M("refused"),
        value: "refused"
    },
};

export const DossierStatusDatasource = datasource.fixed(_.map(DossierStatus,f => {return {value: f.value, label: f.label}}));

export function getDossierStatusDescription(status) {
    let dossierStatuses = _.values(DossierStatus).filter(f => f.value === status);
    if (!_.isEmpty(dossierStatuses))
        return dossierStatuses[0].label;
    else
        return ""
}

export const KPMGStatus = {
    PROGRESSING: {
        label: M("processing"),
        value: "processing"
    },
    APPROVED: {
        label: M("approved"),
        value: "approved"
    },
};

export const KPMGStatusDatasource = datasource.fixed(_.map(KPMGStatus,f => {return {value: f.value, label: f.label}}));

export function getKPMGStatusDescription(status) {
    let statuses = _.values(KPMGStatus).filter(f => f.value === status);
    if (!_.isEmpty(statuses))
        return statuses[0].label;
    else
        return ""
}


export const DossierStatusOrder = {
    STATUS_GIURE_EVALUATION: {
        label: "giureEvaluation",
        value: 0
    },
    STATUS_QUOTATION: {
        label: "quotation",
        value: 1
    },
    STATUS_DRAFT: {
        label: "draft",
        value: 2
    },
    STATUS_TO_CANDIDATE: {
        label: "to-candidate",
        value: 3
    },
    STATUS_CANDIDATED: {
        label: "candidated",
        value: 4
    },
    STATUS_APPROVED: {
        label: "approved",
        value: 5
    },
    STATUS_PAY_OFF: {
        label: "pay-off",
        value: 6
    },
    STATUS_REFUSED: {
        label: "refused",
        value: 7
    },
};


export const QUOTATION_TYPES_ONLY_BLIND = ["physical-blind-housing",  "physical-blind-no_housing", "legal-blind"];

export const QUOTATION_TYPES_BONUSCASA_2022_2_ONLY_BLIND = ["supplyAndPoseBlind"];

export const QUOTATION_TYPES_BONUSCASA_2022_2_ONLY_SUPPLY = ["supplyWindowsBlind"]

export const QUOTATION_TYPES_2023_75_ONLY_BLIND = ["supplyAndPoseBlind"];

export const QUOTATION_TYPES_2023_75_ONLY_SUPPLY = ["supplyWindowsBlind"]


export const DOSSIER_TYPE ={
    ECOBONUS_2019: "ecobonus2019",
    ECOBONUS_2020_50: "2020_50",
    ECOBONUS_2021_50: "2021_50",
    ECOBONUS_2021_BONUS_CASA: "2021_BonusCasa",
    ECOBONUS_2022_BONUS_CASA: "2022_BonusCasa",
    ECOBONUS_2022_50: "2022_50",
    ECOBONUS_2022_1: "2022_1",
    ECOBONUS_2022_BONUS_CASA_1: "2022_BonusCasa_1",
    ECOBONUS_2022_BONUS_CASA_2: "2022_BonusCasa_2",
    ECOBONUS_2023_75: "2023_75"

}

export const DossierTypeDatasource = datasource.fixed(_.map(DOSSIER_TYPE,f => {return {value: f, label: M(f)}}));

export function getDossierEntityNameFromType(type) {
    switch (type) {
        case DOSSIER_TYPE.ECOBONUS_2019:
            return "dossier";
        case DOSSIER_TYPE.ECOBONUS_2020_50:
            return "dossier_2020_50";
        case DOSSIER_TYPE.ECOBONUS_2021_50:
            return "dossier_2021_50";
        case DOSSIER_TYPE.ECOBONUS_2021_BONUS_CASA:
            return "dossier_2021_BonusCasa";
        case DOSSIER_TYPE.ECOBONUS_2022_BONUS_CASA:
            return "dossier_2022_BonusCasa";
        case DOSSIER_TYPE.ECOBONUS_2022_50:
            return "dossier_2022_50";
        case DOSSIER_TYPE.ECOBONUS_2022_1:
            return "dossier_2022_1";
        case DOSSIER_TYPE.ECOBONUS_2022_BONUS_CASA_1:
            return "dossier_2022_BonusCasa_1";
        case DOSSIER_TYPE.ECOBONUS_2022_BONUS_CASA_2:
            return "dossier_2022_BonusCasa_2";
        case DOSSIER_TYPE.ECOBONUS_2023_75:
            return "dossier_2023_75";
    }
}


export function getDossierStatusPosition(status) {
    let dossierStatuses = _.values(DossierStatusOrder).filter(f => f.label === status);
    if (!_.isEmpty(dossierStatuses))
        return dossierStatuses[0].value;
    else
        return ""
}


export function generateDossierItem(type) {

    return {
        id : type,
        active: false,
        children: [],
        icon: "zmdi zmdi-assignment",
        text: M(type),
        href: "/#/entities/dossier/list/" + type,
        permissions: [ getDossierEntityNameFromType(type) + ":list"]
    }

}

export const DEFAULT_DOSSIER_TYPE = DOSSIER_TYPE.ECOBONUS_2022_1;

export const defaultActiveMenuItem = generateDossierItem(DEFAULT_DOSSIER_TYPE);

export const UserDeviceStatus = {
    ACTIVE: {
        label: M("active"),
        value: "active"
    },
    NOT_ACTIVE: {
        label: M("notActive"),
        value: "notActive"
    },
    PENDING: {
        label: M("pending"),
        value: "pending"
    },
};

export const UserDeviceStatusDatasource = datasource.fixed(_.map(UserDeviceStatus,f => {return {value: f.value, label: f.label}}));

export function getUserDeviceStatusDescription(status) {
    let userDeviceStatuses = _.values(UserDeviceStatus).filter(f => f.value === status);
    if (!_.isEmpty(userDeviceStatuses))
        return userDeviceStatuses[0].label;
    else
        return ""
}

export function generateLoggedUserFirstScreenDossierType(){
    let dossierType = DEFAULT_DOSSIER_TYPE;
    if (!hasPermission([getDossierEntityNameFromType(dossierType) + ":list"]))
        dossierType = DOSSIER_TYPE.ECOBONUS_2023_75;
    return dossierType;
}

export const STATISTIC_EXPORT ={
    DOSSIER: "dossierExport",
}

export const QuestionType = {
    MULTIPLE_CHOICE: 1,
    MULTIPLE_TEXT: 2
}

export const SelectYesNo = {
    NO: {
        label: M("no"),
        value: false
    },
    YES: {
        label: M("yes"),
        value: true
    },
}

export const SelectYesNoDatasource = datasource.fixed(_.map(SelectYesNo,f => {return {value: f.value, label: f.label}}));

export const DossierServiceProvided = {
    SUPPLY_AND_INSTALLATION: {
        label: M("supplyAndInstallation"),
        value: "supplyAndInstallation"
    },
    ONLY_SUPPLY: {
        label: M("onlySupply"),
        value: "onlySupply"
    },
}

export const BlindsPresentsValues = {
    YES: {
        label: M("yes"),
        value: "yes"
    },
    NO: {
        label: M("no"),
        value: "no"
    },
    BOTH: {
        label: M("bothBlind"),
        value: "both"
    },
};

export function getBlindsPresentsValueDescription(value) {
    let values = _.values(BlindsPresentsValues).filter(f => f.value === value);
    if (!_.isEmpty(values))
        return values[0].label;
    else
        return ""
}

export const KPMGGroups = {
    VALIDATION_TRANSMISSION: {
        label: M("validationTransmission"),
        value: "validation-transmission"
    },
    CLOSING: {
        label: M("closing"),
        value: "closing"
    },
};

export function getKPMGGroupsDescription(group) {
    let types = _.values(KPMGGroups).filter(f => f.value === group);
    if (!_.isEmpty(types))
        return types[0].label;
    else
        return ""
}

export const KPMGGroupsDatasource = datasource.fixed(_.map(KPMGGroups,f => {return {value: f.value, label: f.label}}));

export const WORK_COMPLETION_DOCUMENTS_CODE = ["2050D14","2150D14","BC_2150D36"];
