"use strict"

import {alert, hideLoader, showLoader, toast} from "../plugins";
import {createAsyncAction} from "../utils/ajex";
import * as responses from "../api/responses";
import {
    CONFIRM_QUOTATION_2022_BONUS_CASA_2,
    CREATE_DOSSIER_2022_BONUS_CASA_2,
    EDIT_DOSSIER_2022_BONUS_CASA_2,
    GENERATE_QUESTIONNAIRE_DOSSIER_2022_BONUS_CASA_2,
    GENERATE_QUOTATION_AMOUNTS_2022_BONUS_CASA_2,
    GENERATE_QUOTATION_DOSSIER_2022_BONUS_CASA_2,
    UPDATE_QUESTIONNAIRE_2022_BONUS_CASA_2
} from "./types";
import * as DossierApi from "../api/dossier_2022_2_bonusCasa";
import {freeEntities, getEntity, manualSavedEntities} from "./entities";
import M from "../strings";
import * as aj from "../aj";
import * as ui from "../web/utils/ui";
import {DOSSIER_TYPE, getDossierEntityNameFromType} from "../model/vars";
import {format} from "../utils/lang";

export const generateQuotationDossier_2022_BonusCasa_2 = createAsyncAction(GENERATE_QUOTATION_DOSSIER_2022_BONUS_CASA_2, data => {
    showLoader();
    aj.dispatch({
        type: GENERATE_QUOTATION_DOSSIER_2022_BONUS_CASA_2
    });

    DossierApi.generateQuotationDossier_2022_BonusCasa_2(data.dossierDTO)
        .then(response => {
            hideLoader();
            generateQuotationDossier_2022_BonusCasa_2.complete({downloadUrl: response.value})
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            generateQuotationDossier_2022_BonusCasa_2.fail()
        })
});

export const generateQuestionnaireDossier_2022_BonusCasa_2 = createAsyncAction(GENERATE_QUESTIONNAIRE_DOSSIER_2022_BONUS_CASA_2, data => {
    showLoader();
    aj.dispatch({
        type: GENERATE_QUESTIONNAIRE_DOSSIER_2022_BONUS_CASA_2
    })

    DossierApi.generateQuestionnaireDossier_2022_BonusCasa_2(data.dossierDTO)
        .then(response => {
            hideLoader();
            if (response.value === null) {
                alert("Attenzione!", responses.msg({responseCode: 1034}), "error");
                generateQuestionnaireDossier_2022_BonusCasa_2.fail()
            } else {
                generateQuestionnaireDossier_2022_BonusCasa_2.complete({downloadUrl: response.value})
            }
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            generateQuestionnaireDossier_2022_BonusCasa_2.fail()
        })
});

export const updateQuestionnaire_2022_BonusCasa_2 = createAsyncAction(UPDATE_QUESTIONNAIRE_2022_BONUS_CASA_2, data => {

    showLoader();

    aj.dispatch({
        type: UPDATE_QUESTIONNAIRE_2022_BONUS_CASA_2
    })
    DossierApi.updateQuestionnaire_2022_BonusCasa_2(data.dossierDTO)
        .then(response => {
            hideLoader();
            toast(M("saveComplete"))
            updateQuestionnaire_2022_BonusCasa_2.complete()
            if (data.callback)
                data.callback()
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            updateQuestionnaire_2022_BonusCasa_2.fail()
        })
});

export const createDossier_2022_BonusCasa_2 = createAsyncAction(CREATE_DOSSIER_2022_BONUS_CASA_2, data => {

    showLoader();

    aj.dispatch({
        type: CREATE_DOSSIER_2022_BONUS_CASA_2
    })
    DossierApi.createDossier_2022_BonusCasa_2(data.dossierDTO)
        .then(response => {
            hideLoader();
            toast(M("saveComplete"))
            freeEntities({discriminator: data.discriminator});
            createDossier_2022_BonusCasa_2.complete()
            ui.navigate("/entities/" + getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2022_BONUS_CASA_2) + "/" + response.value.id + "?type=" + DOSSIER_TYPE.ECOBONUS_2022_BONUS_CASA_2)
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            createDossier_2022_BonusCasa_2.fail()
        })
});

export const confirmQuotation_2022_BonusCasa_2 = createAsyncAction(CONFIRM_QUOTATION_2022_BONUS_CASA_2, data => {
    aj.dispatch({
        type: CONFIRM_QUOTATION_2022_BONUS_CASA_2
    })

    showLoader();
    DossierApi.confirmQuotation_2022_BonusCasa_2(data.dossierDTO)
        .then(response => {
            hideLoader();
            toast("Operazione eseguita con successo!")
            confirmQuotation_2022_BonusCasa_2.complete({dossier: response.value});
            let entityName = getDossierEntityNameFromType(data.dossierType);
            getEntity({discriminator: "entity_form_" + entityName, entity: entityName, id: response.value.id});
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            confirmQuotation_2022_BonusCasa_2.fail()
        })
});

export const generateQuotationAmounts_2022_BonusCasa_2 = createAsyncAction(GENERATE_QUOTATION_AMOUNTS_2022_BONUS_CASA_2, data => {
    DossierApi.generateQuotationAmounts_2022_BonusCasa_2(data)
        .then(response => {
            generateQuotationAmounts_2022_BonusCasa_2.complete({priceCalculatorSheet: response.value});
        })
        .catch(e => {
            alert("Attenzione!", format(responses.msg(e), e.data), "error");
            generateQuotationAmounts_2022_BonusCasa_2.fail()
        })
        .finally(() => {
            hideLoader()
        })
});

export const editDossier_2022_BonusCasa_2 = createAsyncAction(EDIT_DOSSIER_2022_BONUS_CASA_2, data => {

    showLoader();

    aj.dispatch({
        type: EDIT_DOSSIER_2022_BONUS_CASA_2
    })
    DossierApi.editDossier_2022_BonusCasa_2(data.dossierDTO)
        .then(response => {
            hideLoader();
            let entityName = getDossierEntityNameFromType(DOSSIER_TYPE.ECOBONUS_2022_BONUS_CASA_2);
            toast(M("saveComplete"))
            manualSavedEntities({discriminator: "entity_form_" + entityName});
            editDossier_2022_BonusCasa_2.complete()
            getEntity({discriminator: "entity_form_" + entityName, entity: entityName, id: data.dossierDTO.dossierId});
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            editDossier_2022_BonusCasa_2.fail()
        })
});