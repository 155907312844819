import M from "../strings";
import {Select} from "./components/forms";
import {fixed} from "../utils/datasource";
import {EntitiesSelectContainer, ValuesSelectContainer} from "./components/containers";
import * as _ from "underscore";
import {DossierStatus} from "../model/vars";


export const activeSearchForm = {
        showInCard: false,
        fields: [
            {
                property: "active",
                label: M("active"),
                control: Select,
                filterType: "eq",
                props: {
                    allowNull: true,
                    datasource: fixed([
                        {label: M("active"), value: true},
                        {label: M("notActive"), value: false},
                    ]),
                }
            }
        ]
    };

export function entitySelectContainerField(options = {}) {

    let property = options.property;
    let label = options.label || options.property;
    let filterType = options.filterType;
    let singleItemLabelName = options.itemLabel || "fullDescription";
    let singleItemValueName = options.itemValue || "id";
    let entity = options.entity;
    let mode = options.mode || "single";
    let allowNull = options.allowNull || true;
    let isRequired = options.isRequired || false;
    let size = options.size || "col-sm-12";

    return  {
        property: property,
        label: M(label),
        control: EntitiesSelectContainer,
        filterType: filterType,
        size: size,
        props: {
            id: property + new Date().getTime(),
            mode: mode,
            allowNull: allowNull,
            searchEnabled: true,
            entity: entity,
            isRequired: isRequired,
            getSingleItemLabel: (value) => {
                return value[singleItemLabelName]
            },
            getSingleItemValue: (value) => {
                return value[singleItemValueName]
            },
            formatter: v => {
                return  v[singleItemLabelName]
            }
        }
    }
}

export function valuesSelectContainerField(options = {}) {

    let property = options.property;
    let label = options.label || options.property;
    let filterType = options.filterType;
    let singleItemLabelName = options.itemLabel || "fullDescription";
    let singleItemValueName = options.itemValue || "id";
    let mode = options.mode || "single";
    let allowNull = options.allowNull || true;
    let isRequired = options.isRequired || false;
    let size = options.size || "col-sm-12";
    let visibility = _.isFunction(options.visibility) ? options.visibility : null
    let params = options.params || null

    let formatter = _.isFunction(options.formatter) ? options.formatter : (v) => {
        return  v != null ? v[singleItemLabelName] : ""
    }

    return {
        property: property,
        label: M(label),
        getControl(model) {
            return _.isFunction(options.getControl) ? options.getControl(model) : ValuesSelectContainer;
        },
        filterType: filterType,
        size: size,
        visibility: visibility,
        isRequired: isRequired,
        props: {
            ...params,
            id: property + new Date().getTime(),
            multiple: mode === "multiple",
            allowNull: allowNull,
            searchEnabled: true,
            getCollection(model) {
                return _.isFunction(options.getCollection) ? options.getCollection(model) : options.collection;
            },

            getSingleItemLabel: (value) => {
                return value[singleItemLabelName]
            },
            getSingleItemValue: (value) => {
                return value[singleItemValueName]
            },
            formatter: formatter
        }
    }
}

