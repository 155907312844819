"use strict"

import {
    FABRICATOR_APPROVE_DOCUMENT,
    FABRICATOR_ATTACH_DOCUMENT,
    FABRICATOR_CLEAR_DOCUMENT,
    FABRICATOR_HIDE_ADD_DOCUMENT_DIALOG,
    FABRICATOR_HIDE_REFUSE_DOCUMENT_DIALOG,
    FABRICATOR_REFUSE_DOCUMENT,
    FABRICATOR_SHOW_ADD_DOCUMENT_DIALOG,
    FABRICATOR_SHOW_REFUSE_DOCUMENT_DIALOG
} from "./types";
import * as FabricatorApi from "../api/fabricator";
import {createAsyncAction} from "../utils/ajex";
import {alert, hideLoader, showLoader} from "../plugins";
import * as aj from "../aj";
import * as responses from "../api/responses";

export const attachDocument = createAsyncAction(FABRICATOR_ATTACH_DOCUMENT, data => {
    aj.dispatch({
        type: FABRICATOR_ATTACH_DOCUMENT
    })

    showLoader();
    FabricatorApi.attachDocument(data.fabricatorId, data.documentTypeId, data.attachment.path)
        .then(response => {
            hideLoader();
            attachDocument.complete({fabricator: response.value});
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            attachDocument.fail()
        })
});

export const approveDocument = createAsyncAction(FABRICATOR_APPROVE_DOCUMENT, data => {
    aj.dispatch({
        type: FABRICATOR_APPROVE_DOCUMENT
    })

    showLoader();
    FabricatorApi.approveDocument(data.fabricatorId, data.documentTypeId)
        .then(response => {
            hideLoader();
            approveDocument.complete({fabricator: response.value});
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            approveDocument.fail()
        })
});

export const refuseDocument = createAsyncAction(FABRICATOR_REFUSE_DOCUMENT, data => {
    aj.dispatch({
        type: FABRICATOR_REFUSE_DOCUMENT
    })

    showLoader();
    FabricatorApi.refuseDocument(data.fabricatorId, data.documentTypeId, data.refuseReason)
        .then(response => {
            hideLoader();
            refuseDocument.complete({fabricator: response.value});
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            refuseDocument.fail()
        })
});

export const clearDocument = createAsyncAction(FABRICATOR_CLEAR_DOCUMENT, data => {
    aj.dispatch({
        type: FABRICATOR_CLEAR_DOCUMENT
    })

    showLoader();
    FabricatorApi.clearDocument(data.fabricatorId, data.documentTypeId)
        .then(response => {
            hideLoader();
            clearDocument.complete({fabricator: response.value});
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            clearDocument.fail()
        })
});

export const showAddDocumentDialog = aj.createAction(FABRICATOR_SHOW_ADD_DOCUMENT_DIALOG, data => {
    aj.dispatch({
        type: FABRICATOR_SHOW_ADD_DOCUMENT_DIALOG,
        documentType: data.documentType
    })
});

export const hideAddDocumentDialog = aj.createAction(FABRICATOR_HIDE_ADD_DOCUMENT_DIALOG, data => {
    aj.dispatch({
        type: FABRICATOR_HIDE_ADD_DOCUMENT_DIALOG
    })
});

export const showRefuseDocumentDialog = aj.createAction(FABRICATOR_SHOW_REFUSE_DOCUMENT_DIALOG, data => {
    aj.dispatch({
        type: FABRICATOR_SHOW_REFUSE_DOCUMENT_DIALOG,
        documentTypeId: data.documentTypeId
    })
});

export const hideRefuseDocumentDialog = aj.createAction(FABRICATOR_HIDE_REFUSE_DOCUMENT_DIALOG, data => {
    aj.dispatch({
        type: FABRICATOR_HIDE_REFUSE_DOCUMENT_DIALOG
    })
});