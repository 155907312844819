"use strict"

import * as React from "react";
import {Column, ReadOnlyText} from "../forms";
import {formatEuroNumber, getCardColorFromModel} from "../dossiers/utils";
import {CustomerType} from "../../../model/vars";

export class Dossier_2022_bonusCasa_1_priceCalculatorControl extends Column {

    getFields() {

        let fields = [
            {
                property: "totalWorkAmount",
                control: ReadOnlyText,
                label: "Spesa complessiva della fornitura (IVA inclusa)",
                getLabel: model => {
                    if (model != null && model.get("_customer") != null && model.get("_customer").subjectType === CustomerType.SUBJECT_TYPE_PHYSICAL_PERSON.value) {
                        return "Spesa complessiva della fornitura (IVA inclusa)"
                    } else return "Spesa complessiva della fornitura (IVA esclusa)"
                },
                size: "col-sm-12 ",
                props: {
                    getExtraClassName(model) {
                        return "quotation-value"
                    },
                    formatter(v, model) {
                        if (model) {
                            let priceCalculatorSheet = model.get("priceCalculatorSheet")
                            return priceCalculatorSheet && priceCalculatorSheet.totalWorkAmount ? formatEuroNumber(priceCalculatorSheet.totalWorkAmount ):  "-"
                        }
                    }
                }
            },

            {
                property: "totalDecrease",
                control: ReadOnlyText,
                label: "Detrazione spettante",
                size: "col-sm-12 ",
                props: {
                    getExtraClassName(model) {
                        return "quotation-value"
                    },
                    formatter(v, model) {
                        if (model) {
                            let priceCalculatorSheet = model.get("priceCalculatorSheet")
                            return priceCalculatorSheet && priceCalculatorSheet.totalDecrease ? formatEuroNumber((-1 * priceCalculatorSheet.totalDecrease))  :  "-"
                        }
                    }
                }
            },

            {
                property: "totalNotPermittedWhitVat",
                control: ReadOnlyText,
                getLabel: model => {
                    if (model != null && model.get("_customer") != null && model.get("_customer").subjectType === CustomerType.SUBJECT_TYPE_PHYSICAL_PERSON.value) {
                        return "Spesa non agevolabile (IVA inclusa)"
                    } else return "Spesa non agevolabile (IVA esclusa)"
                },
                size: "col-sm-12 ",
                props: {
                    getExtraClassName(model) {
                        return "quotation-value"
                    },
                    formatter(v, model) {
                        if (model) {
                            let priceCalculatorSheet = model.get("priceCalculatorSheet")
                            if (model.get("_customer") != null && model.get("_customer").subjectType === CustomerType.SUBJECT_TYPE_PHYSICAL_PERSON.value) {
                                return priceCalculatorSheet && priceCalculatorSheet.totalNotPermittedWhitVat ? formatEuroNumber(priceCalculatorSheet.totalNotPermittedWhitVat) : "-"
                            } else {
                                return priceCalculatorSheet && priceCalculatorSheet.totalNotPermitted ? formatEuroNumber(priceCalculatorSheet.totalNotPermitted) : "-"
                            }
                        }
                    }
                }
            },

            {
                property: "amountToPay",
                control: ReadOnlyText,
                label: "Importo netto da pagare (IVA inclusa)",
                size: "col-sm-12 ",
                props: {
                    getExtraClassName(model) {
                        return "quotation-value bold"
                    },
                    formatter(v, model) {
                        if (model) {
                            let priceCalculatorSheet = model.get("priceCalculatorSheet")
                            return priceCalculatorSheet && priceCalculatorSheet.amountToPay ? formatEuroNumber(priceCalculatorSheet.amountToPay):  "-"
                        }
                    }
                }
            },
        ];

        return [{
            property: "column_ecobonus_2020_bonusCasa",
            control: Column,
            size: "col-sm-12",
            getClassName(model) {
                return getCardColorFromModel(model)
            },
            noLateralPadding: false,
            useBoostrapRow: true,
            fields: fields
        }]
    }



    renderExtra() {
        return null
    }

}

