"use strict"

import {connect} from "../utils/aj";
import React from "react";
import M from "../../strings";
import * as _ from "underscore";
import {
    goBack,
    goNext,
    loadQuestionnaire,
    loadQuestionnaireForDossier,
    setOptionAnswer,
    setOptionChoose,
    setQuestionFieldAnswerProperty,
    showAddRequiredPolicyDocumentDialog
} from "../../actions/questionnaire";
import {optional, safeGet} from "../../utils/lang";
import {QuestionnaireModel} from "../../model/questionnarie";
import QuestionnaireStore from "../../stores/questionnaire";
import {DOSSIER_TYPE, getDossierEntityNameFromType, QuestionType} from "../../model/vars";
import {Conditioned} from "../components/conditioned";
import {alert} from "../../plugins";
import * as ui from "../utils/ui";
import DossierForm from "../components/dossiers/dossierForm";
import {AddRequiredPolicyDocumentDialog} from "../components/questionnaire/addRequiredPolicyDocumentDialog";
import {updateQuestionnaire} from "../../actions/dossier";
import {generateDossierDTOFromData} from "../components/dossiers/utils";
import {updateQuestionnaire_2021_bonusCasa} from "../../actions/dossier_2021_bonusCasa";
import {updateQuestionnaire_2022_BonusCasa_1} from "../../actions/dossier_2022_bonusCasa";
import {updateQuestionnaire_2022_BonusCasa_2} from "../../actions/dossier_2022_2_bonusCasa";
import {updateQuestionnaire_2023_75} from "../../actions/dossier_2023_75";

const {Screen, Layout} = require("../components/layout")
const Secure = require("../components/secure")

export default class QuestionnaireScreen extends Screen {

    constructor(props) {
        super(props);
        connect(this, QuestionnaireStore, QuestionnaireStore.defaultState())
    }

    componentDidMount() {
        //Modifica questionario di una pratica esistente
        if (this.props.dossierId) {
            loadQuestionnaireForDossier({
                dossierId: this.props.dossierId,
                dossierType: this.props.dossierType,
            })
        } else {
            //Creazione questionario
            if (!this.props.customerId)
                ui.navigate("customerSelection/" + this.props.dossierType)
            else
                loadQuestionnaire(this.props.dossierType)
        }


    }

    componentDidUpdate() {
        if (this.state && this.state.error === true)
            ui.navigate("entities/dossier/list/" + this.props.dossierType)
    }

    onCancel() {
        if (this.props.dossierId) {
            ui.navigate("/entities/" + getDossierEntityNameFromType(this.props.dossierType) + "/" + this.props.dossierId + "?type=" + this.props.dossierType)
        } else {
            ui.navigate("/entities/dossier/list/" + this.props.dossierType)
        }
    }

    onConfirm() {
        if (this.props.dossierId) {
            let data = {questionnaire: this.questionnaire, id : this.props.dossierId}
            if (this.props.dossierType === DOSSIER_TYPE.ECOBONUS_2021_BONUS_CASA) {
                if (this.questionnaire.requiredPolicyDocument == null) {
                    alert("Attenzione", "E' necessario caricare il documento di privacy firmato prima di procedere!", "error")
                } else {
                    updateQuestionnaire_2021_bonusCasa({
                        callback: this.onCancel.bind(this),
                        dossierDTO: generateDossierDTOFromData(data),
                    })
                }
            } else if (this.props.dossierType === DOSSIER_TYPE.ECOBONUS_2022_BONUS_CASA_1) {
                if (this.questionnaire.requiredPolicyDocument == null) {
                    alert("Attenzione", "E' necessario caricare il documento di privacy firmato prima di procedere!", "error")
                } else {
                    updateQuestionnaire_2022_BonusCasa_1({
                        callback: this.onCancel.bind(this),
                        dossierDTO: generateDossierDTOFromData(data),
                    })
                }
            } else if (this.props.dossierType === DOSSIER_TYPE.ECOBONUS_2022_BONUS_CASA_2) {
                if (this.questionnaire.requiredPolicyDocument == null) {
                    alert("Attenzione", "E' necessario caricare il documento di privacy firmato prima di procedere!", "error")
                } else {
                    updateQuestionnaire_2022_BonusCasa_2({
                        callback: this.onCancel.bind(this),
                        dossierDTO: generateDossierDTOFromData(data),
                    })
                }
            } else if (this.props.dossierType === DOSSIER_TYPE.ECOBONUS_2023_75){
                if (this.questionnaire.requiredPolicyDocument == null) {
                    alert("Attenzione", "E' necessario caricare il documento di privacy firmato prima di procedere!", "error")
                } else {
                    updateQuestionnaire_2023_75({
                        callback: this.onCancel.bind(this),
                        dossierDTO: generateDossierDTOFromData(data),
                    })
                }
            } else {
                updateQuestionnaire({
                    callback: this.onCancel.bind(this),
                    dossierDTO: generateDossierDTOFromData(data),
                    dossierType: this.props.dossierType
                })
            }

        } else {

            if (this.questionnaire.requiredPolicyDocument == null) {
                alert("Attenzione", "E' necessario caricare il documento di privacy firmato prima di procedere!", "error")
            } else {
                let key = "dossier_" + new Date();
                ui.changeScreen(<DossierForm key={key} entity={getDossierEntityNameFromType(this.props.dossierType)} entityId="new"
                                             params={{type: this.props.dossierType, customerId: this.props.customerId}}
                                             questionnaire={this.questionnaire}/>)
            }



        }
    }

    showRequiredPolicyDialog() {
        showAddRequiredPolicyDocumentDialog();
    }

    render() {

        this.questionnaire = QuestionnaireModel.of(this.state.questionnaire)
        let headerTitle = this.props.dossierId ? "Modifica questionario" : M("questionnaire")
        //todo: da controllare
        let showPrivacyButton = true
        let confirmText = this.props.dossierId ? "Aggiorna questionario" : M("confirmQuestionnaire")
        return (
            <Secure>
                <Layout hideFooter={true}>

                    {
                        !_.isEmpty(this.questionnaire) &&
                        <div className="questionnaire block-header">
                            <QuestionnaireHeader
                                title={headerTitle}
                                showPrivacyButton={showPrivacyButton}
                                questionnaire={this.questionnaire}
                                showRequiredPolicyDialog={this.showRequiredPolicyDialog.bind(this)}/>
                            <div className="m-t-45">
                                <QuestionnaireProgressBar questionnaire={this.questionnaire}/>
                                <QuestionnaireContent questionnaire={this.questionnaire}/>
                            </div>
                            <QuestionnaireFooter

                                confirmText={confirmText}
                                questionnaire={this.questionnaire}
                                onCancel={this.onCancel.bind(this)}
                                onConfirm={this.onConfirm.bind(this)}
                            />
                            <AddRequiredPolicyDocumentDialog
                                customerId={this.props.customerId}/>
                        </div>
                    }

                </Layout>
            </Secure>
        )
    }

}


const QuestionnaireContent = (props) => {

    const currentQuestion = props.questionnaire.currentQuestion

    function onPropertyChange(option_index, value) {
        setQuestionFieldAnswerProperty(option_index, value)
    }

    function onSelectOption(value) {
        setOptionChoose(value)
    }

    function onChangeOptionAnswer(value) {
        setOptionAnswer(value)
    }

    return (

        <div className="card questionnaire__content ">
            <h3>{currentQuestion.text}</h3>
            <div className="form">
                {/*TODO: Inserire dentro verified l'altra condition (vedi mobile)*/}
                <Conditioned
                    condition={currentQuestion.type === QuestionType.MULTIPLE_CHOICE}
                    verified={
                        <Conditioned
                            condition={currentQuestion.options.length <= 12}
                            verified={
                                _.map(currentQuestion.options, (o, index) =>
                                    <Radio key={"radio-" + o.id} currentQuestion={currentQuestion} option={o}
                                           optionIndex={index} onSelectOption={onSelectOption}
                                           onChangeOptionAnswer={onChangeOptionAnswer}/>
                                )
                            }
                            notVerified={
                                renderRadioByColumns()
                            }
                        />
                    }
                    notVerified={
                        _.map(currentQuestion.options, (o, index) =>
                            <TextInput key={"text-" + o.id} option={o} optionIndex={index}
                                       onPropertyChange={onPropertyChange}/>
                        )}
                />


            </div>
        </div>
    )

    function renderRadioByColumns() {
        const columns = 6
        var rows = []
        var containerRow = []

        for (var i = 0; i < currentQuestion.options.length; i += columns) {
            for (var j = i; j < currentQuestion.options.length && j <= i + (columns - 1); j++) {
                const option = currentQuestion.options[j]
                rows.push(
                    <Radio key={"radio-bycolumns-" + option.id} className=" col-sm-2 col-4"
                           currentQuestion={currentQuestion} option={option} optionIndex={i}
                           onSelectOption={onSelectOption} onChangeOptionAnswer={onChangeOptionAnswer}/>
                )
            }
            containerRow.push(
                <div className="row mb-3">
                    {rows}
                </div>
            )
            rows = []
        }

        return (
            <div>
                {containerRow}
            </div>
        )

    }
}

const Radio = (props) => {

    const checked = _.any(optional(props.currentQuestion.selectedOptionIds, []), id => id === props.option.id)

    function onChange(e) {
        if (props.option.notSelectable) {
            alert(M("ooops"), props.option.notSelectableAlertText, "error")
        } else if (_.isFunction(props.onSelectOption)) {

            if (props.option.alertText !== null) {
                alert(M("warning"), props.option.alertText, "warning");
            }
            props.onSelectOption(props.option.id);
        }
    }

    function onChangeOptionAnswer(e) {
        if (_.isFunction(props.onChangeOptionAnswer)) {
            props.onChangeOptionAnswer(e.target.value);
        }
    }

    return (
        <div className={"radio " + (optional(props.className, ""))}>
            <input type="radio" name="customRadio" id={props.option.id} checked={checked} onChange={onChange}/>
            <label className="radio__label" htmlFor={props.option.id} dangerouslySetInnerHTML={{__html: props.option.text}}></label>

            {props.option.shortAnswerRequired && checked &&

            <div className="form-group" style={{marginLeft: "27px"}}>
                <input type="text" className="form-control" value={optional(props.currentQuestion.shortAnswer, "")}
                       onChange={onChangeOptionAnswer} placeholder={M("note")}/>
                <i className="form-group__bar"/>
            </div>
            }

        </div>
    )
}

const TextInput = (props) => {
    function onChange(e) {
        if (_.isFunction(props.onPropertyChange)) {
            props.onPropertyChange(props.optionIndex, e.target.value);
        }
    }

    return (
        <div className="form-group">
            <label>{props.option.text + (props.option.required ? " *" : "")}</label>
            <input type="text" className="form-control" value={optional(props.option.answer, "")} onChange={onChange}/>
            <i className="form-group__bar"/>
        </div>
    )
}

const QuestionnaireProgressBar = (props) => {
    const currentIndex = safeGet(props.questionnaire, "progress", 0) + 1
    const questions = optional(safeGet(props.questionnaire, "questions"), [])

    const buttons = _.map(questions, q => {
        const isActive = q.number === currentIndex
        const isCheck = q.number < currentIndex

        return (
            <span key={"progress-btn-" + q.id}>
                <button
                    className={"btn btn-light btn--icon btn-outline-qst questionnaire__progress__btn " + (isActive ? "active" : (isCheck ? "check" : ""))}>
                    {isCheck ? <i className="zmdi zmdi-check"/> : q.number}
                </button>
                {q.number < questions.length && <hr className="questionnaire__progress__hr"/>}
            </span>
        )
    })

    return (
        <div className=" m-b-20 text-center">
            {buttons}
        </div>
    )
}

export const QuestionnaireFooter = (props) => {

    function onClickCancel() {
        if (_.isFunction(props.onCancel)) {
            props.onCancel()
        }
    }

    function onClickNext() {
        if (props.questionnaire.isCurrentQuestionComplete) {
            goNext()
        }
    }

    function onClickBack() {
        goBack()
    }

    function onConfirm() {
        if (props.questionnaire.isCurrentQuestionComplete) {
            if (_.isFunction(props.onConfirm)) {
                props.onConfirm()
            }
        }
    }

    return (
        <div className="questionnaire__footer ">
            <div className="btn-actions-bar">
                <button type="button" className="btn btn-cancel float-left" onClick={onClickCancel}>
                    {M("cancel")}
                </button>
                {
                    props.questionnaire.progress > 0 &&
                    <button type="button" className="btn btn-outline-primary btn-go-back" onClick={onClickBack}>
                        {M("back")}
                    </button>
                }

                {
                    props.questionnaire.progress < props.questionnaire.questions.length - 1 &&
                    <button type="submit" className="btn btn-primary" onClick={onClickNext}>
                        {M("next")}
                    </button>
                }

                {
                    props.questionnaire.progress === props.questionnaire.questions.length - 1 &&
                    <button type="submit" className="btn btn-warning" onClick={onConfirm} style={{width: "212px"}}>
                        {props.confirmText}
                    </button>
                }
            </div>


        </div>
    )
}

export const QuestionnaireHeader = (props) => {

    function onClick() {
        if (_.isFunction(props.showRequiredPolicyDialog)) {
            props.showRequiredPolicyDialog()
        }
    }
    return (
        <div className="questionnaire__header ">
            <div className="btn-actions-bar">
                <span className="float-left fs-18" style={{lineHeight: "32px"}}>{props.title}</span>
                {props.showPrivacyButton && <button type="button"
                                                    className={"btn float-right " + (props.questionnaire.requiredPolicyDocument == null ? "btn-upload-required-policy" : "btn-required-policy-uploaded")}
                                                    onClick={onClick}>
                    {M("privacy")}
                </button>}
            </div>


        </div>
    )
}
