"use strict"

export const LOGIN = "LOGIN";
export const RESUME_SESSION = "RESUME_SESSION"
export const LOGOUT = "LOGOUT";
export const REGISTER = "REGISTER";
export const RECOVER_ACCOUNT = "RECOVER_ACCOUNT"
export const REQUEST_RECOVERY_CODE = "REQUEST_RECOVERY_CODE"
export const RESET_PASSWORD = "RESET_PASSWORD"
export const VALIDATE_RECOVERY_CODE = "VALIDATE_RECOVERY_CODE"
export const SET_ACTIVATION_CODE = "SET_ACTIVATION_CODE"
export const CONFIRM_ACCOUNT = "CONFIRM_ACCOUNT"

export const GET_GRID = "GET_GRID"
export const LOAD_ENTITIES = "LOAD_ENTITIES"
export const DELETE_ENTITIES = "DELETE_ENTITIES"
export const SAVE_ENTITY = "SAVE_ENTITY"
export const NEW_ENTITY = "NEW_ENTITY"
export const GET_ENTITY = "GET_ENTITY"
export const FREE_ENTITIES = "FREE_ENTITIES"
export const MANUAL_SAVED_ENTITIES = "MANUAL_SAVED_ENTITIES"
export const GET_LOOKUP_RESULT = "GET_LOOKUP_RESULT"
export const GET_LOOKUP_VALUES = "GET_LOOKUP_VALUES"
export const FREE_LOOKUP = "FREE_LOOKUP"
export const GET_SELECT_ENTITIES = "GET_SELECT_ENTITIES"
export const GET_SELECT_VALUES = "GET_SELECT_VALUES"
export const FREE_SELECT = "FREE_SELECT"

export const SETUP_MENU = "SETUP_MENU"
export const SET_ACTIVE_MENU_ITEM = "SET_ACTIVE_MENU_ITEM"
export const EXPAND_MENU_ITEM = "EXPAND_MENU_ITEM"

export const GET_USER_COVER_IMAGE = "GET_USER_COVER_IMAGE"
export const GET_USER_PROFILE_IMAGE = "GET_USER_PROFILE_IMAGE"

export const RESET_USER_PASSWORD = "RESET_USER_PASSWORD"
export const CHANGE_PASSWORD = "CHANGE_PASSWORD"
export const FREE_SETTINGS_VALUES = "FREE_SETTINGS_VALUES"
export const UPDATE_MULTIVALUE_SETTINGS = "UPDATE_MULTIVALUE_SETTINGS"
export const SET_MULTIVALUE_SETTINGS = "SET_MULTIVALUE_SETTINGS"
export const CHECK_REVISION_ENABLE_STATUS = "CHECK_REVISION_ENABLE_STATUS"
export const UPDATE_FORM_DATA = "UPDATE_FORM_DATA";
export const RESET_PASSWORD_CHANGE = "RESET_PASSWORD_CHANGE";

export const SYSTEM_INFORMATIONS = "SYSTEM_INFORMATIONS"

export const GET_ALL_ORGANIZATIONS = "GET_USER_ORGANIZATIONS"
export const CREATE_ORGANIZATION = "CREATE_ORGANIZATION"
export const SELECT_ORGANIZATION = "SELECT_ORGANIZATION"
export const SHOW_ORGANIZATION_SELECTION_DIALOG = "SHOW_ORGANIZATION_SELECTION_DIALOG"
export const HIDE_ORGANIZATION_SELECTION_DIALOG = "HIDE_ORGANIZATION_SELECTION_DIALOG"
export const FIND_FREE_ORGANIZATION_NAME = "FIND_FREE_ORGANIZATION_NAME"

export const FIND_NODES = "FIND_NODES"
export const SET_CONFIGURATION = "SET_CONFIGURATION"
export const REQUEST_STATE_UPDATE = "REQUEST_STATE_UPDATE"
export const SET_STATE = "SET_STATE"

export const CREATE_DOSSIER = "CREATE_DOSSIER"
export const GENERATE_QUOTATION_DOSSIER_2021_50 = "GENERATE_QUOTATION_DOSSIER_2021_50";
export const GENERATE_QUOTATION_DOSSIER_2022_1 = "GENERATE_QUOTATION_DOSSIER_202_1";
export const GENERATE_QUOTATION_DOSSIER_2020_50 = "GENERATE_QUOTATION_DOSSIER_2020_50";
export const RESTORE_CAN_DOWNLOAD = "RESTORE_CAN_DOWNLOAD";
export const GENERATE_QUESTIONNAIRE_DOSSIER_2021_50 = "GENERATE_QUESTIONNAIRE_DOSSIER_2021_50";
export const GENERATE_QUESTIONNAIRE_DOSSIER_2022_1 = "GENERATE_QUESTIONNAIRE_DOSSIER_2022_1";
export const GENERATE_QUESTIONNAIRE_DOSSIER_2020_50 = "GENERATE_QUESTIONNAIRE_DOSSIER_2020_50";
export const UPDATE_QUESTIONNAIRE = "UPDATE_QUESTIONNAIRE"
export const CREATE_DOSSIER_2020_50 = "CREATE_DOSSIER_2020_50";
export const CREATE_DOSSIER_2021_50 = "CREATE_DOSSIER_2021_50";
export const CREATE_DOSSIER_2022_1 = "CREATE_DOSSIER_2022_1";
export const EDIT_DOSSIER = "EDIT_DOSSIER"
export const EDIT_DOSSIER_2021_50 = "EDIT_DOSSIER_2021_50"
export const EDIT_DOSSIER_2022_1 = "EDIT_DOSSIER_2022_1"
export const EDIT_DOSSIER_2020_50 = "EDIT_DOSSIER_2020_50"
export const CONFIRM_QUOTATION_2020_50 = "CONFIRM_QUOTATION_2020_50"
export const CONFIRM_QUOTATION_2021_50 = "CONFIRM_QUOTATION_2021_50"
export const CONFIRM_QUOTATION_2022_1 = "CONFIRM_QUOTATION_2022_1"
export const DOSSIER_ATTACH_DOCUMENT = "DOSSIER_ATTACH_DOCUMENT"
export const DOSSIER_APPROVE_DOCUMENT = "DOSSIER_APPROVE_DOCUMENT"
export const DOSSIER_REFUSE_DOCUMENT = "DOSSIER_REFUSE_DOCUMENT"
export const DOSSIER_CLEAR_DOCUMENT = "DOSSIER_CLEAR_DOCUMENT"

export const GIURE_DOSSIER_ATTACH_DOCUMENT = "DOSSIER_ATTACH_DOCUMENT"
export const GIURE_DOSSIER_APPROVE_DOCUMENT = "DOSSIER_APPROVE_DOCUMENT"
export const GIURE_DOSSIER_REFUSE_DOCUMENT = "DOSSIER_REFUSE_DOCUMENT"
export const GIURE_DOSSIER_CLEAR_DOCUMENT = "DOSSIER_CLEAR_DOCUMENT"
export const DOSSIER_SHOW_ADD_DOCUMENT_DIALOG = "DOSSIER_SHOW_ADD_DOCUMENT_DIALOG"
export const DOSSIER_HIDE_ADD_DOCUMENT_DIALOG = "DOSSIER_HIDE_ADD_DOCUMENT_DIALOG"
export const DOSSIER_SHOW_REFUSE_DOCUMENT_DIALOG = "DOSSIER_SHOW_REFUSE_DOCUMENT_DIALOG"
export const DOSSIER_HIDE_REFUSE_DOCUMENT_DIALOG = "DOSSIER_HIDE_REFUSE_DOCUMENT_DIALOG"
export const DOSSIER_CALCULATE_ACTUAL_VAT = "DOSSIER_CALCULATE_ACTUAL_VAT"
export const DOSSIER_CALCULATE_INVOICE_VAT = "DOSSIER_CALCULATE_INVOICE_VAT"
export const DOSSIER_SHOW_VAT_CALCULATOR_DIALOG = "DOSSIER_SHOW_VAT_CALCULATOR_DIALOG"
export const DOSSIER_HIDE_VAT_CALCULATOR_DIALOG = "DOSSIER_HIDE_VAT_CALCULATOR_DIALOG"
export const DOSSIER_SET_PRICE_CALCULATOR_SHEET = "DOSSIER_SET_PRICE_CALCULATOR_SHEET"
export const DOSSIER_SHOW_FINANCING_DIALOG = "DOSSIER_SHOW_FINANCING_DIALOG"
export const DOSSIER_HIDE_FINANCING_DIALOG = "DOSSIER_HIDE_FINANCING_DIALOG"
export const DOSSIER_SHOW_BILLS_DIALOG = "DOSSIER_SHOW_BILLS_DIALOG"
export const DOSSIER_HIDE_BILLS_DIALOG = "DOSSIER_HIDE_BILLS_DIALOG"
export const DOSSIER_SELECT_PAYMENT_FEE = "DOSSIER_SELECT_PAYMENT_FEE"
export const DOSSIER_SHOW_SERVICE_COSTS = "DOSSIER_SHOW_SERVICE_COSTS"
export const DOSSIER_HIDE_SERVICE_COSTS = "DOSSIER_HIDE_SERVICE_COSTS"

//2022
export const GENERATE_QUOTATION_DOSSIER_2022 = "GENERATE_QUOTATION_DOSSIER_2022_BONUS_CASA"
export const CREATE_DOSSIER_2022 = "CREATE_DOSSIER_2022_BONUS_CASA"
export const CONFIRM_QUOTATION_2022 = "CONFIRM_QUOTATION_2022_BONUS_CASA"
export const GENERATE_QUOTATION_AMOUNTS_2022 = "GENERATE_QUOTATION_AMOUNTS_2022_BONUS_CASA"
export const EDIT_DOSSIER_2022 = "EDIT_DOSSIER_2022_BONUS_CASA"

//2021_BonusCasa
export const GENERATE_QUOTATION_DOSSIER_2021_BONUS_CASA = "GENERATE_QUOTATION_DOSSIER_2021_BONUS_CASA"
export const GENERATE_QUESTIONNAIRE_DOSSIER_2021_BONUS_CASA = "GENERATE_QUESTIONNAIRE_DOSSIER_2021_BONUS_CASA"
export const UPDATE_QUESTIONNAIRE_2021_BONUS_CASA = "UPDATE_QUESTIONNAIRE_2021_BONUS_CASA"
export const CREATE_DOSSIER_2021_BONUS_CASA = "CREATE_DOSSIER_2021_BONUS_CASA"
export const CONFIRM_QUOTATION_2021_BONUS_CASA = "CONFIRM_QUOTATION_2021_BONUS_CASA"
export const GENERATE_QUOTATION_AMOUNTS_2021_BONUS_CASA = "GENERATE_QUOTATION_AMOUNTS_2021_BONUS_CASA"
export const EDIT_DOSSIER_2021_BONUS_CASA = "EDIT_DOSSIER_2021_BONUS_CASA"

//2022_BonusCasa_1
export const GENERATE_QUOTATION_DOSSIER_2022_BONUS_CASA_1 = "GENERATE_QUOTATION_DOSSIER_2022_BONUS_CASA_1"
export const GENERATE_QUESTIONNAIRE_DOSSIER_2022_BONUS_CASA_1 = "GENERATE_QUESTIONNAIRE_DOSSIER_2022_BONUS_CASA_1"
export const UPDATE_QUESTIONNAIRE_2022_BONUS_CASA_1 = "UPDATE_QUESTIONNAIRE_2022_BONUS_CASA_1"
export const CREATE_DOSSIER_2022_BONUS_CASA_1 = "CREATE_DOSSIER_2022_BONUS_CASA_1"
export const CONFIRM_QUOTATION_2022_BONUS_CASA_1 = "CONFIRM_QUOTATION_2022_BONUS_CASA_1"
export const GENERATE_QUOTATION_AMOUNTS_2022_BONUS_CASA_1 = "GENERATE_QUOTATION_AMOUNTS_2022_BONUS_CASA_1"
export const EDIT_DOSSIER_2022_BONUS_CASA_1 = "EDIT_DOSSIER_2022_BONUS_CASA_1"

//2022_BonusCasa_2
export const GENERATE_QUOTATION_DOSSIER_2022_BONUS_CASA_2 = "GENERATE_QUOTATION_DOSSIER_2022_BONUS_CASA_2"
export const GENERATE_QUESTIONNAIRE_DOSSIER_2022_BONUS_CASA_2 = "GENERATE_QUESTIONNAIRE_DOSSIER_2022_BONUS_CASA_2"
export const UPDATE_QUESTIONNAIRE_2022_BONUS_CASA_2 = "UPDATE_QUESTIONNAIRE_2022_BONUS_CASA_2"
export const CREATE_DOSSIER_2022_BONUS_CASA_2 = "CREATE_DOSSIER_2022_BONUS_CASA_2"
export const CONFIRM_QUOTATION_2022_BONUS_CASA_2 = "CONFIRM_QUOTATION_2022_BONUS_CASA_2"


export const GENERATE_QUOTATION_AMOUNTS_2022_BONUS_CASA_2 = "GENERATE_QUOTATION_AMOUNTS_2022_BONUS_CASA_2"
export const EDIT_DOSSIER_2022_BONUS_CASA_2 = "EDIT_DOSSIER_2022_BONUS_CASA_2"

export const CONFIRM_QUOTATION_2023_75 = "CONFIRM_QUOTATION_2023_75"

export const UPDATE_QUESTIONNAIRE_2023_75 = "UPDATE_QUESTIONNAIRE_2023_75"

export const EDIT_DOSSIER_2023_75 = "EDIT_DOSSIER_2023_75"

export const CREATE_DOSSIER_2023_75 = "CREATE_DOSSIER_2023_75"

export const GENERATE_QUOTATION_DOSSIER_2023_75 = "GENERATE_QUOTATION_DOSSIER_2023_75"
export const GENERATE_QUESTIONNAIRE_DOSSIER_2023_75 = "GENERATE_QUESTIONNAIRE_DOSSIER_2023_75"

export const GENERATE_QUOTATION_AMOUNTS_2023_75 = "GENERATE_QUOTATION_AMOUNTS_2023_75"

//2020_50
export const GENERATE_QUOTATION_AMOUNTS_2020_50 = "GENERATE_QUOTATION_AMOUNTS_2020_50";
export const KPMG_SEND_FOR_REVIEW = "KPMG_SEND_FOR_REVIEW";

export const FORCE_ALL_KPMG_SEND_FOR_REVIEW = "FORCE_ALL_KPMG_SEND_FOR_REVIEW";

export const QUOTATION = "QUOTATION"

export const GIURE_EVALUATION = "GIURE_EVALUATION"
export const CONFIRM_QUOTATION = "CONFIRM_QUOTATION"
export const COMMIT = "COMMIT"
export const CANDIDATE = "CANDIDATE"
export const APPROVE = "APPROVE"
export const PAY_OFF = "PAY_OFF"
export const REFUSE = "REFUSE"

export const DOSSIER_COUNT = "DOSSIER_COUNT"

export const DOWNLOAD_TEMPLATE = "DOWNLOAD_TEMPLATE"
export const GENERATE_AND_DOWNLOAD_TEMPLATE = "GENERATE_AND_DOWNLOAD_TEMPLATE"

export const FABRICATOR_ATTACH_DOCUMENT = "FABRICATOR_ATTACH_DOCUMENT"
export const FABRICATOR_APPROVE_DOCUMENT = "FABRICATOR_APPROVE_DOCUMENT"
export const FABRICATOR_REFUSE_DOCUMENT = "FABRICATOR_REFUSE_DOCUMENT"
export const FABRICATOR_CLEAR_DOCUMENT = "FABRICATOR_CLEAR_DOCUMENT"
export const FABRICATOR_SHOW_ADD_DOCUMENT_DIALOG = "FABRICATOR_SHOW_ADD_DOCUMENT_DIALOG"
export const FABRICATOR_HIDE_ADD_DOCUMENT_DIALOG = "FABRICATOR_HIDE_ADD_DOCUMENT_DIALOG"
export const FABRICATOR_SHOW_REFUSE_DOCUMENT_DIALOG = "FABRICATOR_SHOW_REFUSE_DOCUMENT_DIALOG"
export const FABRICATOR_HIDE_REFUSE_DOCUMENT_DIALOG = "FABRICATOR_HIDE_REFUSE_DOCUMENT_DIALOG"
export const QST_LOAD_QUESTIONNAIRE_DOSSIER = "QST_LOAD_QUESTIONNAIRE_DOSSIER"
export const QST_LOAD_QUESTIONNAIRE = "QST_LOAD_QUESTIONNAIRE"
export const QST_UPLOAD_REQUIRED_POLICY = "QST_UPLOAD_REQUIRED_POLICY"
export const QST_GO_NEXT = "QST_GO_NEXT"
export const QST_GO_BACK = "QST_GO_BACK"
export const QST_SET_TEXT_ANSWER = "QST_SET_TEXT_ANSWER"
export const QST_SET_OPTION_ANSWER = "QST_SET_OPTION_ANSWER"
export const QST_SET_OPTION_CHOOSE = "QST_SET_OPTION_CHOOSE"
export const QST_SET_REQUIRED_POLICY_DOCUMENT = "QST_SET_REQUIRED_POLICY_DOCUMENT"
export const SHOW_ADD_REQUIRED_POLICY_DOCUMENT_DIALOG = "SHOW_ADD_REQUIRED_POLICY_DOCUMENT_DIALOG"
export const HIDE_ADD_REQUIRED_POLICY_DOCUMENT_DIALOG = "HIDE_ADD_REQUIRED_POLICY_DOCUMENT_DIALOG"

//Notification
export const COUNT_UNREAD = "COUNT_UNREAD";
export const LIST_NOTIFICATION = "LIST_NOTIFICATION";
export const TOGGLE_OPEN = "TOGGLE_OPEN";

export const GET_QUERY = "GET_QUERY"
export const UPDATE_QUERY = "UPDATE_QUERY";
