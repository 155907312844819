let language = "it"

let strings = {}

strings["it"] = {
    appName: "InPonzioAdmin",
    registering: "Registrazione...",
    ooops: "Ooops...",
    badLogin: "Non riesco ad accedere! Per favore controlla il tuo indirizzo email o password!",
    welcome: "Benvenuto",
    congratulations: "Congratulazioni",
    welcomeMessage: "Ciao {0}, la tua registrazione è completa.\nUn link per la conferma è stato inviato a {1}.\nPer favore conferma prima di effettuare l'accesso",
    continue: "Continuare",
    exports: "Esportazioni",
    fabricatorNotes: "Note serramentista",
    register: "Registrati",
    alreadyRegistered: "Sei già registrato? Login",
    notRegistered: "Non sei registrato? Registrati",
    forgotPassword: "Password dimenticata?",
    signIn: "Rgistrati",
    mailAddress: "Indirizzo mail",
    name: "Nome",
    password: "Password",
    accountConfirmText: "Inserisci il codice di attivazione che abbiamo inviato alla tua casella mail per confermare il tuo account",
    accountConfirmed: "Il tuo account è confermato. Puoi effettuare l'accesso ora",
    mailAndPasswordRequired: "Email e password sono richieste",
    nameMailAndPasswordRequired: "Nome, email e password sono richieste",
    mailRequired: "Email è richiesta",
    activationCodeRequired: "Codice di attivazione richiesto",
    accountRecoverText: "Per favore inserisci il tuo indirizzo email per recuperare la password. Ti invieremo una nuova password al tuo indirizzo mail!",
    problemOccoured: "C'è un problema",
    sendMail: "Invia mail",
    accountRecovered: "Una nuova password è stata inviata a {0}",
    pleaseSpecifyId: "Per favore specifica il tuo ID",
    pleaseSpecifyQuery: "Per favore specifica la domanda",
    pleaseSpecifyEntity: "Per favore specifica l'entità",
    search: "Ricerca",
    close: "Chiudi",
    selectFilterType: "Seleziona il tipo di filtro",
    filterType: "Tipo di filtro",
    typeValueToSearch: "Tipo di valore da cercare",
    value: "Valore",
    filters: "Filtri",
    pagination: "Record da {0} a {1} di {2} totali",
    noResults: "Non ci sono risultati con i criteri specificati",
    selectAll: "Seleziona tutto",
    delete: "Rimuovi",
    create: "Crea",
    refresh: "Ricarica",
    confirm: "Conferma",
    entityDeleteConfirm: "Sei sicuro di voler eliminare {0} entità? Verranno rimosse solo le entità non in uso nel sistema, in caso contrario verranno disattivate.",
    submit: "Invia",
    cancel: "Annulla",
    add: "Aggiungi",
    pleaseSpecifyData: "Per favore specifica la data",
    ok: "OK",
    security: "Sicurezza",
    users: "Utenti",
    roles: "Ruoli",
    setup: "Setup",
    categories: "Categorie",
    nElementsSelected: "{0} elementi selezionati",
    oneElementSelected: "1 elemento selezionato",
    nothingSelected: "Niente selezionato",
    usersListDescription: "Creare, modificare o eliminare gli utenti di sistema",
    mail: "Email",
    active: "Attivo",
    editUser: "Modifica utente",
    editUserDescription: "Usa questo modulo per modificare le informazioni dell'utente",
    generalInformations: "Informazioni generali",
    rolesListDescription: "Un ruolo è un'entità che da all'utente l'autorizzazione per fare qualcosa",
    nameOfRole: "Nome del ruolo",
    role: "Ruolo",
    permissions: "Permessi",
    selectPermissions: "Seleziona i premessi per il ruolo",
    back: "Indietro",
    save: "Salva",
    saveAndGoBack: "Salva e torna alla lista",
    revisions: "Revisioni",
    image: "Immagine",
    cover: "Rivestimenti",
    saveComplete: "Salvataggio completato",
    articles: "Articoli",
    articlesListDescription: "Gli articoli devono essere presenti sul sistema Gamma. In commodo gli articoli vengono estesi per aggiungere funzionalità richiesta solo a Commodo",
    company: "Azienda",
    id: "ID",
    description: "Descrizione",
    companies: "Aziende",
    article: "Articolo",
    select: "Seleziona",
    component: "Componente",
    selectedComponent: "Componente selezionato",
    noComponentSelected: "Nessun componente selezionato",
    versions: "Versioni",
    version: "Versione",
    countries: "Paesi",
    country: "Paese",
    design: "Design",
    state: "Stato",
    model: "Modello",
    models: "Modelli",
    code: "Codice",
    extraSize: "Extra size",
    destinationCountry: "Paese di destinazione",
    revision: "Revisione",
    lastUpdate: "Ultimo aggiornamento",
    editedBy: "Modificato da",
    yes: "Si",
    no: "No",
    notes: "Appunti",
    makeACopy: "Crea una copia",
    duplicate: "Duplica",
    edit: "Modifica",
    editRole: "Modifica ruolo",
    editRoleDescription: "Usa un ruolo per gestire cosa può fare un utente nel sistema",
    shortName: "Nome breve",
    conversionFactor: "Fattore di conversione",
    status: "Stato",
    quantity: "Quantità",
    remove: "Rimuovi",
    unitOfMeasurement: "Unità di misura",
    colors: "Colori",
    color: "Colore",
    compositions: "Composizioni",
    customers: "Clienti",
    companyName: "Nome dell'Azienda",
    paymentCode: "Codice di pagamento",
    zipCode: "CAP",
    fiscalCode: "Codice fiscale",
    removeThisPhase: "Rimuovi questa fase",
    phases: "Fasi",
    workingTime: "Tempo di lavoro",
    address: "Indirizzo e civico",
    city: "Città",
    defaultTime: "Tempo predefinito",
    customer: "Cliente",
    admins: "Amministratori",
    endUser: "Utente finale",
    fabricators: "Serramentisti",
    adminUserCategories: "Categorie utenti admin",
    endUserCategories: "Categorie utenti finali",
    fabricatorCategories: "Categorie serramentisti",
    createAdminUsersCategory: "Crea categoria utenti admin",
    editAdminUsersCategory: "Modifica categoria utenti admin",
    createEndUsersCategory: "Crea categoria utenti finali",
    editEndUsersCategory: "Modifica categoria utenti finali",
    createFabricatorsCategory: "Crea categoria serramentisti",
    editFabricatorsCategory: "Modifica categoria serramentisti",
    ecoBonus: "EcoBonus",
    systemSettings: "Impostazioni di sistema",
    settings: "Impostazioni",
    referenceToUserCategory: "Categoria utente",
    accountInformations: "Informazioni account",
    endUserList: "Lista utente finale",
    fabricatorList: "Lista serramentisti",
    createEndUser: "Crea utente finale",
    editEndUser: "Modifica utente finale",
    createFabricator: "Crea serramentista",
    editFabricator: "Modifica serramentista",
    businessName: "Ragione sociale",
    province: "Provincia",
    cadastralCategory: "Categoria catastale",
    cadastralMunicipalCode: "Codice catastale del comune",
    cadastralSheet: "Foglio catastale",
    particle: "Particella",
    subParticle: "Subparticella",
    propertyEnjoymentTitle: "Titolo di godimento dell'immobile",
    phoneNumber: "Numero di telefono",
    referent: "Referente",
    lastname: "Cognome",
    endUsersList: "Lista utenti finali",
    adminUsersList:"Lista amministratori",
    editAdmin:"Modifica amministratore",
    cap: "Cap",
    streetNumber: "N° civico",
    category: "Categoria",
    physicalPerson: "Persona fisica",
    legalPerson: "Persona giuridica",
    customerList: "Lista clienti",
    createCustomer: "Crea cliente",
    editCustomer: "Modifica cliente",
    type: "Tipologia",
    sex: "Sesso",
    placeOfBirth: "Luogo di nascita",
    residence: "Residenza",
    residenceAddress: "Indirizzo di residenza",
    vatCode: "P.IVA",
    vatCodeDisabled: "Condominio o altro soggetto senza partita IVA",
    registeredOffice: "Sede legale",
    customerInformations: "Informazioni cliente",
    region: "Regione",
    municipality: "Città",
    postalCode: "CAP",
    noSelection: "Nessuna selezione",
    information: "Informativa",
    required: "Obbligatorio",
    documentTypeList: "Lista documenti",
    createDocumentType: "Crea nuova tipologia documenti",
    editDocumentType: "Modifica documento",
    documentType: "Tipologia documenti",
    _documentTypeSet: "Tipologia documento",
    template: "Template",
    assignTo: "Assegna a",
    dossiers: "Pratiche",
    downloadableTemplate: "Modello scaricabile",
    assignationType: "Tipo assegnazione",
    creationDate: "Data di creazione",
    fabricator: "Serramentista",
    significantValue: "Beni significativi (€)",
    nonSignificantValue: "Beni non significativi (€)",
    serviceValue: "Servizi (€)",
    recommendedRetailPrice: "Prezzo consigliato in fattura",
    netAmountToBePaid: "Prezzo scontato (-{0}%)",
    priceDiscounted: "Prezzo scontato",
    initiativeCost: "Costo dell'iniziativa",
    fabricatorPayOff: "Liquidazione Serramentista",
    feeForPraticalManagement: "Quota per gestione pratica",
    dossierEcoBonus: "Pratica EcoBonus",
    dossiersEcoBonus: "Pratiche EcoBonus",
    customerRequired: "Il cliente è obbligatorio",
    fabricatorRequired: "Il serramentista è obbligatorio",
    priceCalculatorSheetRequired: "I valori dei beni e dei servizi sono obbligatori",
    dossierNumber: "Pratica {0}",
    approvedDate: "Approvato il {0}",
    receivedDate: "Ricevuto il {0}",
    approvedUser: "da {1}",
    uploadedDate: "Caricato il {0}",
    refusedDate: "Rifiutato il {0}, da {1} da ricaricare",
    refusedDateAndReason: "Rifiutato da {0} il {1}, motivazione: \"{2}\". Da ricaricare",
    toBeUpload: "Da caricare",
    fabricatorProfile: "Profilo serramentista",
    preparatoryDocumentation1AE: "Documentazione propedeutica 1AE",
    preparatoryDocumentation: "Documentazione propedeutica",
    eneaPreparatoryDocumentation: "Documentazione propedeutica 2 ENEA",
    privateClosingDocumentation: "Documentazione privata di chiusura",
    publicClosingDocumentation: "Documentazione pubblica di chiusura",
    quotationDocumentation: "Preventivo",
    closingDocumentation: "Documentazione di chiusura",
    giureDocumentation: "Validazione",
    giureDocuments: "Doc. di Validazione",
    typology: "Tipologia",
    noModel: "Nessun modello",
    selfCompiledDownloadableTemplate: "Modello scaricabile autocompilato",
    uploadDocument: "Carica documento",
    refuse: "Rifiuta",
    refuseReasonDescription: "Motivazione del rifiuto (opzionale)",
    refuseDocument: "Rifiuta documento",
    deleteAttachment: "Elimina allegato",
    downloadTemplate: "Scarica template",
    downloadAutofilledTemplate: "Scarica template autocompilato",
    quotation: "In preventivo",
    giureEvaluation: "In Valutazione",
    giureEvaluationLabel: "Validazione Giure",
    giureAccepted: "Accettato dal Giure",
    giureList: "Lista Utenti Giure",
    editGiure: "Modifica utente Giure",
    createGiure: "Crea utente Giure",
    giure: "Giure",
    draft: "In compilazione",
    toCandidate: "Da candidare",
    candidated: "Candidata",
    approved: "Accettata",
    payOff: "Liquidata",
    refused: "Rifiutata",
    rowsDescription: "{0} di {1}",
    refusedRows: "{0} rifiutato",
    fabricatorDocumentation: "Documentazione del serramentista",
    valueInBill: "Valore in fattura",
    fabricatorPayOffShort: "Liquidazione serr.",
    serviceFeeInvoiced: "Quota serv. fatt.",
    areYouSure: "Sei sicuro di voler procedere?",
    preparatoryDocumentsShort: "Doc. propedeutica",
    _preparatoryDocumentations: "Doc. propedeutica",
    _approvedDocumentationsNumber: "Doc. approvati",
   _approvedDocuments_filterType: "Doc. approvati - Tipo filtro",
    closingDocumentsShort: "Doc. di chiusura",
    allStates: "Tutti gli stati",
    searchCode: "Ricerca codice",
    deleteDossier: "Elimina pratica",
    deleteNotAllowedForStatusPayOff: "Eliminazione non disponibile nello stato \"Liquidata\"",
    warning: "Attenzione",
    resetPassword: "Reset Password",
    confirmResetPassword: "Verrà impostata una nuova password ed inviata all'indirizzo mail dell'utente",
    changePasswordDescription: "E\' necessario impostare una password personale al primo accesso nel sistema",
    passwordConfirm: "Conferma password",
    birthDate: "Data di nascita (DD/MM/YYYY)",
    subjectType: "Tipologia",
    physicalperson: "Persona fisica",
    legalperson: "Persona giuridica",
    dossierManagementFee: "Quota per gestione pratica",
    birthPlace: "Luogo di nascita",
    fabricatorInformations: "Inforazioni serramenstista",
    appEnabled: "Abilitato all\'utilizzo dell\'app",
    createdBy: "Creato da",
    notActive: "Non attivo",
    documentName: "Nome documento",
    legalPersonInformations: "Informazioni rappresentante legale",
    loginEmail: "Email di accesso",
    all: "Tutti",
    customerType: "Tipo cliente",
    formChangeAlert: "Vuoi uscire senza salvare?",
    from: "Da",
    to: "A",
    _dateFrom: "Da",
    _dateTo: "A",
    dossierCounter: "N. pratiche",
    totalAmount: "Spesa complessiva",
    totalDecrease: "Detrazione spettante",
    legalRepresentativeInformations: "Informazioni rappresentate legale",
    subtitleLegalRepresentativeInformations: "Inserire i dati di nascita del rappresentante",
    createAdmin: "Crea amministratore",
    filterByDate: "Filtra per data",
    completedProfile: "Profilo completo",
    equals: "Uguale",
    like: "Contiene",
    confirmQuotation: "Conferma preventivo",
    confirmChangeDossierWithChangeFlag: "Cambiando lo stato della pratica i campi \"Candidata AE\" e \"Candidata ENEA\" verranno automaticamente impostati a SI. Sei sicuro di voler procedere?",
    documentLoaded: "Documento caricato correttamente",
    approve: "Approva",
    confirmChangeDossier: "La pratica è nello stato {0}. Sei sicuro di voler procedere?",
    collaboratorList: "Lista collaboratori",
    pairings: "Dispositivi",
    editCollaborator: "Modifica collaboratore",
    createCollaborator: "Crea collaboratore",
    collaborators: "Collaboratori",
    responsible: "Responsabile",
    devices: "Dispositivi",
    pending: "Da confermare",
    manufacturer: "Marca",
    startDate: "Data di inizio (DD/MM/YYYY)",
    endDate: "Data di fine (DD/MM/YYYY)",
    requestDate: "Data di richiesta",
    canBeCandidate: "Candidabile",
    candidateAE: "Candidata AE",
    candidateENEA: "Candidata ENEA",
    collaborator: "Collaboratore",
    editUserDevice: "Modifica dispositivo",
    createUserDevice: "Crea dispositivo",
    lowerThan: "Minore di",
    greaterThan: "Maggiore di",
    equalsTo: "Uguale a",
    statistics: "Statistiche",
    dossierExport: "Pratiche",
     codeValidationText: "Per favore inserisci il il codice di verifica che hai ricevuto",
    recoveryCodeSent: "Codice di verifica inviato",
    newPasswordText: "Inserisci una nuova password",
    "2020_50": "Pratiche 2020/50",
    "2021_50": "Pratiche 2021/50",
    "2022_1": "Pratiche 2022",
    "2022_BonusCasa_1": "Pratiche 2022/BC",
    "2022_BonusCasa_2": "Pratiche 2022/BC/2",
    "2021_BonusCasa": "Pratiche 2021/BC",
    "2022_BonusCasa": "Pratiche 2022/BC/no",
    "2022_50": "Pratiche 2022/50",
    "2023_75": "Pratiche 2023/75/BC",
    ecobonus2019: "Pratiche 2019",
    dossierTypes: "Tipologie pratiche",
    ALL: "Tutti",
    DOSSIER_2022_BONUS_CASA: "Pratiche 2022/BC/no",
    DOSSIER_2022_BONUS_CASA_1: "Pratiche 2022/BC",
    DOSSIER_2022_BONUS_CASA_2: "Pratiche 2022/BC/2",
    DOSSIER_2022_50: "Pratiche 2022/50",
    DOSSIER_2021_BONUS_CASA: "Pratiche 2021/BC",
    DOSSIER_2021_50: "Pratiche 2021/50",
    DOSSIER_2023_75: "Pratiche 2023/75",
    DOSSIER_2020_50: "Pratiche 2020/50",
    DOSSIER_2022_1: "Pratiche 2022",
    DOSSIER_2019: "Pratiche 2019",
    dossierType: "Tipologia pratica",
    OTHER: "Altro",
    quotationType: "Modello fornitura",
    climaticZone: "Zona climatica",
    surfaceSizeMq: "Superficie dell'intervento (Mq)",
    next: "Avanti",
    requiredFieldsAlert: "E' necessario compilare tutti i campi obblicatori prima di procedere",
    requiredSelectOptionAlert: "E' necessario selezionare la risposta prima di procedere",
    note: "Nota",
    agents: "Agenti",
    supervisors: "Supervisori",
    documentTypeNumber: "N. sequenza (se propedeutico)",
    documentTypeNumberGrid: "Sequenza",
    fabricatorCategoryId: "Categoria serramentista",
    customerId: "Cliente",
    fabricatorId: "Serramentista",
    confirmQuestionnaire: "Conferma questionario",
    desiredVat: "Imponibile desiderato",
    calculatedVat: "Imponibile per l'iniziativa",
    totalInvoiceVat: "Totale iva in fattura",
    priceQuotation: "Offerta",
    saveQuotation: "Salva preventivo",
    simulateFinancing: "Calcola rata",
    fees: "Rate",
    calculate: "Calcola",
    desiredVatRequired: "Imponibile desiderato obbligatorio",
    questionnaire: "Questionario",
    calculator: "Calcolatore",
    vat22Amount: "Importo con iva al 22%",
    vat10Amount: "Importo con iva al 10%",
    requiredFieldsRequired: "E' necessario compilare i campi obbligatori",
    subtitleCalculatorDialog: "La somma degli importi deve essere uguale all'imponibile per l'iniziativa",
    simulatedFeeAmount: "Importo rata approssimativo",
    billValues: "Valori per fattura",
    requiredPolicy: "Clausole obbligatorie",
    policy: "Clausole",
    serviceCosts: "Costi del servizio",
    serviceCostsLabel: "Costi del servizio al netto dell'iva",
    amount: "Importo",
    privacy: "Privacy",
    passwordSuccessfulChanged: "Password modificata con successo",
    passwordResetChanged: "Password resettata con successo",
    uploadDocumentInfo: "Scarica il modulo e scansionalo o ricaricalo firmato dal cliente",
    upload: "Carica",
    document: "Documento",
    blindsPresentsType: "Fornirà anche oscuranti?",
    insertValue: "Inserisci valore",
    YES: "SI",
    NO: "NO",
    supervisor: "Supervisore",
    refuseHistory: "Storico rifiuti",
    bothBlind: "Si - Ma non su tutti gli infissi",
    enableGiureEvaluation: "Abilita valutazione Giure",
    youNeedToSaveFirst: "E' necessario salvare le modifiche effettuate prima di procedere con la conferma del oreventivo. Vuoi salvare?",
    enableKPMGReview: "Abilita alla revisione KPMG",
    areYouSureSendKPMG: "Sei sicuro di voler inviare le pratiche in revisione a KPMG?",
    KPMGStatus: "Stato KPMG",
    refusedByKPMG: " (Rifiutato da KPMG)",
    onHold: "In attesa",
    toSend: "Da inviare",
    sent: "Inviata",
    takenMeasuresSum: "Presa misure/elab tavole/elab dich. tecnica e gest. Documenti",
    enabledForKPMG: "Doc. KPMG ({0})",
    validationTransmission: "Validazione/Transmissione",
    closing: "Chiusura",
    kpmgGroup: "Gruppo KPMG",
    kpmgSentDate: "Inviato il {0}",
    processing: "In lavorazione",
    kpmgDocumentGroupsStatus: "Stato gruppi di documenti KPMG",
    kpmgStatus: "Stato KPMG",
    maxDossierNumber: "Numero massimo di pratiche (0 = Infinito)",
    kpmg: "KPMG",
    receiveByKPMG: "Ricevi da KPMG",
    enableImmediateKPMGReview: "Invia immediatamente a KPMG",
    sendToKPMG: "Invia a KPMG",
    workCompletionDate: "Data fine lavori",
    workCompletionDateRequired: "E' necessario inserire la data di fine lavori e salvare la pratica per caricare questo file",
    activeDossierType: "Attiva per le tipologie di dossier",
    priority: "Priorità",
    operationType: "Tipo intervento",
    estateTypeAndUse: "Tipo ed uso dell'immobile",
    buildingTitle: "Titolo Edilizio",
    serviceProvided: "Servizio fornito",
    continuationWork: "Prosecuzione di lavori iniziati in anni precedenti",
    continuationWorkValue: "Ammontare (iva inclusa) delle spese già sostenute (€)",
    continuationWorkValueNoVat: "Ammontare (iva esclusa) delle spese già sostenute (€)",
    companyCustomType: "Tipologia Azienda",
    noQuestionnaireFound: "Nessun questionario disponibile",
    noAnswer: "Nessuna risposta",
    noContinuationWorkMessage: "Non è stata indicata l'eventuale presenza di lavori agevolabili Bonus Casa iniziati in anni precedenti. Se presenti, il preventivo potrebbe subire variazioni.",
    communicationENEA: "Comunicazione ENEA",
    privacyNotLoaded: "Documento di privacy non ancora caricato",
    buildingAddress: "Indirizzo e civico immobile",
    buildingCity: "Città immobile",
    buildingProvince: "Provincia immobile",
    questionnaireNotUpload: "E' necessario compilare prima il questionario",
    guide: "Guida",
    guideDocumentNotLoaded: "Il documento non è stato caricato",
    giureValidationDocument: "Validazione Giure",
    giureCanEditDocumentAttachment: "Modifiche per utenti Giure",
    _approvedClosingDocuments_filterType: "Doc. di chiusura caricati - Tipo filtro",
    _approvedGiureDocuments_filterType: "Doc. di Validazione caricati - Tipo filtro",
    _approvedDocumentationsClosingNumber: "Doc. di chiusura caricati",
    _uploadClosingDocuments_filterType: "Doc. caricati - Tipo filtro",
    _uploadGiureDocuments_filterType: "Doc. Validazione caricati - Tipo filtro",
    _uploadDocumentationsClosingNumber: "Doc. caricati",
    _approvedDocumentationsGiureNumber: "Doc. di Validazione caricati",
    _uploadDocumentationsGiureNumber: "Doc. caricati",
    _documentTypeClosingSet: "Tipologia Documento",
    _documentTypeGiureSet: "Tipologia Documento",
    dl157: "Decreto legge 157",
    freeConstruction: "Edilizia libera",
    totalCostHigherTenThousand: "Costo complessivo interventi > € 10.000?",

    proprietary: "Proprietario, comproprietario o nudo proprietario",
    realEnjoymentLaw: "Titolare di un reale diritto di godimento (es. usufrutto, uso, abitazione o superficie)",
    lessee: "Detentore (locatari, comodatari) dell'immobile derivante da contratto (di locazione, comodato) regolarmente registrato",
    cooperativeMembers: "Soci di cooperative divise e indivise",
    individual_entrepreneurs: "Imprenditori individuali, per gli immobili non rientranti fra i beni strumentali o merce",
    article5OfTiur: "Soggetti indicati nell’articolo 5 del Tuir, che producono redditi in forma associata, alle stesse condizioni previste per gli imprenditori individuali",
    cohabitingFamilyMember: "Familiari conviventi con il titolare del diritto di godimenti dell'immobile (appartenente all'ambito privatistico nel quale si svolge la convivenza)",
    separatedSpouse: "Coniuge separato assegnatario dell'immobile intestato all'altro coniuge",
    deFactoCohabitants: "Conviventi di fatto di cui all'art. 1, commi 36 e 37, della Legge n.76 del 2016",
    moreUxorio: "Il convivente more uxorio, non proprietario dell’immobile oggetto degli interventi né titolare di un contratto di comodato, per le spese sostenute a partire dal 1° gennaio 2016",
    realEstateBuyer: "Acquirente dell’immobile che ha diritto all’agevolazione a seguito di contratto preliminare di vendita (compromesso)",
    iacpPropertyBuyer: "Titolare di immobile IACP",
    promissaryBuyer: "Promissario acquirente",
    buildingTitleOpeningDate: "Data apertura titolo edilizio",
    isDelimitingHeatedWithNotHeated: "L'intervento delimita un'area riscaldata da una non riscaldata?"

};

export function setLanguage(language_) {
    language = language_
}

export function getLanguage() {
    return language
}

export default function M(key) {
    if (strings[language] && strings[language][key]) {
        return strings[language][key]
    } else {
        logger.w("String not found for language " + language + ":", key)
        return key
    }
}
