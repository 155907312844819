import React from "react"

export default class HeaderExtra extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return null
	}
}