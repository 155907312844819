"use strict";

import * as config from "../framework/config";
import {get} from "./utils";
import * as utils from "./utils";

export function getNotifications() {
    let url = config.get("notifications.url")
    return utils.get(url)
}

export function countUnread() {
    let url = config.get("notifications.url") + "/countUnread"
    return utils.get(url)
}