"use strict"

import {connect} from "../../utils/aj";
import {DossierStore} from "../../../stores/dossier";
import {attachDocument, hideAddDocumentDialog} from "../../../actions/dossier";
import React from "react";
import {MULTI_FILE_MODE_SINGLE, NewMultiFile} from "../forms";
import {Dialog} from "../dialogs";
import M from "../../../strings";
import {getLoggedUser, getSessionToken, isSuperuser} from "../../../api/session";
import * as config from "../../../framework/config";
import {safeGet, format} from "../../../utils/lang";
import {DocumentTypeTypology, USER_ROLE} from "../../../model/vars";

export class DossierAddDocumentDialog extends React.Component {

    constructor(props) {
        super(props)

        connect(this, DossierStore, {showAddDocumentDialog:false, documentType: null});
    }

    componentDidUpdate() {
        if (safeGet(this.state, "attachCompleted", false)) {
            this.onClose();
        }
    }

    onValueChange(newFile) {
        attachDocument({
            dossierType: this.props.model.get("type"),
            dossierId: this.props.model.get("id"),
            documentTypeId: this.state.documentType.id,
            attachment: newFile
        })
    }

    onClose() {
        hideAddDocumentDialog();
    }

    onShow() {
        //Resetta dropzone
        $(".dz-preview").remove();
    }

    downloadTemplate(e){
        e.preventDefault()
        window.open(config.get("document.url") + "/generate?dossierType=" + this.props.model.get("type") + "&dossierId=" + this.props.model.get("id") + "&documentTypeId=" + this.state.documentType.id + "&empty=true&__TOKEN=" + encodeURIComponent(getSessionToken()))
    }

    downloadSelfCompiledTemplate(e){
        e.preventDefault()
        window.open(config.get("document.url") + "/generate?dossierType=" + this.props.model.get("type") + "&dossierId=" + this.props.model.get("id") + "&documentTypeId=" + this.state.documentType.id + "&empty=false&__TOKEN=" + encodeURIComponent(getSessionToken()))
    }

    render() {
        let model = this.props.model;
        let documentType = safeGet(this.state, "documentType", null);
        let loading = safeGet(this.state, "loading", false);
        let typology = safeGet(documentType, "typology", null);
        let userRole = getLoggedUser().roles[0].role;
        let title = format("{0} {1}", M("upload"), safeGet(documentType, "description", M("document")))
        let information = safeGet(documentType, "information", null)

        return (
            <Dialog onShow={this.onShow.bind(this)} onClose={this.onClose.bind(this)} ref="addDocument" icon={"zmdi zmdi-upload"} title={title}  hidden={!this.state.showAddDocumentDialog} large="false">                
                {!loading ? <div>
                    <div className="row" style={{marginBottom: "30px"}}>
                        {information && <p className="p-l-15 p-r-15">{information}</p> }
                        {typology !== DocumentTypeTypology.NO_MODEL.value && isSuperuser() && <div className="col-sm">
                            <button style={{width: "100%"}} onClick={this.downloadTemplate.bind(this)} className="btn btn-primary">
                                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-download m-r-10" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M.5 8a.5.5 0 0 1 .5.5V12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V8.5a.5.5 0 0 1 1 0V12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8.5A.5.5 0 0 1 .5 8z"/>
                                    <path fillRule="evenodd" d="M5 7.5a.5.5 0 0 1 .707 0L8 9.793 10.293 7.5a.5.5 0 1 1 .707.707l-2.646 2.647a.5.5 0 0 1-.708 0L5 8.207A.5.5 0 0 1 5 7.5z"/>
                                    <path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0v-8A.5.5 0 0 1 8 1z"/>
                                </svg>
                                {M("downloadTemplate")}
                            </button>
                        </div>}
                        {typology === DocumentTypeTypology.SELF_COMPILED_DOWNLOADABLE_TEMPLATE.value &&
                        <div className="col-sm">
                            <div className="col-sm">
                                <button style={{width: "100%"}} onClick={this.downloadSelfCompiledTemplate.bind(this)} className="btn btn-primary">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-download m-r-10" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M.5 8a.5.5 0 0 1 .5.5V12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V8.5a.5.5 0 0 1 1 0V12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8.5A.5.5 0 0 1 .5 8z"/>
                                        <path fill-rule="evenodd" d="M5 7.5a.5.5 0 0 1 .707 0L8 9.793 10.293 7.5a.5.5 0 1 1 .707.707l-2.646 2.647a.5.5 0 0 1-.708 0L5 8.207A.5.5 0 0 1 5 7.5z"/>
                                        <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0v-8A.5.5 0 0 1 8 1z"/>
                                    </svg>    
                                    {M("downloadAutofilledTemplate")}
                                </button>
                            </div>
                        </div>}
                    </div>
                    <div className="col-12 zero-padding" >
                        <NewMultiFile model={model} disableInitOnModelLoad={true} onValueChange={this.onValueChange.bind(this)} mode={MULTI_FILE_MODE_SINGLE}/>
                    </div>
                </div>
                :
                    <div className="preloader" style={{display: 'block'}}>
                        <svg className="pl-circular" viewBox="25 25 50 50">
                            <circle className="plc-path" cx="50" cy="50" r="20" />
                        </svg>
                    </div>
                }
            </Dialog>
        )

    }
}