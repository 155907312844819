"use strict"

import {alert, hideLoader, showLoader, toast} from "../plugins";
import {createAsyncAction} from "../utils/ajex";
import * as responses from "../api/responses";
import {
    CONFIRM_QUOTATION_2022,
    CREATE_DOSSIER_2022,
    EDIT_DOSSIER_2022,
    GENERATE_QUOTATION_AMOUNTS_2022,
    GENERATE_QUOTATION_DOSSIER_2022,
} from "./types";
import {freeEntities, getEntity, manualSavedEntities} from "./entities";
import M from "../strings";
import * as aj from "../aj";
import * as ui from "../web/utils/ui";
import {getDossierEntityNameFromType} from "../model/vars";
import {format} from "../utils/lang";
import * as DossierApi from "../api/dossier_2022";


export const generateQuotationDossier_2022 = createAsyncAction(GENERATE_QUOTATION_DOSSIER_2022, data => {
    showLoader();
    aj.dispatch({
        type: GENERATE_QUOTATION_DOSSIER_2022
    });

    DossierApi.generateQuotationDossier_2022(data.dossierType, data.dossierDTO)
        .then(response => {
            hideLoader();
            generateQuotationDossier_2022.complete({downloadUrl: response.value})
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            generateQuotationDossier_2022.fail()
        })
});

export const createDossier_2022 = createAsyncAction(CREATE_DOSSIER_2022, data => {

    showLoader();

    aj.dispatch({
        type: CREATE_DOSSIER_2022
    })
    DossierApi.createDossier_2022(data.dossierType, data.dossierDTO)
        .then(response => {
            hideLoader();
            toast(M("saveComplete"))
            freeEntities({discriminator: data.discriminator});
            createDossier_2022.complete()
            ui.navigate("/entities/" + getDossierEntityNameFromType(data.dossierType) + "/" + response.value.id + "?type=" + data.dossierType)
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            createDossier_2022.fail()
        })
});

export const confirmQuotation_2022 = createAsyncAction(CONFIRM_QUOTATION_2022, data => {
    aj.dispatch({
        type: CONFIRM_QUOTATION_2022
    })

    showLoader();
    DossierApi.confirmQuotation_2022(data.dossierType, data.dossierDTO)
        .then(response => {
            hideLoader();
            toast("Operazione eseguita con successo!")
            confirmQuotation_2022.complete({dossier: response.value});
            let entityName = getDossierEntityNameFromType(data.dossierType);
            getEntity({discriminator: "entity_form_" + entityName, entity: entityName, id: response.value.id});
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            confirmQuotation_2022.fail()
        })
});

export const generateQuotationAmounts_2022 = createAsyncAction(GENERATE_QUOTATION_AMOUNTS_2022, (data, dossierType) => {
    DossierApi.generateQuotationAmounts_2022(dossierType, data)
        .then(response => {
            generateQuotationAmounts_2022.complete({priceCalculatorSheet: response.value});
        })
        .catch(e => {
            alert("Attenzione!", format(responses.msg(e), e.data), "error");
            generateQuotationAmounts_2022.fail()
        })
        .finally(() => {
            hideLoader()
        })
});

export const editDossier_2022 = createAsyncAction(EDIT_DOSSIER_2022, data => {

    showLoader();

    aj.dispatch({
        type: EDIT_DOSSIER_2022
    })
    DossierApi.editDossier_2022(data.dossierType, data.dossierDTO)
        .then(response => {
            hideLoader();
            let entityName = getDossierEntityNameFromType(data.dossierType);
            toast(M("saveComplete"))
            manualSavedEntities({discriminator: "entity_form_" + entityName});
            editDossier_2022.complete()
            getEntity({discriminator: "entity_form_" + entityName, entity: entityName, id: data.dossierDTO.dossierId});
        })
        .catch(e => {
            hideLoader()
            alert("Attenzione!", responses.msg(e), "error");
            editDossier_2022.fail()
        })
});